import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  QueryList,
  ViewChildren,
  ViewContainerRef,
} from "@angular/core";
import { Params } from "@angular/router";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { map, skipWhile, take, tap, withLatestFrom } from "rxjs/operators";
import { Member } from "src/app/shared/interfaces/member";
import { RankingStudent } from "src/app/shared/interfaces/ranking-student";
import { UserSummary } from "src/app/shared/interfaces/user-summary";
import * as fromDashboard from "src/app/store/actions/dashboard.actions";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";
import * as fromDashboardStore from "src/app/store/reducers/dashboard.reducer";

const TABLE_CAROUSEL_DURATION_MS = 400;

interface RankingWidgetCard {
  name: string;
  position: number;
  unitLabel: string;
  rankingType: keyof UserSummary;
  ranking: {
    results: Array<RankingStudent>;
  };
}

@Component({
  selector: "ranking-widget",
  templateUrl: "./ranking-widget.component.html",
  styleUrls: ["./ranking-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RankingWidgetComponent implements AfterViewInit, OnDestroy {
  @ViewChildren("positionNumber", { read: ViewContainerRef })
  numbers: QueryList<ViewContainerRef>;

  member$: Observable<Member> = this.store
    .select((state) => state.account.member)
    .pipe(
      skipWhile((member) => !member),
      take(1),
    );
  rankings$: Observable<Array<RankingWidgetCard>> = this.store
    .select((state) => state.student.dashboard.rankingSummary)
    .pipe(
      withLatestFrom(this.member$),
      map(([summary, member]: [UserSummary, Member]) => {
        if (summary === null) {
          return [];
        }

        const rankings = [];

        rankings.push({
          name: "GLOBAL.RANKING_WIDGET.DAILY",
          position: this.getPoints(summary.days, member),
          unitLabel: "RANKING.TABLE.DAYS",
          icon: "analysis",
          rankingType: "days",
          ranking: {
            results: summary.days,
          },
        });

        rankings.push({
          name: "GLOBAL.RANKING_WIDGET.POINTS",
          position: this.getPosition(summary.points, member),
          unitLabel: "RANKING.TABLE.POINTS",
          icon: "coins",
          rankingType: "points",
          ranking: {
            results: summary.points,
          },
        });

        rankings.push({
          name: "GLOBAL.RANKING_WIDGET.QUIZ",
          position: this.getPosition(summary.quiz, member),
          unitLabel: "RANKING.TABLE.POINTS",
          icon: "question-circle",
          rankingType: "quiz",
          ranking: {
            results: summary.quiz,
          },
        });

        return rankings;
      }),
    );
  summaryCompleted$: Observable<boolean> = this.store.select(
    (state) => state.student.dashboard.getRankingSummaryCompleted,
  );
  summaryFailed$: Observable<boolean> = this.store.select(
    (state) => state.student.dashboard.getRankingSummaryFailed,
  );
  mouseOver$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  numbersSubscription: Subscription;
  tableCarouselDuration: number = TABLE_CAROUSEL_DURATION_MS;

  constructor(
    private cd: ChangeDetectorRef,
    private store: Store<{
      account: fromAccountStore.AccountState;
      student: { dashboard: fromDashboardStore.DashboardState };
    }>,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new fromDashboard.GetRankingSummary());
  }

  ngAfterViewInit(): void {
    this.numbersSubscription = this.numbers.changes
      .pipe(
        withLatestFrom(this.rankings$),
        tap(([_, rankings]: [ViewContainerRef, Array<RankingWidgetCard>]) => {
          this.setPositionsOffset(rankings);
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.mouseOver$.unsubscribe();
    this.numbersSubscription.unsubscribe();
  }

  getQueryParams(ranking): Params {
    return { ranking_type: ranking.rankingType };
  }

  private setPositionsOffset(rankings: Array<RankingWidgetCard>): void {
    if (this.numbers) {
      this.numbers.forEach((positionNumber, index) => {
        const position: number = rankings[index].position;
        const offset: number = 100;

        (
          positionNumber.element.nativeElement as HTMLAnchorElement
        ).style.setProperty("--position-offset", String(position + offset));
      });

      this.cd.markForCheck();
    }
  }

  private getPosition(ranking: Array<RankingStudent>, member: Member): number {
    const student = ranking.find((student) => student.id === member.id);
    return student ? student.position : 0;
  }

  private getPoints(ranking: Array<RankingStudent>, member: Member): number {
    const student = ranking.find((student) => student.id === member.id);
    return student ? student.points : 0;
  }

  @HostListener("mouseenter")
  private onMouseEnter(): void {
    this.mouseOver$.next(true);
  }

  @HostListener("mouseleave")
  private onMouseLeave(): void {
    this.mouseOver$.next(false);
  }
}
