import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { Certificate } from "src/app/shared/interfaces/certificate";

export const certificatePrefix = "[Certificates List]";

const certificatesListActions = {
  request: `${certificatePrefix} Load`,
  requestSuccess: `${certificatePrefix} Load success`,
  requestFail: `${certificatePrefix} Load fail`,
};

export const certificatesLoad = createAction(certificatesListActions.request);

export const certificatesLoadSuccess = createAction(
  certificatesListActions.requestSuccess,
  props<{ certificates: Certificate[] }>(),
);

export const certificatesLoadFail = createAction(
  certificatesListActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
