import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { Guid } from "../../../types/guid";
import { Nillable } from "../../../types/nillable";
import { CourseExerciseAnswerTableSortKey } from "./types/course-exercise-answer-table-sort-key";
import { IconName } from "../../../types/icon-name";
import { isNil } from "lodash-es";
import { SortOrder } from "../../../lodash/enums/sort-order";
import { AnswerResponse } from "../../../interfaces/answer-response";

@Component({
  selector: "course-exercise-answer-table",
  templateUrl: "./course-exercise-answer-table.component.html",
  styleUrls: ["./course-exercise-answer-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseExerciseAnswerTableComponent {
  @Output() downloadEvent = new EventEmitter<{ id: Guid; filename: string }>();
  @Output() downloadBulkEvent = new EventEmitter<void>();
  @Output() failAnswerEvent = new EventEmitter<Guid>();
  @Output() passAnswerEvent = new EventEmitter<Guid>();
  @Output() sortEvent = new EventEmitter<{
    key: CourseExerciseAnswerTableSortKey;
    order: SortOrder;
  }>();

  @Input() answers: Nillable<AnswerResponse[]> = [];
  @Input() bulkLoader: boolean = false;

  private sortOrder = SortOrder.Descending;

  private readonly sortEntities = new Map<
    CourseExerciseAnswerTableSortKey,
    IconName
  >([
    ["uploadedAt", "arrow-unsorted"],
    ["filename", "arrow-unsorted"],
    ["username", "arrow-unsorted"],
  ]);

  isPending(answer: AnswerResponse): boolean {
    return isNil(answer.review);
  }

  isPassed(answer: AnswerResponse): boolean {
    return answer.review === true;
  }

  isFailed(answer: AnswerResponse): boolean {
    return answer.review === false;
  }

  sortOrderIcon(key: CourseExerciseAnswerTableSortKey): IconName {
    return this.sortEntities.get(key);
  }

  handleSortClick(key: CourseExerciseAnswerTableSortKey): void {
    this.setSortOrderIcon(key);
    this.toggleSortOrder();

    this.sortEvent.emit({ key: key, order: this.sortOrder });
  }

  handleDownloadClick(answer: AnswerResponse): void {
    this.downloadEvent.emit({ filename: answer.filename, id: answer.id });
  }

  handleBulkDownloadClick(): void {
    this.downloadBulkEvent.emit();
  }

  handleFailAnswerClick(answer: AnswerResponse): void {
    this.failAnswerEvent.emit(answer.id);
  }

  handlePassAnswerClick(answer: AnswerResponse): void {
    this.passAnswerEvent.emit(answer.id);
  }

  private setSortOrderIcon(key: CourseExerciseAnswerTableSortKey): void {
    this.sortEntities.forEach((_, key) => {
      this.sortEntities.set(key, "arrow-unsorted");
    });

    switch (key) {
      case "uploadedAt":
      case "filename":
      case "username": {
        if (this.isDescending()) {
          this.sortEntities.set(key, "arrow-sorted-up");

          return;
        }

        this.sortEntities.set(key, "arrow-sorted-down");

        break;
      }
    }
  }

  private toggleSortOrder(): void {
    if (this.isDescending()) {
      this.sortOrder = SortOrder.Ascending;

      return;
    }

    this.sortOrder = SortOrder.Descending;
  }

  private isAscending(): boolean {
    return this.sortOrder === SortOrder.Ascending;
  }

  private isDescending(): boolean {
    return this.sortOrder === SortOrder.Descending;
  }
}
