<span class="countdown" [ngClass]="color" [ngStyle]="{'width': size, 'height': size}">
  <span class="countdown-base">
    <span class="countdown-dot"></span>
  </span>

  <span class="countdown-base">
    <span class="countdown-dot"></span>
  </span>

  <span class="countdown-base">
    <span class="countdown-dot"></span>
  </span>

  <span class="countdown-base">
    <span class="countdown-dot"></span>
  </span>

  <span class="countdown-base">
    <span class="countdown-dot"></span>
  </span>

  <span class="countdown-base">
    <span class="countdown-dot"></span>
  </span>

</span>