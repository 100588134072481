import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { FlaggedExerciseList } from "src/app/shared/interfaces/flagged-exercises";

export const coursePrefix = "[Flagged Exercise List]";

const flaggedExerciseListActions = {
  request: `${coursePrefix} Load`,
  requestActiveSuccess: `${coursePrefix} Load success`,
  requestFail: `${coursePrefix} Load fail`,
};

export const flaggedExerciseListLoad = createAction(
  flaggedExerciseListActions.request,
  props<{
    courseId: string;
    groupId: string;
  }>(),
);

export const flaggedExerciseListLoadSuccess = createAction(
  flaggedExerciseListActions.requestActiveSuccess,
  props<{ exercises: FlaggedExerciseList[] }>(),
);

export const flaggedExerciseListFailure = createAction(
  flaggedExerciseListActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
