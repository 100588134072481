<tutorial-text-box
  *ngIf="
    (tutorial$ | async) === TutorialTeacherSteps.GROUP_DASHBOARD &&
    (isTeacher$ | async)
  "
  [customStyle]="{
    top: '10px',
    left: '30%',
    'z-index': '1008'
  }"
  [title]="'TUTORIAL_TEACHER_DATA.GROUPS_BTN_LABEL' | translate"
  [message]="'TUTORIAL_TEACHER_DATA.GROUP_DASHBOARD' | translate"
  [step]="TutorialTeacherSteps.GROUP_DASHBOARD"
/>
<tutorial-text-box
  *ngIf="
    (tutorial$ | async) === TutorialTeacherSteps.COURSE_DASHBOARD &&
    (isTeacher$ | async)
  "
  [customStyle]="{
    top: '70px',
    left: '50%'
  }"
  [title]="'TUTORIAL_TEACHER_DATA.COURSE_VIEW_LABEL' | translate"
  [message]="'TUTORIAL_TEACHER_DATA.COURSE_VIEW' | translate"
  [step]="TutorialTeacherSteps.COURSE_DASHBOARD"
/>
<div
  class="column-layout d-block d-lg-flex flex-lg-row-reverse"
  [ngClass]="{
    'tutorial-border':
      (tutorial$ | async) === TutorialTeacherSteps.GROUP_DASHBOARD ||
      ((tutorial$ | async) === TutorialTeacherSteps.COURSE_DASHBOARD &&
        (isTeacher$ | async))
  }"
  [style.justify-content]="position"
>
  <div class="column right-column column-{{ rightColumnWidth }}">
    <ng-content select="[right-column]"></ng-content>
  </div>

  <div class="column left-column column-{{ leftColumnWidth }}">
    <ng-content select="[left-column]"></ng-content>
  </div>
</div>
