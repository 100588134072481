import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import {
  invitedGroupStudentsListLoad,
  invitedGroupStudentsListLoadFail,
  invitedGroupStudentsListLoadSuccess,
} from "../../actions/admin/invited-group-students.actions";
import { GroupStudents } from "src/app/shared/interfaces/orgadmin/invited-students";

export interface InvitedGroupStudentsListState {
  students: GroupStudents;
  error: HttpErrorResponse | null;
}

const initialState: InvitedGroupStudentsListState = {
  students: null,
  error: null,
};

export const invitedGroupStudentsListReducer = createReducer(
  initialState,
  on(
    invitedGroupStudentsListLoad,
    (state): InvitedGroupStudentsListState => ({
      ...state,
      error: null,
    }),
  ),
  on(
    invitedGroupStudentsListLoadSuccess,
    (state, action): InvitedGroupStudentsListState => ({
      ...state,
      students: action.invitedStudents,
      error: null,
    }),
  ),
  on(
    invitedGroupStudentsListLoadFail,
    (state, { error }): InvitedGroupStudentsListState => ({
      ...state,
      error,
    }),
  ),
);
