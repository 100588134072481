import { Injectable } from "@angular/core";
import { NavbarNavItem } from "../models/navbar-nav-item";

@Injectable({
  providedIn: "root",
})
export class NavbarNavStudentProvider {
  getRightSideItems(): Array<NavbarNavItem> {
    return [
      {
        label: "GLOBAL.NAVBAR.NAV.COURSES",
        icon: "mortar-board",
        iconSet: "typcn",
        route: "/student/courses",
      },
      {
        label: "GLOBAL.NAVBAR.NAV.RANKING",
        icon: "trophy",
        iconSet: "uil",
        route: "/student/ranking",
      },
    ];
  }

  getLeftSideItems(): Array<NavbarNavItem> {
    return [
      {
        label: "GLOBAL.NAVBAR.NAV.DASHBOARD",
        icon: "home-outline",
        iconSet: "typcn",
        route: "/student/dashboard",
      },
    ];
  }
}
