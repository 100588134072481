import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'navbar-element',
  templateUrl: './navbar-element.component.html',
  styleUrls: ['./navbar-element.component.scss']
})
export class NavbarElementComponent {
  @Output()
  toggleFunction: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggle() {
    this.toggleFunction.emit();
  }

  constructor() {}
}
