import { Component } from "@angular/core";

@Component({
  selector: "modal-overlay",
  template: `
    <div class="overlay-wrapper d-flex align-items-center">
      <div
        class="overlay-body d-flex align-items-center justify-content-center"
      >
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ["./modal-overlay.component.scss"],
  host: {
    class: ""
  }
})
export class ModalOverlayComponent {
  constructor() {}
}
