<ng-container
  *ngIf="{
    list: quizSolutionsList$ | async,
    listCompleted: getQuizSolutionsListCompleted$ | async,
    item: quizSolutionItem$ | async,
    itemCompleted: getQuizSolutionItemCompleted$ | async
  } as quizHistory"
>
  <history-modal-frame
    [list]="quizHistory.list"
    [item]="quizHistory.item"
    [listCompleted]="quizHistory.listCompleted"
    [itemCompleted]="quizHistory.itemCompleted"
    [mode]="listMode.mode"
    noSelectedText="COURSES.COURSE.EXERCISE.QUIZ_HISTORY.SELECT_FROM_LIST"
    noSubmissionsText="COURSES.COURSE.EXERCISE.QUIZ_HISTORY.NO_SUBMISSIONS"
  >
    <history-modal-header
      title="COURSES.COURSE.EXERCISE.QUIZ_HISTORY.HISTORY_MODAL_TITLE"
      [suffix]="titleSuffix"
    >
    </history-modal-header>

    <item-list-mode-selector
      [(mode)]="listMode.mode"
      [showBackButton]="data.isTeacher && data.activeGroup"
      left-column
    >
      <history-modal-students-list
        [students]="data.students"
        mySubmissionsText="COURSES.COURSE.EXERCISE.QUIZ_HISTORY.MY_SUBMISSIONS"
        (studentClicked)="studentClicked($event)"
        student-list
      ></history-modal-students-list>

      <history-modal-item-list
        [list]="quizHistory.list"
        [activeItemId]="quizHistory.item ? quizHistory.item.id : null"
        [statusClassFn]="getStatusClass(quizHistory.list)"
        [statusIconClassFn]="getStatusIconClass(quizHistory.list)"
        [itemText]="itemText"
        [itemDate]="itemDate"
        loadMoreText="COURSES.COURSE.EXERCISE.QUIZ_HISTORY.LOAD_MORE_BUTTON"
        (itemClicked)="getHistoryItem($event)"
        (nextPage)="nextPage($event)"
        item-list
      >
        <ng-template #itemText let-item>
          {{ "COURSES.COURSE.EXERCISE.QUIZ_HISTORY.SCORE" | translate }}:
          {{ item.score }}/{{ quizHistory.list.quiz.points_max }}
        </ng-template>

        <ng-template #itemDate let-item>
          {{
            "COURSES.COURSE.EXERCISE.QUIZ_HISTORY." +
              (item.score >= quizHistory.list.quiz.pass_mark
                ? "PASSED"
                : "FAILED") | translate
          }}
          {{ item.end ? (item.end | date) : "" }}
        </ng-template>
      </history-modal-item-list>
    </item-list-mode-selector>

    <quiz-history-modal-item
      [item]="quizHistory.item"
      [list]="quizHistory.list"
      [courseId]="data.courseId"
      [isTeacher]="data.isTeacher"
      [activeGroup]="data.activeGroup"
      [exerciseId]="data.exercise.id"
      content
    ></quiz-history-modal-item>

    <button class="theme-hprimary" (click)="close.emit('close')" footer>
      {{ "GLOBAL.CLOSE_BUTTON" | translate }}
    </button>
  </history-modal-frame>
</ng-container>
