<div class="validation-message" [ngClass]="theme">
  <ng-container *ngTemplateOutlet="customTemplate || default">
  </ng-container>
</div>

<ng-template #default>
  <p class="label validation-message-text">
    <span class="warning-icon typcn typcn-warning-outline"></span>
    <ng-content></ng-content>
  </p>
</ng-template>