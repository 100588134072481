import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Guid } from "src/app/shared/types/guid";
import { AnswerPayload } from "../../../interfaces/answer-payload";
import { Exercise } from "../../../interfaces/exercise";
import { ExerciseControl } from "../../../interfaces/exercise-control";
import { HelpRequest } from "../../../interfaces/help-request";
import { AnswerType } from "src/app/shared/types/answer-type";

@Component({
  selector: "course-exercise-answer",
  templateUrl: "./course-exercise-answer.component.html",
  styleUrls: ["./course-exercise-answer.component.scss"],
})
export class CourseExerciseAnswerComponent implements OnInit, OnChanges {
  @Input() courseId: Guid;
  @Input() activeGroup: Guid;
  @Input() exercise: Exercise;
  @Input() topicId: Guid;

  @Input("teacher") isTeacher = false;

  @Input()
  emitters: {
    [key: string]: EventEmitter<ExerciseControl | HelpRequest | AnswerPayload>;
  };

  answerForm: UntypedFormGroup;
  isAnswerSent = false;

  AnswerType: typeof AnswerType = AnswerType;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.createAnswerForm();
  }

  ngOnChanges() {
    this.isAnswerSent = false;
  }

  private createAnswerForm() {
    this.answerForm = this.formBuilder.group({
      answer: ["", [Validators.required]],
    });
  }

  submitAnswer() {
    if (this.answerForm.valid) {
      this.isAnswerSent = true;

      this.emitters["answer"].emit({
        request: this.answerForm.value,
        CourseId: this.courseId,
        GroupId: this.isTeacher ? null : this.activeGroup,
        ExerciseId: this.exercise.id,
        TopicId: this.topicId,
        was_passed_before: this.exercise.was_passed_before
      });
    }
  }
}
