import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { isNil } from "lodash-es";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";
import { Member } from "../../interfaces/member";
import { Nillable } from "../../types/nillable";
import { Router } from "@angular/router";

@Component({
  selector: "logo",
  templateUrl: "./logo.component.html",
  styleUrls: ["./logo.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  logoLink$: Observable<string | null> = this.store
    .select((state) => state.account.member)
    .pipe(
      map((member: Nillable<Member>) => {
        if (isNil(member)) {
          return "/";
        }

        if (member.is_orgadmin && this.router.url.startsWith("/orgadmin")) {
          return "/orgadmin/";
        }

        if (member.is_teacher && this.router.url.startsWith("/teacher")) {
          return "/teacher/";
        }

        if (!member.is_teacher && this.router.url.startsWith("/student")) {
          return "/student/";
        }

        if (member.is_teacher && this.router.url.startsWith("/ide/editor")) {
          return "/teacher/";
        }

        if (!member.is_teacher && this.router.url.startsWith("/ide/editor")) {
          return "/student/";
        }

        return null;
      }),
    );

  constructor(
    private store: Store<{ account: fromAccountStore.AccountState }>,
    private router: Router,
  ) {}
}
