import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY, of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { AdminService } from "src/app/shared/services/admin/admin.service";
import {
  createTeamTeachers,
  createTeamTeachersFail,
} from "../../actions/admin/create-team-teachers.actions";
import { courseTeachersListLoad } from "../../actions/admin/course-teachers.actions";
import {
  editTeamTeachers,
  editTeamTeachersFail,
  editTeamTeachersLoad,
} from "../../actions/admin/edit-team-teachers.actions";
import {
  deleteTeamTeacherLoad,
  deleteTeamTeacherLoadFail,
} from "../../actions/admin/delete-team-teacher.action";
import { packageLoad } from "../../actions/packages/single-package.actions";

@Injectable()
export class CreateTeamTeachersEffect {
  createTeamTeachersList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createTeamTeachers),
      switchMap((param) =>
        this.adminService
          .createTeamTeachers(
            param.organizationId,
            param.courseId,
            param.payload,
          )
          .pipe(
            switchMap(() => {
              if (param.subId) {
                return of(
                  courseTeachersListLoad({
                    organizationId: param.organizationId,
                    subId: param.subId,
                  }),
                );
              }
              if (param.packageId) {
                return of(
                  packageLoad({
                    organizationId: param.organizationId,
                    packageId: param.packageId,
                  }),
                );
              }
              return EMPTY;
            }),
            catchError(({ error }) => of(createTeamTeachersFail({ error }))),
          ),
      ),
    ),
  );

  editTeamTeachersList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editTeamTeachers),
      switchMap((param) => {
        return this.adminService
          .editTeamTeachers(
            param.organizationId,
            param.courseId,
            param.teamId,
            param.payload,
          )
          .pipe(
            switchMap(() => {
              if (param.subId) {
                return of(
                  courseTeachersListLoad({
                    organizationId: param.organizationId,
                    subId: param.subId,
                  }),
                );
              }
              return EMPTY;
            }),
            catchError(({ error }) => {
              return of(editTeamTeachersFail({ error }));
            }),
          );
      }),
    ),
  );

  deleteTeamTeachersList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTeamTeacherLoad),
      switchMap((param) => {
        return this.adminService
          .deleteTeamTeachers(
            param.organizationId,
            param.courseId,
            param.teamId,
          )
          .pipe(
            switchMap(() => {
              if (param.subId) {
                return of(
                  courseTeachersListLoad({
                    organizationId: param.organizationId,
                    subId: param.subId,
                  }),
                );
              }
              return EMPTY;
            }),
            catchError(({ error }) => {
              return of(deleteTeamTeacherLoadFail({ error }));
            }),
          );
      }),
    ),
  );

  constructor(
    private adminService: AdminService,
    private actions$: Actions,
  ) {}
}
