<ng-container *ngIf="{ ranking: ranking$ | async,
                    getRankingCompleted: getRankingCompleted$ | async,
                    criteria: criteria$ | async,
                    isTeacher: isTeacher$ | async,
                    inRanking: inRanking$ | async,
                    regions: rankRegions$ | async,
                    organizations: rankOrganizations$ | async,
                    groups: rankCoursegroups$ | async,
                    hasFilters: hasFilters$ | async} as rank">
    <page-standard-layout>
        <page-header class="ranking-page-header" page-header>
            {{(rank.criteria.filter_by !== filterBy.coursegroup ? 'RANKING.RANKING_GLOBAL' : 'RANKING.GROUP_RANKING') | translate}}

            <button
            *ngIf="rank.criteria.filter_by === filterBy.coursegroup"
            class="theme-hprimary"
            [routerLink]="[getCourseUrl(rank)]">
            <i class="typcn typcn-arrow-back-outline"></i> {{'RANKING.GO_TO_GROUP' | translate}}</button>

            <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between mt-4">
                <div
                class="d-flex align-items-start align-items-lg-center filters"
                [ngClass]="{ 'expanded': expanded }">
                <button
                    (click)="toggleFiltersMobile()"
                    class="btn-link d-inline d-lg-none"
                    [ngClass]="{ 'mb-3' : expanded }">
                    <i class="uil uil-filter"></i> {{'RANKING.FILTERS' | translate}} <span class="toggle-arrow typcn typcn-chevron-right"></span>
                </button>

                    <list-picker
                        *ngIf="rank.criteria.ranking_type !== rankType.days"
                        [value]="rank.criteria.span"
                        [options]="rankSpans"
                        icon="calender"
                        (picked)="setSpan($event)">
                    </list-picker>

                    <ng-container *ngIf="rank.organizations.length">
                        <list-picker
                            [value]="rank.criteria.filter_by"
                            [options]="rankFilters"
                            (picked)="setFilter($event, rank)">
                            <span #customLabel *ngIf="rank.criteria.filter_by === filterBy.coursegroup">
                                <i class="uil uil-university"></i> {{'RANKING.FILTER.ORGANIZATION' | translate}}
                            </span>

                            <span #customLabel *ngIf="!rank.criteria.filter_by">
                                <i class="uil uil-globe"></i> {{'RANKING.FILTER.GLOBAL' | translate}}
                            </span>
                        </list-picker>

                        <list-picker
                            *ngIf="rank.regions.length && rank.criteria.filter_by === filterBy.region"
                            [value]="rank.criteria.filter_pk"
                            [options]="rank.regions"
                            (picked)="setRegion($event)">
                        </list-picker>

                        <list-picker
                            *ngIf="rank.criteria.filter_by === filterBy.organization || rank.criteria.filter_by === filterBy.coursegroup"
                            [value]="rank.criteria.filter_by === filterBy.organization ? rank.criteria.filter_pk : rank.groups.organizationId"
                            [options]="rank.organizations"
                            (picked)="setOrganization($event)">
                        </list-picker>

                        <list-picker
                            *ngIf="rank.groups.options.length > 1 && rank.criteria.filter_by === filterBy.organization || rank.criteria.filter_by === filterBy.coursegroup"
                            [value]="rank.criteria.filter_pk"
                            [options]="rank.groups.options"
                            [optionTemplate]="groupOption"
                            (picked)="setCoursegroup($event, rank.groups.organizationId)">
                            <span #customLabel *ngIf="rank.criteria.filter_by === filterBy.organization">
                                <i class="uil uil-users-alt"></i> {{'RANKING.COURSEGROUPS.CHOOSE' | translate}}
                            </span>

                            <span #customLabel *ngIf="rank.criteria.filter_by === filterBy.coursegroup">
                                <i class="uil uil-users-alt"></i> {{ groupName(rank.groups.options, rank.criteria.filter_pk) }}
                            </span>


                            <ng-template #groupOption let-option>
                                <div class="d-flex align-items-center">
                                    <i *ngIf="option.icon" class="uil uil-{{option.icon}} mr-2"></i>
                                    <span>
                                        {{option.label.text | translate}}
                                        <span class="d-block mini-label" *ngIf="option.label.subtext">
                                            {{option.label.subtext | translate}}
                                        </span>
                                    </span>
                                </div>

                            </ng-template>
                        </list-picker>

                        <button
                            *ngIf="rank.hasFilters"
                            routerLink="./"
                            [queryParams]="{}"
                            class="btn-error-600">
                            <i class="uil uil-filter-slash"></i> {{'RANKING.CLEAR_FILTERS' | translate}}
                        </button>
                    </ng-container>
                </div>

                <div class="d-flex mt-4 mt-0-lg align-items-center" *ngIf="shouldShowGrouping(rank)">
                    <slider-picker
                        *ngIf="rank.criteria.filter_by !== filterBy.region"
                        [options]="rankRanges"
                        [selected]="rank.criteria.group_by"
                        (picked)="setGroupBy($event);"></slider-picker>
                    <slider-picker
                        *ngIf="rank.criteria.filter_by === filterBy.region"
                        [options]="rankRanges2"
                        [selected]="rank.criteria.group_by"
                        (picked)="setGroupBy($event);"></slider-picker>
                </div>
            </div>
        </page-header>

        <ul page-content class="rank-types">
            <li *ngFor="let type of rankTypes"
                class="rank-type"
                [ngClass]="{'active' : rank.criteria.ranking_type === type.value}"
                (click)="setRankType(type)">
                <i class="uil uil-{{type.icon}}"></i>
                <span class="label">{{type.label | translate}}</span>
            </li>
        </ul>

        <p class="label rank-desc"
            [innerHTML]="rankDescriptions[rank.criteria.ranking_type] | translate"
            page-content>
        </p>

        <div page-content *ngIf="rank.getRankingCompleted">
            <div class="d-flex flex-column">
                <ranking-table
                    [ranking]="rank.ranking"
                    [unitLabel]="unitLabels[rank.criteria.ranking_type]"
                    [itemsToHighlight]="getHighlightItems(rank)"
                    [groupBy]="rank.criteria.group_by"
                ></ranking-table>

                <div *ngIf="rank.ranking.results.length" class="d-flex align-items-center justify-content-end flex-column-reverse flex-lg-row">
                    <pagination
                        [pagination]="rank.ranking"
                        [pageSize]="rank.criteria.page_size"
                        [showMyPositionButton]="!rank.isTeacher && rank.inRanking">
                    </pagination>
                </div>
            </div>

        </div>

        <loading-box page-content *ngIf="!rank.getRankingCompleted"></loading-box>
    </page-standard-layout>
</ng-container>
