import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "shortName",
})
export class ShortNamePipe implements PipeTransform {
  transform(fullName: string): any {
    if (!fullName || fullName.trim() === "") {
      return;
    }

    return fullName
      .split(" ")
      .slice(0, 2)
      .map((n) => n[0].toUpperCase())
      .join("");
  }
}
