import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'select-with-placeholder',
  templateUrl: './select-with-placeholder.component.html',
  styleUrls: ['./select-with-placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectWithPlaceholderComponent {
  @Input() placeholder: string;
}
