import { Component, Input } from "@angular/core";
import { CourseLimit } from "src/app/shared/interfaces/limit";

@Component({
  selector: "organization-limit-widget-carousel-content",
  templateUrl: "./organization-limit-widget-carousel-content.component.html",
  styleUrls: ["./organization-limit-widget-carousel-content.component.scss"],
})
export class OrganizationLimitWidgetCarouselContentComponent {
  @Input() limit: CourseLimit;

  get isLimited(): boolean {
    return this.limit.limit_max > 0;
  }

  get organizationName(): string {
    return this.limit.name;
  }

  get label(): string {
    return !this.isLimited
      ? "GLOBAL.LIMIT_WIDGET.GRANTED_ACCESS"
      : "GLOBAL.LIMIT_WIDGET.LIMITS";
  }
}
