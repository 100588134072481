import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ListboxContentData } from "src/app/shared/interfaces/listbox-content.model";
import { IconName } from "../../types/icon-name";
import { IconSet } from "../../types/icon-set";

export interface Group {
  id: string;
  name: string;
  used_limit: number;
}

@Component({
  selector: "teacher-dashboard-group-listbox-element-content",
  templateUrl:
    "./teacher-dashboard-group-listbox-element-content.component.html",
  styleUrls: [
    "./teacher-dashboard-group-listbox-element-content.component.scss",
  ],
})
export class TeacherDashboardGroupListboxElementContentComponent
  implements OnInit
{
  @Input() element: ListboxContentData;
  @Input() groups: Group[];
  @Input() icon: IconName = "chevron-right";
  @Input() set: IconSet = "typcn";
  @Input() isDisabled: boolean = false;
  @Input() editBtn: boolean = false;
  @Input() pathBtn: boolean = true;
  @Input() deleteBtn: boolean = false;
  @Input() usedLimit: boolean = false;
  @Input() dashboardTeacher: boolean = false;
  @Input() possibleEmptyGroups: boolean = false;
  @Output() editEmitter = new EventEmitter<ListboxContentData>();
  @Output() deleteEmitter = new EventEmitter<ListboxContentData>();

  usedAndMaxLimits: string;
  groupUsedLimit: string;

  ngOnInit(): void {
    if (this.element.max_limits !== "0")
      this.usedAndMaxLimits = `${this.element.used_limits}/${this.element.max_limits}`;
    else this.usedAndMaxLimits = this.element.used_limits;

    if (this.usedLimit) {
      let limit = 0;
      this.groups.map((group) => {
        limit += group.used_limit;
      });
      this.groupUsedLimit = limit.toString();
    }
  }

  handleEditEmitter(element: ListboxContentData): void {
    this.editEmitter.emit(element);
  }

  handleDeleteEmitter(element: ListboxContentData): void {
    this.deleteEmitter.emit(element);
  }
}
