import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";
import {
  coursesAdminActiveListLoad,
  coursesAdminActiveListLoadSuccess,
  loadActiveTeachersFailure,
} from "../../actions/admin/course-admin.actions";
import { AdminService } from "src/app/shared/services/admin/admin.service";
import {
  CourseAdmin,
  PagionationData,
} from "src/app/shared/interfaces/orgadmin/course-organization";

@Injectable()
export class CourseAdminEffect {
  getActiveCoursesAdminList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(coursesAdminActiveListLoad),
      concatMap((param) => {
        return this.adminService
          .getSubscriptions(
            param.id,
            param.page,
            param.size,
            param.search,
            param.active,
          )
          .pipe(
            map((res: PagionationData<CourseAdmin>) =>
              coursesAdminActiveListLoadSuccess({ coursesActiveList: res }),
            ),
            catchError(({ error }) => of(loadActiveTeachersFailure(error))),
          );
      }),
    ),
  );

  constructor(
    private adminService: AdminService,
    private actions$: Actions,
  ) {}
}
