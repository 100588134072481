import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { StatusValues } from "../../enums/status-values";

@Component({
  selector: "status-chip",
  templateUrl: "./status-chip.component.html",
  styleUrls: ["./status-chip.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusChipComponent {
  @Input() statusValue: StatusValues;
}
