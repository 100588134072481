import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ModalComponent } from "../../modal/modal.component";
import { Observable, of } from "rxjs";
import { UploadConfig } from "../../../interfaces/upload-config";
import { FileUploadComponent } from "../../../blocks/file-upload/file-upload.component";
import { CourseExerciseFileUploadModalDataService } from "./services/course-exercise-file-upload-modal-data.service";
import { isEmpty } from "lodash-es";
import { StudentExerciseFileUploadApiService } from "../../../services/exercise/student-exercise-file-upload-api.service";
import { ModalService } from "src/app/shared/services/modal/modal.service";
import { ConfirmationModalComponent } from "../confirmation-modal/confirmation-modal.component";
import { tap } from "rxjs/operators";

@Component({
  selector: "course-exercise-file-upload-modal",
  templateUrl: "./course-exercise-file-upload-modal.component.html",
  styleUrls: ["./course-exercise-file-upload-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseExerciseFileUploadModalComponent
  extends ModalComponent
  implements OnInit
{
  @ViewChild(FileUploadComponent) fileUploadCmpRef!: FileUploadComponent;

  uploadConfig$!: Observable<UploadConfig>;
  fileName: string;

  constructor(
    private readonly dialog: CourseExerciseFileUploadModalDataService,
    private readonly api: StudentExerciseFileUploadApiService,
    private readonly modal: ModalService,
  ) {
    super();
  }

  get isUploadDisabled(): boolean {
    if (!this.fileUploadCmpRef) {
      return true;
    }

    return isEmpty(this.fileUploadCmpRef.files);
  }

  ngOnInit(): void {
    this.uploadConfig$ = this.uploadFileConfigSource();
  }

  handleCancelClick(): void {
    this.hideOnUI();
  }

  setFileName(file: string[]): void {
    this.fileName = file[0];
  }

  handleUploadFileClick(): void {
    const modal = this.modal.showModal(
      {
        modalTitle: "GLOBAL.CONFIRM_UPLOAD_FILE",
        message: this.fileName,
        confirmButtonText: "GLOBAL.NEXT",
        cancelButtonText: "GLOBAL.CANCEL_BUTTON",
        confirmButtonColor: "primary",
      },
      ConfirmationModalComponent,
      "FILE_UPLOAD_CONFIRMATION",
    ) as ConfirmationModalComponent;

    modal.onConfirm
      .pipe(
        tap((confirm) => {
          if (confirm) {
            this.dialog.patch(this.fileUploadCmpRef.files);
          }
        }),
      )
      .subscribe();

    this.hideOnUI();
  }

  private uploadFileConfigSource(): Observable<UploadConfig> {
    return this.api.uploadConfig({
      courseId: this.data.courseId,
      groupId: this.data.groupId,
      exerciseId: this.data.exerciseId,
    });
  }

  private hideOnUI(): void {
    this.close.emit("close");
  }
}
