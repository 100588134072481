import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { ModalComponent } from "../../modal/modal.component";
import * as fromGroup from "src/app/store/actions/group.actions";
import * as fromGroupStore from "src/app/store/reducers/group.reducer";
import { Observable } from "rxjs";
import { MovableGroup } from "src/app/shared/interfaces/group";
import { Guid } from "src/app/shared/types/guid";
import { ErrorListing } from "src/app/shared/types/error-listing";
import { tap } from "rxjs/operators";

@Component({
  selector: "move-student-modal",
  templateUrl: "./move-student-modal.component.html",
  styleUrls: ["./move-student-modal.component.scss"],
})
export class MoveStudentModalComponent
  extends ModalComponent<{ GroupId: Guid; CourseId: Guid; StudentId: Guid }>
  implements OnInit
{
  matchingGroups$: Observable<Array<MovableGroup>> = this.store.select(
    (state) => state.teacher.singleGroup.matchingGroups
  );
  getMatchingGroupsCompleted$: Observable<boolean> = this.store.select(
    (state) => state.teacher.singleGroup.getMatchingGroupsCompleted
  );
  moveStudentCompleted$: Observable<boolean> = this.store
    .select((state) => state.teacher.singleGroup.moveStudentCompleted)
    .pipe(
      tap((completed: boolean) => {
        if (completed) {
          this.close.emit("close");
        }
      })
    );

  moveStudentInProgress$: Observable<boolean> = this.store.select(
    (state) => state.teacher.singleGroup.moveStudentInProgress
  );
  moveStudentFailed$: Observable<boolean> = this.store.select(
    (state) => state.teacher.singleGroup.moveStudentFailed
  );
  moveStudentErrors$: Observable<ErrorListing> = this.store.select(
    (state) => state.teacher.singleGroup.moveStudentErrors
  );

  constructor(
    private store: Store<{
      teacher: { singleGroup: fromGroupStore.GroupState };
    }>
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(new fromGroup.GetMatchingGroups(this.data));
  }

  moveStudent(coursegroup: Guid): void {
    this.store.dispatch(
      new fromGroup.MoveStudent({ data: this.data, body: { coursegroup } })
    );
  }
}
