import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Nillable } from "../../types/nillable";

@Component({
  selector: "input-switch",
  templateUrl: "./input-switch.component.html",
  styleUrls: ["./input-switch.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSwitchComponent),
      multi: true,
    },
  ],
})
export class InputSwitchComponent implements ControlValueAccessor {
  @Input() disabled: boolean = false;
  @Input() isChecked: boolean = false;
  @Input() label: Nillable<string>;

  @ViewChild("input") inputRef!: ElementRef;

  onChange = (value: Nillable<boolean>): void => {};
  onTouched = () => {};

  value: Nillable<boolean>;

  constructor(private readonly cd: ChangeDetectorRef) {}

  get checked(): boolean {
    return this.value === true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { isChecked } = changes;

    if (isChecked) {
      this.value = true;
    }
  }

  handleClickEvent(event: MouseEvent): void {
    if (this.disabled) {
      return;
    }

    event.preventDefault();
    this.toggleSwitchState();
    this.onChange(this.value);
    this.inputRef.nativeElement.focus();
  }

  writeValue(value: boolean): void {
    this.value = value;
    this.cd.markForCheck();
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    this.cd.markForCheck();
  }

  private toggleSwitchState(): void {
    this.value = !this.value;
  }
}
