<ng-container
    *ngIf="{notifications : notifications$ | async, getNotificationsCompleted: getNotificationsCompleted$ | async} as panelData">
    <div class="notification-panel-settings d-flex justify-content-between">
        <p *ngIf="activeTab" (click)="setTab($event, ActiveTab.NONE)" class="panel-button paragraph-small color-hprimary">
            <i class="uil uil-arrow-left"></i> {{ "GLOBAL.NOTIFICATIONS.SETTINGS.BACK" | translate}}
        </p>
        <div *ngIf="!activeTab" class="d-flex">
            <p (click)="setTab($event, ActiveTab.SNOOZE)" class="panel-button paragraph-small color-hprimary">
                <i class="uil uil-volume-mute"></i>
                {{ "GLOBAL.NOTIFICATIONS.SETTINGS.SNOOZE.TITLE" | translate}}
            </p>
            <p (click)="setTab($event, ActiveTab.SETTINGS)" class="panel-button paragraph-small color-hprimary">
                <i class="uil uil-cog"></i> {{ "GLOBAL.NOTIFICATIONS.SETTINGS.TYPES.TITLE" | translate}}
            </p>
        </div>
    </div>
    <div class="notifications-tabs">
        <ng-container [ngSwitch]="activeTab">
            <notifications-snooze *ngSwitchCase="ActiveTab.SNOOZE"></notifications-snooze>
            <notifications-settings *ngSwitchCase="ActiveTab.SETTINGS"></notifications-settings>
        </ng-container>
        <div class="notifications-panel" #notificationsPanel>
            <div class="no-notifications d-flex flex-column align-items-center justify-content-center"
                *ngIf="panelData.getNotificationsCompleted && panelData.notifications.length === 0">
                <span class="typcn typcn-bell"></span>
                <span class="no-notifications-message">{{'GLOBAL.NOTIFICATIONS.NO_NOTIFICATIONS' | translate}}</span>
            </div>
            <ul class="notifications-list">
                <li *ngFor="let notification of panelData.notifications" [notification]="notification"
                    (actionPerformed)="notifyPanel()"></li>
            </ul>
            <loading-spinner width="20" height="20" *ngIf="!panelData.getNotificationsCompleted"></loading-spinner>
        </div>
    </div>
    <div *ngIf="!activeTab" class="notifications-footer d-flex justify-content-center">
      <p class="panel-button paragraph-small color-hsecondary mark-as-read" (click)="markAllAsRead()">
        <i class="uil uil-check"></i> {{ 'GLOBAL.NOTIFICATIONS.MARK_ALL_AS_READ' | translate}}
      </p>
    </div>
</ng-container>
