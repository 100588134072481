import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Params } from "@angular/router";
import { IconRotate } from "../../types/icon-rotate";
import { IconName } from "../../types/icon-name";
import { IconSet } from "../../types/icon-set";
import { Nillable } from "../../types/nillable";

@Component({
  selector: "round-button",
  templateUrl: "./round-button.component.html",
  styleUrls: ["./round-button.component.scss"],
})
export class RoundButtonComponent {
  @Input() link = "";
  @Input() params: Params;
  @Input({ required: true }) icon!: IconName;
  @Input() theme = "";
  @Input() pure = false;
  @Input() set: IconSet = "typcn";
  @Input() rotate: IconRotate | undefined;
  @Input() isActive = false;
  @Input() disabled: boolean = false;

  @Output() action: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  emitAction(event: MouseEvent): void {
    if (!this.pure) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }

    this.action.emit(event);
  }
}
