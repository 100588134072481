import { Component, ElementRef, HostListener } from "@angular/core";

@Component({
  selector: "dropdown-menu",
  templateUrl: "./dropdown-menu.component.html",
  styleUrls: ["./dropdown-menu.component.scss"],
})
export class DropdownMenuComponent {
  isActive = false;

  constructor(private readonly ref: ElementRef) {}

  toggleMenu() {
    this.isActive = !this.isActive;
  }

  @HostListener("document:click", ["$event"])
  hideMenu(event: MouseEvent) {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.isActive = false;
    }
  }
}
