import { HttpClient } from "@angular/common/http";
import { Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { ExerciseTimerComponent } from "src/app/shared/components/exercise-timer/exercise-timer.component";
import { ConfigStorageService } from "src/app/shared/services/config-storage/config-storage.service";
import * as fromUIStore from "src/app/store/reducers/ui.reducer";
import { RunningExercise } from "src/app/shared/types/running-exercise";
import { Router } from "@angular/router";
import { UIService } from '../../services/ui/ui.service';

@Component({
  selector: "navbar-exercise-timer",
  templateUrl: "./navbar-exercise-timer.component.html",
  styleUrls: [
    "../exercise-timer/exercise-timer.component.scss",
    "./navbar-exercise-timer.component.scss",
  ],
})
export class NavbarExerciseTimerComponent extends ExerciseTimerComponent {
  constructor(
    private _store: Store<{ ui: fromUIStore.UIState }>,
    private _configStorage: ConfigStorageService,
    private _http: HttpClient,
    private _translate: TranslateService,
    private _uiService: UIService,
    private router: Router,
  ) {
    super(_store, _configStorage, _http, _translate, _uiService);
  }

  @Input() stopOnConfirm = false;
  @Input() confirmationMessage = "";
  @Input() confirmationTitle = "";
  @Input() confirmButtonText;
  @Input() cancelButtonText;

  stop(runningExercise: RunningExercise) {
    super.stop(runningExercise);
    this.router.navigate(
      [
        "/" +
          (runningExercise[0].group_id ? "student" : "teacher") +
          "/courses/" +
          runningExercise[0].course_id +
          (runningExercise[0].group_id
            ? "/groups/" + runningExercise[0].group_id
            : ""),
      ],
      { queryParams: { topicId: runningExercise[0].topic_id } }
    );
  }
}
