import { Pipe, PipeTransform } from "@angular/core";
import { CodeCheckStatus } from "src/app/shared/enums/code-check-status";

@Pipe({
  name: "codeCheckStatus",
})
export class CodeCheckStatusPipe implements PipeTransform {
  transform(value: CodeCheckStatus): string {
    switch (value) {
      case CodeCheckStatus.CHECK_STATUS_ACCEPTED:
        return "IDE.EXERCISE.CODE_CHECK_STATUS.CHECK_STATUS_ACCEPTED";
      case CodeCheckStatus.CHECK_STATUS_PROCESSING:
        return "IDE.EXERCISE.CODE_CHECK_STATUS.CHECK_STATUS_PROCESSING";
      case CodeCheckStatus.CHECK_STATUS_ERROR:
        return "IDE.EXERCISE.CODE_CHECK_STATUS.CHECK_STATUS_ERROR";
      case CodeCheckStatus.CHECK_STATUS_REJECTED:
        return "IDE.EXERCISE.CODE_CHECK_STATUS.CHECK_STATUS_REJECTED";
      case CodeCheckStatus.CHECK_STATUS_FINISHED:
        return "IDE.EXERCISE.CODE_CHECK_STATUS.CHECK_STATUS_FINISHED";
      case CodeCheckStatus.CHECK_STATUS_PREPARING:
        return "IDE.EXERCISE.CODE_CHECK_STATUS.CHECK_STATUS_PREPARING";
      case CodeCheckStatus.CHECK_STATUS_CANCELED:
        return "IDE.EXERCISE.CODE_CHECK_STATUS.CHECK_STATUS_CANCELED";
    }
  }
}
