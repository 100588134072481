import { Action } from "@ngrx/store";
import { Ranking } from "src/app/shared/interfaces/ranking";
import { RankingCriteria } from "src/app/shared/models/ranking-criteria";

export const GET_RANKING = "[Ranking Method] GetRanking";
export const GET_RANKING_COMPLETED = "[Ranking Method] GetRankingCompleted";
export const GET_RANKING_FAILED = "[Ranking Method] GetRankingFailed";

export const UPDATE_RANKING_CRITERIA = "[Ranking Method] UpdateRankingCriteria";

export const GET_RANKING_DEFFERED = "[Ranking Method] GetRankingDeffered";
export const RANKING_REQUEST_FINISHED =
  "[Ranking Method] RankingRequestFinished";

export class GetRanking implements Action {
  readonly type = GET_RANKING;

  constructor(public payload: RankingCriteria) {}
}

export class GetRankingCompleted implements Action {
  readonly type = GET_RANKING_COMPLETED;

  constructor(public payload: Ranking) {}
}

export class GetRankingFailed implements Action {
  readonly type = GET_RANKING_FAILED;
}

export class UpdateRankingCriteria implements Action {
  readonly type = UPDATE_RANKING_CRITERIA;

  constructor(public payload: RankingCriteria) {}
}

export class GetRankingDeffered implements Action {
  readonly type = GET_RANKING_DEFFERED;

  constructor(public payload: { hash: string }) {}
}

export class RankingRequestFinished implements Action {
  readonly type = RANKING_REQUEST_FINISHED;

  constructor(public payload: { hash: string }) {}
}

export type RankingActions =
  | GetRanking
  | GetRankingCompleted
  | GetRankingFailed
  | UpdateRankingCriteria
  | GetRankingDeffered;
