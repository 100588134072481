<ng-container *ngIf="{ userId: userId$ | async } as settingsView">
  <p class="paragraph-small color-hannotationgray mb-3">
    {{"GLOBAL.NOTIFICATIONS.SETTINGS.TYPES.DESC" | translate}}
  </p>
  <ul *ngIf="settings" class="settings-list">
    <li
      *ngFor="let group of filteredSettingGroups"
      setting-group
      [appliesTo]="group.appliesTo"
      [name]="group.name"
      [value]="settings.toJS().notificationTypeSettings[group.appliesTo[0]]"
      (valueChanged)="setSetting($event, settingsView.userId)">
    </li>
  </ul>
</ng-container>
