import { ComponentPortal, DomPortalHost } from "@angular/cdk/portal";
import {
  ApplicationRef,
  ComponentFactoryResolver,
  Injectable,
  Injector,
  Renderer2,
  RendererFactory2,
  Type,
} from "@angular/core";

export type ModalContext<T> = {
  portalHost: DomPortalHost;
  portal: ComponentPortal<T>;
};

@Injectable({
  providedIn: "root",
})
export class ModalContextService {
  private renderer: Renderer2;

  constructor(
    rendererFactory: RendererFactory2,
    private injector: Injector,
    private appRef: ApplicationRef,
    private factoryResolver: ComponentFactoryResolver
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  createContext<T>(
    modalType: Type<T>,
    injector?: Injector,
    factoryResolver?: ComponentFactoryResolver
  ): ModalContext<T> {
    return {
      portalHost: new DomPortalHost(
        this.createElement(),
        factoryResolver ? factoryResolver : this.factoryResolver,
        this.appRef,
        injector ? injector : this.injector
      ),
      portal: new ComponentPortal(
        (factoryResolver ? factoryResolver : this.factoryResolver)
          .resolveComponentFactory(modalType)
          .create(injector ? injector : this.injector).componentType
      ),
    };
  }

  deleteContext<T>(context: ModalContext<T>) {
    context.portalHost.dispose();
    delete context.portalHost;
    delete context.portal;
  }

  private createElement(): HTMLDivElement {
    let element = this.renderer.createElement("div");

    this.renderer.addClass(element, "modal-context");
    this.renderer.appendChild(document.body, element);

    return element;
  }
}
