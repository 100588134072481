import { Component } from "@angular/core";

/* Tile is like a Box component but less strict about its placement and margins */
@Component({
  selector: "tile",
  template: `
    <div class="tile">
      <ng-content select="[tile-header]"></ng-content>
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ["./tile.component.scss"],
})
export class TileComponent {
  constructor() {}
}
