import { Action } from "@ngrx/store";
import { CodeCheckRequest } from "src/app/shared/interfaces/code-check-request";
import { CodeConfig } from "src/app/shared/interfaces/code-config";
import { CodeConfigRequest } from "src/app/shared/interfaces/code-config-request";
import { CodeRunRequest } from "src/app/shared/interfaces/code-run-request";
import { CodeRunVerification } from "src/app/shared/interfaces/code-run-verification";
import { CodeRunVerifyRequest } from "src/app/shared/interfaces/code-run-verify-request";
import { CodeVerification } from "src/app/shared/interfaces/code-verification";
import { CodeVerifyRequest } from "src/app/shared/interfaces/code-verify-request";
import { UploadConfig } from "src/app/shared/interfaces/upload-config";

export const GET_CODE_CONFIG = "[ExerciseCode Method] GetCodeConfig";
export const GET_CODE_CONFIG_COMPLETED =
  "[ExerciseCode Method] GetCodeConfigCompleted";

export const GET_UPLOAD_CONFIG = "[ExerciseCode Method] GetUploadConfig";
export const GET_UPLOAD_CONFIG_COMPLETED =
  "[ExerciseCode Method] GetUploadConfigCompleted";

export const CODE_CHECK = "[ExerciseCode Method] CodeCheck";
export const CODE_CHECK_COMPLETED = "[ExerciseCode Method] CodeCheckCompleted";
export const CODE_CHECK_FAILED = "[ExerciseCode Method] CodeCheckFailed";

export const CODE_VERIFY = "[ExerciseCode Method] CodeVerify";
export const CODE_VERIFY_COMPLETED =
  "[ExerciseCode Method] CodeVerifyCompleted";
export const ALLOW_MANUAL_VERIFICATION =
  "[ExerciseCode Method] AllowManualVerification";
export const WEBSOCKET_VERIFICATION =
  "[ExerciseCode Method] WebsocketVerification";
export const WEBSOCKET_VERIFICATION_COMPLETED =
  "[ExerciseCode Method] WebsocketVerificationCompleted";

export const PARSE_TESTS_RESULTS = "[ExerciseCode Method] ParseTestsResults";

export const CODE_RUN = "[ExerciseCode Method] CodeRun";
export const CODE_RUN_COMPLETED = "[ExerciseCode Method] CodeRunCompleted";
export const CODE_RUN_FAILED = "[ExerciseCode Method] CodeRunFailed";

export const CODE_RUN_VERIFY = "[ExerciseCode Method] CodeRunVerify";
export const CODE_RUN_VERIFY_COMPLETED =
  "[ExerciseCode Method[ CodeRunVerifyCompleted";
export const WEBSOCKET_RUN = "[ExerciseCode Method] WebsocketRun";
export const WEBSOCKET_RUN_COMPLETED =
  "[ExerciseCode Method] WebsocketRunCompleted";

export const PARSE_RUN_RESULTS = "[ExerciseCode Method] ParseRunResults";

export class GetCodeConfig implements Action {
  readonly type = GET_CODE_CONFIG;

  constructor(public payload: CodeConfigRequest) {}
}

export class GetCodeConfigCompleted implements Action {
  readonly type = GET_CODE_CONFIG_COMPLETED;

  constructor(public payload: CodeConfig) {}
}

export class GetUploadConfig implements Action {
  readonly type = GET_UPLOAD_CONFIG;

  constructor(public payload: CodeConfigRequest) {}
}

export class GetUploadConfigCompleted implements Action {
  readonly type = GET_UPLOAD_CONFIG_COMPLETED;

  constructor(public payload: UploadConfig) {}
}

export class CodeCheck implements Action {
  readonly type = CODE_CHECK;

  constructor(public payload: CodeCheckRequest) {}
}

export class CodeCheckCompleted implements Action {
  readonly type = CODE_CHECK_COMPLETED;

  constructor(public payload: CodeVerification) {}
}

export class CodeCheckFailed implements Action {
  readonly type = CODE_CHECK_FAILED;
}

export class CodeVerify implements Action {
  readonly type = CODE_VERIFY;

  constructor(public payload: CodeVerifyRequest) {}
}

export class CodeVerifyCompleted implements Action {
  readonly type = CODE_VERIFY_COMPLETED;

  constructor(public payload: CodeVerification) {}
}

export class AllowManualVerification implements Action {
  readonly type = ALLOW_MANUAL_VERIFICATION;
}

export class WebsocketVerification implements Action {
  readonly type = WEBSOCKET_VERIFICATION;

  constructor(public payload: CodeVerification) {}
}

export class WebsocketVerificationCompleted implements Action {
  readonly type = WEBSOCKET_VERIFICATION_COMPLETED;
}

export class ParseTestsResults implements Action {
  readonly type = PARSE_TESTS_RESULTS;

  constructor(public payload: CodeVerification) {}
}

export class CodeRun implements Action {
  readonly type = CODE_RUN;

  constructor(public payload: CodeRunRequest) {}
}

export class CodeRunCompleted implements Action {
  readonly type = CODE_RUN_COMPLETED;

  constructor(public payload: CodeRunVerification) {}
}

export class CodeRunFailed implements Action {
  readonly type = CODE_RUN_FAILED;
}

export class CodeRunVerify implements Action {
  readonly type = CODE_RUN_VERIFY;

  constructor(public payload: CodeRunVerifyRequest) {}
}

export class CodeRunVerifyCompleted implements Action {
  readonly type = CODE_RUN_VERIFY_COMPLETED;

  constructor(public payload: CodeRunVerification) {}
}

export class WebsocketRun implements Action {
  readonly type = WEBSOCKET_RUN;

  constructor(public payload: CodeRunVerification) {}
}

export class WebsocketRunCompleted implements Action {
  readonly type = WEBSOCKET_RUN_COMPLETED;
}

export class ParseRunResults implements Action {
  readonly type = PARSE_RUN_RESULTS;

  constructor(public payload: CodeRunVerification) {}
}

export type ExerciseCodeActions =
  | GetCodeConfig
  | GetCodeConfigCompleted
  | GetUploadConfig
  | GetUploadConfigCompleted
  | CodeCheck
  | CodeCheckCompleted
  | CodeCheckFailed
  | CodeVerify
  | CodeVerifyCompleted
  | AllowManualVerification
  | WebsocketVerification
  | WebsocketVerificationCompleted
  | ParseTestsResults
  | CodeRun
  | CodeRunCompleted
  | CodeRunFailed
  | CodeRunVerify
  | CodeRunVerifyCompleted
  | WebsocketRun
  | WebsocketRunCompleted
  | ParseRunResults;
