import { Directive, HostListener, Input, Type } from "@angular/core";
import { ModalComponent } from "../../components/modal/modal.component";
import { ModalService } from "../../services/modal/modal.service";

@Directive({
  selector: "[popup]",
})
export class PopupDirective {
  @Input("popup") popupComponent: Type<ModalComponent>;

  constructor(private modalService: ModalService) {}

  @HostListener("click", ["$event"])
  onHostClick(event) {
    event.stopImmediatePropagation();
    event.preventDefault();

    this.modalService.showModal({}, this.popupComponent, "POPUP");
  }
}
