<modal-overlay>
    <modal-box
        *ngIf="{vpnPassword: vpnPassword$ | async, getVpnPasswordCompleted: getVpnPasswordCompleted$ | async} as vpnConfiguration">
        <div class="vpn-configuration-modal">
            <h4 class="heading-5 mb-4">{{'GLOBAL.AVATAR.LAB_LINK' | translate}}</h4>
            <div>
                <h5 class="heading-6">1. {{'GLOBAL.VPN_CONFIGURATION.CONFIGURATION_FILE' | translate}}</h5>
                <a href="#" (click)="connectToLab($event)" class="button theme-hprimarydark lg shadow open-vpn-download">
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="open-vpn-logo">
                    <path d="M12 .357C5.385.357 0 5.69 0 12.254c0 4.36 2.358 8.153 5.896 10.204l.77-5.076a7.046 7.046 0 0 1-1.846-4.719c0-3.897 3.18-7.076 7.13-7.076 3.948 0 7.126 3.18 7.126 7.076 0 1.847-.717 3.488-1.846 4.77L18 22.51c3.59-2.05 6-5.899 6-10.258C24 5.69 18.615.357 12 .357zm-.05 8.157a3.786 3.786 0 0 0-3.796 3.795 3.738 3.738 0 0 0 2.461 3.54L9.13 23.643h5.64l-1.435-7.795c1.385-.564 2.41-1.898 2.41-3.54a3.786 3.786 0 0 0-3.795-3.794z"/>
                  </svg>

                    <span class="label">{{'GLOBAL.VPN_CONFIGURATION.DOWNLOAD_VPN_CONFIGURATION' | translate}}</span>
                </a>
            </div>

            <div class="vpn-password-container">
                <h5 class="heading-6">2. {{'GLOBAL.VPN_CONFIGURATION.VPN_CONFIGURATION_PASSWORD' | translate}}</h5>

                <div class="vpn-password">
                    <loading-spinner class="d-block" *ngIf="!vpnConfiguration.getVpnPasswordCompleted">
                    </loading-spinner>

                    <div class="row align-items-center">
                        <div class="col-12 col-md-7">
                            <input class="vpn-password-input" *ngIf="vpnConfiguration.getVpnPasswordCompleted"
                            type="{{vpnPasswordToggled ? 'text' : 'password'}}"
                            [value]="vpnConfiguration.vpnPassword.password" readonly="readonly">
                        </div>

                        <div class="col-12 col-sm-auto col-md-5 my-3 my-md-0">
                            <button
                            class="copy-button theme-hsecondary lg"
                            [ngClass]="{'theme-hsecondarylight': vpnPasswordCopied}"
                            (click)="copyToClipboard(vpnConfiguration.vpnPassword.password)">
                                <i class="uil"
                                [ngClass]="{'uil-clipboard': !vpnPasswordCopied, 'uil-clipboard-alt': vpnPasswordCopied}"></i>
                                {{(vpnPasswordCopied ? 'GLOBAL.VPN_CONFIGURATION.PASSWORD_COPIED' : 'GLOBAL.VPN_CONFIGURATION.COPY_PASSWORD') | translate}}
                            </button>
                        </div>

                        <div class="col-12 col-sm col-md-12">
                          <input type="checkbox" class="quiz-answer multi-choice" id="vpnPasswordToggle" name="vpnPasswordToggle"
                          [(ngModel)]="vpnPasswordToggled" />
                          <label class="checkbox" for="vpnPasswordToggle" translate>
                            {{'GLOBAL.VPN_CONFIGURATION.SHOW_PASSWORD'}}
                        </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-content-center button-panel">
                <button class="theme-hprimary lg text-lg"
                    (click)="closeVPNConfig()">{{'GLOBAL.READY_BUTTON' | translate}}</button>
            </div>
        </div>

    </modal-box>
</modal-overlay>
