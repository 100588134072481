<ul class="students-list">
  <li
    class="students-list-element my-submissions"
    (click)="studentClicked.emit(null)"
  >
    <simple-element>
      <i class="typcn typcn-user"></i>
      <span translate>
        {{ mySubmissionsText }}
      </span>
    </simple-element>
  </li>
  <li
    *ngFor="let student of students"
    class="students-list-element"
    (click)="studentClicked.emit(student)"
  >
    <simple-element>
      <i class="typcn typcn-user-outline"></i> {{ student.name }}
    </simple-element>
  </li>
</ul>
