import { Component } from '@angular/core';

@Component({
  selector: 'pills',
  template: `
   <ul class="pills"><ng-content></ng-content></ul>
  `,
  styleUrls: ['./pills.component.scss'],
  host: {
    class: 'd-flex flex-wrap'
  }
})
export class PillsComponent {
  constructor() {}
}
