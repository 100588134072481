import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "circle-badge",
  templateUrl: "./circle-badge.component.html",
  styleUrls: ["./circle-badge.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircleBadgeComponent {
  @Input() badgeData: string;
}
