import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import {
  singleActiveTeacherLoad,
  singleActiveTeacherLoadSuccess,
  singleActiveTeacherLoadFail,
  singleActiveTeacherClear,
} from "../../actions/admin/single-active-teacher.actions";
import { TeacherDetails } from "src/app/shared/interfaces/orgadmin/course-teachers";

export interface SingleActiveTeacherState {
  teacher: TeacherDetails | null;
  error: HttpErrorResponse | null;
}

const initialState: SingleActiveTeacherState = {
  teacher: null,
  error: null,
};

export const singleActiveTeacherReducer = createReducer(
  initialState,
  on(
    singleActiveTeacherLoad,
    (state): SingleActiveTeacherState => ({
      ...state,
      teacher: null,
      error: null,
    }),
  ),
  on(
    singleActiveTeacherLoadSuccess,
    (state, action): SingleActiveTeacherState => ({
      ...state,
      teacher: action.activeTeacher,
      error: null,
    }),
  ),
  on(
    singleActiveTeacherLoadFail,
    (state, { error }): SingleActiveTeacherState => ({
      ...state,
      error,
    }),
  ),
  on(
    singleActiveTeacherClear,
    (state): SingleActiveTeacherState => ({
      ...state,
      teacher: null,
      error: null,
    }),
  ),
);
