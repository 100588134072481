import { Action } from "@ngrx/store";
import { Course } from "src/app/shared/interfaces/course";
import { Invitation } from "src/app/shared/interfaces/invitation";
import { StudentInvitationRequest } from "src/app/shared/interfaces/student-invitation-request";
import { Guid } from "src/app/shared/types/guid";
import { GroupRequest } from "src/app/shared/interfaces/group-request";
import { Group } from 'src/app/shared/interfaces/group';

export const GET_GROUPS = "[Groups Method] GetGroups";
export const GET_GROUPS_COMPLETED = "[GroupsMethod] GetGroupsCompleted";

export const GET_INVITATIONS = "[Groups Method] GetInvitations";
export const GET_INVITATIONS_COMPLETED =
  "[Groups Method] GetInvitationsCompleted";

export const ADD_GROUP = "[Group Method] AddGroup";
export const RESET_ADD_GROUP = "[Group Method] ResetAddGroup";
export const ADD_GROUP_COMPLETED = "[Group Method] AddGroupCompleted";
export const ARCHIVE_GROUP = "[Groups Method] ArchiveGroup";

export const RESPOND_TO_INVITATION = "[Groups Method] RespondToInvitation";

export class GetGroups implements Action {
  readonly type = GET_GROUPS;
}

export class GetGroupsCompleted implements Action {
  readonly type = GET_GROUPS_COMPLETED;

  constructor(public payload: Array<Course>) {}
}

export class AddGroup implements Action {
  readonly type = ADD_GROUP;

  constructor(public payload: GroupRequest) {}
}

export class ResetAddGroup implements Action {
  readonly type = RESET_ADD_GROUP;
}

export class AddGroupCompleted implements Action {
  readonly type = ADD_GROUP_COMPLETED;

  constructor(public payload: Group) {}
}

export class ArchiveGroup implements Action {
  readonly type = ARCHIVE_GROUP;

  constructor(public payload: Guid) {}
}

export class GetInvitations implements Action {
  readonly type = GET_INVITATIONS;
}

export class GetInvitationsCompleted implements Action {
  readonly type = GET_INVITATIONS_COMPLETED;

  constructor(public payload: Array<Invitation>) {}
}

export class RespondToInvitation implements Action {
  readonly type = RESPOND_TO_INVITATION;

  constructor(
    public payload: { invitationId: Guid; request: StudentInvitationRequest },
  ) {}
}

export type GroupsActions =
  | GetGroups
  | GetGroupsCompleted
  | AddGroup
  | ResetAddGroup
  | AddGroupCompleted
  | ArchiveGroup
  | GetInvitations
  | GetInvitationsCompleted
  | RespondToInvitation;
