<ul *ngIf="list" class="item-list">
  <li
    *ngFor="let item of list.results"
    [class.active]="item.id === activeItemId"
    (click)="clicked(item)"
  >
    <div simple-element [ngClass]="['status-box', getStatusClass(item)]">
      <i [ngClass]="['typcn', getStatusIconClass(item)]"></i>
    </div>
    <div simple-element class="status-info">
      <b class="d-block status-info-text">
        <ng-container
          [ngTemplateOutlet]="itemText"
          [ngTemplateOutletContext]="{ $implicit: item }"
        ></ng-container>
      </b>
      <span class="mini-label status-info-date">
        <ng-container
          [ngTemplateOutlet]="itemDate"
          [ngTemplateOutletContext]="{ $implicit: item }"
        ></ng-container>
      </span>
    </div>
  </li>
</ul>

<button
  class="w-100 mt-2 theme-hprimarydark"
  *ngIf="list && list.result_count > list.results.length"
  (click)="nextPageClicked()"
  translate
>
  {{ loadMoreText }}
</button>
