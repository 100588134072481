<ng-container
  *ngIf="{
    rankings: rankings$ | async,
    summaryCompleted: summaryCompleted$ | async,
    summaryFailed: summaryFailed$ | async
  } as widget"
>
  <ng-container
    *ngIf="widget.summaryCompleted || widget.summaryFailed; else loading"
  >
    <tile *ngIf="widget.summaryCompleted" class="ranking-widget">
      <div class="text-center">
        <div>
          <i class="uil uil-trophy"></i>
        </div>

        <span class="label">{{
          "GLOBAL.RANKING_WIDGET.TITLE" | translate
        }}</span>
      </div>

      <div class="ranking-widget-container">
        <carousel
          [elements]="widget.rankings"
          [elementTemplate]="rankingTableTemplate"
          [showDots]="false"
          [animationDuration]="tableCarouselDuration"
          [autoRotation]="true"
          [externalMouseOver]="mouseOver$"
          #tableCarousel
        >
          <ng-template
            #rankingTableTemplate
            let-ranking="element"
            let-index="index"
            let-active="activeElement"
          >
            <div
              [ngClass]="{
                faded: index !== active
              }"
              class="ranking-table-container"
            >
              <ranking-table
                sidebar-content
                [ranking]="ranking.ranking"
                [unitLabel]="ranking.unitLabel"
                [mini]="true"
              ></ranking-table>
            </div>
          </ng-template>
        </carousel>

        <carousel
          [elements]="widget.rankings"
          [elementTemplate]="rankingPositionTemplate"
          [showDots]="false"
          [autoRotation]="true"
          [externalMouseOver]="mouseOver$"
          #positionCarousel
        >
          <ng-template
            #rankingPositionTemplate
            let-ranking="element"
            let-index="index"
            let-active="activeElement"
          >
            <div class="ranking-position">
              <a
                [routerLink]="['../ranking']"
                [queryParams]="getQueryParams(ranking)"
                [attr.data-ranking-position]="ranking.position"
                [class.number-roll]="index === active"
                class="ranking-position-number"
                restart-numbering="true"
                #positionNumber
              >
              </a>
              <a
                [routerLink]="['../ranking']"
                [queryParams]="getQueryParams(ranking)"
                class="ranking-position-name"
              >
                {{ ranking.name | translate }}
              </a>
            </div>
          </ng-template>
        </carousel>

        <dots
          [count]="widget.rankings.length"
          [activeElement]="tableCarousel.activeElement"
          (dotClicked)="
            tableCarousel.setActiveElement($event);
            positionCarousel.setActiveElement($event)
          "
          class="widget-dots"
        >
        </dots>
      </div>
    </tile>
  </ng-container>
</ng-container>

<ng-template #loading>
  <tile class="ranking-widget">
    <box-header tile-header>
      {{ "GLOBAL.RANKING_WIDGET.TITLE" | translate }}
    </box-header>
    <div class="d-flex align-items-center justify-content-center">
      <loading-spinner [width]="70" [height]="70"></loading-spinner>
    </div>
  </tile>
</ng-template>
