import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";
import { PackagesService } from "src/app/shared/services/admin/packages.service";
import {
  loadPackageUsersListFailure,
  packageUsersListLoad,
  packageUsersListLoadSuccess,
} from "../../actions/packages/package-users.actions";
import { PackageUsers } from "src/app/shared/interfaces/packages/package-users";
import {
  packageCourseUsersListLoad,
  packageCourseUsersListLoadSuccess,
  loadPackageCourseUsersListFailure,
} from "../../actions/packages/package-course-users.actions";
import { PackageCourseUsers } from "src/app/shared/interfaces/packages/package-course-users";

@Injectable()
export class PackageUsersEffect {
  getPackageUsersList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(packageUsersListLoad),
      concatMap((param) => {
        return this.packagesService
          .getPackageUsers(
            param.organizationId,
            param.packageId,
            param.teacherId,
            param.search,
          )
          .pipe(
            map((res: PackageUsers) =>
              packageUsersListLoadSuccess({ users: res }),
            ),
            catchError(({ error }) => of(loadPackageUsersListFailure(error))),
          );
      }),
    ),
  );

  getPackageCourseUsersList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(packageCourseUsersListLoad),
      concatMap((param) => {
        return this.packagesService
          .getPackageCourseUsers(
            param.organizationId,
            param.packageId,
            param.courseId,
            param.teacherId,
          )
          .pipe(
            map((res: PackageCourseUsers) =>
              packageCourseUsersListLoadSuccess({ users: res }),
            ),
            catchError(({ error }) =>
              of(loadPackageCourseUsersListFailure(error)),
            ),
          );
      }),
    ),
  );

  constructor(
    private packagesService: PackagesService,
    private actions$: Actions,
  ) {}
}
