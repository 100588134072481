import { createSelector } from "@ngrx/store";
import { Observable } from "rxjs";
import { RankingQuery } from "src/app/shared/enums/ranking-query";
import { Member } from "src/app/shared/interfaces/member";
import { Organization } from "src/app/shared/interfaces/organization";
import {
  PickerOption,
  PickerOptionLabel,
} from "src/app/shared/interfaces/picker-option";
import { Region } from "src/app/shared/interfaces/region";
import { RankingCriteria } from "src/app/shared/models/ranking-criteria";
import { Guid } from "src/app/shared/types/guid";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";
import * as fromRankingStore from "src/app/store/reducers/ranking.reducer";

export type Organizations$ = Observable<PickerOption<Guid, string>[]>;

export type CoursegroupsData = {
  options: Array<PickerOption<Guid, PickerOptionLabel>>;
  organizationId: Guid;
  courseId: Guid;
};
type AccountState = { account: fromAccountStore.AccountState };
type RankingState = { ranking: fromRankingStore.RankingState };

const me = (state: AccountState) => state.account.member;
const criteria = (state: RankingState) => state.ranking.rankingCriteria;

const filterBy: typeof RankingQuery.FilterBy = RankingQuery.FilterBy;
const groupBy: typeof RankingQuery.GroupBy = RankingQuery.GroupBy;

export const selectCities = createSelector(me, (me: Member) =>
  Object.values(
    me.organizations.reduce((acc, organization) => {
      const region = {};
      if (organization.region && typeof organization.region.id === "string") {
        region[organization.region.id] = organization.region;

        return {
          ...acc,
          ...region,
        };
      } else return acc;
    }, {} as { [key: string]: Region })
  ).map(
    (region) =>
      <PickerOption>{ label: region.name, value: region.id, icon: "building" }
  )
);

export const selectOrganizations = createSelector(me, (me: Member) =>
  me.organizations
    .filter((organization) => organization.in_ranking)
    .map(
      (organization) =>
        <PickerOption>{
          label: organization.name,
          value: organization.id,
          icon: "university",
        }
    )
);

export const selectCoursegroups = createSelector<AccountState | RankingState,[Member,RankingCriteria],CoursegroupsData
>(me, criteria, (me: Member, criteria: RankingCriteria): CoursegroupsData => {
  const isFilteredByOrg = criteria.filter_by === filterBy.organization;
  const isFilteredByGroup = criteria.filter_by === filterBy.coursegroup;

  const filterPk = criteria.filter_pk;
  let organization: Organization;
  let courseId: Guid;

  // If it's filtered by organization, find specific organization
  if (isFilteredByOrg && filterPk) {
    organization = me.organizations.find(
      (organization) => organization.id === filterPk
    );

    // If it's filtered by coursegroup, find its organization
  } else if (isFilteredByGroup && filterPk) {
    organization = me.organizations.find(
      (organization) =>
        organization.coursegroups.findIndex(
          (group) => group.id === filterPk
        ) !== -1
    );
    courseId = organization
      ? organization.coursegroups.find((group) => group.id === filterPk).course
          .id
      : undefined;
  }

  return organization
    ? {
        options: [].concat(
          <PickerOption<Guid, PickerOptionLabel>>{
            label: { text: "RANKING.COURSEGROUPS.ALL" },
            value: null,
            icon: "users-alt",
          },
          organization.coursegroups
            .filter((group) => group.has_ranking)
            .map(
              (group) =>
                <PickerOption<Guid, PickerOptionLabel>>{
                  label: { text: group.name, subtext: group.course.name },
                  value: group.id,
                  icon: "users-alt",
                }
            )
        ),
        organizationId: organization.id,
        courseId,
      }
    : { options: [], organizationId: null, courseId };
});

export const hasFilters = createSelector(
  criteria,
  (criteria: RankingCriteria): boolean => {
    return !!(
      [filterBy.coursegroup, filterBy.organization, filterBy.region].includes(
        criteria.filter_by
      ) ||
      [groupBy.organization, groupBy.region].includes(criteria.group_by) ||
      criteria.filter_pk ||
      (criteria.span && criteria.span !== RankingQuery.Span.all)
    );
  }
);
