import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from "@angular/core";
import { Nillable } from "../../../../types/nillable";
import { SelectItem } from "../../models/select-item";

@Component({
  selector: "dropdown-item",
  templateUrl: "./dropdown-item.component.html",
  styleUrls: ["./dropdown-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownItemComponent<Value> {
  @Output() mouseEnterEvent = new EventEmitter<Event>();
  @Output() clickEvent = new EventEmitter<Event>();

  @Input() id: Nillable<string>;
  @Input() option: Nillable<SelectItem<Value>>;
  @Input() templateRef: Nillable<TemplateRef<Value>>;
  @Input() selected: Nillable<boolean>;

  handleMouseEnterEvent(event: Event): void {
    this.mouseEnterEvent.emit(event);
  }

  handleClickEvent(event: Event): void {
    this.clickEvent.emit(event);
  }
}
