import { Injectable } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { filter, map, take, tap } from "rxjs/operators";
import * as fromAuth from "src/app/store/actions/auth.actions";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";
import { Member } from "../../interfaces/member";
import { ApiService } from "../api/api.service";
import { RegisterRequest } from "../../interfaces/register-request";
import { Guid } from "../../types/guid";
import { NameRequest } from "../../interfaces/name-request";
import { PasswordRequest } from "../../interfaces/password-request";
import { ForgotPasswordRequest } from "../../interfaces/forgot-password-request";
import { ResetPasswordRequest } from "../../interfaces/reset-password-request";
import { EmailRequest } from "../../interfaces/email-request";
import { ErrorListing } from "../../types/error-listing";
import { LimitType } from "../../types/limit-type";
import { CourseLimit, OrganizationLimit } from "../../interfaces/limit";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  constructor(
    private apiService: ApiService,
    private router: Router,
    private store: Store<{ account: fromAccountStore.AccountState }>,
  ) {}

  getMember(): Observable<Member> {
    return this.apiService.getRequest<Member>("me/");
  }

  getMemberType(is_teacher: boolean): Observable<any> {
    return this.store
      .select((state) => state.account.member)
      .pipe(
        tap((member: Member) => {
          if (member === null) {
            this.store.dispatch({ type: fromAuth.READ_TOKEN });
          }
        }),
        filter((member: Member) => member && member.is_teacher === is_teacher),
        take(1),
      );
  }

  checkIsLoggedIn(): Observable<any> {
    return this.store
      .select((state) => state.account.member)
      .pipe(
        tap((member: Member) => {
          if (member === null) {
            this.store.dispatch({ type: fromAuth.READ_TOKEN });
          }
        }),
        filter((member: Member) => member !== null),
        take(1),
      );
  }

  redirectMember(
    member: Member,
    redeemCode: Guid,
  ): Promise<boolean> | undefined {
    if (
      !["account/login", "account/redeem"].some((route) =>
        this.router.url.includes(route),
      )
    ) {
      return;
    }

    const extras: NavigationExtras = redeemCode
      ? { queryParams: { redeemCode } }
      : {};

    if (member.is_orgadmin) {
      return this.router.navigate(["/orgadmin"], extras);
    }

    if (member.is_teacher) {
      return this.router.navigate(["/teacher"], extras);
    }

    return this.router.navigate(["/student"], extras);
  }

  registerTeacher(params: {
    request: RegisterRequest;
    invitationId?: Guid;
    redeemCode?: Guid;
  }): Observable<{}> {
    return this.apiService.postRequest<{}>(
      `register/teacher/${params.invitationId || params.redeemCode}/`,
      params.request,
    );
  }

  registerStudent(params: {
    request: RegisterRequest;
    invitationId?: Guid;
    redeemCode?: Guid;
  }): Observable<{}> {
    return this.apiService.postRequest<{}>(
      `register/student/${params.invitationId || params.redeemCode}/`,
      params.request,
    );
  }

  precheckTeacher(params: {
    invitationId: Guid;
  }): Observable<{ email: string }> {
    return this.apiService.getRequest<{ email: string }>(
      `register/teacher/${params.invitationId}/precheck/`,
    );
  }

  precheckStudent(params: {
    invitationId: Guid;
  }): Observable<{ email: string }> {
    return this.apiService.getRequest<{ email: string }>(
      `register/student/${params.invitationId}/precheck/`,
    );
  }

  changeName(params: NameRequest): Observable<Member> {
    return this.apiService.putRequest<Member>(`me/name/`, params);
  }

  changePassword(params: PasswordRequest): Observable<{}> {
    return this.apiService.putRequest<{}>(`me/password/`, params);
  }

  forgotPassword(params: ForgotPasswordRequest): Observable<{}> {
    return this.apiService.postRequest<{}>(`password/forgot/`, params);
  }

  resetPassword(params: {
    request: ResetPasswordRequest;
    forgotToken: Guid;
  }): Observable<{}> {
    return this.apiService.putRequest<{}>(
      `password/reset/${params.forgotToken}/`,
      params.request,
    );
  }

  changeEmail(params: EmailRequest): Observable<{} | ErrorListing> {
    return this.apiService.putRequest<{} | ErrorListing>(`me/email/`, params);
  }

  changeEmailCheck(emailToken: Guid): Observable<{}> {
    return this.apiService.putRequest<{}>(`me/email/${emailToken}/`, {});
  }

  updateInRanking(settingsBody: { in_ranking: boolean }): Observable<Member> {
    return this.apiService.putRequest<Member>(`me/in_ranking/`, settingsBody);
  }

  redeem(code: Guid): Observable<any> {
    return this.apiService.putRequest("coderedeem/activate/", { code });
  }

  getLimits(type: LimitType): Observable<Array<CourseLimit>> {
    return this.apiService.getRequest<Array<CourseLimit>>(`me/limits/courses/`);
  }

  getLimit(
    type: LimitType,
    id: Guid,
    organizationId?: string,
  ): Observable<CourseLimit> {
    let url = `me/limits/courses/${id}/`;

    if (organizationId) {
      url += `?organization=${organizationId}`;
    }
    return this.apiService.getRequest<CourseLimit>(url);
  }
}
