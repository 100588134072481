<header class="page-header">
  <div class="header-container hcontainer hcontainer-extended">
    <h1 class="page-heading">
      <span class="prefix" *ngIf="prefix">{{ prefix }}</span>

      <span class="page-heading-name">
        <ng-content />
      </span>

      <div class="header-bottom">
        <ng-content select="[header-bottom]" />
      </div>
    </h1>
  </div>
</header>
