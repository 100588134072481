import { AbstractControl, ValidationErrors } from "@angular/forms";

export const appendAbstractControlError = (
  control: AbstractControl,
  error: ValidationErrors | null,
): void => {
  const currentErrors = control.errors;

  control.setErrors({ ...currentErrors, ...error });
}


export const markAsTouchedAndMarkAsDirty = (
  control: AbstractControl,
): void => {
  control.markAsDirty();
  control.markAsTouched();
}


export const removeAbstractControlError = (
  control: AbstractControl,
  error: string,
): void => {
  const currentErrors = control.errors;

  if (!currentErrors) {
    return;
  }

  delete currentErrors[error];

  const updatedErrors =
    Object.keys(currentErrors).length === 0 ? null : currentErrors;

  control.setErrors(updatedErrors);
}
