<div class="icon-input d-flex w-100 no-gutters">
  <div class="input-body col">
    <input
      *ngIf="placeholder"
      tabindex="-1"
      type="text"
      class="icon-input-placeholder"
      value="{{ placeholder | translate }}"
      readonly
    />
    <ng-content></ng-content>
  </div>

  <div
    class="icon-glyph col-auto d-flex align-items-center justify-content-center"
  >
    <span class="{{ set }} {{ set }}-{{ icon }}"></span>
  </div>
</div>
