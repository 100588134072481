import { createAction, props } from "@ngrx/store";
import { CreateTeamTeachers } from "src/app/modules/orgadmin/components/team-teachers/team-teachers-output.model";
import { CreateTeamTeachersResponse } from "src/app/shared/interfaces/orgadmin/course-teachers";

export const coursePrefix = "[Create Team Teachers]";

const createTeamTeachersActions = {
  request: `${coursePrefix} Load`,
  requestSuccess: `${coursePrefix} Load success`,
  requestFail: `${coursePrefix} Load fail`,
};

export const createTeamTeachersLoad = createAction(
  createTeamTeachersActions.request,
);

export const createTeamTeachers = createAction(
  createTeamTeachersActions.requestSuccess,
  props<{
    organizationId: string;
    courseId: string;
    subId: string;
    payload: CreateTeamTeachers;
    packageId?: string;
  }>(),
);

export const createTeamTeachersFail = createAction(
  createTeamTeachersActions.requestFail,
  props<{ error: CreateTeamTeachersResponse | null }>(),
);
