import { Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { TutorialTeacherSteps } from "src/app/shared/enums/tutorial-steps";
import { Course } from "src/app/shared/interfaces/course";
import { Group } from "src/app/shared/interfaces/group";
import { Guid } from "src/app/shared/types/guid";
import { Nillable } from "src/app/shared/types/nillable";
import { SetTutorialStep } from "src/app/store/actions/tutorial.actions";
import * as fromTutorial from "src/app/store/reducers/tutorial.reducer";
import { TutorialState } from "src/app/store/reducers/tutorial.reducer";

@Component({
  selector: "course-listbox-element-content",
  templateUrl: "./course-listbox-element-content.component.html",
  styleUrls: ["./course-listbox-element-content.component.scss"],
})
export class CourseListboxElementContentComponent {
  @Input() showProgressBadge = true;
  @Input("teacher") isTeacher = false;
  @Input() course: Course;
  @Input() group: Group;
  @Input() showIndicator = false;
  @Input() showGroupList = false;
  @Input() displayStats = false;
  @Input() organizationId: Nillable<Guid>;

  tutorial$: Observable<TutorialState> = this.store.select(
    (state) => state.tutorial,
  );

  TutorialTeacherSteps = TutorialTeacherSteps;

  constructor(private store: Store<{ tutorial: fromTutorial.TutorialState }>) {}

  getGroupListLink(groupId: Guid): string {
    return `../courses/${this.course.id}/groups/${groupId}`;
  }

  getCourseLink(): string {
    return `../courses/${this.course.id}${
      this.group ? "/groups/" + this.group.id : this.getLinkGroup()
    }`;
  }

  get canShowCourseLink(): boolean {
    if (!this.isTeacher) {
      return true;
    }

    return !this.hasAnyGroup();
  }

  private getLinkGroup(): string {
    if (this.hasAnyGroup()) {
      let activeGroupIndex: number = this.course.groups.findIndex(
        (group) => group.is_active === true,
      );

      let pickedGroupId: Guid =
        this.course.groups[activeGroupIndex > -1 ? activeGroupIndex : 0].id;

      return `/groups/${pickedGroupId}`;
    } else {
      return "";
    }
  }

  private hasAnyGroup(): boolean {
    return this.course.groups && this.course.groups.length !== 0;
  }

  handleTutorialStep(tutorial: TutorialState) {
    if (tutorial.tutorial_step === TutorialTeacherSteps.COURSE_EXPAND_GROUPS) {
      this.store.dispatch(
        new SetTutorialStep({
          id: tutorial.id,
          tutorial_skip: false,
          tutorial_step: TutorialTeacherSteps.COURSE_DASHBOARD,
        }),
      );
    }
  }
}
