import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map, withLatestFrom } from "rxjs/operators";
import { NotificationsService } from "src/app/shared/services/notifications/notifications.service";
import { Guid } from "src/app/shared/types/guid";
import {
  NotificationSettings,
  NotificationSnooze,
} from "src/app/shared/types/notification-settings";
import * as fromUI from "src/app/store/actions/ui.actions";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";
import * as fromUIStore from "src/app/store/reducers/ui.reducer";

@Component({
  selector: "notifications-snooze",
  templateUrl: "./notifications-snooze.component.html",
  styleUrls: ["./notifications-snooze.component.scss"],
})
export class NotificationsSnoozeComponent {
  notificationSettings$: Observable<NotificationSettings> = this.store
    .select((state) => state.ui.notificationSettings)
    .pipe(
      withLatestFrom(
        this.store.select((state) => state.account.member.is_teacher)
      ),
      map(([settings, isTeacher]: [NotificationSettings | null, boolean]) => {
        if (!settings) {
          return this.notificationsService.createSettings(
            isTeacher ? "teacher" : "student"
          );
        } else {
          return settings;
        }
      })
    );

  userId$: Observable<Guid> = this.store.select(
    (state) => state.account.member.id
  );

  snoozeOptions: Array<NotificationSnooze & { name: string }> = [
    { name: "NEVER", period: undefined },
    { name: "MIN30", period: 30 },
    { name: "MIN60", period: 60 },
    { name: "MIN120", period: 120 },
    { name: "MIN480", period: 480 },
    { name: "ALWAYS", period: "always" },
  ];

  constructor(
    private store: Store<{
      ui: fromUIStore.UIState;
      account: fromAccountStore.AccountState;
    }>,
    private notificationsService: NotificationsService
  ) {}

  setSetting(
    snooze: NotificationSnooze,
    settings: NotificationSettings,
    userId: Guid
  ) {
    const updatedSettings = settings.mergeIn(["notificationSnooze"], snooze);
    this.store.dispatch(
      new fromUI.SaveNotificationSettings({ settings: updatedSettings, userId })
    );
  }
}
