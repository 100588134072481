import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { isEmpty } from "lodash-es";
import { Guid } from "../../../types/guid";
import { AnswerResponse } from "../../../interfaces/answer-response";
import { ConnectedPosition } from "@angular/cdk/overlay";

@Component({
  selector: "course-exercise-answer-history-popup-menu",
  templateUrl: "./course-exercise-answer-history-popup-menu.component.html",
  styleUrls: ["./course-exercise-answer-history-popup-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseExerciseAnswerHistoryPopupMenuComponent {
  @ViewChild("triggerButton", { static: false })
  triggerButton: ElementRef<HTMLButtonElement>;
  @Output() downloadEvent = new EventEmitter<{ id: Guid; filename: string }>();

  @Input() answers: AnswerResponse[] = [];

  get isEmpty(): boolean {
    return isEmpty(this.answers);
  }

  isOpen: boolean = false;
  readonly popupPositions: Array<ConnectedPosition> = [
    {
      originX: "end",
      originY: "bottom",
      overlayX: "end",
      overlayY: "top",
      offsetY: 5,
    },
    {
      originX: "end",
      originY: "top",
      overlayX: "end",
      overlayY: "bottom",
      offsetY: -5,
    },
    {
      originX: "end",
      originY: "top",
      overlayX: "start",
      overlayY: "bottom",
      offsetY: -5,
    },
    {
      originX: "center",
      originY: "top",
      overlayX: "center",
      overlayY: "bottom",
      offsetY: -5,
    },
    {
      originX: "center",
      originY: "bottom",
      overlayX: "center",
      overlayY: "top",
      offsetY: 5,
    },
  ];

  handleDownload(answer: AnswerResponse): void {
    this.downloadEvent.emit({ id: answer.id, filename: answer.filename });
  }

  open(): void {
    this.isOpen = true;
  }

  close(event: MouseEvent): void {
    if (
      this.triggerButton.nativeElement.contains(event.target as Node) ||
      event.target === this.triggerButton.nativeElement
    ) {
      event.stopPropagation();
    }
    this.isOpen = false;
  }
}
