import { AfterViewInit, Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { environment } from "src/environments/environment";
import { UIService } from "./shared/services/ui/ui.service";
import videojs from "video.js";

import * as videojsPL from "video.js/dist/lang/pl.json";
import { JiraWidgetService } from "./shared/services/jira-widget/jira-widget.service";

@Component({
  selector: "root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements AfterViewInit {
  title = "CyberSkiller";

  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
    private jiraWidget: JiraWidgetService,
  ) {
    translate.setDefaultLang(environment.defaultLang);
    this.getCookiesLang();
    // this.helpWidget.destroyWidget();
    // this.helpWidget.bootWidget();
    // this.helpWidget.hideWidget();
    UIService.setCSSContainers();
    videojs.addLanguage("pl", { ...videojsPL, Quality: "Jakość" });
  }

  ngAfterViewInit(): void {
    this.jiraWidget.injectDataToWidget();
  }

  private getCookiesLang() {
    let lang: string = this.cookieService.get("cyber-skiller-lang");

    if (lang) {
      this.translate.use(lang);
    } else {
      this.translate.use(environment.defaultLang);
    }
  }
}
