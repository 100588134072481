import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { Certificate } from "../../interfaces/certificate";
@Component({
  selector: "card-certificate",
  templateUrl: "./card-certificate.component.html",
  styleUrls: ["./card-certificate.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardCertificateComponent {
  @Input() certificate: Certificate;
  @Output() certificateUrlEmitter = new EventEmitter<string>();

  certificateUrl(url: string): void {
    this.certificateUrlEmitter.emit(url);
  }
}
