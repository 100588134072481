<input
  type="checkbox"
  [id]="name"
  [ngModel]="value || toggled"
  (ngModelChange)="handleOnChange($event)"
  [disabled]="busy"
/>
<label
  class="checkbox switch-checkbox"
  [for]="name"
  [innerHTML]="label | translate"
  [ngClass]="{ busy: busy }"
>
</label>

<countdown
  *ngIf="busy"
  class="loading"
  size="14px"
  color="hprimarydark"
></countdown>
