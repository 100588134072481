<div class="limit-organization-name heading-5">
  {{ organizationName }}
</div>

<div class="limit-label label">
  {{ label | translate }}
</div>

<div class="limit-counter heading-1">
  <ng-container *ngIf="isLimited; else unlimitedTemplateRef">
    {{ limit.limit_current }}/{{ limit.limit_max }}
  </ng-container>
</div>

<ng-template #unlimitedTemplateRef>
  {{ limit.limit_current }}
</ng-template>
