import { ErrorHandler, Injectable } from "@angular/core";

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  handleError(error: Error): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (
      error &&
      error.hasOwnProperty("message") &&
      chunkFailedMessage.test(error.message)
    ) {
      window.location.reload();
    }

    console.error(error);
  }
}
