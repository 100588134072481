import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromUI from 'src/app/store/actions/ui.actions';
import { Member } from '../../interfaces/member';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  @Input() account: Member;

  isToggled = false;

  constructor(private store: Store<{}>) {}

  toggle() {
    this.isToggled = !this.isToggled;
  }

  storeLangSession(language: string) {
    this.store.dispatch(new fromUI.StoreLangSessionKey({language}));
  }
}
