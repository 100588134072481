import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'formatHour'
})
export class FormatHourPipe implements PipeTransform {
  transform(hour: string): string {
    return moment(hour, 'HH:mm:ss').format('HH:mm');
  }
}
