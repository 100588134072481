import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SimpleListItemComponent } from "src/app/shared/blocks/simple-list-item/simple-list-item.component";
import { Course } from "src/app/shared/interfaces/course";
import { Group } from "src/app/shared/interfaces/group";

@Component({
  selector: "group-reedem-listbox-element-content",
  templateUrl: "./group-reedem-listbox-element-content.component.html",
  styleUrls: ["./group-reedem-listbox-element-content.component.scss"],
})
export class GroupReedemListboxElementContentComponent {
  @Input("teacher") isTeacher = false;
  @Input() course: Course;
  @Input() group: Group;

  @Output() onGo: EventEmitter<void> = new EventEmitter();

  onClick() {
    this.onGo.emit();
  }
}
