import { Component, Input } from "@angular/core";

@Component({
  selector: "resolved-exercises-count-badge",
  templateUrl: "./resolved-exercises-count-badge.component.html",
  styleUrls: ["./resolved-exercises-count-badge.component.scss"],
})
export class ResolvedExercisesCountBadgeComponent {
  @Input() passed: number;
  @Input() all: number;
}
