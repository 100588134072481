import { Component, EventEmitter, Output, Input } from "@angular/core";
import { ModalData } from '../../interfaces/modal-data';

@Component({
  selector: "modal",
  template: ``,
  styles: [],
})
export class ModalComponent<T = ModalData> {
  @Input() modalTitle: string;
  @Input() data: T;
  @Output() close: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}
}
