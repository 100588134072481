import { Component, Input } from "@angular/core";
import { ModalComponent } from "../../modal/modal.component";
import { Store } from "@ngrx/store";

import { SetTutorialStep } from "src/app/store/actions/tutorial.actions";
import { Observable } from "rxjs";
import {
  TutorialSteps,
  TutorialTeacherSteps,
} from "src/app/shared/enums/tutorial-steps";

@Component({
  selector: "tutorial-modal",
  templateUrl: "./tutorial-modal.component.html",
  styleUrls: ["./tutorial-modal.component.scss"],
})
export class TutorialModalComponent extends ModalComponent {
  @Input() teacher: boolean;
  tutorialId$: Observable<string> = this.store.select(
    (state) => state.tutorial.id,
  );
  TutorialSteps = TutorialSteps;
  TutorialTeacherSteps = TutorialTeacherSteps;

  constructor(private store: Store<{ tutorial: { id: string } }>) {
    super();
  }

  handleTutorialStart(id: string): void {
    this.store.dispatch(
      new SetTutorialStep({
        id,
        tutorial_skip: false,
        tutorial_step: TutorialSteps.MAIN_PANEL,
      }),
    );

    this.close.emit("close");
  }

  handleTutorialSkip(id: string): void {
    this.store.dispatch(
      new SetTutorialStep({
        id,
        tutorial_skip: true,
        tutorial_step: 99,
      }),
    );

    this.close.emit("close");
  }
}
