import { Action } from "@ngrx/store";
import {
  QuizSolutionItemRequest,
  QuizSolutionsListRequest,
} from "src/app/modules/quiz/interfaces/quiz-solutions-list-request";
import {
  QuizSolutionItem,
  QuizSolutionsListResults,
} from "src/app/modules/quiz/interfaces/quiz-solutions-list-results";

export const GET_QUIZ_SOLUTIONS_LIST =
  "[QuizSolutionsHistory Method] GetQuizSolutionsList";
export const GET_QUIZ_SOLUTIONS_LIST_PAGE =
  "[QuizSolutionsHistory Method] GetQuizSolutionsListPage";
export const GET_QUIZ_SOLUTIONS_LIST_COMPLETED =
  "[QuizSolutionsHistory Method] GetQuizSolutionsListCompleted";
export const GET_QUIZ_SOLUTIONS_LIST_PAGE_COMPLETED =
  "[QuizSolutionsHistory Method] GetQuizSolutionsListPageCompleted";

export const GET_QUIZ_SOLUTION =
  "[QuizSolutionsHistory Method] GetQuizSolution";
export const GET_QUIZ_SOLUTION_COMPLETED =
  "[QuizSolutionsHistory Method] GetQuizSolutionCompleted";

export class GetQuizSolutionsList implements Action {
  readonly type = GET_QUIZ_SOLUTIONS_LIST;

  constructor(public payload: QuizSolutionsListRequest) {}
}

export class GetQuizSolutionsListPage implements Action {
  readonly type = GET_QUIZ_SOLUTIONS_LIST_PAGE;

  constructor(public payload: QuizSolutionsListRequest) {}
}

export class GetQuizSolutionsListCompleted implements Action {
  readonly type = GET_QUIZ_SOLUTIONS_LIST_COMPLETED;

  constructor(public payload: QuizSolutionsListResults) {}
}

export class GetQuizSolutionsListPageCompleted implements Action {
  readonly type = GET_QUIZ_SOLUTIONS_LIST_PAGE_COMPLETED;

  constructor(public payload: QuizSolutionsListResults) {}
}

export class GetQuizSolution implements Action {
  readonly type = GET_QUIZ_SOLUTION;

  constructor(public payload: QuizSolutionItemRequest) {}
}

export class GetQuizSolutionCompleted implements Action {
  readonly type = GET_QUIZ_SOLUTION_COMPLETED;

  constructor(public payload: QuizSolutionItem) {}
}

export type QuizSolutionsActions =
  | GetQuizSolutionsList
  | GetQuizSolutionsListPage
  | GetQuizSolutionsListCompleted
  | GetQuizSolutionsListPageCompleted
  | GetQuizSolution
  | GetQuizSolutionCompleted;
