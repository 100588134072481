<ng-container *ngIf="question.has_figure">
  <figure *ngIf="figure_ready; else figureLoading" class="question-figure">
    <img class="figure-img" alt="{{ question.content }}" [src]="figure" />
  </figure>

  <ng-template #figureLoading>
    <div class="d-flex justify-content-center figure-loading">
      <loading-spinner width="64" height="64"></loading-spinner>
    </div>
  </ng-template>
</ng-container>
