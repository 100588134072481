<div class="widget-container" (click)="onWidgetClick()">
  <icon 
    *ngIf="icon" 
    class="widget-icon" 
    [name]="icon" 
    [set]="set" 
  />
  <h3 class="widget-header widget-label">{{ data.label | translate }}</h3>
  <p 
    *ngIf="data.sub_label" 
    class="widget-label label pt-3 pb-3"
  >
    {{ data.sub_label | translate }}
  </p>
  <h1 class="widget-header">
    {{ data.numerical_value }}
  </h1>

  <ng-content />
</div>
