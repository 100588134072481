<div
  #tutorialBox
  class="tutorial-message"
  [ngStyle]="customStyle"
  *ngIf="{ tutorial: tutorial$ | async, isTeacher: isTeacher$ | async } as data"
>
  <div class="d-flex justify-content-end">
    <icon
      class="tutorial-exit-icon"
      set="uil"
      name="times"
      (click)="close(data.tutorial.id)"
    ></icon>
  </div>
  <div class="col mb-3">
    <label class="tutorial-title font-weight-bold">{{ title }}</label>
    <p class="tutorial-desc" [innerHTML]="message"></p>
  </div>

  <div class="d-flex justify-content-between align-items-baseline">
    <p class="pl-4 tutorial-step">
      {{ data.tutorial.tutorial_step }}/{{
        data.isTeacher
          ? TutorialTeacherSteps.COURSE_START_BTN
          : TutorialSteps.FIRST_STEPS
      }}
    </p>
    <button
      class="btn-blue-light-600"
      (click)="next(data.tutorial.id, data.isTeacher)"
      [disabled]="
        finishTutorial ||
        this.step === TutorialTeacherSteps.COURSE_EXPAND_GROUPS
      "
    >
      {{ "TUTORIAL_DATA.NEXT" | translate }}
    </button>
  </div>

  <!-- <div class="tooltip__arrow" [ngClass]="arrowPosition | arrowClass"></div> -->
</div>

<div class="overlay"></div>
