import { Injectable, NgZone } from "@angular/core";

type FreshworksWidget = Function;

@Injectable({
  providedIn: "root",
})
export class HelpWidgetService {
  private widget: FreshworksWidget;
  private mounted: boolean = false;
  private containerDelay = 1000;

  constructor(private _ngZone: NgZone) {}

  private getInstance(): void {
    this.widget = window["FreshworksWidget"];
  }

  destroyWidget(): void {
    this.getInstance();

    this._ngZone.runOutsideAngular(() => {
      this.widget("destroy");

      this._ngZone.run(() => {
        this.mounted = false;
      });
    });
  }

  bootWidget(): void {
    this.getInstance();

    this._ngZone.runOutsideAngular(() => {
      this.widget("boot");

      window.setTimeout(() => {
        let containerElement: HTMLDivElement =
          document.querySelector<HTMLDivElement>("#freshworks-container");

        containerElement.style.zIndex = "1001";
        containerElement.style.position = "fixed";

        this._ngZone.run(() => {
          this.mounted = true;
        });
      }, this.containerDelay);
    });
  }

  showWidget(): void {
    this._ngZone.runOutsideAngular(() => {
      this.widget("show");
    });
  }

  hideWidget(): void {
    this._ngZone.runOutsideAngular(() => {
      this.widget("hide");
    });
  }

  hideFields(fields: Array<string>): void {
    this.getInstance();

    this._ngZone.runOutsideAngular(() => {
      this.widget("hide", "ticketForm", fields);
    });
  }

  setIdentify(identify: { name: string; email: string }): void {
    this.getInstance();

    this._ngZone.runOutsideAngular(() => {
      this.widget("identify", "ticketForm", identify);
    });
  }

  isInitialized(): boolean {
    return typeof this.widget !== "undefined";
  }

  isMounted(): boolean {
    return this.mounted;
  }
}
