import { Component, EventEmitter, Input, OnChanges } from "@angular/core";
import { Guid } from "src/app/shared/types/guid";
import { Exercise } from "../../../interfaces/exercise";
import { ExerciseControl } from "../../../interfaces/exercise-control";
import { HelpRequest } from "../../../interfaces/help-request";
import { Hint } from "../../../interfaces/hint";
import { VideoHint } from "src/app/shared/interfaces/video-hint";
import { VideoHintPlayer } from "src/app/shared/enums/video-hint-player";
import { TutorialTeacherSteps } from "src/app/shared/enums/tutorial-steps";
import * as fromTutorial from "src/app/store/reducers/tutorial.reducer";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

@Component({
  selector: "course-exercise-help",
  templateUrl: "./course-exercise-help.component.html",
  styleUrls: ["./course-exercise-help.component.scss"],
})
export class CourseExerciseHelpComponent implements OnChanges {
  @Input() courseId: Guid;
  @Input() topicId: Guid;
  @Input() groupId: Guid;
  @Input() exercise: Exercise;
  @Input() isTeacher: boolean;
  @Input()
  emitters: {
    [key: string]: EventEmitter<ExerciseControl | HelpRequest>;
  };

  tutorial$: Observable<TutorialTeacherSteps> = this.store.select(
    (state) => state.tutorial.tutorial_step,
  );

  TutorialTeacherSteps = TutorialTeacherSteps;

  currentHint: Hint;
  currentVideoHint: VideoHint;
  currentHelpMode: "text" | "video";
  isTextHelpToggled = false;
  isVideoHelpToggled = false;
  isVideoHelpRequested = false;
  isTextHelpRequested = false;

  hintPlayer = VideoHintPlayer;

  constructor(
    private store: Store<{
      tutorial: fromTutorial.TutorialState;
    }>,
  ) {}

  ngOnChanges() {
    if (this.exercise.help) {
      if (this.isTextHelpRequested) {
        this.isTextHelpToggled = true;
        this.isTextHelpRequested = false;
      }

      this.markCurrentHelpModeAsText();
    }

    if (this.exercise.video_help) {
      if (this.isVideoHelpRequested) {
        this.isVideoHelpToggled = true;
        this.isVideoHelpRequested = false;
      }

      this.markCurrentHelpModeAsVideo();

      if (this.exercise.video_help.length === 1) {
        this.setCurrentVideoHint(this.exercise.video_help[0]);
      }
    }
  }

  handleHelpClick(): void {
    this.currentHint = null;

    if (!this.exercise.help) {
      this.tryToGetTextHelp();
    } else {
      this.toggleTextHelp();
    }

    this.markCurrentHelpModeAsText();
  }

  handleVideoHelpClick(): void {
    this.currentVideoHint = null;

    if (!this.exercise.video_help) {
      this.tryToGetVideoHelpOnClick();
    } else {
      this.tryToShowVideoHintOnClick();
    }

    this.markCurrentHelpModeAsVideo();
  }

  private canSetCurrentVideoHint(): boolean {
    return (
      this.isVideoHelpToggled &&
      this.exercise.video_help &&
      this.exercise.video_help.length === 1
    );
  }

  setCurrentHint(hint: Hint): void {
    this.currentHint = hint;
  }

  setCurrentVideoHint(hint: VideoHint): void {
    this.currentVideoHint = hint;
  }

  private notifyGetTextHelpEvent(): void {
    this.emitters["getHelp"].emit({
      CourseId: this.courseId,
      TopicId: this.topicId,
      ExerciseId: this.exercise.id,
      GroupId: this.groupId,
    });
  }

  private toggleTextHelp(): void {
    this.isTextHelpToggled =
      this.currentHelpMode === "video" ? true : !this.isTextHelpToggled;
  }

  private toggleVideoHelp(): void {
    this.isVideoHelpToggled =
      this.currentHelpMode === "text" ? true : !this.isVideoHelpToggled;
  }

  private notifyGetVideoHelpEvent(): void {
    this.emitters["getVideoHelp"].emit({
      CourseId: this.courseId,
      TopicId: this.topicId,
      ExerciseId: this.exercise.id,
      GroupId: this.groupId,
    });
  }

  private markCurrentHelpModeAsVideo(): void {
    this.currentHelpMode = "video";
  }

  private markCurrentHelpModeAsText(): void {
    this.currentHelpMode = "text";
  }

  private tryToGetTextHelp(): void {
    this.notifyGetTextHelpEvent();

    this.isTextHelpRequested = true;
  }

  private tryToGetVideoHelpOnClick(): void {
    this.notifyGetVideoHelpEvent();

    this.isVideoHelpRequested = true;
  }

  private tryToShowVideoHintOnClick(): void {
    this.toggleVideoHelp();

    if (!this.canSetCurrentVideoHint()) {
      return;
    }

    this.setCurrentVideoHint(this.exercise.video_help[0]);
  }
}
