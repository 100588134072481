import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { SelectButtonItem } from "./models/select-button-item";
import { Nillable } from "../../types/nillable";
import { isNil } from "lodash-es";
import { SelectButtonOptionClickEvent } from "./events/select-button-option-click-event";

@Component({
  selector: "select-button",
  templateUrl: "./select-button.component.html",
  styleUrls: ["./select-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectButtonComponent<Value> implements OnChanges, OnInit {
  @Output() optionClickEvent =
    new EventEmitter<SelectButtonOptionClickEvent<Value> | null>();

  @Input() unselectable: boolean = true;
  @Input() selected: Nillable<Value>;
  @Input() options: Array<SelectButtonItem<Value>> = [];

  value: Nillable<Value>;

  ngOnChanges(changes: SimpleChanges): void {
    const { selected } = changes;

    if (selected) {
      this.configureSelectedOption();
    }
  }

  ngOnInit(): void {
    this.configureSelectedOption();

    if (!this.unselectable) {
      this.value = null;
    }
  }

  isSelected(option: SelectButtonItem<Value>): boolean {
    return this.value === option.value;
  }

  handleOptionSelect(
    event: MouseEvent,
    option: SelectButtonItem<Value>,
    index: number,
  ): void {
    const isSelected = this.isSelected(option);

    if (isSelected && !this.unselectable) {
      this.value = null;
      this.optionClickEvent.emit(null);
      return;
    }

    if (isSelected && this.unselectable) {
      return;
    }

    const model = new SelectButtonOptionClickEvent(event, option, index);

    if (option.value === this.value) {
      this.value = null;
      this.optionClickEvent.emit(model);

      return;
    }

    this.value = option.value;
    this.optionClickEvent.emit(model);
  }

  private configureSelectedOption(): void {
    const found = this.options.find((option) => option.value === this.selected);

    if (isNil(found)) {
      const [first] = this.options;

      this.value = first.value;

      return;
    }

    this.value = found.value;
  }
}
