import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Nillable } from "../../types/nillable";

@Component({
  selector: "input-number",
  templateUrl: "./input-number.component.html",
  styleUrls: ["./input-number.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputNumberComponent),
      multi: true,
    },
  ],
})
export class InputNumberComponent implements ControlValueAccessor {
  @Input() min: Nillable<number>;
  @Input() max: Nillable<number>;
  @Input() prefix: Nillable<string>;
  @Input() suffix: Nillable<string>;
  @Input() mask: Nillable<string>;
  @Input() readOnly: boolean = false;

  value: Nillable<number>;

  onChange = (value: Nillable<number>): void => {};
  onTouched = () => {};

  registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: number): void {
    this.value = value;
  }

  handleInputEvent(event: Event): void {
    const value: string = (event.target as HTMLInputElement).value;
    const convertedValue: number = value ? Number(value) : null;

    this.value = convertedValue;
    this.onChange(convertedValue);
    this.onTouched();
    this.writeValue(convertedValue);
  }
}
