import {
  Directive,
  ElementRef,
  HostListener,
  Renderer2,
  Input,
  OnInit
} from '@angular/core';

@Directive({
  selector: '[highlight]'
})
export class HighlightDirective implements OnInit {
  @Input('highlight') group = '';

  constructor(private ref: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.applyGroup();
  }

  private applyGroup() {
    if (this.group) {
      this.ref.nativeElement.setAttribute('data-highlight-group', this.group);
    }
  }

  @HostListener('mouseenter')
  mouseover() {
    if (this.group) {
      let elements = document.querySelectorAll(
        `[data-highlight-group="${this.group}"]`
      );

      elements.forEach(element => {
        this.renderer.addClass(element, 'highlight');
      });
    } else {
      this.renderer.addClass(this.ref.nativeElement, 'highlight');
    }
  }

  @HostListener('mouseleave')
  mouseleave() {
    if (this.group) {
      let elements = document.querySelectorAll(
        `[data-highlight-group="${this.group}"]`
      );

      elements.forEach(element => {
        this.renderer.removeClass(element, 'highlight');
      });
    } else {
      this.renderer.removeClass(this.ref.nativeElement, 'highlight');
    }
  }
}
