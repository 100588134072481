import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";
import { PackagesService } from "src/app/shared/services/admin/packages.service";
import {
  loadPackageListFailure,
  packageListLoad,
  packageListLoadSuccess,
} from "../../actions/packages/packages.actions";
import {
  loadPackageFailure,
  packageLoad,
  packageLoadSuccess,
} from "../../actions/packages/single-package.actions";
import { Package } from "src/app/shared/interfaces/packages/package";

@Injectable()
export class PackagesEffect {
  getPackageList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(packageListLoad),
      concatMap((param) => {
        return this.packagesService.getPackages(param.id).pipe(
          map((res: Package[]) => packageListLoadSuccess({ packages: res })),
          catchError(({ error }) => of(loadPackageListFailure(error))),
        );
      }),
    ),
  );

  getSinglePackage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(packageLoad),
      concatMap((param) => {
        return this.packagesService
          .getSinglePackage(param.organizationId, param.packageId)
          .pipe(
            map((res: Package) => packageLoadSuccess({ package: res })),
            catchError(({ error }) => of(loadPackageFailure(error))),
          );
      }),
    ),
  );

  constructor(
    private packagesService: PackagesService,
    private actions$: Actions,
  ) {}
}
