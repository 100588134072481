import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import {
  CourseAdmin,
  PagionationData,
} from "src/app/shared/interfaces/orgadmin/course-organization";

export const coursePrefix = "[Courses Active Admin List]";

const coursesAdminListActions = {
  request: `${coursePrefix} Load`,
  requestActiveSuccess: `${coursePrefix} Load active success`,
  requestFail: `${coursePrefix} Load fail`,
};

export const coursesAdminActiveListLoad = createAction(
  coursesAdminListActions.request,
  props<{
    id: string;
    page?: number;
    size?: number;
    search?: string;
    active: boolean;
  }>(),
);

export const coursesAdminActiveListLoadSuccess = createAction(
  coursesAdminListActions.requestActiveSuccess,
  props<{ coursesActiveList: PagionationData<CourseAdmin> }>(),
);

export const loadActiveTeachersFailure = createAction(
  coursesAdminListActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
