import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "pointsPluralization"
})
export class PointsPluralizationPipe implements PipeTransform {
  transform(value: number, args: { currentLang: string }): string {
    if (typeof value === "undefined") {
      value = 0;
    }

    let solvedWindowKey: string = "COURSES.COURSE.EXERCISE.SOLVED_WINDOW";
    let pointsKey: string = "POINTS";

    let valueStr: string = value.toString();
    let valueStrLen: number = valueStr.length;
    let firstDigit: number = Number.parseInt(
      valueStr.substring(valueStrLen - 1)
    );
    let secondDigit: number = Number.parseInt(
      valueStr.substring(valueStrLen - 2, valueStrLen - 1)
    );

    switch (args.currentLang) {
      case "pl":
        if (
          (value >= 2 && value <= 4) ||
          (value > 20 &&
            (secondDigit === 0 || secondDigit >= 2) &&
            firstDigit >= 2 &&
            firstDigit <= 4)
        ) {
          pointsKey = "POINTS_2_4";
        } else if (value === 1) {
          pointsKey = "POINT";
        } else {
          pointsKey = "POINTS_0_5_10";
        }
        break;

      case "en":
        if (value === 1) {
          pointsKey = "POINT";
        } else {
          pointsKey = "POINTS";
        }
        break;
    }

    return `${solvedWindowKey}.${pointsKey}`;
  }
}
