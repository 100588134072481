import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl } from "@angular/forms";
import { Store } from "@ngrx/store";
import { isNil } from "lodash-es";
import { Observable } from "rxjs";
import {
  distinctUntilChanged,
  filter,
  map,
  startWith,
  take,
  tap,
} from "rxjs/operators";
import * as fromAccount from "src/app/store/actions/account.actions";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";
import { SelectItem } from "../../blocks/dropdown/models/select-item";
import { Guid } from "../../types/guid";
import { Nillable } from "../../types/nillable";

@Component({
  selector: "organization-selector",
  templateUrl: "./organization-selector.component.html",
  styleUrls: ["./organization-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationSelectorComponent {
  private readonly destroyRef = inject(DestroyRef);
  readonly organizations$: Observable<Array<SelectItem<Guid | null>>> =
    this.store
      .select((state) => state.account.member)
      .pipe(
        filter((member) => !isNil(member)),
        map((member) => [
          new SelectItem<null>("-", null),
          ...member.organizations.map(
            (organization) =>
              new SelectItem<Guid>(organization.name, organization.id),
          ),
        ]),
        startWith([]),
      );

  readonly currentOrganization = new FormControl(null);

  constructor(
    private readonly store: Store<{
      account: fromAccountStore.AccountState;
    }>,
  ) {
    this.initOrganization();
    this.handleValueChange();
  }

  private initOrganization(): void {
    this.store
      .select((state) => state.account.currentOrganization)
      .pipe(
        take(1),
        tap((organization) => {
          this.currentOrganization.setValue(organization, { emitEvent: false });
        }),
      )
      .subscribe();
  }

  private handleValueChange(): void {
    this.currentOrganization.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), distinctUntilChanged())
      .subscribe((organization: Nillable<Guid>) => {
        this.store.dispatch(
          new fromAccount.SetCurrentOrganization(organization),
        );
      });
  }
}
