<span class="d-flex flex-wrap align-items-center">
  <div
    class="input-switch"
    [class.input-switch--checked]="checked"
    [class.input-switch--disabled]="disabled"
    (click)="handleClickEvent($event)"
  >
    <div class="input-switch-hidden-accessible">
      <input
        #input
        id="input-switch"
        type="checkbox"
        role="switch"
        [disabled]="disabled"
        [checked]="checked"
      />
    </div>

    <span class="input-switch-slider"></span>
  </div>

  <ng-container *ngIf="label">
    <label class="input-switch-label" for="input-switch">{{ label }}</label>
  </ng-container>
</span>
