import { Component, DestroyRef, OnInit } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import * as fromTutorial from "../../../store/reducers/tutorial.reducer";
import { tap } from "rxjs/operators";
import { TutorialSteps } from "../../enums/tutorial-steps";
import { Member } from "../../interfaces/member";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";
import { TutorialState } from "../../../store/reducers/tutorial.reducer";

@Component({
  selector: "dashboard-layout",
  templateUrl: "./dashboard-layout.component.html",
  styleUrls: ["./dashboard-layout.component.scss"],
})
export class DashboardLayoutComponent implements OnInit {
  private cookieName = "cs-sidebar-expanded";

  member$: Observable<Member> = this.store.select(
    (state) => state.account.member,
  );

  tutorial$: Observable<TutorialState> = this.store.select(
    (state) => state.tutorial,
  );

  expanded: boolean = false;
  TutorialSteps = TutorialSteps;

  constructor(
    private cookieService: CookieService,
    private store: Store<{
      account: fromAccountStore.AccountState;
      tutorial: fromTutorial.TutorialState;
    }>,
  ) {}

  ngOnInit(): void {
    if (this.cookieService.check(this.cookieName)) {
      this.expanded = this.cookieService.get(this.cookieName) === "true";
    }
  }

  setExpand(_expanded: boolean): void {
    this.expanded = _expanded;
    this.cookieService.set(
      this.cookieName,
      String(this.expanded),
      730,
      "/",
      undefined,
      undefined,
      "Strict",
    );
  }
}
