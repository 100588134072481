import {ApiService} from "../api/api.service";
import {Guid} from "../../types/guid";
import {Observable} from "rxjs/internal/Observable";
import {Injectable} from "@angular/core";
import {PdfReport} from "../../interfaces/pdfreport";

@Injectable({
  providedIn: "root",
})
export class PdfReportService {
  constructor(
    private readonly apiService: ApiService,
  ) {
  }

  downloadIndividualReport(data: {
    CourseId: Guid;
    GroupId: Guid;
    UserId?: Guid;
  }): Observable<PdfReport> {
    return this.apiService.getRequest(
      `pdfreport/individual/${data.CourseId}/${data.GroupId}/${data.UserId}/`,
      { responseType: "json" },
    );
  }

  downloadGroupReport(data: {
    CourseId: Guid;
    GroupId: Guid;
  }): Observable<PdfReport> {
    return this.apiService.getRequest(
      `pdfreport/group/${data.CourseId}/${data.GroupId}/`,
      { responseType: "json" },
    );
  }

  downloadOrganizationReport(data: {
    OrganizationId: Guid;
  }): Observable<PdfReport> {
    return this.apiService.getRequest(
      `pdfreport/organization/${data.OrganizationId}/`,
      { responseType: "json" },
    );
  }
}
