import { Injectable } from "@angular/core";
import { Guid } from "../../types/guid";
import { Observable } from "rxjs";
import { Group, MovableGroup } from "../../interfaces/group";
import { ApiService } from "../api/api.service";
import { GroupRequest } from "../../interfaces/group-request";
import { InvitationsRequest } from "../../interfaces/invitations-request";
import { Invitation } from "../../interfaces/invitation";
import { Student } from "../../interfaces/student";
import { map } from "rxjs/operators";
import { HttpContextBuilder } from "../../http/context/builders/http-context-builder.service";
import { PackageInvitations } from "../../interfaces/packages/package-teacher-invitations";

@Injectable({
  providedIn: "root",
})
export class GroupService {
  constructor(
    private readonly apiService: ApiService,
    private readonly contextBuilder: HttpContextBuilder,
  ) {}

  getGroup(data: { GroupId: Guid }): Observable<Group> {
    const context = this.contextBuilder.skipBy404Token().build();

    return this.apiService.getRequest<Group>(`groups/${data.GroupId}/`, {
      context: context,
    });
  }

  getPackageGroups(packageId: string): Observable<PackageInvitations[]> {
    const context = this.contextBuilder.skipBy404Token().build();

    return this.apiService.getRequest<PackageInvitations[]>(
      `packet_groups/${packageId}/`,
      {
        context: context,
      },
    );
  }

  editGroup(data: { GroupId: Guid; request: GroupRequest }): Observable<Group> {
    return this.apiService.putRequest<Group>(
      `groups/${data.GroupId}/`,
      data.request,
    );
  }

  getInvitations(data: {
    GroupId: Guid;
    CourseId: Guid;
  }): Observable<Array<Invitation>> {
    return this.apiService.getRequest<Array<Invitation>>(
      `courses/${data.CourseId}/groups/${data.GroupId}/invitations/`,
    );
  }

  sendInvitations(data: {
    GroupId: Guid;
    CourseId: Guid;
    body: InvitationsRequest;
  }): Observable<Array<Invitation>> {
    return this.apiService.postRequest<Array<Invitation>>(
      `courses/${data.CourseId}/groups/${data.GroupId}/invitations/`,
      data.body,
    );
  }

  deleteInvitation(data: {
    GroupId: Guid;
    CourseId: Guid;
    InvitationId: Guid;
  }): Observable<{}> {
    const context = this.contextBuilder.skipBy404Token().build();

    return this.apiService.deleteRequest<{}>(
      `courses/${data.CourseId}/groups/${data.GroupId}/invitations/${data.InvitationId}/`,
      { context },
    );
  }

  deleteStudentFromGroup(data: {
    GroupId: Guid;
    CourseId: Guid;
    StudentId: Guid;
  }): Observable<{}> {
    const context = this.contextBuilder.skipBy404Token().build();

    return this.apiService.deleteRequest<{}>(
      `courses/${data.CourseId}/groups/${data.GroupId}/students/${data.StudentId}/`,
      { context },
    );
  }

  suspendUnsuspendStudent(
    data: {
      GroupId: Guid;
      CourseId: Guid;
      StudentId: Guid;
    },
    body: { blocked: boolean },
  ): Observable<{}> {
    const context = this.contextBuilder.skipBy404Token().build();

    return this.apiService.patchRequest<{}>(
      `courses/${data.CourseId}/groups/${data.GroupId}/students/${data.StudentId}/`,
      body,
      { context },
    );
  }

  searchUsers(query: string): Observable<Array<Student>> {
    return this.apiService.getRequest<Array<Student>>(
      `groups/search/?query=${query}`,
    );
  }

  copyGroupEmails(GroupId: Guid): Observable<Array<string>> {
    return this.getGroup({ GroupId }).pipe(
      map((group) =>
        Object.keys(
          group.students
            .map((student) => student.email)
            .concat(group.invitations.map((invitation) => invitation.email))
            .reduce((acc, email) => {
              if (email) {
                acc[email] = true;
              }
              return acc;
            }, {}),
        ),
      ),
    );
  }

  getMatchingGroups(data: {
    GroupId: Guid;
    CourseId: Guid;
    StudentId: Guid;
  }): Observable<Array<MovableGroup>> {
    return this.apiService.getRequest<Array<MovableGroup>>(
      `courses/${data.CourseId}/groups/${data.GroupId}/students/${data.StudentId}/movable_groups/`,
    );
  }

  moveStudent(
    data: {
      GroupId: Guid;
      CourseId: Guid;
      StudentId: Guid;
    },
    body: { coursegroup: Guid },
  ): Observable<unknown> {
    const context = this.contextBuilder.skipBy404Token().build();

    return this.apiService.patchRequest<{}>(
      `courses/${data.CourseId}/groups/${data.GroupId}/students/${data.StudentId}/`,
      body,
      { context },
    );
  }
}
