import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";

export const coursePrefix = "[Archive Group Students]";

const archiveGroupStudentsActions = {
  request: `${coursePrefix} Load`,
  requestSuccess: `${coursePrefix} Archive success`,
  requestFail: `${coursePrefix} Archive fail`,
};

export const archiveGroupStudentsLoad = createAction(
  archiveGroupStudentsActions.request,
  props<{ organizationId: string; teacherId: string; groupId: string }>(),
);

export const archiveGroupStudentsLoadSuccess = createAction(
  archiveGroupStudentsActions.requestSuccess,
);

export const archiveGroupStudentsLoadFail = createAction(
  archiveGroupStudentsActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
