import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '../../shared/services/auth/auth.service';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Token } from '../../shared/types/token';
import * as fromAuth from '../actions/auth.actions';
import * as fromAuthStore from '../reducers/auth.reducer';
import { Store } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import { AuthToken } from 'src/app/shared/interfaces/auth-token';

@Injectable()
export class RefreshTokenEffect {
  refreshToken$: Observable<Token> = this.store.select(
    state => state.auth.authToken.refresh
  );


  refresh$ = createEffect(() => this.actions$.pipe(
    ofType(fromAuth.REFRESH_TOKEN),
    mergeMap(() => {
      return this.refreshToken$.pipe(
        mergeMap((token: Token) => {
          return this.authService.refreshAccessToken(token).pipe(
            map((newToken: AuthToken) => {
              this.authService.saveToStorage(newToken, true);
              return {
                type: fromAuth.REFRESH_TOKEN_COMPLETED,
                payload: newToken.access
              };
            }),
            catchError(() => EMPTY)
          );
        })
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store: Store<{ auth: fromAuthStore.AuthState }>
  ) {}
}
