import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ComponentContainer } from "golden-layout";
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { CodeConfigFile } from "src/app/shared/interfaces/code-config-file";

type InvalidFileReason =
  | "IDE.UPLOAD.FILE_SIZE"
  | "IDE.UPLOAD.FILE_EXTENSION"
  | "IDE.UPLOAD.EMPTY_FILE";

@Component({
  selector: "file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent {
  @ViewChild("filePicker")
  filePicker: ElementRef<HTMLInputElement>;
  @Input() maxFileSize: number;
  @Input() maxFiles: number;
  @Input() minFileSize: number;
  @Input() extensions: Array<string> = [];
  @Input() customFileListTemplateRef?: TemplateRef<{
    files: Array<CodeConfigFile>;
  }> | null;
  @Output() filesAdded = new EventEmitter<Array<string>>();

  files: Array<CodeConfigFile> = [];
  draggingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  dragging$: Observable<boolean> = this.draggingSubject
    .asObservable()
    .pipe(distinctUntilChanged());
  invalidFileList: { name: string; reason: InvalidFileReason }[] = [];
  filesAmountExceeded: boolean = false;

  constructor(
    private cd: ChangeDetectorRef,
    @Optional() @Inject("Container") public container?: ComponentContainer,
  ) {}

  getFiles(event: Event): void {
    const fileInput = event.currentTarget as HTMLInputElement;
    this.invalidFileList = [];

    this.validateFilesAmount(fileInput.files);
    this.trimFiles(fileInput.files).forEach((file: File) => {
      this.processFile(file);
    });

    this.clearInput();
  }

  dataDropped(event: MouseEvent & { dataTransfer: DataTransfer }) {
    event.preventDefault();

    this.invalidFileList = [];
    const filteredItems: Array<DataTransferItem> = Array.prototype.filter.call(
      event.dataTransfer.items || [],
      (item) => item.kind === "file",
    );

    if (filteredItems) {
      this.validateFilesAmount(filteredItems);
      this.trimFiles(filteredItems).forEach((item: DataTransferItem) => {
        const file: File = item.getAsFile();
        this.processFile(file);
      });
    }

    this.dragleave();
    this.clearInput();
  }

  dragleave(): void {
    this.draggingSubject.next(false);
  }

  dragover(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.draggingSubject.next(true);
  }

  openFilePicker(): void {
    this.filePicker.nativeElement.click();
  }

  removeFile(fileIndex: number): void {
    if (fileIndex > -1) {
      delete this.files[fileIndex].content;
      this.files.splice(fileIndex, 1);
    }
  }

  private processFile(file: File): void {
    const reader = new FileReader();
    if (file.size < this.minFileSize) {
      this.invalidFileList.push({
        name: file.name,
        reason: "IDE.UPLOAD.EMPTY_FILE",
      });
      return;
    }
    if (file.size > this.maxFileSize) {
      this.invalidFileList.push({
        name: file.name,
        reason: "IDE.UPLOAD.FILE_SIZE",
      });
      return;
    } else if (
      !this.extensions.includes(this.getExtension(file.name.toLowerCase()))
    ) {
      this.invalidFileList.push({
        name: file.name,
        reason: "IDE.UPLOAD.FILE_EXTENSION",
      });
    } else {
      const fileIndex = this.getFileIndex(file.name);
      this.removeFile(fileIndex);

      this.files = [
        ...this.files,
        {
          filename: file.name,
          content: file,
        },
      ];
      this.filesAdded.emit(this.files.map(({ filename }) => filename));
      this.cd.detectChanges();
    }
  }

  private getFileIndex(filename: string): number {
    return this.files.findIndex((file) => file.filename === filename);
  }

  private getExtension(filename: string): string {
    return filename.substring(filename.lastIndexOf(".") + 1, filename.length);
  }

  private validateFilesAmount(files: Array<DataTransferItem> | FileList): void {
    const fileCount = files.length;
    this.filesAmountExceeded = false;

    if (fileCount > this.maxFiles - this.files.length) {
      this.filesAmountExceeded = true;
    }
  }

  private trimFiles(
    files: Array<DataTransferItem> | FileList,
  ): Array<File | DataTransferItem> {
    return Array.prototype.slice.apply(files, [
      0,
      this.maxFiles - this.files.length,
    ]);
  }

  private clearInput(): void {
    this.filePicker.nativeElement.value = null;
  }
}
