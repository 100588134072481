import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ListboxContentData } from "../../interfaces/listbox-content.model";

@Component({
  selector: "card-course",
  templateUrl: "./card-course.component.html",
  styleUrls: ["./card-course.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardCourseComponent {
  @Input() course: ListboxContentData;
  @Input() isDisabled: boolean = false;
}
