import { Injectable } from "@angular/core";
import { HttpContext } from "@angular/common/http";
import { SkipByAuthTokenInterceptor } from "../tokens/skip-by-auth-token-interceptor";
import { SkipBy503TokenInterceptor } from "../tokens/skip-by-503-token-inteceptor";
import { SkipBy404TokenInterceptor } from "../tokens/skip-by-404-token-inteceptor";

@Injectable({
  providedIn: "root",
})
export class HttpContextBuilder {
  private context = new HttpContext();

  constructor() {
    this.reset();
  }

  reset(): this {
    this.context = new HttpContext();

    return this;
  }

  skipByAuthToken(): this {
    this.context.set(SkipByAuthTokenInterceptor, true);

    return this;
  }

  skipBy503Token(): this {
    this.context.set(SkipBy503TokenInterceptor, true);

    return this;
  }

  skipBy404Token(): this {
    this.context.set(SkipBy404TokenInterceptor, true);

    return this;
  }

  build(): HttpContext {
    const context = this.context;

    this.reset();

    return context;
  }
}
