import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from "@angular/core";
import { Nillable } from "../../../../types/nillable";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Component({
  selector: "tab-panel",
  templateUrl: "./tab-panel.component.html",
  styleUrls: ["./tab-panel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabPanelComponent {
  @Input() header: Nillable<string>;
  @Input() tabTooltip: Nillable<string>;
  @Input() disabled: boolean = false;

  @Input()
  set selected(val: boolean) {
    this._selected.next(val);

    this.cd.markForCheck();
  }

  get isSelected(): Observable<boolean> {
    return this._selected.asObservable();
  }

  private readonly _selected: Subject<Nillable<boolean>> = new BehaviorSubject(
    false,
  );

  constructor(private readonly cd: ChangeDetectorRef) {}
}
