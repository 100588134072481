import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formatExtensions",
})
export class FormatExtensionsPipe implements PipeTransform {
  transform(
    extensions: Array<string>,
    args: { space: boolean } = { space: false }
  ): string {
    return extensions.map((ex) => `.${ex}`).join(`,${args.space ? " " : ""}`);
  }
}
