import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Guid } from "src/app/shared/types/guid";
import { Store } from "@ngrx/store";
import * as fromUI from "src/app/store/actions/ui.actions";
import { NotificationElement } from "src/app/shared/interfaces/notification-element";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService, ActiveToast } from "ngx-toastr";
import { NotificationToastComponent } from "../notification-toast.component";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { UIService } from "src/app/shared/services/ui/ui.service";

@Component({
  selector: "notification, li[notification]",
  template: `
    <a
      href="{{ getNotificationUrl() }}"
      target="_blank"
      (click)="performAction($event)"
      class="notification"
      [ngClass]="{ unread: notification.read_at === null }"
    >
      <div class="row no-gutters align-items-center">
        <div class="col-auto">
          <span
            class="notification-type d-inline-flex align-items-center justify-content-center"
            [ngClass]="'notification-type-' + notification.type"
          >
            <i class="typcn typcn-{{ getNotificationTypeIcon() }}"></i>
          </span>
        </div>
        <div class="col">
          <p
            class="notification-title"
            [innerHtml]="notification.content.message"
          ></p>
          <span
            *ngIf="notification.created_at"
            class="notification-date label d-flex align-items-center"
          >
            <span class="typcn typcn-calendar"></span>
            {{
              notification.created_at
                | dateDifference: { useLang: getCurrentLang() }
            }}
            {{ "GLOBAL.NOTIFICATIONS.AGO" | translate }}
          </span>
        </div>
        <div
          class="col-auto"
          *ngIf="showMarkDot && notification.read_at === null"
        >
          <button
            type="button"
            (click)="markAsRead($event, notification.id)"
            class="mark-as-read"
            tooltip="{{ 'GLOBAL.NOTIFICATIONS.MARK_AS_READ' | translate }}"
          >
            {{ "GLOBAL.NOTIFICATIONS.MARK_AS_READ" | translate }}
          </button>
        </div>
      </div>
    </a>
  `,
  styleUrls: ["./notification.component.scss"],
})
export class NotificationComponent {
  @Input("notification") notification: NotificationElement;
  @Input() showMarkDot: boolean = true;
  @Output()
  actionPerformed: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private store: Store<{}>,
    private uiService: UIService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private router: Router,
    private http: HttpClient,
  ) {}

  markAsRead(
    event: MouseEvent,
    notificationId: Guid,
    fromCircle: boolean = true,
  ): void {
    if (fromCircle) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }

    this.store.dispatch(new fromUI.MarkNotificationAsRead(notificationId));
    this.remove(notificationId);
  }

  getCurrentLang(): string {
    return this.translate.currentLang;
  }

  getNotificationTypeIcon(): string {
    switch (this.notification.type) {
      case "info":
        return "info-large";
      case "success":
        return "tick";
      case "warning":
      case "danger":
        return "warning";
    }
  }

  getNotificationUrl(): string {
    let url: string = this.notification.content.url;
    return url ? url : "#";
  }

  performAction(event: MouseEvent): void {
    let url: string = this.getNotificationUrl();
    let url_type: "default" | "external" | "ajax" =
      this.notification.content.url_type;

    if (url !== "#") {
      if (url_type !== "external") {
        event.preventDefault();
        event.stopImmediatePropagation();

        if (url_type === "ajax") {
          this.http.get(url);
        } else {
          this.router.navigate([url]);
        }
      }
    } else {
      event.preventDefault();
      event.stopImmediatePropagation();
    }

    if (!this.notification.read_at && this.notification.id) {
      if (this.notification.just_remove) {
        this.remove(this.notification.id);
      } else {
        this.markAsRead(event, this.notification.id, false);
      }
    }

    if (this.notification.id === "unread-notifications") {
      this.uiService.notificationsPanelToggle$.next();
    }

    this.actionPerformed.emit(true);
  }

  private remove(notificationId: Guid): void {
    let toastInstance: ActiveToast<NotificationToastComponent> =
      this.toastr.toasts.find(
        (toast: ActiveToast<NotificationToastComponent>) =>
          toast.portal.instance.notification.id === notificationId,
      );
    if (toastInstance) {
      this.toastr.remove(toastInstance.toastId);
    }
  }
}
