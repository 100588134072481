<li
  role="option"
  class="dropdown-item"
  [class.dropdown-item--highlight]="selected"
  [id]="id"
  (click)="handleClickEvent($event)"
  (mouseenter)="handleMouseEnterEvent($event)"
>
  <span class="dropdown-label" *ngIf="!templateRef">
    {{ option.label ?? "empty" }}
  </span>

  <ng-container
    *ngTemplateOutlet="templateRef; context: { $implicit: option }"
  />
</li>
