import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { Exercise } from "../../../../interfaces/exercise";

@Injectable({
  providedIn: "root",
})
export class CourseExerciseRefreshService {
  private readonly refreshSource$ = new BehaviorSubject<Exercise>(void 0);

  get refresh$(): Observable<Exercise> {
    return this.refreshSource$.asObservable();
  }

  refresh(exercise: Exercise): void {
    this.refreshSource$.next(exercise);
  }
}
