import { Component } from '@angular/core';

@Component({
  selector: 'photo',
  template: `
  <span class="photo" [ngStyle]="{
    'background-image': 'url(' + 'assets/images/sample-avatar.jpeg' + ')'
  }"></span>
  `,
  styleUrls: ['./photo.component.scss']
})
export class PhotoComponent {
  constructor() {}
}
