import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";
import { Ranking } from "../../interfaces/ranking";
import { Observable } from "rxjs";
import { Member } from "../../interfaces/member";
import { RankingStudent } from "../../interfaces/ranking-student";
import { Guid } from "../../types/guid";
import { RankingQuery } from '../../enums/ranking-query';

@Component({
  selector: "ranking-table",
  templateUrl: "./ranking-table.component.html",
  styleUrls: ["./ranking-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RankingTableComponent {
  account$: Observable<Member> = this.store.select(
    (state) => state.account.member
  );

  @Input() ranking: Ranking;
  @Input() mini: boolean = false;
  @Input() unitLabel: string;
  @Input() itemsToHighlight: Array<Guid>;
  @Input() groupBy: RankingQuery.GroupBy | undefined;

  GroupBy: typeof RankingQuery.GroupBy = RankingQuery.GroupBy;

  constructor(
    private store: Store<{ account: fromAccountStore.AccountState }>
  ) {}

  getPositionNumber(
    points: number,
    position: number,
    index: number
  ): number | string {
    if (index > 0 && this.ranking.results[index - 1].points === points) {
      return '<i class="uil uil-arrow-up-right"></i>';
    } else {
      return position;
    }
  }

  shouldBeHighlighted(entity: RankingStudent, account: Member): boolean {
    return this.itemsToHighlight
      ? this.itemsToHighlight.includes(entity.id)
      : entity.id === account.id;
  }
}
