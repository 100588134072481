import { ItemListMode } from "../enums/item-list-mode";
import { Student } from "../interfaces/student";
import { Guid } from "../types/guid";

export class HistoryListMode {
  mode: ItemListMode;
  private isTeacher: boolean;
  private currentStudent: Student | null = null;
  private currentGroup?: Guid;

  constructor(_mode: ItemListMode, _isTeacher: boolean) {
    this.mode = _mode;
    this.isTeacher = _isTeacher;
  }

  set group(activeGroup: Guid) {
    this.currentGroup =
      this.currentStudent || !this.isTeacher ? activeGroup : undefined;
  }

  get group(): Guid {
    return this.currentGroup;
  }

  set student(_student: Student | null) {
    this.currentStudent = _student;
  }

  get student(): Student | null {
    return this.currentStudent;
  }
}
