import { Component, OnInit, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, Subscription } from "rxjs";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";
import { ModalComponent } from "../../modal/modal.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "exercise-solve-confirm-modal",
  templateUrl: "./exercise-solve-confirm-modal.component.html",
  styleUrls: ["./exercise-solve-confirm-modal.component.scss"]
})
export class ExerciseSolveConfirmModalComponent extends ModalComponent
  implements OnInit, OnDestroy {
  isTeacher$: Observable<boolean> = this.store.select(
    state => state.account.member.is_teacher
  );

  langChange: Subscription;
  currentLang: string;

  constructor(
    private store: Store<{ account: fromAccountStore.AccountState }>,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.currentLang = this.translate.currentLang;

    this.langChange = this.translate.onLangChange.subscribe(() => {
      this.currentLang = this.translate.currentLang;
    });
  }

  ngOnDestroy() {
    this.langChange.unsubscribe();
  }

  closeConfirm() {
    this.close.emit("close");
  }
}
