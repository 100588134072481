import { Pipe, PipeTransform } from "@angular/core";
import { GradeOperator } from "src/app/shared/enums/grade-operator";

@Pipe({
  name: "operatorLiteral",
})
export class OperatorLiteralPipe implements PipeTransform {
  constructor() {}

  transform(operator: GradeOperator): string {
    switch (operator) {
      case GradeOperator.GREATER_THAN:
        return "&gt;";
      case GradeOperator.LESS_THAN:
        return "&lt;";
      case GradeOperator.GREATER_OR_EQUAL:
        return "&ge;";
      case GradeOperator.LESS_OR_EQUAL:
        return "&le;";

      default:
        return "";
    }
  }
}
