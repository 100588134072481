<a
  class="round-button {{ theme }}"
  href="#"
  [class.active]="isActive"
  [ngClass]="{ 'round-button--disabled': disabled }"
  [routerLink]="link ? [link] : null"
  [queryParams]="link ? params : null"
  (click)="action.observers.length ? emitAction($event) : undefined"
>
  <span [class]="icon | roundButtonClass: set : rotate"></span>
</a>
