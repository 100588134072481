import { Observable } from "rxjs";
import { ApiService } from "../api/api.service";
import { Injectable } from "@angular/core";
import { Package } from "../../interfaces/packages/package";
import { PackageUsers } from "../../interfaces/packages/package-users";
import { PackageCourseUsers } from "../../interfaces/packages/package-course-users";

@Injectable({ providedIn: "root" })
export class PackagesService {
  constructor(private readonly apiService: ApiService) {}

  getPackages(organizationId: string): Observable<Package[]> {
    return this.apiService.getRequest<Package[]>(
      `orgadmin/organizations/${organizationId}/packets/`,
    );
  }

  getSinglePackage(
    organizationId: string,
    packageId: string,
  ): Observable<Package> {
    return this.apiService.getRequest<Package>(
      `orgadmin/organizations/${organizationId}/packets/${packageId}/`,
    );
  }

  getPackageUsers(
    organizationId: string,
    packageId: string,
    teacherId?: string | null,
    search?: string,
  ): Observable<PackageUsers> {
    let url = `orgadmin/organizations/${organizationId}/packets/${packageId}/invited-users/`;

    if (teacherId) {
      url += `?teacher=${teacherId}`;
    }
    if (search) {
      url += `?search=${search}`;
    }

    return this.apiService.getRequest<PackageUsers>(url);
  }

  getPackageCourseUsers(
    organizationId: string,
    packageId: string,
    courseId: string,
    teacherId?: string | null,
  ): Observable<PackageCourseUsers> {
    let url = `orgadmin/organizations/${organizationId}/packets/${packageId}/invited-users/course/${courseId}/`;

    if (teacherId) {
      url += `?teacher=${teacherId}`;
    }
    return this.apiService.getRequest<PackageCourseUsers>(url);
  }
}
