import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import { InvitedTeachers } from "src/app/shared/interfaces/orgadmin/invited-teacher";
import {
  invitedTeachersListLoad,
  invitedTeachersListLoadSuccess,
  invitedTeachersListLoadFail,
} from "../../actions/admin/invited-teachers.actions";

export interface InvitedTeachersListState {
  teachers: InvitedTeachers;
  error: HttpErrorResponse | null;
}

const initialState: InvitedTeachersListState = {
  teachers: { active_teachers: [], invitations: [] },
  error: null,
};

export const invitedTeachersListReducer = createReducer(
  initialState,
  on(
    invitedTeachersListLoad,
    (state): InvitedTeachersListState => ({
      ...state,
      error: null,
    }),
  ),
  on(
    invitedTeachersListLoadSuccess,
    (state, action): InvitedTeachersListState => ({
      ...state,
      teachers: action.invitedTeachers,
      error: null,
    }),
  ),
  on(
    invitedTeachersListLoadFail,
    (state, { error }): InvitedTeachersListState => ({
      ...state,
      error,
    }),
  ),
);
