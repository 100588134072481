import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ListboxEmptyVariant } from "src/app/shared/types/listbox-empty-variant";

@Component({
  selector: "course-listbox-empty",
  templateUrl: "./course-listbox-empty.component.html",
  styleUrls: ["./course-listbox-empty.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CourseListboxEmptyComponent {
  @Input() showIcon = false;
  @Input() variant: ListboxEmptyVariant;
  @Input() text = "COURSES.COURSES.NO_COURSES";
}
