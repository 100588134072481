import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";
import {
  packageTeacherGroupsListLoad,
  packageTeacherGroupsListLoadSuccess,
  loadPackageTeacherGroupsListFailure,
} from "../../actions/packages/package-teacher-groups.actions";
import { GroupService } from "src/app/shared/services/group/group.service";

@Injectable()
export class PackageTeacherGroupsEffect {
  getPackageTeacherGroupList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(packageTeacherGroupsListLoad),
      concatMap((param) => {
        return this.groupService.getPackageGroups(param.packageId).pipe(
          map((res: any[]) =>
            packageTeacherGroupsListLoadSuccess({ packagesGroups: res }),
          ),
          catchError(({ error }) =>
            of(loadPackageTeacherGroupsListFailure(error)),
          ),
        );
      }),
    ),
  );

  constructor(
    private groupService: GroupService,
    private actions$: Actions,
  ) {}
}
