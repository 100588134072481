import { ChangeDetectionStrategy, Component, Input, Type } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Exercise } from "src/app/shared/interfaces/exercise";
import { Student } from "src/app/shared/interfaces/student";
import { ModalService } from "src/app/shared/services/modal/modal.service";
import { Guid } from "src/app/shared/types/guid";
import * as fromCourseStore from "src/app/store/reducers/course.reducer";
import { selectCourseState } from "src/app/store/selectors/course.selectors";
import { ModalComponent } from "../../modal/modal.component";
import { QuizHistoryModalComponent } from "../../modals/quiz-history-modal/quiz-history-modal.component";

@Component({
  selector: "quiz-history-button",
  templateUrl: "../code-check-history-button/code-check-history-button.component.html",
  styleUrls: [
    "../exercise-control-buttons/exercise-control-buttons.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuizHistoryButtonComponent {
  students$: Observable<Array<Student>> = this.store
    .select(selectCourseState)
    .pipe(map((course) => course.topicStats));

  activeGroup$: Observable<Guid> = this.store
    .select(selectCourseState)
    .pipe(map((course) => course.activeGroup));

  quizHistoryModal: Type<ModalComponent> = QuizHistoryModalComponent;

  @Input() courseId: Guid;
  @Input("teacher") isTeacher = false;
  @Input() exercise: Exercise;

  constructor(
    private store: Store<{
      teacher: {
        singleCourse: fromCourseStore.CourseState;
      };
    }>,
    private modalService: ModalService
  ) {}

  showHistory(
    courseId: Guid,
    exercise: Exercise,
    isTeacher: boolean,
    activeGroup: Guid | null,
    students: Array<Student> | null,
    multiMode: boolean
  ): void {
    this.modalService.showModal(
      { courseId, exercise, isTeacher, activeGroup, students, multiMode },
      this.quizHistoryModal,
      "QUIZ_HISTORY_MODAL"
    );
  }
}
