import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { BehaviorSubject, interval, Subscription } from "rxjs";
import { withLatestFrom } from "rxjs/operators";

const CAROUSEL_DEFAULT_DURATION_MS = 300;
const CAROUSEL_DEFAULT_ROTATION_MS = 10000;
@Component({
  selector: "carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselComponent implements OnInit, OnDestroy {
  @Input() elementTemplate: TemplateRef<any>;
  @Input() elements: Array<any> = [];
  @Input() showDots: boolean = true;
  @Input() animationDuration: number = CAROUSEL_DEFAULT_DURATION_MS;
  @Input() autoRotation: boolean = false;
  @Input("externalMouseOver") externalMouseOver$: BehaviorSubject<boolean>;

  @ViewChild("reelElement", { static: true })
  reelElement: ElementRef<HTMLDivElement>;
  activeElement: number;

  private rotationSubscription: Subscription;
  private mouseOver$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setActiveElement(0);
    this.setDuration();
    if (this.autoRotation) {
      this.setAutoRotation();
    }
  }

  ngOnDestroy(): void {
    if (this.rotationSubscription) {
      this.rotationSubscription.unsubscribe();
    }

    this.mouseOver$.unsubscribe();
  }

  setDuration(): void {
    this.reelElement.nativeElement.style.setProperty(
      "--reel-duration",
      `${this.animationDuration.toString()}ms`
    );
  }

  setActiveElement(index: number): void {
    this.activeElement = index;
    this.reelElement.nativeElement.style.setProperty(
      "--active-element",
      this.activeElement.toString()
    );
    this.cd.markForCheck();
  }

  private setAutoRotation(): void {
    this.rotationSubscription = interval(CAROUSEL_DEFAULT_ROTATION_MS)
      .pipe(
        withLatestFrom(
          this.externalMouseOver$ ? this.externalMouseOver$ : this.mouseOver$
        )
      )
      .subscribe(([_, mouseOver]: [number, boolean]) => {
        if (!mouseOver) {
          const nextElement = this.activeElement + 1;
          if (nextElement > this.elements.length - 1) {
            this.setActiveElement(0);
            return;
          }

          this.setActiveElement(nextElement);
        }
      });
  }

  @HostListener("mouseenter")
  private onMouseEnter(): void {
    this.mouseOver$.next(true);
  }

  @HostListener("mouseleave")
  private onMouseLeave(): void {
    this.mouseOver$.next(false);
  }
}
