<button
  type="button"
  (click)="handleToggleClick($event)"
  class="default-language-picker-button theme-{{ theme }}"
>
  <img
    [src]="'assets/lang-icons/' + currentLang.icon"
    alt="{{ currentLang.name }}"
  />
  <span class="typcn typcn-chevron-right" [ngClass]="{ toggled: isToggled }">
  </span>
</button>
