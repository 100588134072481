<a
  class="navbar-nav-element"
  [hover]
  [routerLink]="[route ? route : currentRoute]"
  [routerLinkActive]="route ? 'active' : ''"
  (click)="onNavClick($event)"
>
  <span class="navbar-nav-element-icon">
    <navbar-icon
      [icon]="icon"
      [set]="set"
      [ngClass]="{
        'no-outline': !outlined,
        'tutorial-border': showTutorial,
        'icon-bg': showTutorial && outlined
      }"
    />
  </span>

  <span
    class="navbar-nav-element-tooltip"
    position="bottom"
    [tooltip]="label"
  ></span>
  <span class="navbar-nav-element-label">{{ label }}</span>
</a>
