import { Component, EventEmitter, Output } from "@angular/core";
import { ModalComponent } from "../../modal/modal.component";
import { ConfirmationModalData } from "src/app/shared/interfaces/confirmation-modal-data";

@Component({
  selector: "confirmation-modal",
  template: `
    <modal-overlay>
      <modal-box>
        <div [ngSwitch]="data.icon" class="confirmation-modal-icon">
          <key-icon
            *ngSwitchCase="'warning'"
            class="confirmation-modal-icon-image"
            size="lg"
            theme="light"
            kind="warning"
            set="typcn"
            icon="warning-outline"
          />
          <key-icon
            *ngSwitchCase="'error'"
            class="confirmation-modal-icon-image"
            size="lg"
            theme="light-outline"
            kind="error"
            icon="cancel-outline"
          />
        </div>
        <div class="confirmation-modal">
          <div class="confirmation-modal-content">
            <h3 class="confirmation-modal-title">
              {{ modalTitle | translate: data.translationParams }}
            </h3>
            <p
              *ngIf="data.message"
              class="confirmation-modal-text"
              [innerHTML]="
                data.message
                  | translate: data.translationParams
                  | replaceNewLine
              "
            ></p>
            <div class="confirmation-modal-buttons">
              <button
                [ngClass]="confirmButtonClass"
                type="button"
                class="btn-lg"
                (click)="confirm()"
              >
                {{ data.confirmButtonText || "GLOBAL.OK_BUTTON" | translate }}
              </button>

              <button
                type="button"
                class="btn-primary-600 btn-lg"
                (click)="cancel()"
              >
                {{
                  data.cancelButtonText || "GLOBAL.CANCEL_BUTTON" | translate
                }}
              </button>
            </div>
          </div>
        </div>
      </modal-box>
    </modal-overlay>
  `,
  styleUrls: ["./confirmation-modal.component.scss"],
})
export class ConfirmationModalComponent extends ModalComponent<ConfirmationModalData> {
  @Output() onConfirm = new EventEmitter<boolean>();
  @Output() onCancel = new EventEmitter<boolean>();

  constructor() {
    super();
  }

  get confirmButtonClass(): string {
    switch (this.data.confirmButtonColor) {
      case "primary":
        return "btn-primary-900";
      case "secondary":
        return "btn-blue-light-900";
      case "warning":
        return "btn-warning-500";
      case "success":
        return "btn-success-600";
      case "error":
        return "btn-error-600";
      default:
        return "btn-success-600";
    }
  }

  confirm(): void {
    this.onConfirm.emit(true);
    this.close.emit("close");
  }

  cancel(): void {
    this.onCancel.emit(true);
    this.close.emit("close");
  }
}
