import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { StylesSheetPageComponent } from "./shared/pages_TEMPORARY/styles-sheet-page/styles-sheet-page.component";
import { ComponentSheetPageComponent } from "./shared/pages_TEMPORARY/component-sheet-page/component-sheet-page.component";
import { StudentGuard } from "./guards/auth/student.guard";
import { TeacherGuard } from "./guards/auth/teacher.guard";
import { LoginLoadGuard } from "./guards/auth/login-load.guard";
import { AdminGuard } from "./guards/auth/admin.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "account",
    pathMatch: "full",
  },
  {
    path: "account",
    loadChildren: () =>
      import("./modules/account/account.module").then((m) => m.AccountModule),
  },
  {
    path: "student",
    canActivate: [StudentGuard],
    loadChildren: () =>
      import("./modules/student/student.module").then((m) => m.StudentModule),
  },
  {
    path: "teacher",
    canActivate: [TeacherGuard],
    loadChildren: () =>
      import("./modules/teacher/teacher.module").then((m) => m.TeacherModule),
  },
  {
    path: "orgadmin",
    canLoad: [AdminGuard],
    loadChildren: () => import("./modules/orgadmin/admin-routing"),
  },
  {
    path: "ide",
    canLoad: [LoginLoadGuard],
    loadChildren: () =>
      import("./modules/ide/ide.module").then((m) => m.IdeModule),
  },
  {
    path: "quiz",
    canLoad: [LoginLoadGuard],
    loadChildren: () =>
      import("./modules/quiz/quiz.module").then((m) => m.QuizModule),
  },
  {
    path: "error",
    loadChildren: () =>
      import("./modules/error/error.module").then((m) => m.ErrorModule),
  },
  {
    path: "style-sheet",
    component: StylesSheetPageComponent,
  },
  {
    path: "component-sheet",
    component: ComponentSheetPageComponent,
  },
  { path: "**", redirectTo: "/error/404" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
