export enum StatusValues {
  NONE = 0,
  ACTIVE = 1,
  REJECTED = 2,
  PENDING = 3,
  DELIVERED = 4,
  FAILED = 5,
  REMOVED = 6,
  EXPIRED = 7,
}
