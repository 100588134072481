import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { combineLatest, Observable, Subscription } from "rxjs";
import { NotificationsService } from "src/app/shared/services/notifications/notifications.service";
import { Guid } from "src/app/shared/types/guid";
import { NotificationSettings } from "src/app/shared/types/notification-settings";
import {
  WebSocketMessageAccessConfig as accessConfig,
  WebSocketMessageAccessConfigType as configType,
  WebSocketMessageType,
} from "src/app/shared/types/websocket-message-type";
import * as fromUI from "src/app/store/actions/ui.actions";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";
import * as fromUIStore from "src/app/store/reducers/ui.reducer";

type SettingGroup = {
  name: string;
  appliesTo: Array<WebSocketMessageType>;
  access: configType;
};

@Component({
  selector: "notifications-settings",
  templateUrl: "./notifications-settings.component.html",
  styleUrls: ["./notifications-settings.component.scss"],
})
export class NotificationsSettingsComponent implements OnInit, OnDestroy {
  notificationSettings$: Observable<NotificationSettings | null> =
    this.store.select((state) => state.ui.notificationSettings);

  userId$: Observable<Guid> = this.store.select(
    (state) => state.account.member.id,
  );

  isTeacher$: Observable<boolean> = this.store.select(
    (state) => state.account.member.is_teacher,
  );

  settings: NotificationSettings = null;
  settingGroups: Array<SettingGroup> = [
    {
      name: "GENERAL",
      appliesTo: ["NOTIFICATION_RECEIVED"],
      access: accessConfig.NOTIFICATION_RECEIVED,
    },
    {
      name: "EXERCISE",
      appliesTo: ["EXERCISE_ADDED", "EXERCISE_DELETED", "EXERCISE_REVIEWED"],
      access: accessConfig.EXERCISE_ADDED,
    },
    {
      name: "EXERCISE_HELP",
      appliesTo: ["EXERCISE_HELP_TURNED_ON", "EXERCISE_HELP_TURNED_OFF"],
      access: accessConfig.EXERCISE_HELP_TURNED_ON,
    },
    {
      name: "QUIZ_SOLUTIONS",
      appliesTo: [
        "EXERCISE_QUIZ_SOLUTIONS_TURNED_ON",
        "EXERCISE_QUIZ_SOLUTIONS_TURNED_OFF",
      ],
      access: accessConfig.EXERCISE_QUIZ_SOLUTIONS_TURNED_ON,
    },
    {
      name: "EXERCISE_SOLVED",
      appliesTo: ["EXERCISE_SOLVED"],
      access: accessConfig.EXERCISE_SOLVED,
    },
    {
      name: "SUSPENDED",
      appliesTo: ["STUDENT_SUSPENDED"],
      access: accessConfig.STUDENT_SUSPENDED,
    },
    {
      name: "VPN_UPDATED",
      appliesTo: ["VPN_UPDATED"],
      access: accessConfig.VPN_UPDATED,
    },
    {
      name: "USER_VPN_UPDATED",
      appliesTo: ["USER_VPN_UPDATED"],
      access: accessConfig.USER_VPN_UPDATED,
    },
  ];
  filteredSettingGroups: Array<SettingGroup> = [];

  private settingsSubscription: Subscription;

  constructor(
    private store: Store<{
      ui: fromUIStore.UIState;
      account: fromAccountStore.AccountState;
    }>,
    private notificationsService: NotificationsService,
  ) {}

  ngOnInit() {
    this.settingsSubscription = combineLatest([
      this.notificationSettings$,
      this.isTeacher$,
    ]).subscribe(
      ([settings, isTeacher]: [NotificationSettings | null, boolean]) => {
        if (settings) {
          this.settings = settings;
        } else {
          this.settings = this.notificationsService.createSettings(
            isTeacher ? "teacher" : "student",
          );
        }

        this.filteredSettingGroups = this.settingGroups.filter((settingGroup) =>
          ["both", isTeacher ? "teacher" : "student"].includes(
            settingGroup.access,
          ),
        );
      },
    );
  }

  ngOnDestroy() {
    this.settingsSubscription.unsubscribe();
  }

  setSetting(
    event: {
      value: boolean;
      appliesTo: Array<WebSocketMessageType>;
    },
    userId: Guid,
  ) {
    const updatedSettings = this.settings.mergeIn(
      ["notificationTypeSettings"],
      event.appliesTo.reduce((acc, key) => {
        return { ...acc, [key]: event.value };
      }, {}),
    );

    this.store.dispatch(
      new fromUI.SaveNotificationSettings({
        settings: updatedSettings,
        userId,
      }),
    );
  }
}
