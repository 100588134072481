<div [ngClass]="{ 'ranking-box': !mini, 'ranking-box-mini': mini }">
  <table
    *ngIf="account$ | async as account"
    [ngClass]="{ mini: mini, 'show-organization': groupBy === GroupBy.user }"
    class="ranking-table-new"
  >
    <thead>
      <tr>
        <th class="text-center">{{ "RANKING.TABLE.POSITION" | translate }}</th>
        <th>{{ "RANKING.TABLE.NAME" | translate }}</th>
        <th class="organization">
          {{ "RANKING.TABLE.ORGANIZATION" | translate }}
        </th>
        <th class="text-center">
          {{ unitLabel || "RANKING.TABLE.POINTS" | translate }}
        </th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngFor="let student of ranking.results; let i = index">
        <tr
          class="highlight-{{ i }}"
          [ngClass]="{
            highlight: shouldBeHighlighted(student, account)
          }"
        >
          <td>
            <div
              [innerHTML]="
                getPositionNumber(student.points, student.position, i)
              "
              class="v-center text-center"
            >
              {{ student.position }}
            </div>
          </td>
          <td>
            <div class="v-center">
              <div>
                <span class="student-name">
                  {{ student.name }}
                </span>

                <div *ngIf="groupBy === GroupBy.user" class="d-lg-none mt-3">
                  <b>{{ "RANKING.TABLE.ORGANIZATION" | translate }}</b>
                  <ng-container
                    *ngTemplateOutlet="
                      organizationsList;
                      context: { $implicit: student.organizations }
                    "
                  >
                  </ng-container>
                </div>
              </div>
            </div>
          </td>
          <td class="organization">
            <ng-container
              *ngTemplateOutlet="
                organizationsList;
                context: { $implicit: student.organizations }
              "
            >
            </ng-container>
          </td>
          <td>
            <div class="v-center text-center">
              {{ student.points }}
            </div>
          </td>
        </tr>
      </ng-container>

      <tr *ngIf="!ranking.results.length">
        <td></td>
        <td>{{ "RANKING.TABLE.NO_RESULTS" | translate }}</td>
        <td class="organization"></td>
        <td></td>
      </tr>
    </tbody>
  </table>

  <div class="mt-4 text-right" *ngIf="ranking.cached_at">
    <span class="paragraph-small color-hannotationgray">
      {{ "RANKING.TABLE.GENERATED" | translate }}
      {{ ranking.cached_at | date: { format: "DD-MM-YYYY H:mm" } }}
    </span>
  </div>
</div>

<ng-template let-organizations #organizationsList>
  <ul class="organizations-list">
    <li
      *ngFor="let organization of organizations"
      class="organizations-list-item"
    >
      {{ organization.name }}
    </li>
  </ul>
</ng-template>
