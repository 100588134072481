import { Injectable, SecurityContext } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Injectable({
  providedIn: "root",
})
export class EscapeService {
  constructor(private domSanitizer: DomSanitizer) {}

  escapeHTML(text: string): SafeHtml {
    return this.domSanitizer.sanitize(
      SecurityContext.HTML,
      this.replaceHTMLTags(text ? text : "")
    );
  }

  private replaceHTMLTags(text: string): SafeHtml {
    return text
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }
}
