<ng-container *ngIf="{ isRunning: isRunning$ | async } as controlButtons">
  <code-check-history-button
    *ngIf="exercise.answer_type === AnswerType.ANSWER_TYPE_CODE"
    [courseId]="courseId"
    [teacher]="isTeacher"
    [exercise]="exercise"
  >
  </code-check-history-button>

  <button
    [disabled]="exercise.status === 'starting'"
    class="theme-hlogo exercise-control-button"
    type="button"
    (click)="start($event, controlButtons.isRunning)"
  >
    <countdown *ngIf="exercise.status === 'starting'"></countdown>
    <span
      *ngIf="exercise.status !== 'starting' && startButton.icon"
      class="uil uil-{{ startButton.icon }}"
    ></span>
    {{ startButton.caption | translate }}
  </button>
</ng-container>
