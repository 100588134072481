import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { catchError, filter, mergeMap, switchMap, take } from "rxjs/operators";
import * as errorConfig from "src/app/config/errors";
import * as fromAuth from "src/app/store/actions/auth.actions";
import * as fromAuthStore from "src/app/store/reducers/auth.reducer";
import { Token } from "../../types/token";
import { AuthService } from "../auth/auth.service";
import { WarningModalService } from "../warning-modal/warning-modal.service";
import { Router } from "@angular/router";
import { SkipByAuthTokenInterceptor } from "../../http/context/tokens/skip-by-auth-token-interceptor";
import { SkipBy503TokenInterceptor } from "../../http/context/tokens/skip-by-503-token-inteceptor";
import { SkipBy404TokenInterceptor } from "../../http/context/tokens/skip-by-404-token-inteceptor";

@Injectable({
  providedIn: "root",
})
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  refreshedToken$: Observable<Token> = this.store.select(
    (state) => state.auth.authToken.access,
  );

  constructor(
    private readonly store: Store<{ auth: fromAuthStore.AuthState }>,
    private readonly warningModalService: WarningModalService,
    private readonly router: Router,
  ) {}

  private handleErrorMessage(
    error: HttpErrorResponse,
    request: HttpRequest<any>,
  ) {
    let handleModalOrListning: Function = (error: HttpErrorResponse) => {
      if (
        error.status === HttpStatusCode.ServiceUnavailable &&
        error.error.message?.includes("Service unavailable")
      ) {
        window.location.reload();
      }

      let foundErrorListing = false;

      for (let key of Object.keys(error.error)) {
        if (error.error.hasOwnProperty(key)) {
          foundErrorListing =
            foundErrorListing ||
            !["non_field_errors", "detail", "errors"].includes(key);
        }
      }

      if (!foundErrorListing || typeof error.error === "string") {
        let textStatus = error.status.toString();
        let messageText = "";

        if (error.error?.detail) {
          messageText = this.prepareMessageByDetail(error, request);
        }

        if (error.error?.non_field_errors) {
          messageText = this.prepareMessageByNonFieldErrors(error, request);
        }

        if (typeof error.error === "string") {
          let errorMessage = JSON.parse(error.error) as { detail: string };
          messageText = errorMessage.detail;
        }

        let shouldSkipModal = errorConfig.SKIP_MODAL_BLACKLIST.reduce(
          (acc: boolean, previous) => {
            acc =
              acc ||
              (previous.errorCodes.includes(error.status) &&
                new RegExp(previous.name).test(request.url));
            return acc;
          },
          false,
        );

        if (!shouldSkipModal) {
          this.warningModalService.showModal({
            modalTitle: `GLOBAL.MESSAGES.ERROR_TITLES.ERROR_${textStatus}`,
            text: messageText,
            icon: errorConfig.ERROR_ICONS[error.status],
          });
        }
      }
    };

    switch (error.status) {
      case HttpStatusCode.NotFound: {
        this.isSkippedBy404(request)
          ? this.router.navigate(["/error/404"])
          : handleModalOrListning(error);

        break;
      }
      case HttpStatusCode.ServiceUnavailable: {
        if (!this.isSkippedBy503(request)) {
          handleModalOrListning(error);
        }

        break;
      }
      case 0:
      case HttpStatusCode.BadRequest:
      case HttpStatusCode.Forbidden:
      case HttpStatusCode.InternalServerError:
      case HttpStatusCode.BadGateway:
      case HttpStatusCode.GatewayTimeout:
        handleModalOrListning(error);
        break;
    }
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return this.injectToken(request, next).pipe(
      catchError((error: HttpErrorResponse) => {
        if (request.url.includes("auth/token")) {
          if (request.url.includes("refresh")) {
            this.store.dispatch({ type: fromAuth.LOGOUT });
          }

          return throwError(error);
        }

        if (error.status !== HttpStatusCode.Unauthorized) {
          this.handleErrorMessage(error, request);

          return throwError(error);
        }

        if (new RegExp("token/$").test(request.url)) {
          return throwError(error);
        }

        if (this.isRefreshing) {
          return this.refreshSubject.pipe(
            filter((result) => result !== null),
            take(1),
            switchMap(() => this.injectToken(request, next)),
          );
        } else {
          this.isRefreshing = true;

          this.refreshSubject.next(null);

          this.store.dispatch({ type: fromAuth.REFRESH_TOKEN });

          return this.refreshedToken$.pipe(
            switchMap((token: Token) => {
              this.isRefreshing = false;
              this.refreshSubject.next(token);

              return this.injectToken(request, next);
            }),
            catchError((error) => {
              this.isRefreshing = false;

              this.store.dispatch({ type: fromAuth.LOGOUT });
              return throwError(error);
            }),
          );
        }
      }),
    );
  }

  injectToken(request, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      new RegExp(errorConfig.ENDPOINT_INJECT_BLACKLIST.join("|")).test(
        request.url,
      )
    ) {
      return next.handle(request);
    }

    return this.refreshedToken$.pipe(
      mergeMap((token: Token) => {
        return of(token);
      }),
      take(1),
      switchMap((token) => {
        if (
          new RegExp(
            errorConfig.ENDPOINT_PARTIAL_INJECT_BLACKLIST.join("|"),
          ).test(request.url) &&
          !token
        ) {
          return next.handle(request);
        }

        return next.handle(
          request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          }),
        );
      }),
    );
  }

  private isSkippedBy503(request: HttpRequest<Body>): boolean {
    return request.context.has(SkipBy503TokenInterceptor);
  }

  private isSkippedBy404(request: HttpRequest<Body>): boolean {
    return request.context.has(SkipBy404TokenInterceptor);
  }

  private prepareMessageByDetail(
    error: HttpErrorResponse,
    request: HttpRequest<unknown>,
  ): string {
    const status = error.status.toString();

    if (
      request.responseType === "text" &&
      error.error.search("detail") !== -1
    ) {
      return JSON.parse(error.error).detail;
    }

    if (error.error.detail) {
      return error.error.detail;
    }

    this.prepareMessageByStatus(status);
  }

  private prepareMessageByNonFieldErrors(
    error: HttpErrorResponse,
    request: HttpRequest<unknown>,
  ): string {
    const status = error.status.toString();

    if (
      request.responseType === "text" &&
      error.error.search("non_field_errors") !== -1
    ) {
      return JSON.parse(error.error).non_field_errors;
    }

    const [message] = error.error.non_field_errors;

    if (message) {
      return message;
    }

    this.prepareMessageByStatus(status);
  }

  private prepareMessageByStatus(status: string): string {
    if (status.startsWith("5")) {
      return `GLOBAL.MESSAGES.ERROR_MESSAGES.ERROR_${
        errorConfig.ERROR_MESSAGESS[status] || "5XX"
      }`;
    }

    if (status === "404") {
      return "GLOBAL.MESSAGES.ERROR_MESSAGES.ERROR_404";
    }

    return "";
  }
}
