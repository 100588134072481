import { Component, Input } from "@angular/core";
import { CourseLimit, OrganizationLimit } from '../../interfaces/limit';

@Component({
  selector: "p[limit-text]",
  template: `
    <span
      class="limit-text"
      [ngClass]="{ big: big }"
      [innerHTML]="
        limit.limit_max
          ? ((formatted
              ? 'GLOBAL.LIMIT_WIDGET.LIMIT_TEXT_FORMATTED'
              : 'GLOBAL.LIMIT_WIDGET.LIMIT_TEXT'
            )
            | translate
              : {
                  free: limit.limit_max - limit.limit_current,
                  max: limit.limit_max
                })
          : ('GLOBAL.LIMIT_WIDGET.NO_LIMIT' | translate)
      "
    >
    </span>
  `,
  styleUrls: ["./limit-text.component.scss"],
})
export class LimitTextComponent {
  @Input() limit: OrganizationLimit | CourseLimit;
  @Input() big?: boolean;
  @Input() formatted?: boolean;

  constructor() {}
}
