<modal-overlay>
  <modal-box>
    <div class="warning-modal-body row no-gutters">
      <div class="col col-auto">
        <span class="warning-icon typcn typcn-warning-outline"></span>
      </div>
      <div class="col modal-content">
        <h5 class="modal-title">{{'GLOBAL.MESSAGES.INFO' | translate}}</h5>
        <p class="modal-text" [innerHTML]="text | translate | replaceNewLine"></p>
        <div class="d-flex align-items-center justify-content-between modal-buttons">
          <button type="button" class="modal-button theme-hsecondary"
          (click)="dismiss()">{{'GLOBAL.OK_BUTTON' | translate}}</button>
          <button type="button" class="modal-button theme-hprimary"
            (click)="help()">
            <i class="uil uil-question-circle"></i>
            {{'GLOBAL.HELP_BUTTON' | translate}}
          </button>
        </div>
      </div>
    </div>
  </modal-box>
</modal-overlay>
