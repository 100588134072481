import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CodeConfigFile } from "../../../../interfaces/code-config-file";

@Component({
  selector: 'file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileListComponent {
  @Input() files: CodeConfigFile[] = [];
}
