<form [formGroup]="answerForm" (submit)="submitAnswer()">
  <div
    class="course-exercise-answer d-flex flex-wrap row no-gutters flex-md-nowrap align-items-center"
  >
    <div class="col-12 col-sm">
      <input
        type="text"
        class="answer-input theme-exercise"
        formControlName="answer"
        name="Answer"
        placeholder="{{ 'COURSES.COURSE.EXERCISE.ANSWER' | translate }}"
      />
      <validation-text [control]="answerForm.get('answer')">
        {{ "COURSES.COURSE.EXERCISE.ANSWER_REQUIRED" | translate }}
      </validation-text>
    </div>

    <div
      class="col-12 col-sm-auto d-flex justify-content-center justify-content-md-start"
    >
      <button type="submit" class="answer-button theme-hlogo">
        {{ "COURSES.COURSE.EXERCISE.SEND_ANSWER_BUTTON" | translate }}
      </button>
    </div>

    <div class="col-auto answer-spinner" *ngIf="isAnswerSent">
      <loading-spinner width="20" height="20"></loading-spinner>
    </div>
  </div>
</form>
