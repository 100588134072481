import { Component, Input } from "@angular/core";
import { IconSet } from "../../types/icon-set";

@Component({
  selector: "navbar-icon",
  templateUrl: "./navbar-icon.component.html",
  styleUrls: ["./navbar-icon.component.scss"],
})
export class NavbarIconComponent {
  @Input() icon = "";
  @Input() set: IconSet = "typcn";
}
