import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { AdminService } from "src/app/shared/services/admin/admin.service";
import {
  invitedGroupStudentsListLoad,
  invitedGroupStudentsListLoadSuccess,
  invitedGroupStudentsListLoadFail,
} from "../../actions/admin/invited-group-students.actions";
import { GroupStudents } from "src/app/shared/interfaces/orgadmin/invited-students";
import {
  archiveGroupStudentsLoad,
  archiveGroupStudentsLoadFail,
  archiveGroupStudentsLoadSuccess,
} from "../../actions/admin/archive-group.actions";
import { singleActiveTeacherLoad } from "../../actions/admin/single-active-teacher.actions";

@Injectable()
export class InvitedGroupStudentsEffect {
  getInivitedGroupStudentsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitedGroupStudentsListLoad),
      switchMap((param) => {
        return this.adminService
          .getInvitedStudentsInGroup(
            param.organizationId,
            param.teacherId,
            param.groupId,
          )
          .pipe(
            map((res: GroupStudents) =>
              invitedGroupStudentsListLoadSuccess({ invitedStudents: res }),
            ),
            catchError(({ error }) =>
              of(invitedGroupStudentsListLoadFail(error)),
            ),
          );
      }),
    ),
  );

  archiveGroupStudents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(archiveGroupStudentsLoad),
      switchMap((param) =>
        this.adminService
          .archiveGroup(param.organizationId, param.teacherId, param.groupId)
          .pipe(
            switchMap(() => [
              singleActiveTeacherLoad({
                organizationId: param.organizationId,
                teacherId: param.teacherId,
              }),
              archiveGroupStudentsLoadSuccess(),
            ]),
            catchError(({ error }) => of(archiveGroupStudentsLoadFail(error))),
          ),
      ),
    ),
  );

  constructor(
    private adminService: AdminService,
    private actions$: Actions,
  ) {}
}
