import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Lang } from "../../../interfaces/lang";
import { last } from "rxjs/operators";

@Component({
  selector: "default-language-picker-langs-layout",
  templateUrl: "./default-language-picker-langs-layout.component.html",
  styleUrls: ["./default-language-picker-langs-layout.component.scss"],
})
export class DefaultLanguagePickerLangsLayoutComponent {
  @Input() langs: Array<Lang>;

  @Output() langChangeEvent = new EventEmitter<string>();

  handleLangClick(lang: Lang): void {
    const { name } = lang;

    this.langChangeEvent.emit(name);
  }

  protected readonly last = last;
}
