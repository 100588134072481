import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { AdminService } from "src/app/shared/services/admin/admin.service";
import {
  CourseAdmin,
  PagionationData,
} from "src/app/shared/interfaces/orgadmin/course-organization";
import {
  coursesAdminInactiveListLoad,
  coursesAdminInactiveListLoadSuccess,
  loadInactiveTeachersFailure,
} from "../../actions/admin/course-inactive-admin.actions";

@Injectable()
export class CourseInactiveAdminEffect {
  getInactiveCoursesAdminList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(coursesAdminInactiveListLoad),
      switchMap((param) => {
        return this.adminService
          .getSubscriptions(
            param.id,
            param.page,
            param.size,
            param.search,
            param.active,
          )
          .pipe(
            map((res: PagionationData<CourseAdmin>) =>
              coursesAdminInactiveListLoadSuccess({ coursesInactiveList: res }),
            ),
            catchError(({ error }) => of(loadInactiveTeachersFailure(error))),
          );
      }),
    ),
  );

  constructor(
    private adminService: AdminService,
    private actions$: Actions,
  ) {}
}
