import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CourseProgress } from "../../interfaces/course-progress";

@Component({
  selector: "ring-progress-bar",
  templateUrl: "./ring-progress-bar.component.html",
  styleUrls: ["./ring-progress-bar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RingProgressBarComponent {
  @Input() progress: CourseProgress;

  readonly PROGRESS_SIZE = 280;
  readonly RING_WIDTH = 18;
  readonly RING_SPACING = 10;

  get outerRadius(): number {
    return this.PROGRESS_SIZE / 2 - this.RING_SPACING;
  }

  get innerRadius(): number {
    return this.PROGRESS_SIZE / 2 - this.RING_SPACING * 2 - this.RING_WIDTH;
  }

  getCircumference(radius: number): number {
    return 2 * Math.PI * radius;
  }

  getDashArray(progress: number, radius: number, max_points: number): string {
    if (max_points == 0 && radius == this.innerRadius) {
      return `0 ${this.getCircumference(this.innerRadius)}`;
    }

    const circumference = this.getCircumference(radius);
    return `${(progress / 100) * circumference} ${circumference}`;
  }
}
