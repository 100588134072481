import { Pipe, PipeTransform } from '@angular/core';
import { TopicExerciseSummary } from "../interfaces/stat-list-table-row";

import { ExerciseStatusCssClassPipe } from "src/app/shared/pipes/exercise-status-css-class.pipe";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { isNil } from "lodash-es";
import { Nillable } from "src/app/shared/types/nillable";
import { ExerciseStatus } from 'src/app/shared/enums/exercise-status';
import { StudentPassFilterFormData } from 'src/app/modules/teacher/components/student-pass-filter-form/types/models/student-pass-filter-form-data';
import { StudentPassConditionFormService } from 'src/app/modules/teacher/components/student-pass-filter-form/types/services/student-pass-condition-form.service';

@Pipe({
  name: 'studentExerciseCellClass'
})
export class StudentExerciseCellClassPipe implements PipeTransform {

  constructor(
    private readonly exerciseStatusCssClassPipe: ExerciseStatusCssClassPipe,
    private readonly studentPassConditionFormDataService: StudentPassConditionFormService
  ) {
  }

  transform(summary: TopicExerciseSummary): Observable<Nillable<string>> {
    return this.studentPassConditionFormDataService.formData().pipe(
      map((data: Nillable<StudentPassFilterFormData>) => {
        const { points, maxPoints, passedTime } = summary;

        if (isNil(passedTime)) {
          return this.exerciseStatusCssClassPipe.transform(ExerciseStatus.NEW);
        }

        if (isNil(data)) {
          return null;
        }

        if ((points / maxPoints) * 100 >= data.passFrom) {
          return this.exerciseStatusCssClassPipe.transform(ExerciseStatus.COMPLETED);
        }

        return this.exerciseStatusCssClassPipe.transform(ExerciseStatus.UNCOMPLETED);
      }),
    )
  }
}
