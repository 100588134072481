import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { ModalComponent } from "../../modal/modal.component";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";
import { RedeemResult } from "src/app/shared/interfaces/redeem-result";
import { ErrorListing } from "src/app/shared/types/error-listing";

@Component({
  selector: "redeem-modal",
  templateUrl: "./redeem-modal.component.html",
  styleUrls: ["./redeem-modal.component.scss"],
})
export class RedeemModalComponent extends ModalComponent {
  redeemCompleted$: Observable<boolean> = this.store.select(
    (state) => state.account.redeemCompleted
  );
  redeemResult$: Observable<RedeemResult> = this.store.select(
    (state) => state.account.redeemResult
  );

  redeemFailed$: Observable<boolean> = this.store.select(
    (state) => state.account.redeemFailed
  );
  redeemError$: Observable<ErrorListing> = this.store.select(
    (state) => state.account.redeemError
  );

  isTeacher$: Observable<boolean> = this.store.select(
    (state) => state.account.member.is_teacher
  );

  constructor(
    private store: Store<{ account: fromAccountStore.AccountState }>
  ) {
    super();
  }

  getStatusTitle({
    completed,
    failed,
  }: {
    completed: boolean;
    failed: boolean;
  }): string {
    if (!completed && !failed) {
      return "ACCOUNT.REDEEM.REDEEM_PROGRESS";
    } else if (completed) {
      return "ACCOUNT.REDEEM.REDEEM_COMPLETED";
    } else if (failed) {
      return "ACCOUNT.REDEEM.REDEEM_FAILED";
    }
  }
}
