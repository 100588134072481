import { Component } from "@angular/core";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "cookie-bar",
  template: `
    <div class="cookie-bar" *ngIf="show">
      <p
        class="cookie-bar-text"
        [innerHTML]="'GLOBAL.COOKIE_BAR.MESSAGE' | translate"
      ></p>
      <button
        class="button lg theme-hsecondary cookie-bar-button"
        (click)="agree()"
      >
        {{ "GLOBAL.COOKIE_BAR.PROCEED_BUTTON" | translate }}
      </button>
    </div>
  `,
  styleUrls: ["./cookie-bar.component.scss"],
})
export class CookieBarComponent {
  private cookieName = "cyber-skiller-cookie-permit";
  show = false;

  constructor(private cookieService: CookieService) {
    this.show = !this.cookieService.check(this.cookieName);
  }

  agree() {
    this.cookieService.set(
      this.cookieName,
      "agree",
      730,
      "/",
      undefined,
      undefined,
      "Strict"
    );
    this.show = false;
  }
}
