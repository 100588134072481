import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import { FlaggedExercise } from "src/app/shared/interfaces/flagged-exercises";
import {
  flaggedExerciseFailure,
  flaggedExerciseLoad,
  flaggedExerciseLoadSuccess,
} from "../../actions/flagged-exercises/create-flagged-exercise.actions";
import { RequestStatus } from "src/app/shared/interfaces/request-status";

export interface CreateFlaggedExerciseState {
  exercise: FlaggedExercise | null;
  error: HttpErrorResponse | null;
  status: RequestStatus;
}

const initialState: CreateFlaggedExerciseState = {
  exercise: null,
  error: null,
  status: "IDLE",
};

export const createFlaggedExerciseReducer = createReducer(
  initialState,
  on(
    flaggedExerciseLoad,
    (state): CreateFlaggedExerciseState => ({
      ...state,
      exercise: null,
      error: null,
      status: "PENDING",
    }),
  ),
  on(
    flaggedExerciseLoadSuccess,
    (state, action): CreateFlaggedExerciseState => ({
      ...state,
      exercise: action.exercise,
      error: null,
      status: "SUCCESS",
    }),
  ),
  on(flaggedExerciseFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: "FAILED",
  })),
);
