import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY, of } from "rxjs";
import { map, mergeMap, switchMap, take, catchError, withLatestFrom } from "rxjs/operators";
import { NotificationElement } from "src/app/shared/interfaces/notification-element";
import { NotificationsResults } from "src/app/shared/interfaces/notifications-results";
import { RunningExercise } from "src/app/shared/types/running-exercise";
import { VPNPassword } from "src/app/shared/interfaces/vpn-password";
import { NotificationsService } from "src/app/shared/services/notifications/notifications.service";
import { UIService } from "src/app/shared/services/ui/ui.service";
import { WebSocketService } from "src/app/shared/services/web-socket/web-socket.service";
import * as fromUI from "../actions/ui.actions";
import * as fromUIStore from "../reducers/ui.reducer";
import { FileService } from "src/app/shared/services/file/file.service";
import { NotificationSettings } from "src/app/shared/types/notification-settings";
import { Announcement } from "src/app/shared/interfaces/announcement";
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class UIEffect {

  connectToLab$ = createEffect(() => this.actions$.pipe(
    ofType(fromUI.CONNECT_TO_LAB),
    switchMap(() => {
      this.uiService.connectToLab().subscribe((config) => {
        this.fileService.download(config, "cyberskiller.ovpn");
      });

      return of({});
    })
  ), { dispatch: false });


  getVPNPassword$ = createEffect(() => this.actions$.pipe(
    ofType(fromUI.GET_VPN_PASSWORD),
    mergeMap(() =>
      this.uiService.getVPNPassword().pipe(
        mergeMap((password: VPNPassword) => [
          {
            type: fromUI.GET_VPN_PASSWORD_COMPLETED,
            payload: password,
          },
        ]),
        catchError(() => {
          return of({ type: fromUI.GET_VPN_PASSWORD_FAILED });
        })
      )
    )
  ));


  getNotifications$ = createEffect(() => this.actions$.pipe(
    ofType(fromUI.GET_NOTIFICATIONS),
    mergeMap((action: fromUI.GetNotifications) =>
      this.notificationsService.getNotifications(action.payload).pipe(
        mergeMap((results: NotificationsResults) => {
          let actions = [];

          actions.push({
            type: fromUI.GET_NOTIFICATIONS_COMPLETED,
            payload: results,
          });

          if (action.payload === 1) {
            actions.push({
              type: fromUI.UPDATE_UNREAD_NOTIFICATIONS,
              payload: results.unread,
            });
          }

          return actions;
        })
      )
    )
  ));


  markNotificationAsRead$ = createEffect(() => this.actions$.pipe(
    ofType(fromUI.MARK_NOTIFICATION_AS_READ),
    mergeMap((action: fromUI.MarkNotificationAsRead) =>
      this.notificationsService.markAsRead(action.payload).pipe(
        map((notification: NotificationElement) => {
          return of(notification);
        })
      )
    )
  ), { dispatch: false });

  markAllNotificationsAsRead$ = createEffect(() => this.actions$.pipe(
    ofType(fromUI.MARK_ALL_NOTIFICATIONS_AS_READ),
    switchMap(() =>
      this.notificationsService.markAllAsRead().pipe(
        switchMap(() => EMPTY)
      )
    )
  ), { dispatch: false });

  updateUnreadNotifications$ = createEffect(() => this.actions$.pipe(
    ofType(fromUI.UPDATE_UNREAD_NOTIFICATIONS),
    withLatestFrom(this.store.select(state => state.ui.unreadNotificationsCount)),
    take(1),
    switchMap(([_, unread]) => {
      if(unread > 0) {
        this.translate.get('GLOBAL.NOTIFICATIONS.UNREAD', {unread}).subscribe((message) => {
          this.notificationsService.showToastr({
            id: "unread-notifications",
            content: {
              message
            },
            type: "info",
            created_at: "",
            read_at: null,
            just_remove: true,
          })
        });
      }
      return EMPTY;
    })
  ), { dispatch: false });

  initWebsocket$ = createEffect(() => this.actions$.pipe(
    ofType(fromUI.INIT_WEBSOCKET),
    mergeMap((action: fromUI.InitWebsocket) => {
      this.wsService.connect();
      this.wsService.authorize({
        authorization: action.payload.access,
      });

      return EMPTY;
    })
  ), { dispatch: false });


  storeLangSessionKey$ = createEffect(() => this.actions$.pipe(
    ofType(fromUI.STORE_LANG_SESSION_KEY),
    mergeMap((action: fromUI.StoreLangSessionKey) => {
      this.uiService
        .languageSessionKey(action.payload)
        .pipe(take(1))
        .subscribe(() => {});

      return EMPTY;
    })
  ), { dispatch: false });


  getRunningExercise$ = createEffect(() => this.actions$.pipe(
    ofType(fromUI.GET_RUNNING_EXERCISE),
    switchMap(() =>
      this.uiService.getRunningExercise().pipe(
        switchMap((runningExercise: RunningExercise) => [
          {
            type: fromUI.GET_RUNNING_EXERCISE_COMPLETED,
            payload: runningExercise,
          },
        ])
      )
    )
  ));


  extendRunningExercise$ = createEffect(() => this.actions$.pipe(
    ofType(fromUI.EXTEND_RUNNING_EXERCISE),
    mergeMap((action: fromUI.ExtendRunningExercise) =>
      this.uiService.extendRunningExercise(action.payload).pipe(
        mergeMap((result: RunningExercise) => [
          {
            type: fromUI.EXTEND_RUNNING_EXERCISE_COMPLETED,
            payload: result,
          },
        ])
      )
    )
  ));


  loadNotificationSettings = createEffect(() => this.actions$.pipe(
    ofType(fromUI.LOAD_NOFITICATION_SETTINGS),
    mergeMap((action: fromUI.LoadNotificationSettings) =>
      this.notificationsService
        .loadSettings(action.payload.accessMember, action.payload.userId)
        .pipe(
          mergeMap((settings: NotificationSettings | null) => [
            { type: fromUI.SET_NOTIFICATION_SETTINGS, payload: settings },
          ])
        )
    )
  ));


  saveNotificationSettings = createEffect(() => this.actions$.pipe(
    ofType(fromUI.SAVE_NOTIFICATION_SETTINGS),
    mergeMap((action: fromUI.SaveNotificationSettings) => {
      this.notificationsService.saveSettings(action.payload);
      return [
        {
          type: fromUI.SET_NOTIFICATION_SETTINGS,
          payload: action.payload.settings,
        },
      ];
    })
  ));


  getAnnouncements = createEffect(() => this.actions$.pipe(
    ofType(fromUI.GET_ANNOUNCEMENTS),
    switchMap(() =>
      this.uiService.getAnnouncements().pipe(
        mergeMap((announcements: Array<Announcement>) => [
          {
            type: fromUI.GET_ANNOUNCEMENTS_COMPLETED,
            payload: announcements,
          },
        ])
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private uiService: UIService,
    private notificationsService: NotificationsService,
    private wsService: WebSocketService,
    private fileService: FileService,
    private store: Store<{ui: fromUIStore.UIState}>,
    private translate: TranslateService
  ) {}
}
