import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { GroupedOrganizationLimit } from "src/app/shared/interfaces/limit";

@Component({
  selector: "course-limit-widget",
  templateUrl: "./course-limit-widget.component.html",
  styleUrls: ["./course-limit-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseLimitWidgetComponent {
  @Input() limits: Array<GroupedOrganizationLimit> = [];
}
