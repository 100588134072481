import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";
import { Pagination } from "../../interfaces/pagination";
type PaginationElement = {
  page: number;
  label: string;
  isVisible: boolean;
  iconName?: string;
  iconPosition?: "left" | "right";
};

@Component({
  selector: "pagination",
  templateUrl: "./pagination.component.html",
  styleUrls: ["./pagination.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnChanges {
  @Input() pagination: Pagination;
  @Input() pageSize: number;
  @Input() showMyPositionButton: boolean = true;
  @Output() pageChange: EventEmitter<number> = new EventEmitter();

  paginationElements: Array<PaginationElement> = [];

  constructor() {}

  ngOnChanges() {
    this.setPaginationElements();
  }

  private setPaginationElements() {
    const elements: Array<PaginationElement> = [];
    const {
      page,
      page_last: pageLast,
      position_last: positionLast,
    } = this.pagination;
    const pageSize = this.pageSize;

    // First page element
    elements.push({
      page: 1,
      label: `1 - ${pageSize}`,
      isVisible: page > 2,
      iconName: "angle-double-left",
      iconPosition: "left",
    });

    // Previous page element
    elements.push({
      page: page - 1,
      label: `${(page - 2) * pageSize + 1} - ${(page - 1) * pageSize}`,
      isVisible: page > 1,
      iconName: "angle-left",
      iconPosition: "left",
    });

    // Current page element
    elements.push({
      page: page,
      label: `${(page - 1) * pageSize + 1} - ${
        pageLast === page ? positionLast : page * pageSize
      }`,
      isVisible: true,
    });

    // Next page element
    elements.push({
      page: page + 1,
      label: `${page * pageSize + 1} - ${
        pageLast === page + 1 ? positionLast : (page + 1) * pageSize
      }`,
      isVisible: page < pageLast,
      iconName: "angle-right",
      iconPosition: "right",
    });

    // Last page element
    elements.push({
      page: pageLast,
      label: `${(pageLast - 1) * pageSize + 1} - ${positionLast}`,
      isVisible: page < pageLast - 1,
      iconName: "angle-double-right",
      iconPosition: "right",
    });

    this.paginationElements = elements;
  }
}
