import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
  DestroyRef,
} from "@angular/core";
import { NavbarElementComponent } from "../navbar-element/navbar-element.component";
import { NavbarNavTeacherProvider } from "../../../providers/navbar-nav-teacher.provider";
import { NavbarNavStudentProvider } from "../../../providers/navbar-nav-student.provider";
import { NavbarNavAdminProvider } from "../../../providers/navbar-nav-admin.provider";
import { NavbarNavItem } from "../../../models/navbar-nav-item";
import { Guid } from "src/app/shared/types/guid";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs/internal/Observable";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import * as fromTutorial from "../../../../store/reducers/tutorial.reducer";
import {
  TutorialSteps,
  TutorialTeacherSteps,
} from "src/app/shared/enums/tutorial-steps";
import { tap } from "rxjs/operators";
import { TutorialState } from "../../../../store/reducers/tutorial.reducer";

@Component({
  selector: "navbar-nav",
  templateUrl: "./navbar-nav.component.html",
  styleUrls: ["./navbar-nav.component.scss"],
})
export class NavbarNavComponent
  extends NavbarElementComponent
  implements OnChanges, OnInit
{
  @Input() isTeacher = false;
  @Input() isAdmin = false;
  @Input() activeOrganization: Guid | undefined;

  rightSideNavItems: Array<NavbarNavItem>;
  leftSideNavItems: Array<NavbarNavItem>;

  tutorial$: Observable<TutorialState> = this.store.select(
    (state) => state.tutorial,
  );

  showTutorialId: number | null = null;
  TutorialSteps = TutorialSteps;
  TutorialTeacherSteps = TutorialTeacherSteps;

  constructor(
    private readonly navbarNavTeacherProvider: NavbarNavTeacherProvider,
    private readonly navbarNavStudentProvider: NavbarNavStudentProvider,
    private readonly navbarNavAdminProvider: NavbarNavAdminProvider,
    private store: Store<{
      tutorial: fromTutorial.TutorialState;
    }>,
    private destroyRef: DestroyRef,
  ) {
    super();
  }
  ngOnInit() {
    this.tutorial$
      .pipe(
        tap((value) => {
          switch (value.tutorial_step) {
            case TutorialTeacherSteps.GROUPS_BTN:
              this.showTutorialId = 0;
              break;

            case TutorialSteps.COURSES_BTN:
            case TutorialTeacherSteps.COURSES_BTN:
              this.showTutorialId = 1;
              break;

            case TutorialSteps.RANKING_BTN:
            case TutorialTeacherSteps.RANKING_BTN:
              this.showTutorialId = 2;
              break;

            default:
              this.showTutorialId = null;
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { isTeacher, isAdmin, activeOrganization } = changes;

    if (isTeacher || isAdmin || activeOrganization) {
      this.rightSideNavItems = this.configureRightSideNavItems();
      this.leftSideNavItems = this.configureLeftSideNavItems();
    }
  }

  hideMenu() {
    super.toggle();
  }

  private configureRightSideNavItems(): any {
    if (this.isAdmin) {
      return this.navbarNavAdminProvider.getRightSideItems(
        this.activeOrganization,
      );
    }

    if (this.isTeacher) {
      return this.navbarNavTeacherProvider.getRightSideItems();
    }

    return this.navbarNavStudentProvider.getRightSideItems();
  }

  private configureLeftSideNavItems(): any {
    if (this.isAdmin) {
      return this.navbarNavAdminProvider.getLeftSideItems(
        this.activeOrganization,
      );
    }

    if (this.isTeacher) {
      return this.navbarNavTeacherProvider.getLeftSideItems();
    }

    return this.navbarNavStudentProvider.getLeftSideItems();
  }
}
