<modal-overlay>
  <modal-box>
    <div class="quiz-solve-modal-body row no-gutters text-center">
      <div class="col-12 congrats-title">
        <h5 class="heading-4" *ngIf="data.result">
          {{
            (data.result.points_earned >= data.request.quiz.pass_mark
              ? "QUIZ.EXERCISE.QUIZ_SUCCESS"
              : "QUIZ.EXERCISE.QUIZ_FAILED"
            ) | translate
          }}
        </h5>

        <h5 class="heading-4" *ngIf="!data.result">
          {{
            "QUIZ.EXERCISE.QUIZ_COMPLETED" | translate
          }}
        </h5>
      </div>

      <div class="col-12 questions-answered" *ngIf="data.result">
        <p>
          {{ "QUIZ.EXERCISE.CORRECT_ANSWERS" | translate }}:
          <b>{{ data.result.question_correct_count }}</b
          >/{{ data.request.quiz.question_count }}
        </p>
      </div>

      <div class="col-12 d-flex justify-content-center" *ngIf="data.result">
        <div class="points-progress">
          <div class="required-points">
            <div
              class="required-points-label mini-label"
              [style.width]="
                (progressFlow$ | async) ? required_percentage : '0%'
              "
            >
              <span class="label-text"
                >{{ "QUIZ.EXERCISE.POINTS_REQUIRED" | translate }}:
                <b>{{ data.request.quiz.pass_mark }}</b
                >/{{ data.request.quiz.points_max }}</span
              >
            </div>
            <div
              class="required-points-value"
              [style.width]="
                (progressFlow$ | async) ? required_percentage : '0%'
              "
            ></div>
          </div>

          <div
            class="earned-points"
            [ngClass]="{
              passed:
                data.result.points_earned >= data.request.quiz.pass_mark &&
                (progressFlow$ | async)
            }"
            [style.width]="(progressFlow$ | async) ? '100%' : '0%'"
          >
            <div
              class="earned-points-value"
              [style.width]="(progressFlow$ | async) ? points_percentage : '0%'"
            ></div>
          </div>

          <div
            class="earned-points-label mini-label"
            [style.width]="(progressFlow$ | async) ? points_percentage : '0%'"
          >
            <span class="label-text"
              >{{ "QUIZ.EXERCISE.POINTS_EARNED" | translate }}:
              <b>{{ data.result.points_earned }}</b
              >/{{ data.request.quiz.points_max }}</span
            >
          </div>
        </div>
      </div>
      <div class="col-12 button-panel">
        <button class="theme-hprimary lg text-lg" (click)="closeConfirm()">
          {{ "COURSES.COURSE.BACK_TO_COURSE" | translate }}
        </button>
      </div>
    </div>
  </modal-box>
</modal-overlay>
