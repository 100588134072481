import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from "@angular/core";
import { Topic } from "../../../interfaces/topic";
import { Guid } from "src/app/shared/types/guid";

enum ThemeVariant {
  InProgress = 0,
  Completed = 1,
  InProgressActive = 10,
  CompletedActive = 11,
}

@Component({
  selector: "course-progress-topic",
  templateUrl: "./course-progress-topic.component.html",
  styleUrls: ["./course-progress-topic.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseProgressTopicComponent {
  @Input() topic: Topic;
  @Input() state: { index: number; currentTopicIndex: number };
  @Output()
  topicChange: EventEmitter<Guid> = new EventEmitter<Guid>();
  @Input() courseId: Guid;
  @Input() groupId: Guid;

  constructor() {}

  goToTopic(event: MouseEvent) {
    event.preventDefault();

    this.topicChange.emit(this.topic.id);
  }

  get theme(): string {
    const variant: ThemeVariant =
      Number(this.isActive) * 10 + Number(this.isCompleted);

    switch (variant) {
      case ThemeVariant.InProgress:
      case ThemeVariant.InProgressActive:
        return "gray";
      case ThemeVariant.Completed:
        return "success";
      case ThemeVariant.CompletedActive:
        return "white";
    }
  }

  get isActive(): boolean {
    return this.state.index === this.state.currentTopicIndex;
  }

  get isCompleted(): boolean {
    return (
      Boolean(this.topic.exercises.length) &&
      this.topic.exercises.every((exercise) => exercise.passed)
    );
  }

  get solved(): number {
    return this.topic.exercises.reduce(
      (acc, exericse) => acc + Number(exericse.passed),
      0,
    );
  }
}
