<tile>
  <div class="course-limit-widget">
    <icon 
      name="university" 
      set="uil" 
      class="course-limit-widget__icon" 
    />
    <carousel
      [elements]="limits"
      [elementTemplate]="carouselContentTemplateRef"
      class="course-limit-widget__carousel"
    />
  </div>

  <ng-template #carouselContentTemplateRef let-limit="element">
    <span
      class="course-limit-widget__organization-name d-flex justify-content-center"
    >
      {{ limit.organization_id | organizationName | async }}
    </span>
    <div class="course-limit-widget__carousel-element-wrapper">
      <div class="course-limit-widget__carousel-element">
        <span class="course-limit-widget__limit-head">
          {{ "GLOBAL.LIMIT_WIDGET.COURSE_NAME_HEAD" | translate }}
        </span>

        <span class="course-limit-widget__limit-head course-limit-widget__limit-head--right">
          {{ "GLOBAL.LIMIT_WIDGET.LIMITS_HEAD" | translate }}
        </span>

        <ng-container *ngFor="let course of limit.courses">
          <span class="course-limit-widget__course-name">
            {{ course.name }}
          </span>

          <span class="course-limit-widget__limit-text">
            {{ course.limit_current }}/{{ course.limit_max ? course.limit_max : ("GROUPS.LIST.UNLIMITED" | translate | lowercase) }}
          </span>
        </ng-container>
      </div>
    </div>
  </ng-template>
</tile>
