import { Pipe, PipeTransform } from "@angular/core";
import { IconSet } from "../../../types/icon-set";
import { IconName } from "../../../types/icon-name";

@Pipe({
  name: "roundButtonClass",
})
export class RoundButtonClassPipe implements PipeTransform {
  transform(icon: IconName, set: IconSet = "typcn", rotate?: number): string {
    return [
      "round-button-icon",
      this.prepareRotateClass(rotate),
      this.prepareIconClass(set, icon),
    ].join(" ");
  }

  private prepareRotateClass(rotate?: number): string {
    return rotate ? `rotate-${rotate}` : "";
  }

  private prepareIconClass(set: IconSet, icon: IconName): string {
    return `${set} ${set}-${icon}`;
  }
}
