import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { QuizRequest } from "src/app/modules/quiz/interfaces/quiz-request";
import { Observable, of } from "rxjs";
import { QuizResponse } from "src/app/modules/quiz/interfaces/quiz-response";
import { QuizAnswerResponse } from "src/app/modules/quiz/interfaces/quiz-answer-response";
import { QuizAnswerRequest } from "src/app/modules/quiz/interfaces/quiz-answer-request";
import { QuizFigureRequest } from "src/app/modules/quiz/interfaces/quiz-figure-request";
import {
  QuizSolutionItemRequest,
  QuizSolutionsListRequest,
} from "src/app/modules/quiz/interfaces/quiz-solutions-list-request";
import {
  QuizSolutionItem,
  QuizSolutionsListResults,
} from "src/app/modules/quiz/interfaces/quiz-solutions-list-results";
import { FileService } from "../file/file.service";
import { tap } from "rxjs/operators";
import { HttpResponse } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class QuizService {
  constructor(
    private apiService: ApiService,
    private fileService: FileService
  ) {}

  getQuiz(params: QuizRequest): Observable<QuizResponse> {
    return this.apiService.getRequest<QuizResponse>(
      `courses/${params.CourseId}/${
        params.GroupId ? `groups/${params.GroupId}/` : ""
      }exercises/${params.ExerciseId}/quiz/`
    );
  }

  answerQuiz(params: QuizAnswerRequest): Observable<QuizAnswerResponse> {
    return this.apiService.putRequest<QuizAnswerResponse>(
      `courses/${params.CourseId}/${
        params.GroupId ? `groups/${params.GroupId}/` : ""
      }exercises/${params.ExerciseId}/quiz/`,
      params.answerPayload
    );
  }

  getFigure(params: QuizFigureRequest): Observable<Blob> {
    const figureUrl = `courses/${params.CourseId}/${
      params.GroupId ? `groups/${params.GroupId}/` : ""
    }exercises/${params.ExerciseId}/quiz/figure/${params.QuestionId}/`;

    if (typeof this.fileService.figureCache[figureUrl] !== "undefined") {
      return of(this.fileService.figureCache[figureUrl]);
    }

    return this.apiService
      .getRequest<Blob>(
        `courses/${params.CourseId}/${
          params.GroupId ? `groups/${params.GroupId}/` : ""
        }exercises/${params.ExerciseId}/quiz/figure/${params.QuestionId}/`,
        { responseType: "blob" }
      )
      .pipe(
        tap((blob: Blob) => {
          this.fileService.figureCache[figureUrl] = blob;
        })
      );
  }

  /** History related methods */
  getQuizSolutionsList(
    data: QuizSolutionsListRequest
  ): Observable<HttpResponse<QuizSolutionsListResults>> {
    return this.apiService.getRequest<HttpResponse<QuizSolutionsListResults>>(
      `courses/${data.CourseId}/${
        data.GroupId ? `groups/${data.GroupId}/` : ``
      }exercises/${data.ExerciseId}/quiz/solutions/${
        data.UserId ? `users/${data.UserId}/` : ``
      }?${data.Page ? `page=${data.Page}&` : ``}page_size=5`,
      { observe: "response" }
    );
  }

  getQuizSolution(data: QuizSolutionItemRequest): Observable<QuizSolutionItem> {
    return this.apiService.getRequest<QuizSolutionItem>(
      `courses/${data.CourseId}/${
        data.GroupId ? `groups/${data.GroupId}/` : ``
      }exercises/${data.ExerciseId}/quiz/solutions/${
        data.UserId ? `users/${data.UserId}/` : ``
      }${data.id}/`
    );
  }
}
