import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigStorageService {
  private config: { [key: string]: any } = {};

  constructor() {}

  store(config: { [key: string]: any }) {
    this.config = config;
  }

  get(): { [key: string]: any } {
    return this.config;
  }
}
