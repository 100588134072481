import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AccountService } from 'src/app/shared/services/account/account.service';

@Injectable({
  providedIn: 'root'
})
export class TeacherGuard  {
  constructor(private accountService: AccountService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.accountService
      .getMemberType(true)
      .pipe(switchMap(() => of(true)), catchError(() => of(false)));
  }
}
