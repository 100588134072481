import { Action } from "@ngrx/store";
import { Token } from "../../shared/types/token";
import { AuthToken } from "../../shared/interfaces/auth-token";
import { ErrorListing } from "src/app/shared/types/error-listing";
import { Guid } from "src/app/shared/types/guid";

export const LOGIN = "[Auth Method] Login";
export const LOGIN_COMPLETED = "[Auth Method] LoginCompleted";
export const LOGIN_FAILED = "[Auth Method] LoginFailed";
export const LOGOUT = "[Auth Method] Logout";
export const READ_TOKEN = "[Auth Method] ReadToken";
export const READ_TOKEN_FAILED = "[Auth Method] ReadTokenFailed";
export const REFRESH_TOKEN = "[Auth Method] RefreshToken";
export const REFRESH_TOKEN_COMPLETED = "[Auth Method] RefreshTokenCompleted";

export class Login implements Action {
  readonly type = LOGIN;

  constructor(
    public payload: { username: string; password: string; recaptcha: string }
  ) {}
}

export class LoginCompleted implements Action {
  readonly type = LOGIN_COMPLETED;

  constructor(public payload: { authToken: AuthToken; redeemCode?: Guid }) {}
}

export class LoginFailed implements Action {
  readonly type = LOGIN_FAILED;

  constructor(public payload: ErrorListing) {}
}

export class Logout implements Action {
  readonly type = LOGOUT;
}

export class ReadToken implements Action {
  readonly type = READ_TOKEN;
}

export class ReadTokenFailed implements Action {
  readonly type = READ_TOKEN_FAILED;
}

export class RefreshToken implements Action {
  readonly type = REFRESH_TOKEN;
}

export class RefreshTokenCompleted implements Action {
  readonly type = REFRESH_TOKEN_COMPLETED;

  constructor(public payload: Token) {}
}

export type AuthActions =
  | Login
  | LoginCompleted
  | LoginFailed
  | Logout
  | ReadToken
  | ReadTokenFailed
  | RefreshToken
  | RefreshTokenCompleted;
