import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import { invitedTeachersListLoadFail } from "../../actions/admin/invited-teachers.actions";
import {
  invitedStudentsListLoad,
  invitedStudentsListLoadSuccess,
} from "../../actions/admin/invited-students.actions";
import { InvitedStudentsPerTeacher } from "src/app/shared/interfaces/orgadmin/invited-students";

export interface InvitedStudentsListState {
  students: InvitedStudentsPerTeacher[];
  error: HttpErrorResponse | null;
}

const initialState: InvitedStudentsListState = {
  students: [],
  error: null,
};

export const invitedStudentsListReducer = createReducer(
  initialState,
  on(
    invitedStudentsListLoad,
    (state): InvitedStudentsListState => ({
      ...state,
      error: null,
    }),
  ),
  on(
    invitedStudentsListLoadSuccess,
    (state, action): InvitedStudentsListState => ({
      ...state,
      students: action.invitedStudents,
      error: null,
    }),
  ),
  on(
    invitedTeachersListLoadFail,
    (state, { error }): InvitedStudentsListState => ({
      ...state,
      error,
    }),
  ),
);
