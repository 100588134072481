import { Injectable, inject } from "@angular/core";
import { ApiService } from "../api/api.service";
import { Observable } from "rxjs";
import { Certificate } from "../../interfaces/certificate";

@Injectable({
  providedIn: "root",
})
export class CertifcatesService {
  private readonly apiService = inject(ApiService);

  getCertificates(): Observable<Certificate[]> {
    return this.apiService.getRequest<Certificate[]>(`certificates/`);
  }
}
