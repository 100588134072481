import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { IconSet } from "../../../types/icon-set";

@Component({
  selector: "navbar-nav-element",
  templateUrl: "./navbar-nav-element.component.html",
  styleUrls: ["./navbar-nav-element.component.scss"],
})
export class NavbarNavElementComponent {
  @Input() outlined = true;
  @Input() label = "";
  @Input() icon = "";
  @Input() set: IconSet = "typcn";
  @Input() route = "";
  @Input() showTutorial = false
  @Output() onClick = new EventEmitter<MouseEvent>();

  constructor(private readonly router: Router) {}

  get currentRoute(): string {
    return this.router.url;
  }

  onNavClick(event: MouseEvent) {
    this.onClick.emit(event);
  }
}
