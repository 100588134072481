<div class="d-flex align-items-center justify-content-end pagination" *ngIf="pagination">
    <ng-container *ngFor="let element of paginationElements">
        <a *ngIf="element.isVisible"
            routerLink="./" 
            [queryParams]="{page: element.page}"
            queryParamsHandling="merge"
            class="label pagination-element"
            [ngClass]="{
                current: pagination.page === element.page,
                'icon-right': element.iconPosition === 'right'
            }">
            <i *ngIf="element.iconName" class="uil uil-{{element.iconName}}"></i>
            {{element.label}}
        </a>
    </ng-container>


    <button 
        *ngIf="showMyPositionButton"
        routerLink="./" 
        [queryParams]="{page: null}"
        queryParamsHandling="merge"
        class="theme-hprimary ml-3">
        <i class="uil uil-user"></i> {{'RANKING.MY_POSITION' | translate}}
    </button>
</div>