import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ItemListMode } from "src/app/shared/enums/item-list-mode";
import { HistoryPagination } from "src/app/shared/interfaces/history-pagination";
import { Guid } from "src/app/shared/types/guid";

@Component({
  selector: "history-modal-frame",
  templateUrl: "./history-modal-frame.component.html",
  styleUrls: ["./history-modal-frame.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryModalFrameComponent {
  @Input() list: HistoryPagination<{ id: Guid }>;
  @Input() item: unknown;
  @Input() listCompleted: boolean;
  @Input() itemCompleted: boolean;
  @Input() noSelectedText: string;
  @Input() noSubmissionsText: string;
  @Input() mode: ItemListMode;

  ItemListMode: typeof ItemListMode = ItemListMode;

  constructor() {}
}
