import { Action } from "@ngrx/store";
import { EmailRequest } from "src/app/shared/interfaces/email-request";
import { ForgotPasswordRequest } from "src/app/shared/interfaces/forgot-password-request";
import {
  CourseLimit,
  OrganizationLimit,
} from "src/app/shared/interfaces/limit";
import { Member } from "src/app/shared/interfaces/member";
import { NameRequest } from "src/app/shared/interfaces/name-request";
import { PasswordRequest } from "src/app/shared/interfaces/password-request";
import { RedeemResult } from "src/app/shared/interfaces/redeem-result";
import { RegisterRequest } from "src/app/shared/interfaces/register-request";
import { ResetPasswordRequest } from "src/app/shared/interfaces/reset-password-request";
import { ErrorListing } from "src/app/shared/types/error-listing";
import { Guid } from "src/app/shared/types/guid";
import { LimitType } from "src/app/shared/types/limit-type";
import { Nillable } from "src/app/shared/types/nillable";

export const GET_MEMBER = "[Account Method] GetMember";
export const GET_MEMBER_COMPLETED = "[Account Method] GetMemberCompleted";
export const GET_MEMBER_FAILED = "[Account Method] GetMemberFailed";
export const REDIRECT = "[Account Method] Redirect";
export const REGISTER = "[Account Method] Register";
export const REGISTER_TEACHER = "[Account Method] Register Teacher";
export const REGISTER_COMPLETED = "[Account Method] RegisterCompleted";
export const REGISTER_FAILED = "[Account Method] RegisterFailed";
export const CHANGE_NAME = "[Account Method] ChangeName";
export const CHANGE_PASSWORD = "[Account Method] ChangePassword";
export const CHANGE_PASSWORD_COMPLETED =
  "[Account Method] ChangePasswordCompleted";
export const CHANGE_PASSWORD_FAILED = "[Account Method] ChangePasswordFailed";

export const CHANGE_EMAIL = "[Account Method] ChangeEmail";
export const CHANGE_EMAIL_SENT = "[Account Method] ChangeEmailSent";
export const CHANGE_EMAIL_SENT_FAILED =
  "[Account Method] ChangeEmailSentFailed";
export const CHANGE_EMAIL_CHECK = "[Account Method] ChangeEmailCheck";
export const CHANGE_EMAIL_COMPLETED = "[Account Method] ChangeEmailCompleted";
export const CHANGE_EMAIL_FAILED = "[Account Method] ChangeEmailFailed";

export const FORGOT_PASSWORD = "[Account Method] ForgotPassword";
export const FORGOT_PASSWORD_COMPLETED =
  "[Account Method] ForgotPasswordCompleted";
export const FORGOT_PASSWORD_FAILED = "[Account Method] ForgotPasswordFailed";

export const RESET_PASSWORD = "[Account Method] ResetPassword";
export const RESET_PASSWORD_COMPLETED =
  "[Account Method] ResetPasswordCompleted";
export const RESET_PASSWORD_FAILED = "[Account Method] ResetPasswordFailed";

export const UPDATE_USER_VPN_CONNECTION_STATUS =
  "[Account Method] UpdateUserVPNConnectionStatus";

export const UPDATE_IN_RANKING = "[Account Method] UpdateInRanking";
export const UPDATE_IN_RANKING_COMPLETED =
  "[Account Method] UpdateInRankingCompleted";

export const SET_REDEEM_CODE = "[Account Method] SetRedeemCode";
export const REDEEM = "[Account Method] Redeem";
export const REDEEM_COMPLETED = "[Account Method] RedeemCompleted";
export const REDEEM_FAILED = "[Account Method] RedeemFailed";
export const RELEASE_REDEEM = "[Account Method] ReleaseRedeem";

export const GET_LIMITS = "[Account Method] GetLimits";
export const GET_LIMITS_COMPLETED = "[Account Method] GetLimitsCompleted";
export const UPDATE_LIMIT = "[Account Method] UpdateLimit";
export const UPDATE_LIMIT_COMPLETED = "[Account Method] UpdateLimitCompleted";

export const SET_CURRENT_ORGANIZATION =
  "[Account Method] SetCurrentOrganization";

export class GetMember implements Action {
  readonly type = GET_MEMBER;
}

export class GetMemberCompleted implements Action {
  readonly type = GET_MEMBER_COMPLETED;

  constructor(public payload: { member: Member }) {}
}

export class GetMemberFailed implements Action {
  readonly type = GET_MEMBER_FAILED;
}

export class Redirect implements Action {
  readonly type = REDIRECT;

  constructor(public payload: { member: Member }) {}
}

export class Register implements Action {
  readonly type = REGISTER;

  constructor(
    public payload: {
      request: RegisterRequest;
      invitationId?: Guid;
      redeemCode?: Guid;
    },
  ) {}
}

export class RegisterTeacher implements Action {
  readonly type = REGISTER_TEACHER;

  constructor(
    public payload: {
      request: RegisterRequest;
      invitationId?: Guid;
      redeemCode?: Guid;
    },
  ) {}
}

export class RegisterCompleted implements Action {
  readonly type = REGISTER_COMPLETED;

  constructor(public payload?: Guid) {}
}

export class RegisterFailed implements Action {
  readonly type = REGISTER_FAILED;

  constructor(public payload: ErrorListing) {}
}

export class ChangeName implements Action {
  readonly type = CHANGE_NAME;

  constructor(public payload: NameRequest) {}
}

export class ChangeEmail implements Action {
  readonly type = CHANGE_EMAIL;

  constructor(public payload: EmailRequest) {}
}

export class ChangeEmailSent implements Action {
  readonly type = CHANGE_EMAIL_SENT;
}

export class ChangeEmailSentFailed implements Action {
  readonly type = CHANGE_EMAIL_SENT_FAILED;

  constructor(public payload: ErrorListing) {}
}

export class ChangeEmailCheck implements Action {
  readonly type = CHANGE_EMAIL_CHECK;

  constructor(public payload: Guid) {}
}

export class ChangeEmailCompleted implements Action {
  readonly type = CHANGE_EMAIL_COMPLETED;
}

export class ChangeEmailFailed implements Action {
  readonly type = CHANGE_EMAIL_FAILED;
}

export class ChangePassword implements Action {
  readonly type = CHANGE_PASSWORD;

  constructor(public payload: PasswordRequest) {}
}
export class ChangePasswordCompleted implements Action {
  readonly type = CHANGE_PASSWORD_COMPLETED;
}

export class ChangePasswordFailed implements Action {
  readonly type = CHANGE_PASSWORD_FAILED;

  constructor(public payload: ErrorListing) {}
}

export class ForgotPassword implements Action {
  readonly type = FORGOT_PASSWORD;

  constructor(public payload: ForgotPasswordRequest) {}
}

export class ForgotPasswordCompleted implements Action {
  readonly type = FORGOT_PASSWORD_COMPLETED;
}

export class ForgotPasswordFailed implements Action {
  readonly type = FORGOT_PASSWORD_FAILED;

  constructor(public payload: ErrorListing) {}
}

export class ResetPassword implements Action {
  readonly type = RESET_PASSWORD;

  constructor(
    public payload: { request: ResetPasswordRequest; forgotToken: Guid },
  ) {}
}

export class ResetPasswordCompleted implements Action {
  readonly type = RESET_PASSWORD_COMPLETED;
}

export class ResetPasswordFailed implements Action {
  readonly type = RESET_PASSWORD_FAILED;

  constructor(public payload: ErrorListing) {}
}

export class UpdateUserVPNConnectionStatus implements Action {
  readonly type = UPDATE_USER_VPN_CONNECTION_STATUS;

  constructor(public payload: Member) {}
}

export class UpdateInRanking implements Action {
  readonly type = UPDATE_IN_RANKING;

  constructor(public payload: { in_ranking: boolean }) {}
}

export class UpdateInRankingCompleted implements Action {
  readonly type = UPDATE_IN_RANKING_COMPLETED;

  constructor(public payload: Member) {}
}

export class SetRedeemCode implements Action {
  readonly type = SET_REDEEM_CODE;

  constructor(public payload: Guid) {}
}

export class Redeem implements Action {
  readonly type = REDEEM;

  constructor(public payload: Guid) {}
}

export class RedeemCompleted implements Action {
  readonly type = REDEEM_COMPLETED;

  constructor(public payload: RedeemResult) {}
}

export class RedeemFailed implements Action {
  readonly type = REDEEM_FAILED;

  constructor(public payload: ErrorListing) {}
}

export class ReleaseRedeem implements Action {
  readonly type = RELEASE_REDEEM;
}

export class GetLimits implements Action {
  readonly type = GET_LIMITS;

  constructor(public payload: LimitType) {}
}

export class GetLimitsCompleted implements Action {
  readonly type = GET_LIMITS_COMPLETED;

  constructor(
    public payload: {
      type: LimitType;
      limits: Array<CourseLimit>;
    },
  ) {}
}

export class UpdateLimit implements Action {
  readonly type = UPDATE_LIMIT;

  constructor(
    public payload: { type: LimitType; id: Guid; organizationId?: string },
  ) {}
}

export class UpdateLimitCompleted implements Action {
  readonly type = UPDATE_LIMIT_COMPLETED;

  constructor(public payload: { type: LimitType; limit: CourseLimit }) {}
}

export class SetCurrentOrganization implements Action {
  readonly type = SET_CURRENT_ORGANIZATION;

  constructor(public payload: Nillable<Guid>) {}
}

export type AccountActions =
  | GetMember
  | GetMemberCompleted
  | GetMemberFailed
  | Redirect
  | Register
  | RegisterTeacher
  | RegisterCompleted
  | RegisterFailed
  | ChangeName
  | ChangeEmail
  | ChangeEmailSent
  | ChangeEmailSentFailed
  | ChangeEmailCheck
  | ChangeEmailCompleted
  | ChangeEmailFailed
  | ChangePassword
  | ChangePasswordCompleted
  | ChangePasswordFailed
  | ForgotPassword
  | ForgotPasswordCompleted
  | ForgotPasswordFailed
  | ResetPassword
  | ResetPasswordCompleted
  | ResetPasswordFailed
  | UpdateUserVPNConnectionStatus
  | UpdateInRanking
  | UpdateInRankingCompleted
  | SetRedeemCode
  | Redeem
  | RedeemCompleted
  | RedeemFailed
  | ReleaseRedeem
  | GetLimits
  | GetLimitsCompleted
  | UpdateLimit
  | UpdateLimitCompleted
  | SetCurrentOrganization;
