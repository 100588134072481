import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "fileSize",
})
export class FileSizePipe implements PipeTransform {
  units: Record<number, string> = {
    1: "B",
    2: "KB",
    3: "MB",
    4: "GB",
    5: "TB",
  };
  transform(bytes: number): string {
    let reduced = bytes;
    let unit = 1;
    while (Math.trunc(reduced / 1024) > 0) {
      unit++;
      reduced = reduced / 1024;
    }

    return `${reduced.toFixed(2)} ${this.units[unit > 5 ? 5 : unit]}`;
  }
}
