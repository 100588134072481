import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { EMPTY, from, fromEvent, Subscription, timer } from "rxjs";
import { debounce, mergeMap, tap } from "rxjs/operators";
import { Keys } from "../../enums/keys";

const INPUT_DEBOUNCE_MS = 600;

export interface PillsInputHint<T = unknown> {
  value: string;
  data: T;
}

@Component({
  selector: "pills-input",
  templateUrl: "./pills-input.component.html",
  styleUrls: ["./pills-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PillsInputComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PillsInputComponent
  implements AfterViewInit, OnDestroy, OnChanges, ControlValueAccessor
{
  @Input() useHints: boolean = false;
  @Input() hints: Array<PillsInputHint> | null;
  @Input() pattern: RegExp;
  @Input() validationMessage: string;
  @Input() customHintContent: TemplateRef<any>;

  // Emits current part of text (from start to end of the word, based on caret position)
  @Output() partChanged: EventEmitter<string> = new EventEmitter();
  @Output() input: EventEmitter<void> = new EventEmitter();

  @ViewChild("pillSource")
  pillSource: ElementRef<HTMLTextAreaElement>;
  @ViewChild("sourceClone")
  sourceClone: ElementRef<HTMLSpanElement>;
  @ViewChild("virtualCaret")
  virtualCaret: ElementRef<HTMLSpanElement>;
  @ViewChild("hintsList")
  hintsList: ElementRef<HTMLUListElement>;

  values: Array<string> = [];
  hasError = false;
  sourceEventSubscription: Subscription;
  hintsEventSubscription: Subscription;
  separatorPattern: RegExp = /;+/;
  hintsPositionStyle: { left?: string; top?: string } = {};
  hintsVisible = false;
  currentPart: number = 0;
  isPristine = true;
  isDisabled = false;

  onChange = (values: Array<string>) => {};
  onTouched = () => {};

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hints && Array.isArray(changes.hints.currentValue)) {
      this.hintsVisible = true;
      this.cd.detectChanges();

      this.hintsListElement.scrollTop = 0;
      this.cd.detectChanges();
    }
  }

  ngAfterViewInit() {
    this.sourceEventSubscription = from(["input", "keyup", "click", "blur"])
      .pipe(
        mergeMap((event) =>
          fromEvent(this.sourceElement, event).pipe(
            tap((event: InputEvent | KeyboardEvent) => {
              switch (event.type) {
                case "input":
                  if (this.useHints) {
                    this.setHintsPosition();
                  }
                  this.input.emit();
                  this.isPristine = false;
                  break;

                case "click":
                case "blur":
                  this.handleOutsideEvent(
                    event as unknown as MouseEvent | FocusEvent
                  );
                  break;
              }
            }),
            debounce((event) =>
              event.type === "input" ? timer(INPUT_DEBOUNCE_MS) : EMPTY
            )
          )
        )
      )
      .subscribe((event: InputEvent | KeyboardEvent) => {
        switch (event.type) {
          case "input":
            this.handleInputEvent();
            break;

          case "keyup":
            this.handleKeyupEvent(event as KeyboardEvent);
            break;
        }

        this.cd.detectChanges();
      });

    this.hintsEventSubscription = fromEvent(
      this.hintsListElement,
      "keydown"
    ).subscribe((event: KeyboardEvent) => {
      this.handleKeyDownEvent(event);

      this.cd.detectChanges();
    });
  }

  ngOnDestroy() {
    this.sourceEventSubscription.unsubscribe();
    this.hintsEventSubscription.unsubscribe();
  }

  writeValue(filtered: Array<string>): void {
    if (filtered) {
      filtered.forEach((value) => this.values.push(value));
    } else {
      this.values = [];
    }

    this.cd.detectChanges();
    this.onChange(this.values);
    this.onTouched();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    this.cd.markForCheck();
  }

  setHintsPosition() {
    let cloneRect: DOMRect = this.clone.getBoundingClientRect() as DOMRect;

    this.clone.innerText = this.sourceElement.value.substring(0, this.caretPos);
    this.cloneContainer.scroll(0, this.sourceElement.scrollTop);

    this.hintsPositionStyle = {
      left: `${this.virtualCaretPos.left - cloneRect.left}px`,
      top: `${
        this.virtualCaretPos.top - cloneRect.top - this.sourceElement.scrollTop
      }px`,
    };

    this.hasError = false;
  }

  handleInputEvent() {
    const parts: Array<string> = this.sourceElement.value.split(
      this.separatorPattern
    );

    const partsLengths: Array<number> = parts.reduce((lengths, part, index) => {
      lengths.push((index > 0 ? lengths[index - 1] : 0) + part.length + 1);
      return lengths;
    }, [] as Array<number>);

    let caretPart = partsLengths.findIndex(
      (partLength) => this.caretPos < partLength
    );
    let parseResult = false;

    parseResult = this.parseSource(event);

    if (!parseResult) {
      this.currentPart =
        caretPart !== this.currentPart ? caretPart : this.currentPart;

      if (parts[this.currentPart] && parts[this.currentPart].trim()) {
        this.partChanged.emit(parts[this.currentPart]);
      } else {
        this.hintsVisible = false;
      }
    }
  }

  handleKeyupEvent(event: KeyboardEvent) {
    switch (event.key) {
      case Keys.LEFT:
      case Keys.RIGHT:
      case Keys.UP:
      case Keys.ESC:
        this.hintsVisible = false;
        break;

      case Keys.DOWN:
        if (this.hintsListElement && this.hintsVisible) {
          (<HTMLElement>this.hintsListElement.children[0]).focus();
        }

        break;
    }
  }

  handleKeyDownEvent(event: KeyboardEvent) {
    event.preventDefault();
    const prev = document.activeElement.previousElementSibling as HTMLElement;

    const next = document.activeElement.nextElementSibling as HTMLElement;

    switch (event.key as Keys) {
      case Keys.UP:
        if (prev) {
          prev.focus();
        } else {
          this.hintsListElement.children[
            this.hintsListElement.children.length - 1
          ].focus();
        }
        break;

      case Keys.DOWN:
        if (next) {
          next.focus();
        } else {
          this.hintsListElement.children[0].focus();
        }
        break;

      case Keys.ESC:
        this.hintsVisible = false;
        this.sourceElement.focus();
        break;

      case Keys.ENTER:
        if (this.hintsListElement.contains(document.activeElement))
          (<HTMLLIElement>document.activeElement).click();
        break;
    }
  }

  handleOutsideEvent(event: MouseEvent | FocusEvent) {
    let relatedTarget = (event as unknown as FocusEvent).relatedTarget;
    let target = (relatedTarget || event.target) as Node;

    if (!this.hintsListElement.contains(target)) {
      this.hintsVisible = false;
    }
  }

  parseSource(event?, allowSingleParse: boolean = false): boolean {
    let parseSucceed = false;
    let text: string = event ? event.target.value : this.sourceElement.value;

    if (text.match(this.separatorPattern) !== null || allowSingleParse) {
      let splitted: Array<string> = text
        .split(this.separatorPattern)
        .map((value) => value.trim())
        .filter((value) => value);
      let filtered: Array<string> = splitted.filter(
        (value) =>
          value.length && (this.pattern ? this.pattern.test(value) : true)
      );

      if (filtered.length === splitted.length) {
        this.sourceElement.value = "";
        this.writeValue(filtered);
        parseSucceed = true;
      } else {
        this.hasError = true;
        parseSucceed = false;
      }

      this.cd.detectChanges();
    }

    return parseSucceed;
  }

  removeValue(value: string, index: number) {
    this.values.splice(index, 1);
    this.writeValue([]);
  }

  parseHint(value: string) {
    this.hintsVisible = false;
    this.values.push(value);
    let parts = this.sourceElement.value.split(this.separatorPattern);

    parts.splice(this.currentPart, 1);

    let withoutHint = parts.join(";");

    this.sourceElement.value = withoutHint;
    this.cd.detectChanges();
    this.sourceElement.focus();
  }

  get sourceElement(): HTMLTextAreaElement {
    return this.pillSource.nativeElement;
  }

  get clone(): HTMLSpanElement {
    return this.sourceClone.nativeElement;
  }

  get cloneContainer(): HTMLDivElement {
    return this.clone.parentNode as HTMLDivElement;
  }

  get hintsListElement(): HTMLUListElement & {
    children: Array<HTMLLIElement>;
  } {
    return this.hintsList.nativeElement as HTMLUListElement & {
      children: Array<HTMLLIElement>;
    };
  }

  get caretPos(): number {
    return this.sourceElement.selectionStart;
  }

  get virtualCaretPos(): DOMRect {
    return this.virtualCaret.nativeElement.getBoundingClientRect() as DOMRect;
  }
}
