import { Action } from "@ngrx/store";
import { AuthToken } from "src/app/shared/interfaces/auth-token";
import { Member } from "src/app/shared/interfaces/member";
import { NotificationsResults } from "src/app/shared/interfaces/notifications-results";
import {
  RunningExercise,
  RunningExerciseData,
} from "src/app/shared/types/running-exercise";
import { VPNPassword } from "src/app/shared/interfaces/vpn-password";
import { Guid } from "src/app/shared/types/guid";
import { NotificationSettings } from "src/app/shared/types/notification-settings";
import { Announcement } from "src/app/shared/interfaces/announcement";

export const CONNECT_TO_LAB = "[UI Method] ConnectToLab";

export const GET_VPN_PASSWORD = "[UI Method] GetVPNPassword";
export const GET_VPN_PASSWORD_COMPLETED = "[UI Method] GetVPNPasswordCompleted";
export const GET_VPN_PASSWORD_FAILED = "[UI Method] GetVPNPasswordFailed";

export const GET_NOTIFICATIONS = "[UI Method] GetNotifications";
export const GET_NOTIFICATIONS_COMPLETED =
  "[UI Method] GetNotificationsCompleted";
export const CLEAR_NOTIFICATIONS = "[UI Method] ClearNotifications";
export const MARK_NOTIFICATION_AS_READ = "[UI Method] MarkNotificationAsRead";
export const MARK_ALL_NOTIFICATIONS_AS_READ = "[UI Method] MarkAllNotificationsAsRead";

export const LOAD_NOFITICATION_SETTINGS =
  "[UI Method] LoadNotificationSettings";
export const SAVE_NOTIFICATION_SETTINGS =
  "[UI Method] SaveNotificationSettings";
export const SET_NOTIFICATION_SETTINGS = "[UI Method] SetNofiticationSettings";

export const UPDATE_UNREAD_NOTIFICATIONS =
  "[UI Method] UpdateUnreadNotifications";

export const INIT_WEBSOCKET = "[UI Method] InitWebsocket";

export const STORE_LANG_SESSION_KEY = "[UI Method] StoreLangSessionKey";

export const RELOAD_HELP_WIDGET = "[UI Method] ReloadHelpWidget";

export const GET_RUNNING_EXERCISE = "[UI Method] GetRunningExercise";
export const GET_RUNNING_EXERCISE_COMPLETED =
  "[UI Method] GetRunningExerciseCompleted";
export const EXTEND_RUNNING_EXERCISE = "[UI Method] ExtendRunningExercise";
export const EXTEND_RUNNING_EXERCISE_COMPLETED =
  "[UI Method] ExtendRunningExerciseCompleted";
export const RESET_RUNNING_EXERCISE = "[UI Method] ResetRunningExercise";

export const TOGGLE_EXERCISE_ON = "[UI Method] ToggleExerciseOn";
export const TOGGLE_EXERCISE_OFF = "[UI Method] ToggleExerciseOff";

export const GET_ANNOUNCEMENTS = "[UI Method] GetAnnouncements";
export const GET_ANNOUNCEMENTS_COMPLETED =
  "[UI Method] GetAnnouncementsCompleted";

export const SET_STARTED_ID = "[UI Method] SetStartedId";

export class ConnectToLab implements Action {
  readonly type = CONNECT_TO_LAB;

  constructor() {}
}

export class GetVPNPassword implements Action {
  readonly type = GET_VPN_PASSWORD;

  constructor() {}
}

export class GetVPNPasswordCompleted implements Action {
  readonly type = GET_VPN_PASSWORD_COMPLETED;

  constructor(public payload: VPNPassword) {}
}

export class GetVPNPasswordFailed implements Action {
  readonly type = GET_VPN_PASSWORD_FAILED;

  constructor() {}
}

export class GetNotifications implements Action {
  readonly type = GET_NOTIFICATIONS;

  constructor(public payload: number) {}
}

export class GetNotificationsCompleted implements Action {
  readonly type = GET_NOTIFICATIONS_COMPLETED;

  constructor(public payload: NotificationsResults) {}
}

export class ClearNotifications implements Action {
  readonly type = CLEAR_NOTIFICATIONS;
}

export class MarkNotificationAsRead implements Action {
  readonly type = MARK_NOTIFICATION_AS_READ;

  constructor(public payload: Guid) {}
}

export class MarkAllNotificationsAsRead implements Action {
  readonly type = MARK_ALL_NOTIFICATIONS_AS_READ;
}

export class LoadNotificationSettings implements Action {
  readonly type = LOAD_NOFITICATION_SETTINGS;

  constructor(
    public payload: { accessMember: "teacher" | "student"; userId: Guid }
  ) {}
}

export class SaveNotificationSettings implements Action {
  readonly type = SAVE_NOTIFICATION_SETTINGS;

  constructor(
    public payload: { settings: NotificationSettings; userId: Guid }
  ) {}
}

export class SetNotificationSettings implements Action {
  readonly type = SET_NOTIFICATION_SETTINGS;

  constructor(public payload: NotificationSettings) {}
}

export class UpdateUnreadNotifications implements Action {
  readonly type = UPDATE_UNREAD_NOTIFICATIONS;

  constructor(public payload: number) {}
}

export class InitWebsocket implements Action {
  readonly type = INIT_WEBSOCKET;

  constructor(public payload: AuthToken) {}
}

export class StoreLangSessionKey implements Action {
  readonly type = STORE_LANG_SESSION_KEY;

  constructor(public payload: { language: string }) {}
}

export class ReloadHelpWidget implements Action {
  readonly type = RELOAD_HELP_WIDGET;

  constructor(public payload: Member) {}
}

export class GetRunningExercise implements Action {
  readonly type = GET_RUNNING_EXERCISE;
}

export class GetRunningExerciseCompleted implements Action {
  readonly type = GET_RUNNING_EXERCISE_COMPLETED;

  constructor(public payload: RunningExercise) {}
}

export class ExtendRunningExercise implements Action {
  readonly type = EXTEND_RUNNING_EXERCISE;

  constructor(public payload: Guid) {}
}

export class ExtendRunningExerciseCompleted implements Action {
  readonly type = EXTEND_RUNNING_EXERCISE_COMPLETED;

  constructor(public payload: RunningExerciseData) {}
}

export class ResetRunningExercise implements Action {
  readonly type = RESET_RUNNING_EXERCISE;
}

export class ToggleExerciseOn implements Action {
  readonly type = TOGGLE_EXERCISE_ON;

  constructor(public payload: Guid) {}
}

export class ToggleExerciseOff implements Action {
  readonly type = TOGGLE_EXERCISE_OFF;

  constructor(public payload: Guid) {}
}

export class GetAnnouncements implements Action {
  readonly type = GET_ANNOUNCEMENTS;
}

export class GetAnnouncementsCompleted implements Action {
  readonly type = GET_ANNOUNCEMENTS_COMPLETED;

  constructor(public payload: Array<Announcement>) {}
}

export class SetStartedId implements Action {
  readonly type = SET_STARTED_ID;

  constructor(public payload: Guid | null) {}
}

export type UIActions =
  | ConnectToLab
  | GetVPNPassword
  | GetVPNPasswordCompleted
  | GetVPNPasswordFailed
  | GetNotifications
  | GetNotificationsCompleted
  | ClearNotifications
  | MarkNotificationAsRead
  | MarkAllNotificationsAsRead
  | LoadNotificationSettings
  | SaveNotificationSettings
  | SetNotificationSettings
  | UpdateUnreadNotifications
  | InitWebsocket
  | StoreLangSessionKey
  | ReloadHelpWidget
  | GetRunningExercise
  | GetRunningExerciseCompleted
  | ExtendRunningExercise
  | ExtendRunningExerciseCompleted
  | ResetRunningExercise
  | ToggleExerciseOn
  | ToggleExerciseOff
  | GetAnnouncements
  | GetAnnouncementsCompleted
  | SetStartedId;
