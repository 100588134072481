import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import { CourseProgress } from "src/app/shared/interfaces/course-progress";
import {
  courseProgressLoad,
  courseProgressLoadSuccess,
  loadCourseProgressFailure,
} from "../actions/course-progress.actions";

export interface CourseProgressState {
  courseProgress: CourseProgress;
  error: HttpErrorResponse | null;
}

const initialState: CourseProgressState = {
  courseProgress: null,
  error: null,
};

export const courseProgressReducer = createReducer(
  initialState,
  on(
    courseProgressLoad,
    (state): CourseProgressState => ({
      ...state,
      courseProgress: null,
      error: null,
    }),
  ),
  on(
    courseProgressLoadSuccess,
    (state, action): CourseProgressState => ({
      ...state,
      courseProgress: action.courseProgress,
      error: null,
    }),
  ),
  on(loadCourseProgressFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);
