import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import {
  statsGroupLoad,
  statsGroupLoadSuccess,
  statsGroupLoadFail,
} from "../../actions/admin/stats-group.actions";
import { StatElement } from "src/app/shared/interfaces/student";

export interface StatsGroupState {
  stats: StatElement | null;
  error: HttpErrorResponse | null;
}

const initialState: StatsGroupState = {
  stats: null,
  error: null,
};

export const statsGroupReducer = createReducer(
  initialState,
  on(
    statsGroupLoad,
    (state): StatsGroupState => ({
      ...state,
    }),
  ),
  on(
    statsGroupLoadSuccess,
    (state, action): StatsGroupState => ({
      ...state,
      stats: action.stats,
      error: null,
    }),
  ),
  on(
    statsGroupLoadFail,
    (state, { error }): StatsGroupState => ({
      ...state,
      error,
    }),
  ),
);
