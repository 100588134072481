import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from "../api/api.service";
import { UserTutorial } from "../../interfaces/user-tutorial";

@Injectable({
  providedIn: 'root'
})
export class TutorialService {

  constructor(
    private apiService: ApiService
  ) {}

  getTutorialInfo(): Observable<UserTutorial> {
    return this.apiService.getRequest<UserTutorial>("tutorial/");
  }

  updateTutorialInfo(params: UserTutorial): Observable<void> {
    return this.apiService.putRequest<void>(`tutorial/`+params.id+'/', params);
  }
}
