import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { ExerciseFileUploadAnswerRequest } from "../../interfaces/exercise-file-upload-answer-request";
import { Observable } from "rxjs";
import {
  ExerciseFileUploadDownloadRequest,
  S3DownloadResponse,
} from "../../interfaces/exercise-file-upload-download-request";
import { ExerciseFileUploadS3Request } from "../../interfaces/exercise-file-upload-s3-request";
import { ExerciseFileUploadConfirmationRequest } from "../../interfaces/exercise-file-upload-confirmation-request";
import { ExerciseFileUploadConfigRequest } from "../../interfaces/exercise-file-upload-config-request";
import { UploadConfig } from "../../interfaces/upload-config";
import { AnswerResponse } from "../../interfaces/answer-response";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class StudentExerciseFileUploadApiService {
  constructor(
    private readonly apiService: ApiService,
    private http: HttpClient,
  ) {}

  collection(
    request: ExerciseFileUploadAnswerRequest,
  ): Observable<AnswerResponse[]> {
    const { courseId, groupId, exerciseId } = request;

    return this.apiService.getRequest(
      `courses/${courseId}/groups/${groupId}/exercises/${exerciseId}/upload/`,
    );
  }

  s3(request: ExerciseFileUploadS3Request): Observable<AnswerResponse> {
    const { courseId, groupId, exerciseId, filename } = request;
    const data = { filename };

    return this.apiService.postRequest<AnswerResponse>(
      `courses/${courseId}/groups/${groupId}/exercises/${exerciseId}/upload/s3_url/`,
      data,
    );
    // .pipe(
    //   map((response) => {
    //     return {
    //       ...response,
    //       s3_url: JSON.parse(
    //         (response.s3_url as unknown as string).replace(/'/g, '"'),
    //       ),
    //     };
    //   }),
    // );
  }

  uploadConfirmation(
    request: ExerciseFileUploadConfirmationRequest,
  ): Observable<void> {
    const { courseId, groupId, exerciseId, fileId } = request;

    return this.apiService.patchRequest(
      `courses/${courseId}/groups/${groupId}/exercises/${exerciseId}/upload/${fileId}/`,
      void 0,
    );
  }

  uploadConfig(
    request: ExerciseFileUploadConfigRequest,
  ): Observable<UploadConfig> {
    const { courseId, groupId, exerciseId } = request;

    return this.apiService.getRequest(
      `courses/${courseId}/groups/${groupId}/exercises/${exerciseId}/upload/upload_config/`,
    );
  }

  download(
    request: ExerciseFileUploadDownloadRequest,
  ): Observable<S3DownloadResponse> {
    const { courseId, groupId, exerciseId, fileId } = request;

    return this.apiService.getRequest(
      `courses/${courseId}/groups/${groupId}/exercises/${exerciseId}/upload/${fileId}/download/`,
    );
  }

  cancelUpload(
    request: ExerciseFileUploadConfirmationRequest,
  ): Observable<void> {
    const { courseId, groupId, exerciseId, fileId } = request;

    return this.apiService.deleteRequest(
      `courses/${courseId}/groups/${groupId}/exercises/${exerciseId}/upload/${fileId}/`,
      void 0,
    );
  }
}
