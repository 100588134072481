import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { PopupMenuComponent } from "../popup-menu/popup-menu.component";

@Component({
  selector: "switch-button-expanded",
  templateUrl: "./switch-button-expanded.component.html",
  styleUrls: ["./switch-button-expanded.component.scss"],
})
export class SwitchButtonExpandedComponent {
  @Input() toggled: boolean = false;
  @Input() busy: boolean = false;
  @Input() set: string = "typcn";
  @Input() icon: string;
  @Input() theme: "hprimary" | "hsecondary" = "hprimary";
  @Input() tooltipText: string;

  @Output() clicked: EventEmitter<MouseEvent> = new EventEmitter();

  @ViewChild(PopupMenuComponent)
  popupMenu: PopupMenuComponent;
  isExpanded = false;

  constructor() {}

  onClick(event: MouseEvent): void {
    if (!this.busy) {
      this.clicked.emit(event);
    }
  }

  toggle(event: MouseEvent): void {
    event.stopImmediatePropagation();

    if (this.isExpanded) {
      this.popupMenu.hide(event);
      this.isExpanded = false;
    } else {
      this.popupMenu.show();
      this.isExpanded = true;
    }
  }
}
