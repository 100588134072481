import { Pipe, PipeTransform } from "@angular/core";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { isNil } from "lodash-es";
import { StudentPassFilterFormData } from "src/app/modules/teacher/components/student-pass-filter-form/types/models/student-pass-filter-form-data";
import { StudentPassConditionFormService } from "src/app/modules/teacher/components/student-pass-filter-form/types/services/student-pass-condition-form.service";
import { Nillable } from "src/app/shared/types/nillable";
import { StudentRatingSystemFormService } from "src/app/modules/teacher/components/student-rating-system-filter-form/services/student-rating-system-form.service";
import { StudentStatElement } from "src/app/shared/interfaces/student";

@Pipe({
  name: "studentExercisePercentageCellClass",
})
export class StudentExercisePercentageCellClassPipe implements PipeTransform {
  constructor(
    private readonly ratingSystemService: StudentRatingSystemFormService,
    private readonly studentPassConditionFormDataService: StudentPassConditionFormService,
  ) {}

  transform(
    statistic: StudentStatElement,
  ): Observable<Nillable<"passed" | "failed">> {
    return this.studentPassConditionFormDataService.data.pipe(
      map((data: Nillable<StudentPassFilterFormData>) => {
        if (isNil(data)) {
          return null;
        }

        return (this.ratingSystemService.calculateTotalPoints(statistic) /
          this.ratingSystemService.calculateMaxPoints(statistic)) *
          100 >=
          data.passFrom
          ? "passed"
          : "failed";
      }),
    );
  }
}
