import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from "ng-recaptcha";
import { CookieService } from "ngx-cookie-service";
import { ToastrModule } from "ngx-toastr";
import * as fromRankingStore from "src/app/store/reducers/ranking.reducer";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpLoaderFactory } from "./shared/factories/HttpLoaderFactory";
import { ComponentSheetPageComponent } from "./shared/pages_TEMPORARY/component-sheet-page/component-sheet-page.component";
import { StylesSheetPageComponent } from "./shared/pages_TEMPORARY/styles-sheet-page/styles-sheet-page.component";
import { TokenInterceptor } from "./shared/services/token-interceptor/token-interceptor.service";
import { SharedModule } from "./shared/shared.module";
import { AccountEffect } from "./store/effects/account.effect";
import { AuthEffect } from "./store/effects/auth.effect";
import { CourseWebsocketEffect } from "./store/effects/course.websocket.effect";
import { RankingEffect } from "./store/effects/ranking.effect";
import { RefreshTokenEffect } from "./store/effects/refresh-token.effect";
import { UIEffect } from "./store/effects/ui.effect";
import * as fromAccountStore from "./store/reducers/account.reducer";
import * as fromAuthStore from "./store/reducers/auth.reducer";
import * as fromUIStore from "./store/reducers/ui.reducer";
import * as fromTutorialStore from "src/app/store/reducers/tutorial.reducer";
import { MonacoEditorModule } from "ngx-monaco-editor";
import { CustomErrorHandler } from "./shared/services/custom-error-handler/custom-error-handler.service";
import { NgxMaskModule } from "ngx-mask";
import { CourseAdminEffect } from "./store/effects/admin/course-admin.effect";
import { adminCourseListReducer } from "./store/reducers/admin/course-admin.reducer";
import { invitedTeachersListReducer } from "./store/reducers/admin/invited-teachers.reducer";
import { InvitedTeachersEffect } from "./store/effects/admin/invited-teachers.effect";
import { sendInviteTeacherReducer } from "./store/reducers/admin/send-invite-teacher.reducer";
import { courseTeachersListReducer } from "./store/reducers/admin/course-teachers.reducer";
import { CourseTeachersEffect } from "./store/effects/admin/course-teachers.effect";
import * as fromOrganizationsStore from "src/app/store/reducers/organizations.reducer";
import { OrganizationsEffect } from "./store/effects/organizations.effect";
import { CreateTeamTeachersEffect } from "./store/effects/admin/create-team-teachers.effect";
import { createTeamTeachersReducer } from "./store/reducers/admin/create-team-teachers.reducer";
import { editTeamTeachersReducer } from "./store/reducers/admin/edit-team-teachers.reducer";
import { invitedStudentsListReducer } from "./store/reducers/admin/invited-students.reducer";
import { InvitedStudentsEffect } from "./store/effects/admin/invited-students.effect";
import { singleActiveTeacherReducer } from "./store/reducers/admin/single-active-teacher.reducer";
import { invitedGroupStudentsListReducer } from "./store/reducers/admin/invited-group-students.reducer";
import { InvitedGroupStudentsEffect } from "./store/effects/admin/invited-group-students.effect";
import { statsGroupReducer } from "./store/reducers/admin/stats-group.reducer";
import { StatsGroupEffect } from "./store/effects/admin/stats-group.effect";
import { deleteTeamTeachersReducer } from "./store/reducers/admin/delete-team-teacher.reducer";
import { TutorialEffects } from "./store/effects/tutorial.effect";
import { certificatesReducer } from "./store/reducers/certificates.reducer";
import { CertificatesEffect } from "./store/effects/certificates.effect";
import { adminInactiveCourseListReducer } from "./store/reducers/admin/course-inactive-admin.reducer";
import { CourseInactiveAdminEffect } from "./store/effects/admin/course-inactive-admin.effect";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { packageListReducer } from "./store/reducers/packages/packages.reducer";
import { PackagesEffect } from "./store/effects/packages/packages.effect";
import { packageReducer } from "./store/reducers/packages/single-package.reducer";
import { packageUsersListReducer } from "./store/reducers/packages/package-users.reducer";
import { PackageUsersEffect } from "./store/effects/packages/package-users.effect";
import { packageCourseUsersListReducer } from "./store/reducers/packages/package-course-users.reducer";
import { packageTeacherGroupsListReducer } from "./store/reducers/packages/package-teacher-groups.reducer";
import { PackageTeacherGroupsEffect } from "./store/effects/packages/package-teacher-groups.effect";
import * as fromCourseStore from "src/app/store/reducers/course.reducer";
import { createFlaggedExerciseReducer } from "./store/reducers/flagged-exercises/create-flagged-exercise.reducer";
import { FlaggedExerciseEffect } from "./store/effects/flagged-exercises/flagged-exercises.effect";
import { flaggedExerciseListReducer } from "./store/reducers/flagged-exercises/get-flagged-exercises.reducer";
import { cancelFlaggedExerciseReducer } from "./store/reducers/flagged-exercises/cancel-flagged-exercise.reduer";
import { resolveFlaggedExerciseReducer } from "./store/reducers/flagged-exercises/resolve-flagged-exercise.reducer";
import { courseProgressReducer } from "./store/reducers/course-progress.reducer";

@NgModule({
  declarations: [
    AppComponent,
    ComponentSheetPageComponent,
    StylesSheetPageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    AppRoutingModule,
    RecaptchaV3Module,
    ToastrModule.forRoot({
      newestOnTop: false,
      maxOpened: 3,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    EffectsModule.forRoot([
      RefreshTokenEffect,
      AuthEffect,
      AccountEffect,
      UIEffect,
      RankingEffect,
      CourseWebsocketEffect,
      CourseAdminEffect,
      InvitedTeachersEffect,
      CourseTeachersEffect,
      OrganizationsEffect,
      CreateTeamTeachersEffect,
      InvitedStudentsEffect,
      InvitedGroupStudentsEffect,
      StatsGroupEffect,
      TutorialEffects,
      CertificatesEffect,
      CourseInactiveAdminEffect,
      PackagesEffect,
      PackageUsersEffect,
      PackageTeacherGroupsEffect,
      FlaggedExerciseEffect,
    ]),
    StoreModule.forRoot({
      auth: fromAuthStore.reducer,
      account: fromAccountStore.reducer,
      ui: fromUIStore.reducer,
      ranking: fromRankingStore.reducer,
      courseState: fromCourseStore.reducer,
      course: adminCourseListReducer,
      invitedTeachers: invitedTeachersListReducer,
      sendInviteTeacher: sendInviteTeacherReducer,
      courseTeachers: courseTeachersListReducer,
      organizations: fromOrganizationsStore.organizationsListReducer,
      createTeamTeachers: createTeamTeachersReducer,
      editTeamTeachers: editTeamTeachersReducer,
      invitedStudents: invitedStudentsListReducer,
      activeTeacher: singleActiveTeacherReducer,
      groupStudents: invitedGroupStudentsListReducer,
      statsGroup: statsGroupReducer,
      deleteTeamTeachers: deleteTeamTeachersReducer,
      tutorial: fromTutorialStore.reducer,
      certificates: certificatesReducer,
      inactiveCourses: adminInactiveCourseListReducer,
      packages: packageListReducer,
      package: packageReducer,
      packageUsers: packageUsersListReducer,
      packageCourseUsers: packageCourseUsersListReducer,
      packageTeacherGroups: packageTeacherGroupsListReducer,
      createFlaggedExercise: createFlaggedExerciseReducer,
      flaggedExercises: flaggedExerciseListReducer,
      cancelFlaggedExercise: cancelFlaggedExerciseReducer,
      resolveFlaggedExercise: resolveFlaggedExerciseReducer,
      courseProgress: courseProgressReducer,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
    }),
    MonacoEditorModule.forRoot(),
    NgxMaskModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    CookieService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: "6Le82bwUAAAAAFsHZE1L0eIqk_IxBpWiA3gpKLd_",
    },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
