<span [ngClass]="{
    'validation-hint--invalid': controlError
  }" class="validation-hint">
  <ng-content></ng-content>
  <span
    *ngIf="explanation"
    [tooltip]="explanation"
    position="bottom"
    class="explanation uil uil-question-circle">
  </span>
</span>
