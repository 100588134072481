import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { CourseTeachers } from "src/app/shared/interfaces/orgadmin/course-teachers";

export const coursePrefix = "[Course Teachers List]";

const courseTeachersListActions = {
  request: `${coursePrefix} Load`,
  requestSuccess: `${coursePrefix} Load success`,
  requestFail: `${coursePrefix} Load fail`,
};

export const courseTeachersListLoad = createAction(
  courseTeachersListActions.request,
  props<{ organizationId: string; subId: string }>(),
);

export const courseTeachersListLoadSuccess = createAction(
  courseTeachersListActions.requestSuccess,
  props<{ teachersList: CourseTeachers }>(),
);

export const courseTeachersListLoadFail = createAction(
  courseTeachersListActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
