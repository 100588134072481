<p
  *ngIf="control ?
    control.status === 'DISABLED' ?
      control.invalid && control.touched : !control.valid && control.touched
    : true"
  [ngStyle]="{'text-align': textAlign}"
  [ngClass]="{'no-negative-margin': !useNegativeMargin}"
  class="label validation-text">
  <ng-content></ng-content>
</p>
