import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { CodeConfigFile } from "../../../../interfaces/code-config-file";

@Injectable()
export class CourseExerciseFileUploadModalDataService {
  private readonly dataSource$ = new BehaviorSubject<CodeConfigFile[]>([]);

  get data$(): Observable<CodeConfigFile[]> {
    return this.dataSource$.asObservable();
  }

  patch(output: CodeConfigFile[]): void {
    this.dataSource$.next(output);
  }

  clear(): void {
    this.dataSource$.next([]);
  }
}
