import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'day'
})
export class DayPipe implements PipeTransform {
  transform(weekday: number): string {
    switch (weekday) {
      case 0:
        return 'GLOBAL.DAYS.MON';
      case 1:
        return 'GLOBAL.DAYS.TUE';
      case 2:
        return 'GLOBAL.DAYS.WED';
      case 3:
        return 'GLOBAL.DAYS.THU';
      case 4:
        return 'GLOBAL.DAYS.FRI';
      case 5:
        return 'GLOBAL.DAYS.SAT';
      case 6:
        return 'GLOBAL.DAYS.SUN';
    }
  }
}
