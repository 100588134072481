import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { PackageInvitations } from "src/app/shared/interfaces/packages/package-teacher-invitations";

export const coursePrefix = "[Package Teacher Groups List]";

const packageTeacherGroupsListActions = {
  request: `${coursePrefix} Load`,
  requestActiveSuccess: `${coursePrefix} Load success`,
  requestFail: `${coursePrefix} Load fail`,
  requestClear: `${coursePrefix} Clear`,
};

export const packageTeacherGroupsListLoad = createAction(
  packageTeacherGroupsListActions.request,
  props<{
    packageId: string;
  }>(),
);

export const packageTeacherGroupsListLoadSuccess = createAction(
  packageTeacherGroupsListActions.requestActiveSuccess,
  props<{ packagesGroups: PackageInvitations[] }>(),
);

export const loadPackageTeacherGroupsListFailure = createAction(
  packageTeacherGroupsListActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);

export const clearPackageTeacherGroups = createAction(
  packageTeacherGroupsListActions.requestClear,
);
