import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { EditorOptions } from "src/app/modules/ide/types/editor-options";
import { CodeCheckType } from 'src/app/shared/enums/code-check-type';
import { CodeTestStatus } from "src/app/shared/enums/code-test-status";
import { CodeTestResult } from "src/app/shared/interfaces/code-test-result";
import { CodeVerification } from "src/app/shared/interfaces/code-verification";

@Component({
  selector: "code-check-history-modal-item",
  templateUrl: "./code-check-history-modal-item.component.html",
  styleUrls: ["./code-check-history-modal-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeCheckHistoryModalItemComponent implements OnChanges {
  @Input() item: CodeVerification;
  @Input() isTeacher: boolean;

  activeTab: number | string = 0;
  activeTest: CodeTestResult = null;
  activeTestTab: keyof CodeTestResult = "compile_output";

  testTabs: Array<keyof CodeTestResult | string | number> = [
    "compile_output",
    "stdout",
    "stderr",
  ];

  editorOptions: EditorOptions = {
    theme: "vs-light",
    language: "plaintext",
    automaticLayout: true,
    readOnly: true,
    minimap: { enabled: false },
  };

  testEditorOptions: EditorOptions = { ...this.editorOptions };

  CodeCheckType: typeof CodeCheckType = CodeCheckType;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item.currentValue) {
      this.editorOptions = {
        ...this.editorOptions,
        language: changes.item.currentValue.code_check.language.mode,
      };

      this.activeTab = 0;
      this.activeTest = null;
      this.cd.detectChanges();
    }
  }

  getTestClass(
    test: CodeTestResult,
    activeTest: CodeTestResult | null,
    isTeacher: boolean
  ): { [key: string]: boolean } {
    switch (test.result.id) {
      case CodeTestStatus.CHECK_TEST_RESULT_SUCCESS:
        return {
          "test-success": true,
          "with-details": isTeacher,
          active: activeTest && activeTest.name === test.name,
        };
      default:
        return {
          "test-fail": true,
          "with-details": isTeacher,
          active: activeTest && activeTest.name === test.name,
        };
    }
  }

  isNumber(activeTab): boolean {
    return !isNaN(activeTab);
  }
}
