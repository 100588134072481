import { Component, EventEmitter, Input, Output } from "@angular/core";
@Component({
  selector: "switch-button",
  templateUrl: "./switch-button.component.html",
  styleUrls: ["./switch-button.component.scss"],
})
export class SwitchButtonComponent {
  @Input() toggled: boolean = false;
  @Input() busy: boolean = false;
  @Input() set: string = "typcn";
  @Input() icon: string;
  @Input() theme: "hprimary" | "hsecondary" = "hprimary";

  @Output() clicked: EventEmitter<MouseEvent> = new EventEmitter();

  onClick(event: MouseEvent): void {
    if (!this.busy) {
      this.clicked.emit(event);
    }
  }
}
