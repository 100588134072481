import { Action } from "@ngrx/store";
import { UserTutorial } from "src/app/shared/interfaces/user-tutorial"
import {HttpErrorResponse} from "@angular/common/http";

export const GET_TUTORIALS = "[Tutorial] Get Tutorial";
export const GET_TUTORIALS_SUCCESS = "[Tutorial] Get Tutorial Success";
export const GET_TUTORIALS_FAILURE = "[Tutorial] Get Tutorial Failure";

export const SET_TUTORIAL_STEP = "[Tutorial] Set Tutorial Step";
export const SKIP_TUTORIAL = "[Tutorial] Skip Tutorial";


export class GetTutorials implements Action {
  readonly type = GET_TUTORIALS;
}

export class GetTutorialsSuccess implements Action {
  readonly type = GET_TUTORIALS_SUCCESS;
  constructor(public payload: UserTutorial) {}
}

export class GetTutorialsFailure implements Action {
  readonly type = GET_TUTORIALS_FAILURE;
  constructor(public payload: { error: HttpErrorResponse }) {}
}

export class SetTutorialStep implements Action {
  readonly type = SET_TUTORIAL_STEP;
  constructor(public payload: UserTutorial) {}
}

export class SkipTutorial implements Action {
  readonly type = SKIP_TUTORIAL;
  constructor(public payload: UserTutorial) {}
}

export type TutorialActions =
  | GetTutorials
  | GetTutorialsSuccess
  | GetTutorialsFailure
  | SetTutorialStep
  | SkipTutorial;
