<ng-container [ngSwitch]="true">
  <ng-container *ngSwitchCase="canStartOrRestart">
    <button
      [ngClass]="{
        'theme-hsecondary': !exercise.passed,
        'theme-hsecondarylight': exercise.passed,
        'd-none': !isTeacher && !exercise.can_start
      }"
      class="exercise-control-button"
      (click)="start($event)"
    >
      {{
        (exercise.passed
          ? "COURSES.COURSE.EXERCISE.RESTART"
          : "COURSES.COURSE.EXERCISE.START"
        ) | translate
      }}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="canCancelExercise">
    <button
      class="theme-hprimarydark exercise-control-button"
      (click)="stop($event)"
    >
      <countdown></countdown>
      {{ "COURSES.COURSE.EXERCISE.CANCEL" | translate }}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="canStopExercise">
    <button 
      class="btn-error-600 exercise-control-button" 
      (click)="stop($event)">
      {{ "COURSES.COURSE.EXERCISE.STOP" | translate }}
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="canGoToContainer">
    <a
      class="button theme-hlogo exercise-control-button"
      routerLink="{{
        '/ide/container/' +
          courseId +
          (isTeacher ? '/' : '/groups/' + activeGroup + '/') +
          'exercise/' +
          exercise.id
      }}"
    >
      <span class="typcn typcn-code"></span>
      {{ "IDE.CONTAINER_EXERCISE.GO_TO_CONTAINER" | translate }}
    </a>
  </ng-container>
</ng-container>
