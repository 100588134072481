import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateSubscriptionsService {
  private subscriptions: {[key: string] : Subscription} = {};

  constructor(private translate: TranslateService) { }

  subscribe(name: string, callback: Function) {
    if(typeof this.subscriptions[name] === 'undefined' || this.subscriptions[name].closed) {
      this.subscriptions[name] = this.translate.onLangChange.subscribe(callback);
    }
  }

  unsubscribe(name: string) {
    if(typeof this.subscriptions[name] !== 'undefined' && !this.subscriptions[name].closed) {
      this.subscriptions[name].unsubscribe();
    }
  }
}
