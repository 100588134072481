import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { AuthToken } from "../../interfaces/auth-token";
import { Token } from "../../types/token";
import { ApiService } from "../api/api.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private apiService: ApiService, private router: Router) {}

  login(credentials: {
    username: string;
    password: string;
  }): Observable<AuthToken> {
    return this.apiService.postRequest<AuthToken>("token/", credentials);
  }

  saveToStorage(authToken: AuthToken, refreshed = false) {
    if (refreshed) {
      (<AuthToken>(
        JSON.parse(window.localStorage.getItem("cyber-skiller-token"))
      )).access = authToken.access;
    } else {
      window.localStorage.setItem(
        "cyber-skiller-token",
        JSON.stringify(authToken)
      );
    }
  }

  getFromStorage(): Observable<AuthToken> {
    return of(JSON.parse(window.localStorage.getItem("cyber-skiller-token")));
  }

  logout() {
    if (
      !this.router.url.includes("/account/login") &&
      !this.router.url.includes("/account/redeem/")
    ) {
      this.router.navigate(["/account/login"]);
    }
    window.localStorage.removeItem("cyber-skiller-token");
  }

  refreshAccessToken(token: Token): Observable<AuthToken> {
    return this.apiService.postRequest<AuthToken>("token/refresh/", {
      refresh: token,
    });
  }
}
