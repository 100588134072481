import { createAction, props } from "@ngrx/store";
import { InvitationsRequest } from "src/app/shared/interfaces/invitations-request";

export const coursePrefix = "[Invited Teachers List]";

const invitedTeachersListActions = {
  requestSendInvite: `${coursePrefix} Send invite`,
  requestSendInviteFail: `${coursePrefix} Send invite fail`,
};

export const sendInviteTeacher = createAction(
  invitedTeachersListActions.requestSendInvite,
  props<{ payload: InvitationsRequest; organizationId: string }>(),
);

export const sendInviteTeacherFail = createAction(
  invitedTeachersListActions.requestSendInviteFail,
  props<{ error: InvitationsRequest | null }>(),
);
