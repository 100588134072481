import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PickerOption } from "../../interfaces/picker-option";

@Component({
  selector: "slider-picker",
  templateUrl: "./slider-picker.component.html",
  styleUrls: ["./slider-picker.component.scss"],
})
export class SliderPickerComponent implements OnInit {
  @Input() options: Array<PickerOption> = [];
  @Input() selected: any;
  @Output() picked: EventEmitter<PickerOption> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  pick(option: PickerOption) {
    this.picked.emit(option);
  }
}
