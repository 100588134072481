import { Pipe, PipeTransform } from "@angular/core";
import { StatusValues } from "../enums/status-values";

@Pipe({
  name: "colorStatus",
})
export class ColorStatusPipe implements PipeTransform {
  transform(value: StatusValues) {
    const shortcutMap = new Map([
      [
        StatusValues.NONE,
        {
          backgroundColor: "#d8d8d8",
          color: "#ffffff",
          dot: "#ffffff",
          value: "NONE",
        },
      ],
      [
        StatusValues.PENDING,
        {
          backgroundColor: "#FFFAEB",
          color: "#B54708",
          dot: "#F79009",
          value: "ADMIN.STATUS.PENDING",
        },
      ],
      [
        StatusValues.ACTIVE,
        {
          backgroundColor: "#ECFDF3",
          color: "#027A48",
          dot: "#12B76A",
          value: "ADMIN.STATUS.ACTIVE",
        },
      ],
      [
        StatusValues.EXPIRED,
        {
          backgroundColor: "#FEF3F2",
          color: "#B42318",
          dot: "#F04438",
          value: "ADMIN.STATUS.EXPIRIED",
        },
      ],
      [
        StatusValues.REJECTED,
        {
          backgroundColor: "#FEF3F2",
          color: "#B42318",
          dot: "#F04438",
          value: "ADMIN.STATUS.REJECTED",
        },
      ],
      [
        StatusValues.REMOVED,
        {
          backgroundColor: "#d8d8d8",
          color: "#ffffff",
          dot: "#ffffff",
          value: "ADMIN.STATUS.REMOVED",
        },
      ],
      [
        StatusValues.DELIVERED,
        {
          backgroundColor: "#fef0c7",
          color: "#f79009",
          dot: "#f79009",
          value: "ADMIN.STATUS.DELIVERED",
        },
      ],
      [
        StatusValues.FAILED,
        {
          backgroundColor: "#FEF3F2",
          color: "#B42318",
          dot: "#F04438",
          value: "ADMIN.STATUS.FAILED",
        },
      ],
    ]);
    return shortcutMap.get(value);
  }
}
