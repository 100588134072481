import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { Subscription } from "rxjs";

@Component({
  selector: "validation-hint",
  templateUrl: "./validation-hint.component.html",
  styleUrls: ["./validation-hint.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationHintComponent implements OnInit, OnDestroy {
  @Input() control: FormControl;
  @Input() explanation: string | undefined;
  @Input() errorName: string;

  private controlChangeSubscription: Subscription;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.controlChangeSubscription = this.control?.valueChanges.subscribe(
      () => {
        this.cdr.markForCheck();
      }
    );
  }

  ngOnDestroy(): void {
    if (this.controlChangeSubscription) {
      this.controlChangeSubscription.unsubscribe();
    }
  }

  get controlError(): boolean {
    const isTouchedAndHasError =
      this.control.touched &&
      (this.errorName ? this.control.errors[this.errorName] : true); // We can rely on invalid and valid property when errorName is not provided

    return this.control
      ? this.control.status === "DISABLED"
        ? this.control.invalid && isTouchedAndHasError
        : !this.control.valid && isTouchedAndHasError
      : false;
  }
}
