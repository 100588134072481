import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import {
  FlaggedExercise,
  FlaggedExerciseList,
} from "src/app/shared/interfaces/flagged-exercises";

export const coursePrefix = "[Resolve Flagged Exercise]";

const resolveFlaggedExerciseActions = {
  request: `${coursePrefix} Load`,
  requestActiveSuccess: `${coursePrefix} Load success`,
  requestFail: `${coursePrefix} Load fail`,
};

export const resolveflaggedExerciseLoad = createAction(
  resolveFlaggedExerciseActions.request,
  props<{
    courseId: string;
    groupId: string;
    flagId: string;
  }>(),
);

export const resolveflaggedExerciseLoadSuccess = createAction(
  resolveFlaggedExerciseActions.requestActiveSuccess,
  props<{ exercise: FlaggedExerciseList; courseId: string; groupId: string }>(),
);

export const resolveflaggedExerciseFailure = createAction(
  resolveFlaggedExerciseActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
