import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";
import { shortHumanizer } from "src/app/config/humanizers";

@Pipe({
  name: "dateDifference"
})
export class DateDifferencePipe implements PipeTransform {
  constructor() {}

  transform(
    value: string,
    args: { useLang: string; current?: moment.Moment | string } = {
      useLang: "en"
    }
  ): string {
    let current: moment.Moment = args.current ? moment(args.current) : moment();
    let valueLocal: moment.Moment = moment.utc(value).local();

    return `${shortHumanizer(moment.duration(valueLocal.diff(current)), {
      language: args.useLang + "Short",
      delimiter: " ",
      spacer: "",
      largest: 5,
      round: true
    })}`;
  }
}
