import { createReducer, on } from "@ngrx/store";
import { CreateTeamTeachersResponse } from "src/app/shared/interfaces/orgadmin/course-teachers";
import {
  editTeamTeachers,
  editTeamTeachersFail,
  editTeamTeachersLoad,
} from "../../actions/admin/edit-team-teachers.actions";

export interface EditTeamTeachersState {
  editTeamTeachersFailed: boolean | null;
  error: CreateTeamTeachersResponse | null;
}

const initialState: EditTeamTeachersState = {
  editTeamTeachersFailed: null,
  error: null,
};

export const editTeamTeachersReducer = createReducer(
  initialState,
  on(
    editTeamTeachersLoad,
    (state): EditTeamTeachersState => ({
      ...state,
      editTeamTeachersFailed: false,
      error: null,
    }),
  ),
  on(
    editTeamTeachers,
    (state): EditTeamTeachersState => ({
      ...state,
      editTeamTeachersFailed: false,
      error: null,
    }),
  ),
  on(
    editTeamTeachersFail,
    (state, { error }): EditTeamTeachersState => ({
      ...state,
      editTeamTeachersFailed: true,
      error,
    }),
  ),
);
