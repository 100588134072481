import { Component, Input } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "surprise-box",
  templateUrl: "./surprise-box.component.html",
  styleUrls: ["./surprise-box.component.scss"],
})
export class SurpriseBoxComponent {
  @Input() size = 200;
  sanitizer: DomSanitizer;

  constructor(private sanitizier: DomSanitizer) {
    this.sanitizer = sanitizier;
  }
}
