import { Component, Input } from "@angular/core";
import { FlexPosition } from "../../types/flex-position";
import { Store } from "@ngrx/store";
import { TutorialTeacherSteps } from "../../enums/tutorial-steps";
import { Observable } from "rxjs";
import * as fromTutorial from "src/app/store/reducers/tutorial.reducer";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";

@Component({
  selector: "page-column-layout",
  templateUrl: "./page-column-layout.component.html",
  styleUrls: ["./page-column-layout.component.scss"],
})
export class PageColumnLayoutComponent {
  @Input() leftColumnWidth: string | number = 30;
  @Input() rightColumnWidth: string | number = 70;
  @Input() position: FlexPosition = "unset";

  tutorial$: Observable<TutorialTeacherSteps> = this.store.select(
    (state) => state.tutorial.tutorial_step,
  );

  isTeacher$: Observable<boolean> = this.store.select(
    (state) => state.account.member.is_teacher,
  );

  showTutorial: boolean = false;
  TutorialTeacherSteps = TutorialTeacherSteps;

  constructor(
    private store: Store<{
      tutorial: fromTutorial.TutorialState;
      account: fromAccountStore.AccountState;
    }>,
  ) {}
}
