<modal-overlay>
    <modal-box>
        <div class="welcome-modal-body row no-gutters">
            <div class="col-12">
                <h5 class="heading-6">{{modalTitle | translate}}</h5>
            </div>

            <div class="col-12 row no-gutters align-items-center">
                <div class="d-none d-md-block col-4">
                    <img src="/assets/images/welcome-image.png" class="welcome-image" alt="Welcome">
                </div>
                <div class="col-12 col-md-8 steps">
                    <ul class="steps-list">
                        <li *ngFor="let step of steps; let i = index" class="step d-flex align-items-center"
                            [ngClass]="{'flex-md-row-reverse': i%2!==0}">
                            <span class="step-number">{{i+1}}</span>
                            <p class="step-body">

                                <span class="step-text" [innerHTML]="step.text | translate"></span>
                                <a *ngIf="step.videoUrl" [href]="step.videoUrl" target="_blank"
                                    class="button btn-link"><span class="typcn typcn-video"></span>
                                    {{'GLOBAL.WELCOME_SCREEN.VIDEO' | translate}}</a>
                            </p>

                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-12 additional-info">
                <p class="color-hdarkpanel" [innerHTML]="'GLOBAL.WELCOME_SCREEN.ADDITIONAL_INFO' | translate"></p>
            </div>


            <div class="col-12 d-flex flex-column flex-md-row justify-content-center align-items-center button-panel">

                <div>
                    <input type="checkbox" id="dontShowWelcomeAgain" name="dontShowWelcomeAgain"
                        [(ngModel)]="dontShowWelcomeAgain" (change)="setUnsetCookie()" />
                    <label class="checkbox" for="dontShowWelcomeAgain" translate>{{'GLOBAL.DONT_SHOW_AGAIN'}}</label>
                </div>

                <button class="theme-hprimary lg text-lg"
                    (click)="closeWelcome()">{{'GLOBAL.OK_BUTTON' | translate}}</button>
            </div>
        </div>
    </modal-box>
</modal-overlay>
