import { Component, Input, OnInit } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Announcement } from "../../interfaces/announcement";
import * as moment from "moment";
@Component({
  selector: "announcement",
  templateUrl: "./announcement.component.html",
  styleUrls: ["./announcement.component.scss"],
})
export class AnnouncementComponent implements OnInit {
  @Input() announcement: Announcement;
  constructor(private cookieService: CookieService) {}

  shouldBeVisible: boolean = false;

  ngOnInit(): void {
    this.setVisibility();
  }

  hideAnnouncement(): void {
    const to: moment.Moment = moment(this.announcement.date_until).local();
    this.cookieService.set(
      this.cookieName,
      "hide",
      to.add(1, "days").toDate(),
      "/",
      undefined,
      undefined,
      "Strict"
    );
    this.setVisibility();
  }

  get cookieName(): string {
    return `cyberskiller-announcement-${this.announcement.id}`;
  }

  private setVisibility(): void {
    const isCookieExist: boolean = this.cookieService.check(this.cookieName);

    this.shouldBeVisible = !isCookieExist;
  }
}
