import { Pipe, PipeTransform } from "@angular/core";
import { CodeCheckType } from "../enums/code-check-type";

@Pipe({
  name: "codeCheckType",
})
export class CodeCheckTypePipe implements PipeTransform {
  transform(value: CodeCheckType): string {
    switch (value) {
      case CodeCheckType.RUN:
        return "COURSES.COURSE.EXERCISE.CODE_CHECK_HISTORY.RUN";
      case CodeCheckType.VERIFY:
        return "COURSES.COURSE.EXERCISE.CODE_CHECK_HISTORY.VERIFY";
    }
  }
}
