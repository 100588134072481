import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { Observable } from "rxjs";
import { Ranking, RankingProcessing } from "../../interfaces/ranking";
import { Guid } from "../../types/guid";
import { RankingCriteria } from "../../models/ranking-criteria";
import { HttpResponse } from "@angular/common/http";
import { UserSummary } from "../../interfaces/user-summary";
import { HttpContextBuilder } from "../../http/context/builders/http-context-builder.service";

@Injectable({
  providedIn: "root",
})
export class RankingService {
  constructor(
    private readonly apiService: ApiService,
    private readonly contextBuilder: HttpContextBuilder,
  ) {}

  getRanking(
    criteria: RankingCriteria,
  ): Observable<HttpResponse<Ranking | RankingProcessing>> {
    return this.apiService.getRequest<
      HttpResponse<Ranking | RankingProcessing>
    >(`ranking2/aggregated/`, {
      params: this.apiService.cleanPayload<RankingCriteria>(criteria),
      observe: "response",
    });
  }

  getGroupRanking(data: {
    CourseId: Guid;
    GroupId: Guid;
  }): Observable<Ranking> {
    const context = this.contextBuilder.skipBy404Token().build();

    return this.apiService.getRequest<Ranking>(
      `courses/${data.CourseId}/groups/${data.GroupId}/ranking/`,
      { context },
    );
  }

  getRankingSummary(): Observable<UserSummary> {
    const context = this.contextBuilder.skipBy503Token().build();

    return this.apiService.getRequest<UserSummary>("ranking2/user_summary/", {
      context: context,
    });
  }
}
