import { createReducer, on } from "@ngrx/store";
import { Organization } from "src/app/shared/interfaces/organization";
import {
  organizationsListLoad,
  organizationsListLoadFail,
  organizationsListLoadSuccess,
} from "../actions/organizations.actions";
import { HttpErrorResponse } from "@angular/common/http";

export interface OrganizationsListState {
  organizations: Organization[];
  error: HttpErrorResponse | null;
}

const initialState: OrganizationsListState = {
  organizations: [],
  error: null,
};

export const organizationsListReducer = createReducer(
  initialState,
  on(
    organizationsListLoad,
    (state): OrganizationsListState => ({
      ...state,
      error: null,
    }),
  ),
  on(
    organizationsListLoadSuccess,
    (state, action): OrganizationsListState => ({
      ...state,
      organizations: action.organizations,
      error: null,
    }),
  ),
  on(
    organizationsListLoadFail,
    (state, { error }): OrganizationsListState => ({
      ...state,
      error,
    }),
  ),
);
