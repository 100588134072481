import { Component, Input } from "@angular/core";

@Component({
  selector: "highlight",
  templateUrl: "./highlight.component.html",
  styleUrls: ["./highlight.component.scss"],
})
export class HighlightComponent {
  @Input() highlightId: string;
}
