import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import { PackageUsers } from "src/app/shared/interfaces/packages/package-users";
import {
  loadPackageUsersListFailure,
  packageUsersListLoad,
  packageUsersListLoadSuccess,
} from "../../actions/packages/package-users.actions";

export interface PackageUsersListState {
  users: PackageUsers;
  error: HttpErrorResponse | null;
}

const initialState: PackageUsersListState = {
  users: null,
  error: null,
};

export const packageUsersListReducer = createReducer(
  initialState,
  on(
    packageUsersListLoad,
    (state): PackageUsersListState => ({
      ...state,
      users: null,
      error: null,
    }),
  ),
  on(
    packageUsersListLoadSuccess,
    (state, action): PackageUsersListState => ({
      ...state,
      users: action.users,
      error: null,
    }),
  ),
  on(loadPackageUsersListFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);
