import { Component, Input } from "@angular/core";
import { Invitation } from "src/app/shared/interfaces/invitation";
import { Store } from "@ngrx/store";
import * as fromGroups from "src/app/store/actions/groups.actions";

@Component({
  selector: "group-invitation-listbox-element-content",
  templateUrl: "./group-invitation-listbox-element-content.component.html",
  styleUrls: ["./group-invitation-listbox-element-content.component.scss"],
})
export class GroupInvitationListboxElementContentComponent {
  @Input() invitation: Invitation;

  constructor(private store: Store<{}>) {}

  respondToInvitation(accepted: boolean): void {
    this.store.dispatch(
      new fromGroups.RespondToInvitation({
        invitationId: this.invitation.id,
        request: { accepted },
      }),
    );
  }
}
