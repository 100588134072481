import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { map, switchMap, take, tap } from "rxjs/operators";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { StudentRatingSystemFilterFormData } from "./models/student-rating-system-filter-form-data";
import {
  StudentRatingSystemFilterForm,
  StudentRatingSystemFilterGradeForm,
} from "./types/student-rating-system-filter-form";
import { descendingOrderValidator } from "./validators/descending-order.validator";
import { StudentRatingSystemFormService } from "./services/student-rating-system-form.service";
import { StudentRatingSystemGradeEntity } from "./interfaces/student-rating-system-grade-entity";
import { EMPTY, merge, Observable, of } from "rxjs";
import { isNil } from "lodash-es";
import { GradeOperator } from "../../../../shared/enums/grade-operator";
import { SelectButtonItem } from "src/app/shared/blocks/select-button/models/select-button-item";
import { SelectButtonOptionClickEvent } from "src/app/shared/blocks/select-button/events/select-button-option-click-event";
import {
  Config,
  StudentPassCriteriaApi,
} from "src/app/shared/interfaces/student-pass-criteria-api";
import { PassCriteriaType } from "src/app/shared/enums/pass-criteria-type";
import { PassCriteriaService } from "src/app/shared/services/pass-criteria/pass-criteria.service";
import { Guid } from "src/app/shared/types/guid";
import { ActivatedRoute, Params } from "@angular/router";
import { StatElement } from "src/app/shared/interfaces/student";
import { Store } from "@ngrx/store";
import * as fromCourseStore from "src/app/store/reducers/course.reducer";

@Component({
  selector: "student-rating-system-filter-form",
  templateUrl: "./student-rating-system-filter-form.component.html",
  styleUrls: ["./student-rating-system-filter-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentRatingSystemFilterFormComponent
  implements OnChanges, OnInit
{
  @Input() criteriaType: PassCriteriaType;
  @Input() criteriaConfigs: Config[] = [];
  @Output() saveEvent = new EventEmitter<StudentRatingSystemFilterFormData>();
  @Output() savePartialGradesEvent = new EventEmitter<StudentPassCriteriaApi>();

  readonly recordTrackBy = (
    index: number,
    record: StudentRatingSystemGradeEntity,
  ): number => record.grade;

  readonly form: FormGroup<StudentRatingSystemFilterForm> = this.buildForm();
  readonly records$: Observable<StudentRatingSystemGradeEntity[]> =
    this.ratingSystemService.fetchAll();
  readonly stats$: Observable<StatElement> = this.store.select(
    (state) => state.teacher.singleCourse.stats,
  );

  private readonly destroyRef: DestroyRef = inject(DestroyRef);
  readonly ratingOptions$ = this.handleSetRatingOptions();

  criteriaTypeEnum = PassCriteriaType;
  currentCriteriaType: PassCriteriaType = PassCriteriaType.GRADES;
  gradeTwoDisabled: boolean = false;
  isOn: boolean = true;
  individualGrades: boolean = false;

  CourseId: Guid;
  GroupId: Guid;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly ratingSystemService: StudentRatingSystemFormService,
    private readonly passCriteriaService: PassCriteriaService,
    private readonly route: ActivatedRoute,
    private readonly store: Store<{
      teacher: {
        singleCourse: fromCourseStore.CourseState;
      };
    }>,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { criteriaConfigs } = changes;

    if (criteriaConfigs && criteriaConfigs.currentValue.length >= 1)
      this.isOn = false;
    else this.isOn = true;
  }

  ngOnInit(): void {
    this.listenToHalfGradesVisibilityValueChanges();
    this.listenToRecordsChange();
    this.listenToLastControlValueChanges();
    this.listenToThirdControlValueChanges();
    this.listenToCheckableControlsValueChanges();
    this.getStatsData();

    if (this.criteriaType) this.currentCriteriaType = this.criteriaType;
  }

  private getStatsData() {
    this.route.params.subscribe((params: Params) => {
      this.CourseId = params["CourseId"];
      this.GroupId = params["GroupId"];
    });
  }

  private listenToThirdControlValueChanges(): void {
    this.records$
      .pipe(
        switchMap(() => {
          const [first, second, third] = [
            ...this.form.controls.grades.controls,
          ].reverse();

          if (isNil(first) && isNil(second) && isNil(third)) {
            return EMPTY;
          }

          return of({ first, second, third });
        }),
        switchMap(({ first, second, third }) => {
          return third.valueChanges.pipe(
            tap((value) => {
              first.value.active
                ? second.patchValue({
                    prefix: GradeOperator.GREATER_OR_EQUAL,
                  })
                : second.patchValue({
                    prefix: GradeOperator.LESS_THAN,
                    range: value.range,
                  });
            }),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  handleSaveClick(): void {
    if (this.form.invalid) {
      return;
    }

    this.ratingSystemService.update(this.toFormData());
    this.ratingSystemService.save();
    this.saveEvent.emit(this.toFormData());
  }

  handleDisplayPartialGrades(): void {
    this.passCriteriaService
      .calculatePartialGrades(this.CourseId, this.GroupId)
      .pipe(
        switchMap((response) => {
          if (response)
            return this.passCriteriaService.getCriteriaForCourseGroup(
              this.CourseId,
              this.GroupId,
            );
        }),
        tap((response) => {
          if (response) {
            this.savePartialGradesEvent.emit(response.body);
            this.isOn = false;
          }
        }),
      )
      .subscribe();
  }

  handleEditConfigPartialGrades(): void {
    this.passCriteriaService
      .getCriteriaForCourseGroup(this.CourseId, this.GroupId)
      .subscribe((response) => {
        if (response) {
          this.savePartialGradesEvent.emit(response.body);
        }
      });
  }

  handleSetCriteriaConfigs(data: Config[]): void {
    this.criteriaConfigs = data;
  }

  gradeControl(
    record: StudentRatingSystemGradeEntity,
  ): StudentRatingSystemFilterGradeForm {
    const formArrayControl: FormArray<
      FormGroup<StudentRatingSystemFilterGradeForm>
    > = this.form.controls.grades;
    const found: FormGroup<StudentRatingSystemFilterGradeForm> =
      formArrayControl.controls.find(
        (control) => control.get("id").value === Number(record.label),
      );

    return found.controls;
  }

  private buildForm(): FormGroup<StudentRatingSystemFilterForm> {
    return this.formBuilder.group({
      halfGradesVisible: this.formBuilder.control(true),
      grades: this.formBuilder.array<
        FormGroup<StudentRatingSystemFilterGradeForm>
      >([], [descendingOrderValidator()]),
    });
  }

  private toFormData(): StudentRatingSystemFilterFormData {
    const { grades } = this.form.getRawValue();

    return new StudentRatingSystemFilterFormData(
      grades,
      this.currentCriteriaType,
    );
  }

  private listenToHalfGradesVisibilityValueChanges(): void {
    this.form.controls.halfGradesVisible.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        value
          ? this.ratingSystemService.showHalfGrades()
          : this.ratingSystemService.hideHalfGrades();

        this.ratingSystemService.update(this.toFormData());
      });
  }

  private listenToLastControlValueChanges(): void {
    this.records$
      .pipe(
        switchMap(() => {
          const [first, second] = [
            ...this.form.controls.grades.controls,
          ].reverse();

          if (isNil(first) && isNil(second)) {
            return EMPTY;
          }

          return of({ first, second });
        }),
        switchMap(({ first, second }) => {
          return second.valueChanges.pipe(
            tap((value) => {
              value.active
                ? first.patchValue({
                    prefix: GradeOperator.LESS_THAN,
                    range: value.range,
                  })
                : first.patchValue({
                    prefix: GradeOperator.GREATER_OR_EQUAL,
                  });
            }),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private listenToCheckableControlsValueChanges(): void {
    this.records$
      .pipe(
        take(1),
        switchMap(() => {
          const controls = this.form.controls.grades.controls;

          return merge(
            ...controls.map((control: FormGroup) => {
              return control.valueChanges.pipe(
                map((value) => ({ value, control: control })),
                takeUntilDestroyed(this.destroyRef),
              );
            }),
          );
        }),
      )
      .subscribe(() =>
        setTimeout(() => this.ratingSystemService.update(this.toFormData())),
      );
  }

  private listenToRecordsChange(): void {
    this.records$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((records: StudentRatingSystemGradeEntity[]) => {
        this.form.controls.grades.clear();

        records.forEach((record: StudentRatingSystemGradeEntity, index) => {
          const control: FormGroup<StudentRatingSystemFilterGradeForm> =
            this.createControl(record);
          this.addControl(control);
        });

        // Set show half-grades
        this.form.controls.halfGradesVisible.setValue(
          records.some((record) => !Number.isInteger(record.grade)),
          { emitEvent: false, onlySelf: true },
        );
      });
  }

  private createControl(
    record: StudentRatingSystemGradeEntity,
  ): FormGroup<StudentRatingSystemFilterGradeForm> {
    return this.formBuilder.group({
      id: this.formBuilder.control(record.grade),
      active: this.formBuilder.control(record.isActive),
      range: this.formBuilder.control(record.value, [
        Validators.min(0),
        Validators.max(100),
        Validators.required,
        Validators.pattern("^[0-9]*$"),
      ]),
      prefix: this.formBuilder.control(record.prefix),
    });
  }

  private addControl(
    control: FormGroup<StudentRatingSystemFilterGradeForm>,
  ): void {
    this.form.controls.grades.push(control);
  }

  changeSuffixGrade(
    event: boolean,
    record: StudentRatingSystemGradeEntity,
  ): void {
    const [first, second] = [...this.form.controls.grades.controls].reverse();

    if (record.grade !== 6 && record.grade !== 5.5) {
      if (event && record.grade === 1) {
        second.patchValue({ prefix: GradeOperator.GREATER_OR_EQUAL });
        this.gradeTwoDisabled = false;
      } else {
        second.patchValue({
          prefix: GradeOperator.LESS_THAN,
        });
        this.gradeTwoDisabled = true;
      }
    }
  }

  private handleSetRatingOptions(): Observable<
    SelectButtonItem<PassCriteriaType>[]
  > {
    return of([
      new SelectButtonItem(
        "COURSES.COURSE.RATE_ALL_COURSE",
        PassCriteriaType.GRADES,
      ),
      new SelectButtonItem(
        "COURSES.COURSE.RATE_PARTIAL_COURSE",
        PassCriteriaType.PARTIAL_GRADES,
      ),
    ]);
  }

  handleShowRateOption(
    rate: SelectButtonOptionClickEvent<PassCriteriaType>,
  ): void {
    this.currentCriteriaType = rate.option.value;
    if (rate.option.value) {
      this.individualGrades = true;
    } else {
      this.individualGrades = false;
    }
  }

  toggle(): void {
    this.isOn = !this.isOn;
  }

  getClass(i: number): string {
    if (this.form.controls.halfGradesVisible.value) {
      return `half-marks${i}`;
    } else {
      return `marks${i}`;
    }
  }
}
