import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { Package } from "src/app/shared/interfaces/packages/package";

export const coursePrefix = "[Single Package]";

const packageActions = {
  request: `${coursePrefix} Load`,
  requestActiveSuccess: `${coursePrefix} Load success`,
  requestFail: `${coursePrefix} Load fail`,
};

export const packageLoad = createAction(
  packageActions.request,
  props<{
    organizationId: string;
    packageId: string;
  }>(),
);

export const packageLoadSuccess = createAction(
  packageActions.requestActiveSuccess,
  props<{ package: Package }>(),
);

export const loadPackageFailure = createAction(
  packageActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
