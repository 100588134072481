import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'page-standard-layout',
  templateUrl: './page-standard-layout.component.html',
  styleUrls: ['./page-standard-layout.component.scss']
})
export class PageStandardLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
