import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { Organization } from "../../interfaces/organization";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class OrganizationsService {
  constructor(private apiService: ApiService) {}

  getOrganizations(): Observable<Organization[]> {
    return this.apiService.getRequest<Organization[]>("orgadmin/organizations");
  }
}
