import { Pipe, PipeTransform } from "@angular/core";
import { StudentRatingSystemFormService } from "src/app/modules/teacher/components/student-rating-system-filter-form/services/student-rating-system-form.service";
import { StudentStatElement } from "src/app/shared/interfaces/student";

@Pipe({
  name: "studentExerciseTotalPoints",
})
export class StudentExerciseTotalPointsPipe implements PipeTransform {
  constructor(
    private studentRatingSystemFormService: StudentRatingSystemFormService,
  ) {}

  transform(statistic: StudentStatElement): number {
    return this.studentRatingSystemFormService.calculateTotalPoints(statistic);
  }
}
