<div class="list-picker-container" #pickerContainer>
    <button class="list-picker-label label btn-link" [disabled]="disabled" (click)="toggle()">
        <ng-container *ngIf="customLabel || (!customLabel && !value); else valueContainer">
            <ng-content></ng-content>
        </ng-container>
         <i class="ml-3 uil uil-filter color-hsecondary"></i>
    </button>
    
    <ul class="list-picker-options" *ngIf="opened && !disabled">
        <li *ngFor="let option of options" class="list-picker-option label" (click)="pick(option)">
            <ng-container
                [ngTemplateOutlet]="optionTemplate || defaultOptionTemplate"
                [ngTemplateOutletContext]="{$implicit: option}"
            ></ng-container>
        </li>
    </ul>    
</div>

<ng-template #valueContainer>
    <i *ngIf="icon || valueIcon" class="uil uil-{{icon || valueIcon}}"></i> {{ valueLabel | translate }}
</ng-template>

<ng-template #defaultOptionTemplate let-option>
    <i *ngIf="option.icon" class="uil uil-{{option.icon}}"></i> {{option.label | translate}}
</ng-template>
