import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Exercise } from "src/app/shared/interfaces/exercise";
import { AnswerType } from "src/app/shared/types/answer-type";
import { Guid } from "src/app/shared/types/guid";

@Component({
  selector: "div[exercise-control-buttons]",
  templateUrl: "./exercise-control-buttons.component.html",
  styleUrls: ["./exercise-control-buttons.component.scss"],
})
export class ExerciseControlButtonsComponent {
  @Input() courseId: Guid;
  @Input() activeGroup: Guid;
  @Input() exercise: Exercise;
  @Input("teacher") isTeacher: boolean;
  @Output() onStart = new EventEmitter<MouseEvent>();
  @Output() onStop = new EventEmitter<MouseEvent>();

  AnswerType: typeof AnswerType = AnswerType;

  get canGoToContainer(): boolean {
    return (
      this.exercise.status === "started" &&
      this.exercise.answer_type === AnswerType.ANSWER_TYPE_CONTAINER
    );
  }

  get canStartOrRestart(): boolean {
    return this.exercise.status === "not-started";
  }

  get canCancelExercise(): boolean {
    return this.exercise.status === "starting";
  }

  get canStopExercise(): boolean {
    return this.exercise.status === "started";
  }

  start(event: MouseEvent) {
    this.onStart.emit(event);
  }

  stop(event: MouseEvent) {
    this.onStop.emit(event);
  }
}
