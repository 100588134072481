import videojs, { VideoJsPlayer } from "video.js";
import "videojs-contrib-quality-levels";
import "videojs-hls-quality-selector";

export class VideoPlayer {
  private player: VideoJsPlayer;

  initPlayer(
    element: Element,
    currentLang: string,
    options: videojs.PlayerOptions = {},
  ): videojs.Player {
    this.player = videojs(
      element,
      {
        ...options,
        language: currentLang,
      },
      () => {
        const tech = this.player.tech({}) as videojs.Tech & {vhs: any};

        if (typeof tech !== "undefined") {
          const vhs = tech.vhs;

          this.player.on(this.player, "playerresize", () => {
            vhs.masterPlaylistController_.fastQualityChange_();
          });

          (this.player as any).hlsQualitySelector({
            displayCurrentQuality: true,
          });
        }

        this.player.on(this.player, "loadedmetadata", () => {
          // Find captions which match current lang and turn them on
          const tracks = Array.from(this.player.textTracks());
          const langTrack: TextTrack | undefined = tracks.find(
            (track) => track.language === currentLang,
          );

          if (langTrack) {
            langTrack.mode = "showing";
          }
        });
      },
    );

    return this.player;
  }

  disposePlayer(): void {
    this.player?.dispose();
  }

  get playerInstance(): videojs.Player | undefined {
    return this.player;
  }
}
