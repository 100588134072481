<svg
    class="progress-ring-background"
    [class.progress-ring-background--shadow]="shadowEnabled"
    [attr.height]="diameter"
    [attr.width]="diameter">
  <circle
  *ngIf="showBackground"
  class="progress-ring-background-circle"
  [ngClass]="'theme-'+backgroundColor"
  [attr.stroke-width]="thickness"
  fill="transparent"
  [attr.r]="radius"
  [attr.cx]="diameter / 2"
  [attr.cy]="diameter / 2"
/>
</svg>

<span class="progress-text paragraph">
  <ng-content></ng-content>
</span>

<svg
    class="progress-ring"
    [attr.height]="diameter"
    [attr.width]="diameter">
  <circle
    class="progress-ring-circle"
    [ngClass]="'theme-'+theme"
    [attr.stroke-width]="thickness"
    fill="transparent"
    [attr.r]="radius"
    [attr.cx]="diameter / 2"
    [attr.cy]="diameter / 2"
  />
</svg>
