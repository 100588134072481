import { Component, Input } from "@angular/core";

@Component({
  selector: "badge",
  templateUrl: "./badge.component.html",
  styleUrls: ["./badge.component.scss"],
})
export class BadgeComponent {
  @Input() icon = "";
  @Input() theme = "hlogo";
  @Input() set = "typcn";
  @Input() reversed = false;
  @Input() small = false;
  @Input() hover = false;
}
