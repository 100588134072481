<ng-container
  *ngIf="{
    list: codeCheckHistoryList$ | async,
    listCompleted: getCodeCheckHistoryListCompleted$ | async,
    item: codeCheckHistoryItem$ | async,
    itemCompleted: getCodeCheckHistoryItemCompleted$ | async
  } as checkHistory"
>
  <history-modal-frame
    [list]="checkHistory.list"
    [item]="checkHistory.item"
    [listCompleted]="checkHistory.listCompleted"
    [itemCompleted]="checkHistory.itemCompleted"
    [mode]="listMode.mode"
    noSelectedText="COURSES.COURSE.EXERCISE.CODE_CHECK_HISTORY.SELECT_FROM_LIST"
    noSubmissionsText="COURSES.COURSE.EXERCISE.CODE_CHECK_HISTORY.NO_SUBMISSIONS"
  >
    <history-modal-header
      title="COURSES.COURSE.EXERCISE.CODE_CHECK_HISTORY.HISTORY_MODAL_TITLE"
      [suffix]="titleSuffix"
    >
    </history-modal-header>

    <item-list-mode-selector
      [(mode)]="listMode.mode"
      [showBackButton]="data.isTeacher"
      left-column
    >
      <history-modal-students-list
        [students]="data.students"
        mySubmissionsText="COURSES.COURSE.EXERCISE.CODE_CHECK_HISTORY.MY_SUBMISSIONS"
        (studentClicked)="studentClicked($event)"
        student-list
      ></history-modal-students-list>

      <history-modal-item-list
        [list]="checkHistory.list"
        [activeItemId]="
          checkHistory.item ? checkHistory.item.code_check.id : null
        "
        [statusClassFn]="getStatusClass"
        [statusIconClassFn]="getStatusIconClass"
        [itemText]="itemText"
        [itemDate]="itemDate"
        loadMoreText="COURSES.COURSE.EXERCISE.CODE_CHECK_HISTORY.LOAD_MORE_BUTTON"
        (itemClicked)="getHistoryItem($event)"
        (nextPage)="nextPage($event)"
        item-list
      >
        <ng-template #itemText let-item>
          {{ item.status | codeCheckStatus | translate }}
          <code-check-history-modal-check-type
            [type]="item.check_type"
          ></code-check-history-modal-check-type>
        </ng-template>

        <ng-template #itemDate let-item>
          {{ item.created_at | date }}
        </ng-template>
      </history-modal-item-list>
    </item-list-mode-selector>

    <code-check-history-modal-item
      [item]="checkHistory.item"
      [isTeacher]="data.isTeacher"
      content
    >
    </code-check-history-modal-item>

    <button class="theme-hprimary" (click)="close.emit('close')" footer>
      {{ "GLOBAL.CLOSE_BUTTON" | translate }}
    </button>
  </history-modal-frame>
</ng-container>
