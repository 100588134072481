import * as fromTutorials from "../actions/tutorial.actions";

export interface TutorialState {
  id: string;
  tutorial_step: number;
  tutorial_skip: boolean;
}

export const initialTutorialState: TutorialState = {
  id: "",
  tutorial_step: 0,
  tutorial_skip: false
};

export function reducer(
  state = initialTutorialState,
  action: fromTutorials.TutorialActions
): TutorialState {
  switch (action.type) {
    case fromTutorials.GET_TUTORIALS_SUCCESS: {
      return {
        ...state,
        tutorial_step: action.payload.tutorial_step,
        tutorial_skip: action.payload.tutorial_skip,
        id: action.payload.id
      };
    }
    case fromTutorials.SET_TUTORIAL_STEP: {
      return {
        ...state,
        tutorial_step: action.payload.tutorial_step,
        tutorial_skip: action.payload.tutorial_skip,
        id: action.payload.id
      };
    }
    case fromTutorials.SKIP_TUTORIAL: {
      return {
        ...state,
        tutorial_step: action.payload.tutorial_step,
        tutorial_skip: true,
        id: action.payload.id
      };
    }
    default:
      return state;
  }
}
