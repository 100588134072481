import { createReducer, on } from "@ngrx/store";
import {
  createTeamTeachers,
  createTeamTeachersFail,
  createTeamTeachersLoad,
} from "../../actions/admin/create-team-teachers.actions";
import { CreateTeamTeachersResponse } from "src/app/shared/interfaces/orgadmin/course-teachers";

export interface CreateTeamTeachersState {
  createTeamTeachersFailed: boolean;
  error: CreateTeamTeachersResponse | null;
}

const initialState: CreateTeamTeachersState = {
  createTeamTeachersFailed: false,
  error: null,
};

export const createTeamTeachersReducer = createReducer(
  initialState,
  on(
    createTeamTeachersLoad,
    (state): CreateTeamTeachersState => ({
      ...state,
      createTeamTeachersFailed: false,
      error: null,
    }),
  ),
  on(
    createTeamTeachers,
    (state): CreateTeamTeachersState => ({
      ...state,
      createTeamTeachersFailed: false,
      error: null,
    }),
  ),
  on(
    createTeamTeachersFail,
    (state, { error }): CreateTeamTeachersState => ({
      ...state,
      createTeamTeachersFailed: true,
      error,
    }),
  ),
);
