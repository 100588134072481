import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import {
  packageLoad,
  packageLoadSuccess,
  loadPackageFailure,
} from "../../actions/packages/single-package.actions";
import { Package } from "src/app/shared/interfaces/packages/package";

export interface PackageState {
  package: Package | null;
  error: HttpErrorResponse | null;
}

const initialState: PackageState = {
  package: null,
  error: null,
};

export const packageReducer = createReducer(
  initialState,
  on(
    packageLoad,
    (state): PackageState => ({
      ...state,
      package: null,
      error: null,
    }),
  ),
  on(
    packageLoadSuccess,
    (state, action): PackageState => ({
      ...state,
      package: action.package,
      error: null,
    }),
  ),
  on(loadPackageFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);
