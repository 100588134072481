<div class="list-progress">
  <span
    class="progress-percentage"
    [style.--percentage]="
      (data.completed / data.all) *
        (data.completed / data.all > 0.1 ? 90 : 100) +
      '%'
    "
  >
    {{ (((data.completed / data.all) * 100 | number: "1.0-0") || "0") + "%" }}
  </span>
  <span class="progress-base">
    <span
      class="progress-completed"
      [ngClass]="{
        'progress-completed--certificate':
          data.certificate_percent &&
          (data.completed / data.all) * 100 >= data.certificate_percent
      }"
      [ngStyle]="calcPercentage() || '0%'"
    ></span>
    <span
      class="progress-threshold"
      [tooltip]="
        ('COURSES.COURSE.CERT_THRESHOLD' | translate) +
        data.certificate_percent +
        '%'
      "
      [style.width]="(data.certificate_percent || '0') + '%'"
    ></span>
  </span>
</div>
