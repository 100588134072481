import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import {
  CourseAdmin,
  PagionationData,
} from "src/app/shared/interfaces/orgadmin/course-organization";

export const coursePrefix = "[Courses Inactive Admin List]";

const coursesInactiveAdminListActions = {
  request: `${coursePrefix} Load`,
  requestInactiveSuccess: `${coursePrefix} Load inactive success`,
  requestFail: `${coursePrefix} Load fail`,
};

export const coursesAdminInactiveListLoad = createAction(
  coursesInactiveAdminListActions.request,
  props<{
    id: string;
    page?: number;
    size?: number;
    search?: string;
    active: boolean;
  }>(),
);

export const coursesAdminInactiveListLoadSuccess = createAction(
  coursesInactiveAdminListActions.requestInactiveSuccess,
  props<{ coursesInactiveList: PagionationData<CourseAdmin> }>(),
);

export const loadInactiveTeachersFailure = createAction(
  coursesInactiveAdminListActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
