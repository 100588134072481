import { NgTemplateOutlet } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
  selector: "validation-message",
  templateUrl: "./validation-message.component.html",
  styleUrls: ["./validation-message.component.scss"],
})
export class ValidationMessageComponent {
  @Input() theme = "";
  @Input() customTemplate: NgTemplateOutlet;
  constructor() {}
}
