<ng-template [ngIf]="confirmable" [ngIfElse]="nonConfirmableButtonRef">
  <button
    [hover]
    [confirmation]="confirmationMessage"
    [confirmation-title]="confirmationTitle"
    [confirm-button-text]="confirmButtonText"
    [cancel-button-text]="cancelButtonText"
    [confirm-button-color]="confirmButtonColor"
    [confirmation-icon]="icon"
    (onConfirm)="handleExitFromExerciseClick()"
  >
    <ng-container [ngTemplateOutlet]="buttonContentRef" />
  </button>
</ng-template>

<ng-template #nonConfirmableButtonRef>
  <button [hover] (click)="handleExitFromExerciseClick()">
    <ng-container [ngTemplateOutlet]="buttonContentRef" />
  </button>
</ng-template>

<ng-template #buttonContentRef>
  <span class="exit-button-icon">
    <navbar-icon set="uil" icon="sign-out-alt" class="no-outline" />
  </span>

  <ng-template [ngIf]="tooltipMessage">
    <span
      class="navbar-stop-exercise-button-tooltip"
      position="bottom"
      [tooltip]="tooltipMessage | translate"
    >
    </span>
  </ng-template>
</ng-template>
