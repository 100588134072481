import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'course-exercise-competences',
  templateUrl: './course-exercise-competences.component.html',
  styleUrls: ['./course-exercise-competences.component.scss']
})
export class CourseExerciseCompetencesComponent implements OnInit {
  @Input()
  competences = [];

  constructor() {}

  ngOnInit() {}
}
