export const ERROR_ICONS: { [key: number]: string } = {
  0: "cancel-outline",
  200: "tick-outline",
  400: "warning-outline",
  403: "cancel-outline",
  404: "warning-outline",
  409: "warning-outline",
  500: "warning-outline",
  502: "warning-outline",
  503: "warning-outline",
  504: "warning-outline",
};

export const ERROR_MESSAGESS: { [key: number]: string } = {
  503: "503",
};

export const SKIP_MODAL_BLACKLIST: Array<{
  name: string;
  errorCodes: Array<number>;
}> = [
  { name: "courses/.+/exercises/.+/code_config/$", errorCodes: [404] },
  {
    name: "courses/.+/groups/.+/exercises/.+/code_config/$",
    errorCodes: [404],
  },
  { name: "courses/.+/exercises/.+/code_check/$", errorCodes: [404] },
  { name: "courses/.+/groups/.+/exercises/.+/code_check/$", errorCodes: [404] },
  { name: "courses/.+/exercises/.+/code_check/.+/$", errorCodes: [404] },
  {
    name: "courses/.+/groups/.+/exercises/.+/code_check/.+/$",
    errorCodes: [404],
  },
  { name: "coderedeem/activate/$", errorCodes: [403] },
];

export const ENDPOINT_INJECT_BLACKLIST: Array<string> = [
  "token/$",
  "register/.+/$",
  "password/forgot/$",
  "password/reset/.+/$",
  "me/email/.+/$",
  "amazonaws\\.com",
];

export const ENDPOINT_PARTIAL_INJECT_BLACKLIST: Array<string> = [
  "announcements/$",
];
