import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { SetTutorialStep } from "src/app/store/actions/tutorial.actions";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import * as fromDashboardStore from "src/app/store/reducers/dashboard.reducer";
import { Course } from "../../interfaces/course";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "cs-footer",
  template: `
    <footer
      class="footer d-flex flex-column justify-content-center"
      *ngIf="year"
    >
      <ul class="paragraph-small footer-nav d-flex flex-wrap">
        <li>
          <a
            href="{{
              'GLOBAL.ENVIRONMENT.$langKey.TERMS' | environmentTranslate
            }}"
            target="_blank"
          >
            {{ "GLOBAL.FOOTER.TERMS" | translate }}
          </a>
        </li>
        <li>
          <a
            href="{{
              'GLOBAL.ENVIRONMENT.$langKey.PRIVACY_POLICY'
                | environmentTranslate
            }}"
            target="_blank"
          >
            {{
              "GLOBAL.ENVIRONMENT.$langKey.PRIVACY_POLICY_TEXT"
                | environmentTranslate
            }}
          </a>
        </li>
        <ng-container
          *ngIf="{
            id: userId$ | async,
            isTeacher: isTeacher$ | async
          } as footer"
        >
          <ng-container *ngIf="footer.isTeacher; else studentOnboarding">
            <ng-container *ngIf="courses$ | async as courses">
              <li *ngIf="courses.length >= 1 && !logout">
                <a (click)="handleOpenOnboarding(footer.id, footer.isTeacher)">
                  {{ "GLOBAL.FOOTER.ONBOARDING" | translate }}
                </a>
              </li>
            </ng-container>
          </ng-container>

          <ng-template #studentOnboarding>
            <li *ngIf="!logout">
              <a (click)="handleOpenOnboarding(footer.id, footer.isTeacher)">
                {{ "GLOBAL.FOOTER.ONBOARDING" | translate }}
              </a>
            </li>
          </ng-template>
        </ng-container>
      </ul>
      <p class="paragraph-small">
        {{ "GLOBAL.FOOTER.TITLE" | translate: { year: year } }}
      </p>
    </footer>
  `,
  styleUrls: ["./footer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit {
  year = 0;
  logout: boolean = false;

  userId$: Observable<string> = this.store.select(
    (state) => state?.account?.member?.id,
  );

  isTeacher$: Observable<boolean> = this.store.select(
    (state) => state?.account?.member?.is_teacher,
  );

  courses$: Observable<Array<Course>> = this.store.select(
    (state) => state?.teacher?.dashboard?.courses,
  );

  constructor(
    private router: Router,
    private destroyRef: DestroyRef,
    private store: Store<{
      account: fromAccountStore.AccountState;
      teacher: {
        dashboard: fromDashboardStore.DashboardState;
      };
    }>,
  ) {}

  ngOnInit() {
    this.year = new Date().getFullYear();

    this.checkIfAccountPage();
    this.router.events
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.checkIfAccountPage();
      });
  }

  checkIfAccountPage(): void {
    this.logout = this.router.url.includes("/account");
  }

  handleOpenOnboarding(id: string, teacher: boolean): void {
    if (teacher) {
      this.router.navigate(["/teacher/dashboard"]);
    } else {
      this.router.navigate(["/student/dashboard"]);
    }

    this.store.dispatch(
      new SetTutorialStep({
        id,
        tutorial_skip: false,
        tutorial_step: 0,
      }),
    );
  }
}
