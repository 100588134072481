import * as humanizeDuration from 'humanize-duration';

export const shortHumanizer = humanizeDuration.humanizer({
  languages: {
    plShort: {
      y: () => 'l',
      mo: () => 'mies',
      w: () => 't',
      d: () => 'd',
      h: () => 'g',
      m: () => 'm',
      s: () => 's',
      ms: () => 'ms'
    },
    enShort: {
      y: () => 'y',
      mo: () => 'mo',
      w: () => 'w',
      d: () => 'd',
      h: () => 'h',
      m: () => 'min',
      s: () => 's',
      ms: () => 'ms'
    }
  }
});
