import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2,
} from "@angular/core";

@Directive({
  selector: "[hover]",
})
export class HoverDirective {
  @Input() hover: boolean = true;
  @HostListener("mouseenter")
  enter(): void {
    if (this.hover) this.renderer.addClass(this.nativeElement, "hover");
  }

  @HostListener("mouseleave")
  leave(): void {
    if (this.hover) this.renderer.removeClass(this.nativeElement, "hover");
  }

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2,
  ) {}

  get nativeElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }
}
