<navbar [account]="account$ | async"></navbar>
<exercise-timer></exercise-timer>
<div class="hcontainer hcontainer-wide">
  <div class="row no-gutters">
    <div class="col">
      <div class="panel-page-announcements">
        <announcements></announcements>
      </div>
      <router-outlet> </router-outlet>
      <ng-content></ng-content>
    </div>
  </div>
</div>