import { Component, Input, Type } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import * as fromCourseStore from "src/app/store/reducers/course.reducer";
import { Exercise } from "../../../interfaces/exercise";
import { Student } from "../../../interfaces/student";
import { ModalService } from "../../../services/modal/modal.service";
import { Guid } from "../../../types/guid";
import { ModalComponent } from "../../modal/modal.component";
import {
  CodeCheckHistoryModalComponent
} from "../../modals/code-check-history-modal/code-check-history-modal.component";

@Component({
  selector: "code-check-history-button",
  templateUrl: "./code-check-history-button.component.html",
  styleUrls: [
    "../exercise-control-buttons/exercise-control-buttons.component.scss",
  ],
})
export class CodeCheckHistoryButtonComponent {
  students$: Observable<Array<Student>> = this.store.select(
    (state) =>
      state[this.isTeacher ? "teacher" : "student"].singleCourse.topicStats,
  );

  activeGroup$: Observable<Guid> = this.store.select(
    (state) =>
      state[this.isTeacher ? "teacher" : "student"].singleCourse.activeGroup,
  );

  checkHistoryModal: Type<ModalComponent> = CodeCheckHistoryModalComponent;

  @Input() courseId: Guid;
  @Input("teacher") isTeacher = false;
  @Input() exercise: Exercise | Partial<Exercise>;

  constructor(
    private store: Store<{
      teacher: {
        singleCourse: fromCourseStore.CourseState;
      };
    }>,
    private modalService: ModalService,
  ) {}

  showHistory(
    courseId: Guid,
    exercise: Exercise,
    isTeacher: boolean,
    activeGroup: Guid | null,
    students: Array<Student> | null,
    multiMode: boolean,
  ): void {
    this.modalService.showModal(
      { courseId, exercise, isTeacher, activeGroup, students, multiMode },
      this.checkHistoryModal,
      "CODE_CHECK_HISTORY_MODAL",
    );
  }
}
