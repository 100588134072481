<div class="hour-picker row no-gutters">
    <div class="col-6">
        <select [disabled]="disabled" [(ngModel)]="currentHours" (change)="updateHour()">
            <option *ngFor="let hour of hours; let i = index" value="{{(i >= 10 ? i : '0' + i) + ''}}">{{i}}</option>
        </select>
    </div>

    <div class="col-6">
        <select [disabled]="disabled" [(ngModel)]="currentMinutes" (change)="updateHour()">
            <option *ngFor="let minute of minutes; let i = index"
                value="{{i*minutesStep >= 10 ? i*minutesStep + '' : '0' + i*minutesStep}}">
                {{i*minutesStep >= 10 ? (i*minutesStep) + '' : '0' + i*minutesStep}}
            </option>
        </select>
    </div>

    <div class="col-12">
        <input [disabled]="disabled" type="text" [value]="hour" (keyup)="writeHour($event)">
    </div>
</div>