import { Component, OnInit, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "hour-picker",
  templateUrl: "./hour-picker.component.html",
  styleUrls: ["./hour-picker.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HourPickerComponent),
      multi: true,
    },
  ],
})
export class HourPickerComponent implements OnInit, ControlValueAccessor {
  @Input() minutesStep = 15;

  hours: Array<boolean>;
  minutes: Array<boolean>;
  hour = null;

  currentHours = null;
  currentMinutes = null;

  disabled = false;

  onChange = (hour: string) => {};
  onTouched = () => {};

  constructor() {}

  ngOnInit() {
    this.hours = new Array(24).fill(true);
    this.minutes = new Array(60 / this.minutesStep).fill(true);
  }

  writeValue(hour?: string): void {
    if (hour) {
      this.hour = hour;

      const splitted = hour.split(":");
      this.currentHours = splitted[0];
      this.currentMinutes = splitted[1];
    } else {
      this.currentHours = null;
      this.currentMinutes = null;
      this.hour = null;
    }

    this.onChange(this.hour);
    this.onTouched();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  updateHour() {
    if (!this.currentMinutes) {
      this.currentMinutes = "00";
    }

    if (!this.currentHours) {
      this.currentHours = "0";
    }

    this.writeValue(`${this.currentHours}:${this.currentMinutes}`);
  }

  writeHour(event) {
    this.writeValue(event.target.value);
  }
}
