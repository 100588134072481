import { Pipe, PipeTransform } from "@angular/core";
import { ExerciseStatus } from "../enums/exercise-status";

@Pipe({
  name: "exerciseStatusCssClass",
})
export class ExerciseStatusCssClassPipe implements PipeTransform {
  private readonly entries = new Map<ExerciseStatus, string>([
    [ExerciseStatus.NEW, "new"],
    [ExerciseStatus.COMPLETED, "completed"],
    [ExerciseStatus.STARTED, "started"],
    [ExerciseStatus.UNCOMPLETED, "uncompleted"],
    [ExerciseStatus.INDIVIDUAL, "individual"]
  ]);

  transform(status: ExerciseStatus): string | undefined {
    return this.entries.get(status);
  }
}
