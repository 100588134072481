import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ItemListMode } from "src/app/shared/enums/item-list-mode";

@Component({
  selector: "item-list-mode-selector",
  templateUrl: "./item-list-mode-selector.component.html",
  styleUrls: ["./item-list-mode-selector.component.scss"],
})
export class ItemListModeSelectorComponent {
  @Input() mode: ItemListMode;
  @Input() showBackButton: boolean;
  @Output() modeChange: EventEmitter<ItemListMode> = new EventEmitter();

  ItemListMode: typeof ItemListMode = ItemListMode;

  constructor() {}

  backToStudents(): void {
    this.modeChange.emit(ItemListMode.STUDENTS);
  }
}
