import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { AdminService } from "src/app/shared/services/admin/admin.service";
import {
  invitedStudentsListLoad,
  invitedStudentsListLoadFail,
  invitedStudentsListLoadSuccess,
} from "../../actions/admin/invited-students.actions";
import {
  deleteInvitationStudentLoad,
  deleteInvitationStudentLoadSuccess,
  deleteInvitationStudentLoadFail,
} from "../../actions/admin/delete-invitation-student.action";
import { InvitedStudentsPerTeacher } from "src/app/shared/interfaces/orgadmin/invited-students";

@Injectable()
export class InvitedStudentsEffect {
  getInivitedStudentsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(invitedStudentsListLoad),
      switchMap((param) => {
        return this.adminService
          .getInvitedStudents(param.organizationId, param.courseId)
          .pipe(
            map((res: InvitedStudentsPerTeacher[]) =>
              invitedStudentsListLoadSuccess({ invitedStudents: res }),
            ),
            catchError(({ error }) => of(invitedStudentsListLoadFail(error))),
          );
      }),
    ),
  );

  deleteInvitationStudent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteInvitationStudentLoad),
      switchMap((param) => {
        return this.adminService
          .deleteInvitationStudent(
            param.organizationId,
            param.courseId,
            param.invitationId,
          )
          .pipe(
            map(() => deleteInvitationStudentLoadSuccess()),
            catchError(({ error }) =>
              of(deleteInvitationStudentLoadFail(error)),
            ),
          );
      }),
    ),
  );

  constructor(
    private adminService: AdminService,
    private actions$: Actions,
  ) {}
}
