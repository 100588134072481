<div class="ring-container">
  <svg
    class="ring-svg"
    [attr.width]="PROGRESS_SIZE"
    [attr.height]="PROGRESS_SIZE"
    [attr.viewBox]="'0 0 ' + PROGRESS_SIZE + ' ' + PROGRESS_SIZE"
  >
    <defs>
      <radialGradient id="inset-gradient" cx="50%" cy="50%" r="50%">
        <stop offset="85%" stop-color="#bbb" />
        <stop offset="95%" stop-color="#ddd" />
        <stop offset="100%" stop-color="#eee" />
      </radialGradient>

      <radialGradient id="cert-threshold" cx="50%" cy="50%" r="50%">
        <stop offset="85%" stop-color="#aeb7c5" />
        <stop offset="95%" stop-color="#bac1cd" />
        <stop offset="100%" stop-color="#D0D5DD" />
      </radialGradient>
    </defs>

    <circle
      class="ring-bg"
      [attr.cx]="PROGRESS_SIZE / 2"
      [attr.cy]="PROGRESS_SIZE / 2"
      [attr.r]="outerRadius"
      [attr.stroke-width]="RING_WIDTH"
    ></circle>

    <circle
      *ngIf="progress.certificate_percent"
      class="ring-progress progress-threshold"
      [attr.cx]="PROGRESS_SIZE / 2"
      [attr.cy]="PROGRESS_SIZE / 2"
      [attr.r]="outerRadius"
      [attr.stroke-width]="RING_WIDTH"
      [attr.stroke-dasharray]="
        getDashArray(progress.certificate_percent, outerRadius)
      "
      [tooltip]="
        ('COURSES.COURSE.CERT_THRESHOLD' | translate) +
        progress.certificate_percent +
        '%'
      "
      position="custom"
    ></circle>

    <circle
      class="ring-progress ring-progress--animation progress-resolved-exercises"
      [attr.cx]="PROGRESS_SIZE / 2"
      [attr.cy]="PROGRESS_SIZE / 2"
      [attr.r]="outerRadius"
      [attr.stroke-width]="RING_WIDTH"
      [style.--min]="'0,' + getCircumference(outerRadius)"
      [style.--max]="
        getDashArray(
          (progress.points / progress.max_points) * 100,
          outerRadius,
          progress.max_available_points
        )
      "
    ></circle>

    <circle
      class="ring-bg"
      [attr.cx]="PROGRESS_SIZE / 2"
      [attr.cy]="PROGRESS_SIZE / 2"
      [attr.r]="innerRadius"
      [attr.stroke-width]="RING_WIDTH"
    ></circle>

    <circle
      class="ring-progress ring-progress--animation progress-shared-resolved-exercises"
      [attr.cx]="PROGRESS_SIZE / 2"
      [attr.cy]="PROGRESS_SIZE / 2"
      [attr.r]="innerRadius"
      [attr.stroke-width]="RING_WIDTH"
      [style.--min]="'0,' + getCircumference(innerRadius)"
      [style.--max]="
        getDashArray(
          (progress.points /
            (progress.max_available_points >= progress.points
              ? progress.max_available_points
              : progress.points)) *
            100,
          innerRadius,
          progress.max_available_points
        )
      "
      position="custom"
      [tooltip]="
        'COURSES.COURSE.SHARED_EXERCISES'
          | translate
            : {
                exercises: progress.available_exercises,
                all_exercises: progress.exercise_count,
                total_points: progress.max_available_points
              }
      "
    ></circle>
  </svg>

  <div class="progress-value">
    {{ progress.points }}{{ "GLOBAL.POINTS" | translate }}
  </div>
</div>
