import { Action } from "@ngrx/store";
import { Course } from "src/app/shared/interfaces/course";
import { UserSummary } from 'src/app/shared/interfaces/user-summary';
import { Guid } from "src/app/shared/types/guid";

export const GET_DASHBOARD = "[Dashboard Method] GetDashboard";
export const GET_DASHBOARD_COMPLETED =
  "[Dashboard Method] GetDashboardCompleted";
export const ACCEPT_INVITATION = "[Dashboard Method] AcceptInvitation";
export const REJECT_INVITATION = "[Dashboard Method] RejectInvitation";

export const GET_RANKING_SUMMARY = "[Dashboard Method] GetRankingSummary";
export const GET_RANKING_SUMMARY_COMPLETED = "[Dashboard Method] GetRankingSummaryCompleted";
export const GET_RANKING_SUMMARY_FAILED = "[Dashboard Method] GetRankingSummaryFailed";

export class GetDashboard implements Action {
  readonly type = GET_DASHBOARD;
}

export class GetDashboardCompleted implements Action {
  readonly type = GET_DASHBOARD_COMPLETED;
  constructor(public payload: { courses: Array<Course> }) {}
}

export class AcceptInvitation implements Action {
  readonly type = ACCEPT_INVITATION;
  constructor(public payload: { invitationId: Guid }) {}
}

export class RejectInvitation implements Action {
  readonly type = REJECT_INVITATION;
  constructor(public payload: { invitationId: Guid }) {}
}

export class GetRankingSummary implements Action {
  readonly type = GET_RANKING_SUMMARY;
}

export class GetRankingSummaryCompleted implements Action {
  readonly type = GET_RANKING_SUMMARY_COMPLETED;
  constructor(public payload: UserSummary) {}
}

export class GetRankingSummaryFailed implements Action {
  readonly type = GET_RANKING_SUMMARY_FAILED;
}

export type DashboardActions =
  | GetDashboard
  | GetDashboardCompleted
  | AcceptInvitation
  | RejectInvitation
  | GetRankingSummary
  | GetRankingSummaryCompleted
  | GetRankingSummaryFailed;
