import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { CodeCheckStatus } from "src/app/shared/enums/code-check-status";
import { ItemListMode } from "src/app/shared/enums/item-list-mode";
import { CodeCheckData } from "src/app/shared/interfaces/code-check-data";
import { CodeCheckListResults } from "src/app/shared/interfaces/code-check-list-results";
import { CodeVerification } from "src/app/shared/interfaces/code-verification";
import { Student } from "src/app/shared/interfaces/student";
import { HistoryListMode } from "src/app/shared/models/history-list-mode";
import * as fromCodeCheckHistory from "src/app/store/actions/code-check-history.actions";
import * as courseSelectors from "src/app/store/selectors/course.selectors";
import { ModalComponent } from "../../modal/modal.component";

@Component({
  selector: "code-check-history-modal",
  templateUrl: "./code-check-history-modal.component.html",
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeCheckHistoryModalComponent
  extends ModalComponent
  implements OnInit
{
  codeCheckHistoryList$: Observable<CodeCheckListResults> = this.store
    .select(courseSelectors.selectCourseState)
    .pipe(map((state) => state.codeCheckHistoryList));
  getCodeCheckHistoryListCompleted$: Observable<boolean> = this.store
    .select(courseSelectors.selectCourseState)
    .pipe(map((state) => state.getCodeCheckHistoryListCompleted));
  codeCheckHistoryItem$: Observable<CodeVerification> = this.store
    .select(courseSelectors.selectCourseState)
    .pipe(
      map((state) => state.codeCheckHistoryItem),
      distinctUntilChanged(
        (a, b) => a && b && a.code_check.id === b.code_check.id
      )
    );
  getCodeCheckHistoryItemCompleted$: Observable<boolean> = this.store
    .select(courseSelectors.selectCourseState)
    .pipe(map((state) => state.getCodeCheckHistoryItemCompleted));

  listMode: HistoryListMode;

  constructor(private store: Store<{}>) {
    super();
  }

  ngOnInit() {
    this.listMode = new HistoryListMode(
      this.data.multiMode ? ItemListMode.STUDENTS : ItemListMode.ITEM_LIST,
      this.data.isTeacher
    );

    if (this.listMode.mode === ItemListMode.ITEM_LIST) {
      this.listMode.student = null;
      this.listMode.group = this.data.activeGroup;
      this.getStudentItems();
    }
  }

  getHistoryItem(item: CodeCheckData): void {
    this.store.dispatch(
      new fromCodeCheckHistory.GetCodeCheck({
        id: item.id,
        CourseId: this.data.courseId,
        ExerciseId: this.data.exercise.id,
        GroupId: this.listMode.group,
        UserId: this.listMode.student ? this.listMode.student.id : undefined,
      })
    );
  }

  nextPage(Page: number) {
    this.store.dispatch(
      new fromCodeCheckHistory.GetCodeCheckListPage({
        CourseId: this.data.courseId,
        ExerciseId: this.data.exercise.id,
        GroupId: this.listMode.group,
        UserId: this.listMode.student ? this.listMode.student.id : undefined,
        Page,
      })
    );
  }

  getStatusClass(item: CodeCheckData): string {
    switch (item.status) {
      case CodeCheckStatus.CHECK_STATUS_PROCESSING:
      case CodeCheckStatus.CHECK_STATUS_PREPARING:
        return "processing";
      case CodeCheckStatus.CHECK_STATUS_ACCEPTED:
      case CodeCheckStatus.CHECK_STATUS_FINISHED:
        return "accepted";
      case CodeCheckStatus.CHECK_STATUS_REJECTED:
      case CodeCheckStatus.CHECK_STATUS_ERROR:
      case CodeCheckStatus.CHECK_STATUS_CANCELED:
        return "rejected";

      default:
        return "";
    }
  }

  getStatusIconClass(item: CodeCheckData): string {
    switch (item.status) {
      case CodeCheckStatus.CHECK_STATUS_PROCESSING:
      case CodeCheckStatus.CHECK_STATUS_PREPARING:
        return "typcn-arrow-sync";
      case CodeCheckStatus.CHECK_STATUS_ACCEPTED:
      case CodeCheckStatus.CHECK_STATUS_FINISHED:
        return "typcn-tick";
      case CodeCheckStatus.CHECK_STATUS_REJECTED:
      case CodeCheckStatus.CHECK_STATUS_ERROR:
        return "typcn-times";
      case CodeCheckStatus.CHECK_STATUS_CANCELED:
        return "typcn-cancel-outline";

      default:
        return "";
    }
  }

  getStudentItems(): void {
    this.store.dispatch(
      new fromCodeCheckHistory.GetCodeCheckList({
        CourseId: this.data.courseId,
        ExerciseId: this.data.exercise.id,
        GroupId: this.listMode.group,
        UserId: this.listMode.student ? this.listMode.student.id : undefined,
      })
    );
  }

  studentClicked(student: Student | null): void {
    this.listMode.student = student;
    this.listMode.group = this.data.activeGroup;
    this.getStudentItems();
    this.listMode.mode = ItemListMode.ITEM_LIST;
  }

  get titleSuffix(): string {
    return `${this.data.exercise.name}${
      this.data.student ? `- ${<Student>this.data.student.name}` : ``
    }`;
  }
}
