<div class="d-flex fluid-table">
    <div class="left-column">
        <ng-content select="[left-column]"></ng-content>
    </div>

    <div class="fluid-center">
        <ng-content></ng-content>
    </div>

    <div class="right-column">
        <ng-content select="[right-column]"></ng-content>
    </div>
</div>