export type WebSocketMessageAccessConfigType =
  | "teacher"
  | "student"
  | "both"
  | "none";
const asAccessConfigType = <T>(accessConfig: {
  [K in keyof T]: WebSocketMessageAccessConfigType;
}) => accessConfig;

export const WebSocketMessageAccessConfig = asAccessConfigType({
  NOTIFICATION_RECEIVED: "both",
  EXERCISE_ADDED: "both",
  EXERCISE_DELETED: "both",
  EXERCISE_HELP_TURNED_ON: "both",
  EXERCISE_HELP_TURNED_OFF: "both",
  EXERCISE_STARTED: "none",
  EXERCISE_STATUS_UPDATE: "none",
  EXERCISE_START_FAILURE: "none",
  EXERCISE_SOLVED: "teacher",
  STUDENT_SUSPENDED: "both",
  VPN_UPDATED: "both",
  USER_VPN_UPDATED: "teacher",
  CODE_CHECK_RESULT: "none",
  CODE_CHECK_RUN_RESULT: "none",
  RANKING_REQUEST_FINISHED: "none",
  EXERCISE_QUIZ_SOLUTIONS_TURNED_ON: "both",
  EXERCISE_QUIZ_SOLUTIONS_TURNED_OFF: "both",
  EXERCISE_REVIEWED: "student",
  STUDENT_HELP_REQUEST_CREATED: "teacher",
  STUDENT_HELP_REQUEST_CANCELED: "teacher",
});

export type WebSocketMessageType = keyof typeof WebSocketMessageAccessConfig;
