import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Group } from "../../../interfaces/group";
import { Guid } from "../../../types/guid";
import { CourseGroupActionType } from "../course-group-list-actions/types/course-group-action-type";

@Component({
  selector: "course-group-list-header",
  templateUrl: "./course-group-list-header.component.html",
  styleUrls: ["./course-group-list-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseGroupListHeaderComponent {
  @Input({ required: true }) courseId!: Guid;
  @Input() rest: Group[] = [];
  @Input() active: Group;
  @Input() isTeacher = false;
  @Input() disabledActions: Array<CourseGroupActionType> = [];

  get hasAnyGroup(): boolean {
    return !!this.active || this.rest.length > 0;
  }

  getLink(groupId: Guid): string {
    return `/${this.isTeacher ? "teacher" : "student"}/courses/${
      this.courseId
    }/groups/${groupId}`;
  }
}
