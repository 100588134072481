import { DestroyRef, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Member } from "../../interfaces/member";
import { RunningExerciseData } from "../../types/running-exercise";
import { Store } from "@ngrx/store";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";
import * as fromUIStore from "src/app/store/reducers/ui.reducer";
import { NavigationEnd, Router } from "@angular/router";
import { filter, map, switchMap, take, tap } from "rxjs/operators";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Injectable({
  providedIn: "root",
})
export class JiraWidgetService {
  fieldIntervalId: number | null = null;

  member$: Observable<Member> = this.store.select(
    (state) => state.account.member,
  );

  runningExercise$: Observable<RunningExerciseData> = this.store.select(
    (state) => {
      const [running] = state.ui.runningExercise;

      return running;
    },
  );

  constructor(
    private router: Router,
    private destroyRef: DestroyRef,
    private store: Store<{
      account: fromAccountStore.AccountState;
      ui: fromUIStore.UIState;
    }>,
  ) {}

  showJiraWidget(): void {
    const iframe = document.getElementById("jsd-widget") as HTMLIFrameElement;
    if (iframe) {
      iframe.style.visibility = "visible";
    }
  }

  hideJiraWidget() {
    const iframe = document.getElementById("jsd-widget") as HTMLIFrameElement;
    if (iframe) {
      iframe.style.visibility = "hidden";
    }
  }

  resetFormFields(
    form: HTMLFormElement,
    courseField: HTMLInputElement,
    exerciseField: HTMLInputElement,
  ) {
    form.reset();
    courseField.removeAttribute("value");
    exerciseField.removeAttribute("value");

    const inputEvent = new Event("input", { bubbles: true });
    const changeEvent = new Event("submit", { bubbles: true });
    courseField.dispatchEvent(inputEvent);
    courseField.dispatchEvent(changeEvent);
    exerciseField.dispatchEvent(inputEvent);
    exerciseField.dispatchEvent(changeEvent);
  }

  setCourseField(courseField: HTMLInputElement) {
    const courseIdMatch = this.router.url.match(/courses\/([0-9a-fA-F-]{36})/);
    if (courseIdMatch && courseIdMatch[1]) {
      const courseId = courseIdMatch[1];
      this.member$
        .pipe(
          tap((member) => {
            if (member) {
              const result = member.organizations
                .reduce((acc, org) => acc.concat(org.coursegroups), [])
                .find((course) => course.course.id === courseId)?.course;
              if (result) this.fillFieldValue(courseField, result.name);
            }
          }),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe();
    }

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        filter((event: NavigationEnd) => !!event.url),
        map((event: NavigationEnd) =>
          event.url.match(/courses\/([0-9a-fA-F-]{36})/),
        ),
        tap(() => courseField.removeAttribute("value")),
        filter((courseIdMatch) => !!courseIdMatch && !!courseIdMatch[1]),
        map((courseIdMatch) => courseIdMatch[1]),
        switchMap((courseId) =>
          this.member$.pipe(
            take(1),
            map(
              (member) =>
                member.organizations
                  .reduce((acc, org) => acc.concat(org.coursegroups), [])
                  .find((courseGroup) => courseGroup.course.id === courseId)
                  ?.course,
            ),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result) => {
        if (result) this.fillFieldValue(courseField, result.name);
      });
  }

  setExerciseField(
    exercise: RunningExerciseData,
    courseField: HTMLInputElement,
    exerciseField: HTMLInputElement,
  ) {
    if (exercise) {
      this.fillFieldValue(exerciseField, exercise.name);
      if (exercise.course_id) {
        this.member$
          .pipe(
            tap((member) => {
              const result = member.organizations
                .reduce((acc, org) => acc.concat(org.coursegroups), [])
                .find((course) => course.course.id === exercise.course_id)
                ?.course;
              if (result) this.fillFieldValue(courseField, result.name);
            }),
            takeUntilDestroyed(this.destroyRef),
          )
          .subscribe();
      }
    }
  }

  fillFieldValue(field: HTMLInputElement, value: string) {
    field.setAttribute("value", value);

    const inputEvent = new Event("input", { bubbles: true });
    const changeEvent = new Event("submit", { bubbles: true });
    field.dispatchEvent(inputEvent);
    field.dispatchEvent(changeEvent);
  }

  checkFnExists(fn: () => boolean) {
    if (this.fieldIntervalId === null) {
      this.fieldIntervalId = window.setInterval(() => {
        const fieldFound = fn();
        if (fieldFound) {
          clearInterval(this.fieldIntervalId!);
          this.fieldIntervalId = null;
        }
      }, 100);
    }
  }

  injectDataToWidget() {
    const getIframeDocument = (): Document | null => {
      const iframe = document.getElementById("jsd-widget") as HTMLIFrameElement;
      return iframe && iframe.contentWindow
        ? iframe.contentWindow.document
        : null;
    };

    const setupFormButtons = (iframeDocument: Document) => {
      const submitForm = iframeDocument.getElementById(
        "submit-button",
      ) as HTMLButtonElement;
      const dismissBtn = iframeDocument.querySelector(
        'button[aria-label="Dismiss"]',
      );

      if (submitForm) {
        submitForm.addEventListener("click", () =>
          this.checkFnExists(checkJiraWidgetExists),
        );
      }

      if (dismissBtn) {
        dismissBtn.addEventListener("click", () =>
          this.checkFnExists(checkJiraWidgetExists),
        );
      }
    };

    const setupFormFields = (iframeDocument: Document) => {
      const courseField = iframeDocument.getElementById(
        "customfield_10082",
      ) as HTMLInputElement;
      const exerciseField = iframeDocument.getElementById(
        "customfield_10081",
      ) as HTMLInputElement;
      const emailField = iframeDocument.getElementById(
        "email",
      ) as HTMLInputElement;

      if (courseField) {
        this.setCourseField(courseField);
      }

      if (exerciseField) {
        this.runningExercise$
          .pipe(
            tap((exercise: RunningExerciseData) => {
              if (exercise) {
                this.setExerciseField(exercise, courseField, exerciseField);
              } else {
                exerciseField.removeAttribute("value");
              }
            }),
            takeUntilDestroyed(this.destroyRef),
          )
          .subscribe();
      }

      if (emailField) {
        this.member$
          .pipe(
            tap((member) => {
              if (member) {
                this.fillFieldValue(emailField, member.email);
              }
            }),
            takeUntilDestroyed(this.destroyRef),
          )
          .subscribe();
      }

      return true;
    };

    const checkJiraFormExists = (): boolean => {
      const iframeDocument = getIframeDocument();
      if (!iframeDocument) return false;

      const form = iframeDocument.querySelector(
        ".help-form",
      ) as HTMLFormElement;
      if (form) {
        const courseField = iframeDocument.getElementById(
          "customfield_10082",
        ) as HTMLInputElement;
        const exerciseField = iframeDocument.getElementById(
          "customfield_10081",
        ) as HTMLInputElement;
        this.resetFormFields(form, courseField, exerciseField);
      }

      setupFormButtons(iframeDocument);
      setupFormFields(iframeDocument);
      return true;
    };

    const checkJiraWidgetExists = (): boolean => {
      const iframeDocument = getIframeDocument();
      if (!iframeDocument || iframeDocument.readyState !== "complete")
        return false;

      const helpButton = iframeDocument.getElementById("help-button");
      if (helpButton) {
        helpButton.addEventListener("click", () =>
          this.checkFnExists(checkJiraFormExists),
        );
        return true;
      }

      return false;
    };

    this.checkFnExists(checkJiraWidgetExists);
  }
}
