import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { timer } from "rxjs";

@Component({
  selector: "progress-ring",
  templateUrl: "./progress-ring.component.html",
  styleUrls: ["./progress-ring.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressRingComponent implements OnChanges {
  @Input() diameter: number = 120;
  @Input() thickness: number = 4;
  @Input() theme: string = "hprimary";
  @Input() min: number = 0;
  @Input() max: number = 100;
  @Input() value: number = 0;
  @Input() showBackground: boolean = false;
  @Input() backgroundColor: string = "hlightgray";
  @Input() shadowEnabled: boolean = true;

  private circumference = this.radius * 2 * Math.PI;
  private offset = this.circumference;

  constructor(private ref: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    this.circumference = this.radius * 2 * Math.PI;

    if (changes && changes.diameter && changes.diameter.firstChange) {
      this.offset = this.circumference;
      this.setVariables();
    }

    this.offset =
      this.circumference - (this.percentage / 100) * this.circumference;

    timer(1).subscribe(() => {
      this.setVariables();
    });
  }

  setVariables() {
    this.ref.nativeElement.style.setProperty("--diameter", this.diameter);
    this.ref.nativeElement.style.setProperty(
      "--circumference",
      this.circumference
    );
    this.ref.nativeElement.style.setProperty("--offset", this.offset);
  }

  get radius(): number {
    return this.diameter / 2 - this.thickness * 2;
  }

  get percentage(): number {
    return this.max
      ? ((this.value - this.min) / (this.max - this.min)) * 100
      : 0;
  }
}
