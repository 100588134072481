import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Exercise } from "../../../interfaces/exercise";
import { RequestStatus } from "src/app/shared/interfaces/request-status";

@Component({
  selector: "div[exercise-none-control-buttons]",
  templateUrl: "./exercise-none-control-buttons.component.html",
  styleUrls: ["./exercise-none-control-buttons.component.scss"],
})
export class ExerciseNoneControlButtonsComponent implements OnChanges {
  @Input() exercise: Exercise;
  @Input() runningExerciseStatus: RequestStatus;

  @Output() onMarkAsAnswered = new EventEmitter<MouseEvent>();

  exerciseStatusPending: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    const { runningExerciseStatus } = changes;

    if (
      runningExerciseStatus &&
      ["IDLE", "SUCCESS", "FAILED"].includes(runningExerciseStatus.currentValue)
    ) {
      this.exerciseStatusPending = false;
    }
  }

  markAsAnswered(event: MouseEvent): void {
    this.exerciseStatusPending = true;
    this.onMarkAsAnswered.emit(event);
  }
}
