import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from "@angular/core";
import { ConfirmationModalComponent } from "../../components/modals/confirmation-modal/confirmation-modal.component";
import { ModalService } from "../../services/modal/modal.service";
import {
  ConfirmationConfirmButtonColor,
  ConfirmationModalIcon,
} from "../../types/confirmation-modal";
import { ConfirmationModalData } from "../../interfaces/confirmation-modal-data";

@Directive({
  selector: "[confirmation]",
})
export class ConfirmationDirective {
  @Input("confirmation-title") title = "";
  @Input("confirmation") message = "";
  @Input("confirm-button-text") confirmButtonText: string;
  @Input("confirm-button-color")
  confirmButtonColor: ConfirmationConfirmButtonColor;
  @Input("cancel-button-text") cancelButtonText: string;
  @Input("confirmation-icon") icon: ConfirmationModalIcon = "none";
  @Input() translationParams: { [key: string]: string } = {};
  @Output() onConfirm: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();

  constructor(private modalService: ModalService) {}

  private setEvents(instance: ConfirmationModalComponent) {
    instance.onCancel.subscribe(() => {
      this.onCancel.emit();
    });

    instance.onConfirm.subscribe(() => {
      this.onConfirm.emit();
    });
  }

  @HostListener("click", ["$event"])
  onHostClick(event: MouseEvent) {
    event.stopImmediatePropagation();
    event.preventDefault();

    let instance: ConfirmationModalComponent =
      this.modalService.showModal<ConfirmationModalData>(
        {
          modalTitle: this.title,
          message: this.message,
          confirmButtonText: this.confirmButtonText,
          confirmButtonColor: this.confirmButtonColor,
          cancelButtonText: this.cancelButtonText,
          translationParams: this.translationParams,
          icon: this.icon,
        },
        ConfirmationModalComponent,
        "CONFIRMATION",
      ) as ConfirmationModalComponent;
    this.setEvents(instance);
  }
}
