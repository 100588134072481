<div class="loading-spinner d-inline-flex flex-wrap" [ngStyle]="getSpinnerDimensions()">
  <span></span>
  <span></span>
  <span></span>
  <span></span>

  <span></span>
  <span></span>
  <span></span>
  <span></span>

  <span></span>
  <span></span>
  <span></span>
  <span></span>

  <span></span>
  <span></span>
  <span></span>
  <span></span>
</div>