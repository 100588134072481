import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { FlaggedExercise } from "src/app/shared/interfaces/flagged-exercises";

export const coursePrefix = "[Cancel Flagged Exercise]";

const cancelFlaggedExerciseActions = {
  request: `${coursePrefix} Load`,
  requestActiveSuccess: `${coursePrefix} Load success`,
  requestFail: `${coursePrefix} Load fail`,
};

export const cancelflaggedExerciseLoad = createAction(
  cancelFlaggedExerciseActions.request,
  props<{
    courseId: string;
    groupId: string;
    exerciseId: string;
    flagId: string;
  }>(),
);

export const cancelflaggedExerciseLoadSuccess = createAction(
  cancelFlaggedExerciseActions.requestActiveSuccess,
  props<{ exercise: FlaggedExercise }>(),
);

export const cancelflaggedExerciseFailure = createAction(
  cancelFlaggedExerciseActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
