import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { WarningModalService } from '../../services/warning-modal/warning-modal.service';

@Directive({
  selector: '[warningModal]'
})
export class WarningModalDirective implements OnInit {
  @Input() modalTitle = '';
  @Input() text = '';
  @Input() buttonText = 'GLOBAL.OK_BUTTON';
  @Input() icon = 'warning-outline';

  constructor(private warningModalService: WarningModalService) {}

  ngOnInit() {}

  @HostListener('click', ['$event'])
  onHostClick() {
    event.stopImmediatePropagation();
    event.preventDefault();
    this.warningModalService.showModal({
      modalTitle: this.modalTitle,
      text: this.text,
      buttonText: this.buttonText,
      icon: this.icon
    });
  }
}
