import { Component, Input } from "@angular/core";
import { CodeCheckType } from "src/app/shared/enums/code-check-type";

@Component({
  selector: "code-check-history-modal-check-type",
  template: `
    <span
      [ngClass]="{
        run: type === CodeCheckType.RUN,
        verify: type === CodeCheckType.VERIFY
      }"
      class="check-type"
      translate
    >
      {{ type | codeCheckType }}
    </span>
  `,
  styleUrls: ["./code-check-history-modal-check-type.component.scss"],
})
export class CodeCheckHistoryModalCheckTypeComponent {
  @Input() type: CodeCheckType;
  CodeCheckType: typeof CodeCheckType = CodeCheckType;

  constructor() {}
}
