import { createAction, props } from "@ngrx/store";
import { CreateTeamTeachers } from "src/app/modules/orgadmin/components/team-teachers/team-teachers-output.model";
import { CreateTeamTeachersResponse } from "src/app/shared/interfaces/orgadmin/course-teachers";

export const coursePrefix = "[Edit Team Teachers]";

const editTeamTeachersActions = {
  requestLoad: `${coursePrefix} Load`,
  requestSuccess: `${coursePrefix} Load success`,
  requestFail: `${coursePrefix} Load fail`,
};

export const editTeamTeachersLoad = createAction(
  editTeamTeachersActions.requestLoad,
);

export const editTeamTeachers = createAction(
  editTeamTeachersActions.requestSuccess,
  props<{
    organizationId: string;
    courseId: string;
    teamId: string;
    subId: string;
    payload: Partial<CreateTeamTeachers>;
  }>(),
);

export const editTeamTeachersFail = createAction(
  editTeamTeachersActions.requestFail,
  props<{ error: CreateTeamTeachersResponse | null }>(),
);
