import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
} from "@angular/core";

@Component({
  selector: "tooltip",
  templateUrl: "./tooltip.component.html",
  styleUrls: ["./tooltip.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent {
  @Input() isVisible: boolean = false;
  @Input() text: string;
  @Input() coords: { x: string; y: string } = { x: "0", y: "0" };

  constructor(
    public cd: ChangeDetectorRef,
    public ref: ElementRef,
  ) {}

  get nativeElement(): HTMLElement {
    return this.ref.nativeElement;
  }
}
