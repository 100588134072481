import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { of } from "rxjs";
import { switchMap, catchError, map } from "rxjs/operators";
import {
  organizationsListLoad,
  organizationsListLoadSuccess,
  organizationsListLoadFail,
} from "../actions/organizations.actions";
import { OrganizationsService } from "src/app/shared/services/organizations/organizations.service";
import { Organization } from "src/app/shared/interfaces/organization";
import * as fromOrg from "../actions/organizations.actions";
import { PdfReportService } from "src/app/shared/services/pdfreport/pdfreport.service";
import { FileService } from "src/app/shared/services/file/file.service";

@Injectable()
export class OrganizationsEffect {
  getCoursesAdminList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(organizationsListLoad),
      switchMap(() => {
        return this.organizationsService.getOrganizations().pipe(
          map((res: Organization[]) =>
            organizationsListLoadSuccess({ organizations: res }),
          ),
          catchError(({ error }) => of(organizationsListLoadFail(error))),
        );
      }),
    ),
  );

  constructor(
    private organizationsService: OrganizationsService,
    private actions$: Actions,
    private pdfreportService: PdfReportService,
    private fileService: FileService,
  ) {}

  downloadOrganizationReport$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromOrg.DOWNLOAD_ORGANIZATION_REPORT),
        switchMap((action: fromOrg.DownloadOrganizationReport) => {
          this.pdfreportService
            .downloadOrganizationReport(action.payload)
            .subscribe((response) => {
              this.fileService.downloadS3File(
                response.s3_url,
                response.file_name,
              );
            });

          return of({});
        }),
      ),
    { dispatch: false },
  );
}
