import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'validation-text',
  templateUrl: './validation-text.component.html',
  styleUrls: ['./validation-text.component.scss']
})
export class ValidationTextComponent {
  @Input() control: UntypedFormControl | AbstractControl;
  @Input() textAlign = 'left';
  @Input() useNegativeMargin = true;
}
