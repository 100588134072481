<form [formGroup]="form">
  <div class="row justify-content-center">
    <div class="col-8">
      <dropdown
        mask="999"
        [suffix]="suffix"
        [placeholder]="'COURSES.COURSE.PASS_COURSE_FROM' | translate"
        [editable]="true"
        [formControl]="form.controls.passFrom"
        [options]="passSelectItems$ | async"
      />

      <validation-text textAlign="right" [control]="form.controls.passFrom">
        <div class="my-2">{{ "GLOBAL.VALUE_IS_TOO_LARGE" | translate }}</div>
      </validation-text>
    </div>
  </div>

  <div class="d-flex justify-content-center mt-4">
    <button
      type="button"
      class="button btn-primary-900 px-4"
      [disabled]="form.invalid || !form.dirty"
      (click)="handleSaveClick()"
    >
      {{ "GLOBAL.SAVE" | translate }}
    </button>
  </div>
</form>
