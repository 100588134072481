<div class="input-number">
  <span class="input-number-prefix" [innerHtml]="prefix"></span>

  <input
    type="text"
    class="input-number-control"
    [mask]="mask"
    [ngModel]="value"
    [readonly]="readOnly"
    [disabled]="readOnly"
    (input)="handleInputEvent($event)"
  />

  <span class="input-number-suffix" [innerHtml]="suffix"></span>
</div>
