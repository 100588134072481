export enum TutorialSteps {
  START = 0,
  MAIN_PANEL = 1,
  COURSES_LIST = 2,
  DASHBOARD_BTN = 3,
  COURSES_BTN = 4,
  RANKING_BTN = 5,
  NOTIFICATIONS_BTN = 6,
  USER_BTN = 7,
  FIRST_STEPS = 8,
}

export enum TutorialTeacherSteps {
  START = 0,
  MAIN_PANEL = 1,
  ACTIVE_GROUPS = 2,
  MY_GROUPS = 3,
  ORGANIZATIONS_LIMIT = 4,
  EDITOR_CODE = 5,
  FIRST_STEPS = 6,
  DASHBOARD_BTN = 7,
  GROUPS_BTN = 8,
  COURSES_BTN = 9,
  RANKING_BTN = 10,
  NOTIFICATIONS_BTN = 11,
  USER_BTN = 12,
  GROUP_DASHBOARD = 13,
  GROUP_FORM = 14,
  GROUP_FORM_NAME = 15,
  GROUP_FORM_ORGANIZATION = 16,
  GROUP_FORM_COURSE = 17,
  GROUP_FORM_DATE = 18,
  GROUP_FORM_SUBMIT = 19,
  GROUP_LIST = 20,
  GROUP_ACTIONS = 21,
  COURSES_LIST = 22,
  COURSE_EXPAND_GROUPS = 23,
  COURSE_DASHBOARD = 24,
  COURSE_SUBJECT = 25,
  COURSE_GROUP = 26,
  COURSE_EXERCISES = 27,
  COURSE_ACTIONS_BTN = 28,
  COURSE_SINGLE_BTN = 29,
  COURSE_EXPAND_BTN = 30,
  COURSE_VIDEO_BTN = 31,
  COURSE_START_BTN = 32,
}
