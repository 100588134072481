import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ExerciseStatus } from "../../enums/exercise-status";
import { isNil } from "lodash-es";
import { KeyIconComponent } from "../key-icon/key-icon.component";
import { AnswerType } from "../../types/answer-type";
import { EXERCISE_ANSWER_CONFIG } from "./exercises-type-status";

@Component({
  selector: "exercise-status",
  templateUrl: "exercise-status.component.html",
  styleUrls: ["./exercise-status.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExerciseStatusComponent
  implements OnChanges, OnDestroy, AfterViewInit
{
  @Input() status = ExerciseStatus.NEW;
  @Input() exerciseType: AnswerType;

  @ViewChild("statusIcon", { read: ViewContainerRef })
  private readonly iconContainer: ViewContainerRef;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { status } = changes;

    if (!isNil(status) && !status.firstChange && this.iconContainer) {
      this.createIconComponent(status.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.iconContainer.clear();
    this.iconContainer.detach();
  }

  ngAfterViewInit(): void {
    this.createIconComponent(this.status);
  }

  private createIconComponent(status: ExerciseStatus): void {
    this.iconContainer.clear();

    const iconComponent = this.iconContainer.createComponent(KeyIconComponent);
    const { instance } = iconComponent;

    instance.size = "xs";

    if (EXERCISE_ANSWER_CONFIG[this.exerciseType]) {
      const statusConfig = EXERCISE_ANSWER_CONFIG[this.exerciseType][status];
      if (statusConfig) {
        Object.assign(instance, statusConfig);
      }
    }

    this.cd.detectChanges();
  }
}
