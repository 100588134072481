import { Component } from '@angular/core';

@Component({
  selector: 'fluid-table',
  templateUrl: './fluid-table.component.html',
  styleUrls: ['./fluid-table.component.scss']
})
export class FluidTableComponent {
  constructor() {}
}
