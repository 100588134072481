import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";

export const coursePrefix = "[Delete Team Teacher]";

const deleteTeamTeacherActions = {
  request: `${coursePrefix} Load`,
  requestSuccess: `${coursePrefix} Load success`,
  requestFail: `${coursePrefix} Load fail`,
};

export const deleteTeamTeacherLoad = createAction(
  deleteTeamTeacherActions.request,
  props<{
    organizationId: string;
    courseId: string;
    teamId: string;
    subId: string;
  }>(),
);

export const deleteTeamTeacherLoadSuccess = createAction(
  deleteTeamTeacherActions.requestSuccess,
);

export const deleteTeamTeacherLoadFail = createAction(
  deleteTeamTeacherActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
