import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private apiUrl = environment.apiLocation;
  private apiDomain = environment.apiDomain;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
  ) {}

  getRequest<T>(
    action: string,
    options?: { [key: string]: any },
  ): Observable<T> {
    return this.http
      .get<T>(
        `${this.apiDomain}/${this.translate.currentLang}${
          this.apiUrl + action
        }`,
        options,
      )
      .pipe(
        catchError(this.handleError),
        finalize(() => {}),
      );
  }

  postRequest<T>(
    action: string,
    data: any,
    options?: { [key: string]: any },
  ): Observable<T> {
    return this.http
      .post<T>(
        `${this.apiDomain}/${this.translate.currentLang}${
          this.apiUrl + action
        }`,
        data,
        options,
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        }),
      );
  }

  putRequest<T>(
    action: string,
    data: any,
    options?: { [key: string]: any },
  ): Observable<T> {
    return this.http
      .put<T>(
        `${this.apiDomain}/${this.translate.currentLang}${
          this.apiUrl + action
        }`,
        data,
        options,
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        }),
      );
  }

  patchRequest<T>(
    action: string,
    data: any,
    options?: { [key: string]: any },
  ): Observable<T> {
    return this.http
      .patch<T>(
        `${this.apiDomain}/${this.translate.currentLang}${
          this.apiUrl + action
        }`,
        data,
        options,
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        }),
      );
  }

  deleteRequest<T>(
    action: string,
    options?: { [key: string]: any },
  ): Observable<T> {
    return this.http
      .delete<T>(
        `${this.apiDomain}/${this.translate.currentLang}${
          this.apiUrl + action
        }`,
        options,
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error);
        }),
      );
  }

  cleanPayload<T>(payload: T): T {
    return Object.keys(payload).reduce((acc, key) => {
      if (typeof payload[key] !== "undefined") {
        return { ...acc, [key]: payload[key] };
      } else {
        return acc;
      }
    }, {} as T);
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
