import { Component, Input } from "@angular/core";

@Component({
  selector: "list-progress",
  templateUrl: "./list-progress.component.html",
  styleUrls: ["./list-progress.component.scss"],
})
export class ListProgressComponent {
  @Input()
  data: { completed: number; all: number; certificate_percent: number };

  calcPercentage(): { width: string } {
    return { width: (this.data.completed / this.data.all) * 100 + "%" };
  }
}
