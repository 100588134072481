import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Exercise } from "src/app/shared/interfaces/exercise";
import { Guid } from "src/app/shared/types/guid";
import * as fromUIStore from "src/app/store/reducers/ui.reducer";

@Component({
  selector: "div[exercise-quiz-control-buttons]",
  templateUrl: "./exercise-quiz-control-buttons.component.html",
  styleUrls: ["./exercise-quiz-control-buttons.component.scss"],
})
export class ExerciseQuizControlButtonsComponent {
  @Input() courseId: Guid;
  @Input() exercise: Exercise;
  @Input() activeGroup: Guid;
  @Input("teacher") isTeacher: boolean;
  @Output() onStart: EventEmitter<MouseEvent> = new EventEmitter();

  isRunning$: Observable<boolean> = this.store
    .select((state) => state.ui.runningExercise)
    .pipe(
      map((runningExercise) =>
        runningExercise[0]
          ? runningExercise[0].id === this.exercise.id &&
            (this.isTeacher || runningExercise[0].group_id === this.activeGroup)
          : false,
      ),
    );

  constructor(
    private store: Store<{ ui: fromUIStore.UIState }>,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  start(event: MouseEvent, isRunning: boolean): void {
    if (isRunning) {
      this.router.navigate(
        [
          `/quiz/${this.courseId}${
            this.isTeacher ? "/" : `/groups/${this.activeGroup}/`
          }exercise/${this.exercise.id}`,
        ],
        {
          queryParams: {
            ...(this.isTeacher && this.activeGroup
              ? { returnGroupId: this.activeGroup }
              : {}),
          },
        },
      );
    } else {
      if (this.isTeacher && this.activeGroup) {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: { returnGroupId: this.activeGroup },
          queryParamsHandling: "merge",
          replaceUrl: true,
        });
      }
      this.onStart.emit(event);
    }
  }
}
