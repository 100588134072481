import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "dashboard-sidebar",
  templateUrl: "./dashboard-sidebar.component.html",
  styleUrls: ["./dashboard-sidebar.component.scss"],
})
export class DashboardSidebarComponent {
  @Input() expanded = false;
  @Output() expand: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  toggleExpand(): void {
    this.expanded = !this.expanded;
    this.expand.emit(this.expanded);
  }
}
