import {
  AfterViewInit,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
} from "@angular/core";
import { Subscription } from "rxjs";
import { TooltipComponent } from "../../blocks/tooltip/tooltip.component";
import { TooltipService } from "../../services/tooltip/tooltip.service";

@Directive({
  selector: "[tooltip]",
})
export class TooltipDirective implements AfterViewInit, OnDestroy {
  @Input() position: "top" | "bottom" | "custom" = "top";
  @Input("tooltip") text: string;

  private componentRef: ComponentRef<TooltipComponent>;
  private contextSubscription: Subscription;

  constructor(
    private ref: ElementRef<HTMLElement>,
    private tooltip: TooltipService,
  ) {}

  private get nativeElement(): HTMLElement {
    return this.ref.nativeElement;
  }

  ngAfterViewInit(): void {
    this.contextSubscription = this.tooltip.context.subscribe(() => {
      this.componentRef = this.tooltip.componentRef;
    });
  }

  ngOnDestroy(): void {
    this.componentInstance.isVisible = false;
    this.componentInstance.cd.detectChanges();

    this.contextSubscription.unsubscribe();
  }

  @HostListener("mouseenter")
  onMouseEnter(): void {
    this.componentInstance.text = this.text;
    this.componentInstance.isVisible = true;
    this.componentInstance.cd.detectChanges();
  }

  @HostListener("mouseleave")
  onMouseLeave(): void {
    this.componentInstance.isVisible = false;
    this.componentInstance.cd.detectChanges();
  }

  @HostListener("mousemove")
  onMouseMove(): void {
    switch (this.position) {
      case "top":
        return this.calculatePositionForTop();

      case "bottom":
        return this.calculatePositionForBottom();

      case "custom":
        return this.calculatePositionForTopCustom();
    }
  }

  private get componentInstance(): TooltipComponent {
    return this.componentRef.instance;
  }

  private calculatePositionForTop(): void {
    const { left: x, top: y, width, height } = this.currentElementRect();

    this.componentInstance.coords = {
      x: `${x + width / 1.5 + window.scrollX}px`,
      y: `${y - height / 2 + window.scrollY}px`,
    };

    this.componentInstance.cd.detectChanges();
  }

  private calculatePositionForBottom(): void {
    const { left: x, top: y, width, height } = this.currentElementRect();

    this.componentInstance.coords = {
      x: `${x + width / 1.5 + window.scrollX}px`,
      y: `${y + height + window.scrollY}px`,
    };

    this.componentInstance.cd.detectChanges();
  }

  private calculatePositionForTopCustom(): void {
    const { left: x, top: y, width, height } = this.currentElementRect();

    this.componentInstance.coords = {
      x: `${x + width / 3 + window.scrollX}px`,
      y: `${y - height / 12 + window.scrollY}px`,
    };

    this.componentInstance.cd.detectChanges();
  }

  private currentElementRect(): DOMRect {
    return this.nativeElement.getBoundingClientRect();
  }
}
