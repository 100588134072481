import { Pipe, PipeTransform } from "@angular/core";
import { Exercise } from "../interfaces/exercise";

@Pipe({
  name: "orderByAvailability",
})
export class OrderByAvailabilityPipe implements PipeTransform {
  transform(value: Array<Exercise>, isTeacher: boolean): Array<Exercise> {
    return value.sort((a, b) =>
      isTeacher
        ? a.order - b.order
        : (b.available as unknown as number | 0) -
            (a.available as unknown as number | 0) || a.order - b.order
    );
  }
}
