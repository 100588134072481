import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { Package } from "src/app/shared/interfaces/packages/package";

export const coursePrefix = "[Package List]";

const packageListActions = {
  request: `${coursePrefix} Load`,
  requestActiveSuccess: `${coursePrefix} Load success`,
  requestFail: `${coursePrefix} Load fail`,
};

export const packageListLoad = createAction(
  packageListActions.request,
  props<{
    id: string;
  }>(),
);

export const packageListLoadSuccess = createAction(
  packageListActions.requestActiveSuccess,
  props<{ packages: Package[] }>(),
);

export const loadPackageListFailure = createAction(
  packageListActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
