import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { Invitation } from "../../interfaces/invitation";
import { Guid } from "../../types/guid";
import * as fromDashboard from "src/app/store/actions/dashboard.actions";

@Component({
  selector: "invitations-widget",
  templateUrl: "./invitations-widget.component.html",
  styleUrls: ["./invitations-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvitationsWidgetComponent {
  @Input() invitations: Array<Invitation>;

  constructor(private store: Store<{}>) {}

  acceptInvitation(invitationId: Guid): void {
    this.store.dispatch(new fromDashboard.AcceptInvitation({ invitationId }));
  }

  rejectInvitation(invitationId: Guid): void {
    this.store.dispatch(new fromDashboard.RejectInvitation({ invitationId }));
  }
}
