import { Component, HostBinding, Input } from "@angular/core";
import { ListboxEmptyVariant } from "../../types/listbox-empty-variant";

@Component({
  selector: "listbox-empty",
  templateUrl: "./listbox-empty.component.html",
  styleUrls: ["./listbox-empty.component.scss"],
})
export class ListboxEmptyComponent {
  @Input() showIcon = false;

  @Input()
  @HostBinding("class")
  variant: ListboxEmptyVariant;
}
