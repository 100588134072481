import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import {
  packageTeacherGroupsListLoad,
  packageTeacherGroupsListLoadSuccess,
  loadPackageTeacherGroupsListFailure,
  clearPackageTeacherGroups,
} from "../../actions/packages/package-teacher-groups.actions";
import { PackageInvitations } from "src/app/shared/interfaces/packages/package-teacher-invitations";

export interface PackageTeacherGroupListState {
  packagesGroups: PackageInvitations[];
  error: HttpErrorResponse | null;
}

const initialState: PackageTeacherGroupListState = {
  packagesGroups: [],
  error: null,
};

export const packageTeacherGroupsListReducer = createReducer(
  initialState,
  on(
    packageTeacherGroupsListLoad,
    (state): PackageTeacherGroupListState => ({
      ...state,
      packagesGroups: null,
      error: null,
    }),
  ),
  on(
    packageTeacherGroupsListLoadSuccess,
    (state, action): PackageTeacherGroupListState => ({
      ...state,
      packagesGroups: action.packagesGroups,
      error: null,
    }),
  ),
  on(loadPackageTeacherGroupsListFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
  on(
    clearPackageTeacherGroups,
    (state): PackageTeacherGroupListState => ({
      ...state,
      packagesGroups: [],
      error: null,
    }),
  ),
);
