import { Component, Input, ChangeDetectionStrategy } from "@angular/core";

@Component({
  selector: "status-icon",
  template: `
    <div class="status-icon" [class.ok]="isOk">
      <i class="status-icon-image {{ set }} {{ set }}-{{ icon }}"></i>
    </div>
  `,
  styleUrls: ["./status-icon.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusIconComponent {
  @Input() isOk: boolean;
  @Input() icon: string;
  @Input() set: string = 'uil';

  constructor() {}
}
