import { createReducer, on } from "@ngrx/store";
import { Certificate } from "src/app/shared/interfaces/certificate";
import { HttpErrorResponse } from "@angular/common/http";
import {
  certificatesLoad,
  certificatesLoadFail,
  certificatesLoadSuccess,
} from "../actions/certificates.actions";

export interface CertificatesState {
  certificates: Certificate[];
  error: HttpErrorResponse | null;
}

const initialState: CertificatesState = {
  certificates: [],
  error: null,
};

export const certificatesReducer = createReducer(
  initialState,
  on(
    certificatesLoad,
    (state): CertificatesState => ({
      ...state,
      error: null,
    }),
  ),
  on(
    certificatesLoadSuccess,
    (state, action): CertificatesState => ({
      ...state,
      certificates: action.certificates,
      error: null,
    }),
  ),
  on(
    certificatesLoadFail,
    (state, { error }): CertificatesState => ({
      ...state,
      error,
    }),
  ),
);
