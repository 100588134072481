export class RowsExpander {
  private readonly expandedRows = new Map<number, boolean>();

  configure(rows: Array<unknown>): void {
    rows.forEach((_, index: number): void => {
      if (this.expandedRows.has(index)) {
        return;
      }

      if (this.isExpanded(index)) {
        return;
      }

      this.expandedRows.set(index, false);
    });
  }

  isExpanded(index: number): boolean {
    if (!this.expandedRows.has(index)) {
      return false;
    }

    return this.expandedRows.get(index);
  }

  get isAllExpanded(): boolean {
    const values: boolean[] = Array.from(this.expandedRows.values());

    return values.every((value: boolean) => value);
  }

  toggleExpandState(index: number): void {
    if (!this.expandedRows.has(index)) {
      return;
    }

    if (this.expandedRows.get(index)) {
      this.expandedRows.set(index, false);

      return;
    }

    this.expandedRows.set(index, true);
  }

  toggleExpandAllState(): void {
    if (this.isAllExpanded) {
      this.expandedRows.forEach((_: boolean, key: number): void => {
        this.expandedRows.set(key, false);
      });

      return;
    }

    this.expandedRows.forEach((_: boolean, key: number): void => {
      this.expandedRows.set(key, true);
    });
  }
}
