import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { TeacherDetails } from "src/app/shared/interfaces/orgadmin/course-teachers";

export const coursePrefix = "[Single Active Teacher]";

const singleActiveTeacherActions = {
  request: `${coursePrefix} Load`,
  requestSuccess: `${coursePrefix} Load success`,
  requestFail: `${coursePrefix} Load fail`,
  requestClear: `${coursePrefix} Clear`,
};

export const singleActiveTeacherLoad = createAction(
  singleActiveTeacherActions.request,
  props<{ organizationId: string; teacherId: string }>(),
);

export const singleActiveTeacherLoadSuccess = createAction(
  singleActiveTeacherActions.requestSuccess,
  props<{ activeTeacher: TeacherDetails }>(),
);

export const singleActiveTeacherLoadFail = createAction(
  singleActiveTeacherActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);

export const singleActiveTeacherClear = createAction(
  singleActiveTeacherActions.request,
);
