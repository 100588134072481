import { Pipe, PipeTransform } from "@angular/core";
import { InvitationStatus } from "../types/invitation-status";

@Pipe({
  name: "invitationStatus",
})
export class InvitationStatusPipe implements PipeTransform {
  transform(value: InvitationStatus): any {
    switch (value) {
      case InvitationStatus.Sent:
        return "GROUPS.EDIT.INVITATION_SENT";

      case InvitationStatus.Accepted:
        return "GROUPS.EDIT.INVITATION_ACCEPTED";

      case InvitationStatus.Rejected:
        return "GROUPS.EDIT.INVITATION_DECLINED";

      case InvitationStatus.Pending:
        return "GROUPS.EDIT.INVITATION_PENDING";

      case InvitationStatus.Delivered:
        return "GROUPS.EDIT.INVITATION_DELIVERED";

      case InvitationStatus.Failed:
        return "GROUPS.EDIT.INVITATION_FAILED";

      default:
        return null;
    }
  }
}
