import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import * as fromUI from "src/app/store/actions/ui.actions";
import * as fromUIStore from "src/app/store/reducers/ui.reducer";
import { Announcement } from "../../interfaces/announcement";
import { TranslateSubscriptionsService } from "../../services/translate-subscriptions/translate-subscriptions.service";

const ANNOUNCEMENTS_LANG_CHANGE = "ANNOUNCEMENTS_LANG_CHANGE";

@Component({
  selector: "announcements",
  templateUrl: "./announcements.component.html",
  styleUrls: ["./announcements.component.scss"],
})
export class AnnouncementsComponent implements OnInit, OnDestroy {
  getAnnouncementsCompleted$: Observable<boolean> = this.store.select(
    (state) => state.ui.getAnnouncementsCompleted
  );
  announcements$: Observable<Array<Announcement>> = this.store.select(
    (state) => state.ui.announcements
  );

  constructor(
    private store: Store<{ ui: fromUIStore.UIState }>,
    private translateSubscriptions: TranslateSubscriptionsService
  ) {}

  ngOnInit(): void {
    this.translateSubscriptions.subscribe(ANNOUNCEMENTS_LANG_CHANGE, () => {
      this.getAnnouncements();
    });

    this.getAnnouncements();
  }

  ngOnDestroy(): void {
    this.translateSubscriptions.unsubscribe(ANNOUNCEMENTS_LANG_CHANGE);
  }

  private getAnnouncements(): void {
    this.store.dispatch(new fromUI.GetAnnouncements());
  }
}
