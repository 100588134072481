<a
  href="#"
  (click)="goToTopic($event)"
  class="progress-topic-element d-flex align-items-center"
  [ngClass]="{'active' : isActive}"
>
  <progress-ring
    [diameter]="35"
    [thickness]="4"
    [value]="solved"
    [max]="topic.exercises.length"
    [theme]="theme"
    [showBackground]="true"
    [shadowEnabled]="false"
    backgroundColor="gray-300"
  >
      <i *ngIf="isCompleted" class="uil uil-check completed-mark"></i>
  </progress-ring>

  <span class="topic-title label">{{ topic.name }}</span>
</a>
