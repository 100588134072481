export enum CodeTestStatus {
  CHECK_TEST_RESULT_IN_QUEUE = 1,
  CHECK_TEST_RESULT_PROCESSING = 2,
  CHECK_TEST_RESULT_SUCCESS = 3,
  CHECK_TEST_RESULT_WRONG_ANSWER = 4,
  CHECK_TEST_RESULT_TIME_EXCEEDED = 5,
  CHECK_TEST_RESULT_COMPILATION_ERROR = 6,
  CHECK_TEST_RESULT_RUNTIME_ERROR_SIGSEGV = 7,
  CHECK_TEST_RESULT_RUNTIME_ERROR_SIGXFSZ = 8,
  CHECK_TEST_RESULT_RUNTIME_ERROR_SIGFPE = 9,
  CHECK_TEST_RESULT_RUNTIME_ERROR_SIGABRT = 10,
  CHECK_TEST_RESULT_RUNTIME_ERROR_NZEC = 11,
  CHECK_TEST_RESULT_RUNTIME_ERROR_OTHER = 12,
  CHECK_TEST_RESULT_INTERNAL_ERROR = 13,
  CHECK_TEST_RESULT_EXEC_FORMAT_ERROR = 14,
}
