import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { combineLatest, Observable, zip } from "rxjs";
import { first, map, takeWhile } from "rxjs/operators";
import { NotificationElement } from "src/app/shared/interfaces/notification-element";
import { TranslateSubscriptionsService } from "src/app/shared/services/translate-subscriptions/translate-subscriptions.service";
import * as fromUI from "src/app/store/actions/ui.actions";
import * as fromUIStore from "src/app/store/reducers/ui.reducer";

export const UI_NOTIFICATIONS_LANG_CHANGE = "UI_NOTIFICATIONS_LANG_CHANGE";
enum ActiveTab {
  NONE,
  SNOOZE,
  SETTINGS,
}

@Component({
  selector: "notifications-panel",
  templateUrl: "./notifications-panel.component.html",
  styleUrls: ["./notifications-panel.component.scss"],
})
export class NotificationsPanelComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() active: boolean;
  @Output() onPanelNotify: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild("notificationsPanel")
  panel: ElementRef;

  ActiveTab: typeof ActiveTab = ActiveTab;
  activeTab: ActiveTab = ActiveTab.NONE;

  notifications$: Observable<Array<NotificationElement>> = this.store.select(
    (state) => state.ui.notifications
  );

  getNotificationsCompleted$: Observable<boolean> = this.store.select(
    (state) => state.ui.getNotificationsCompleted
  );

  notificationsCurrentPage$: Observable<number> = this.store.select(
    (state) => state.ui.notificationsCurrentPage
  );

  notificationsCount$: Observable<number> = this.store.select(
    (state) => state.ui.notificationsCount
  );

  constructor(
    private store: Store<{ ui: fromUIStore.UIState }>,
    private renderer: Renderer2,
    private translateSubscriptions: TranslateSubscriptionsService
  ) {}

  ngOnInit(): void {
    this.translateSubscriptions.subscribe(UI_NOTIFICATIONS_LANG_CHANGE, () => {
      this.reInitNotifications();
    });
  }

  ngOnDestroy(): void {
    this.translateSubscriptions.unsubscribe(UI_NOTIFICATIONS_LANG_CHANGE);
  }

  ngAfterViewInit(): void {
    this.bindScrollToPanel();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.active.currentValue) {
      this.activeTab = ActiveTab.NONE;
    }
  }

  setTab(event: MouseEvent, tab: ActiveTab): void {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.activeTab = tab;
  }

  private bindScrollToPanel(): void {
    let element: HTMLDivElement = this.panel.nativeElement;
    this.renderer.listen(element, "scroll", (event) => {
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.getNotificationsByScroll();
      }
    });
  }

  private reInitNotifications(): void {
    this.store.dispatch(new fromUI.ClearNotifications());
    this.store.dispatch(new fromUI.GetNotifications(1));
  }

  private getNotificationsByScroll(): void {
    combineLatest(
      this.getNotificationsCompleted$,
      zip(this.notifications$, this.notificationsCount$).pipe(
        takeWhile(
          ([notifications, count]: [Array<NotificationElement>, number]) =>
            notifications.length < count
        ),
        map((result: [Array<NotificationElement>, number]) => result[1])
      ),
      this.notificationsCurrentPage$
    )
      .pipe(
        takeWhile((result: [boolean, number, number]) => {
          return result[0] === true;
        }),
        map((result: [boolean, number, number]) => {
          return result[2];
        }),
        first()
      )
      .subscribe(
        (currentPage: number) => {
          this.store.dispatch(new fromUI.GetNotifications(currentPage));
        },
        (error) => {}
      );
  }

  notifyPanel(): void {
    this.onPanelNotify.emit(true);
  }

  markAllAsRead(): void {
    this.store.dispatch(new fromUI.MarkAllNotificationsAsRead());
  }
}
