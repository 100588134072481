import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import {
  loadPackageListFailure,
  packageListLoad,
  packageListLoadSuccess,
} from "../../actions/packages/packages.actions";
import { Package } from "src/app/shared/interfaces/packages/package";

export interface PackageListState {
  packages: Package[] | null;
  error: HttpErrorResponse | null;
}

const initialState: PackageListState = {
  packages: null,
  error: null,
};

export const packageListReducer = createReducer(
  initialState,
  on(
    packageListLoad,
    (state): PackageListState => ({
      ...state,
      packages: null,
      error: null,
    }),
  ),
  on(
    packageListLoadSuccess,
    (state, action): PackageListState => ({
      ...state,
      packages: action.packages,
      error: null,
    }),
  ),
  on(loadPackageListFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);
