import { createSelector } from "@ngrx/store";
import { isNil, omit } from "lodash-es";
import { Course } from "src/app/shared/interfaces/course";
import { CourseGroup } from "src/app/shared/interfaces/course-group";
import { CourseListGroup } from "src/app/shared/interfaces/group";
import {
  GroupedCourseLimit,
  GroupedOrganizationLimit,
} from "src/app/shared/interfaces/limit";
import { Guid } from "src/app/shared/types/guid";
import { Nillable } from "src/app/shared/types/nillable";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";
import * as fromCourseStore from "src/app/store/reducers/course.reducer";
import * as fromCoursesStore from "src/app/store/reducers/courses.reducer";

export type CourseSelectorsState = {
  account?: fromAccountStore.AccountState;
  teacher?: {
    singleCourse?: fromCourseStore.CourseState;
    coursesList?: fromCoursesStore.CoursesState;
  };
  student?: { singleCourse?: fromCourseStore.CourseState };
};

export const course = (state: CourseSelectorsState) =>
  state.teacher
    ? state.teacher.singleCourse.course
    : state.student.singleCourse.course;
export const activeGroup = (state: CourseSelectorsState) =>
  state.teacher
    ? state.teacher.singleCourse.activeGroup
    : state.student.singleCourse.activeGroup;
export const coursesLimits = (state: CourseSelectorsState) =>
  state.account.coursesLimits;

export const selectCourseState = createSelector(
  (state: CourseSelectorsState) =>
    state.teacher || state.student
      ? state.teacher
        ? state.teacher.singleCourse
        : state.student.singleCourse
      : null,
  (state: fromCourseStore.CourseState) => state,
);

export const selectHasRanking = createSelector(
  course,
  activeGroup,
  (course: CourseGroup | Course, activeGroup: Guid) => {
    if (course && typeof course.groups === "undefined") {
      return false;
    } else if (course && typeof course.groups === "object") {
      const group: CourseListGroup = course.groups.find(
        (group) => group.id === activeGroup,
      );
      return group ? group.has_ranking : false;
    } else {
      return false;
    }
  },
);

export const coursesGroupedByOrganization = createSelector(
  (state: CourseSelectorsState) => state.teacher.coursesList.courses,
  (state: CourseSelectorsState) => state.account.currentOrganization,
  (
    courses: Nillable<Array<Course>>,
    currentOrganization: Nillable<Guid>,
  ): Record<Guid, Array<Course>> => {
    const coursesRecord: Record<Guid, Array<Course>> = courses?.reduce(
      (courseAcc, course) => {
        course.organizations
          .filter(
            (organization) =>
              isNil(currentOrganization) ||
              organization === currentOrganization,
          )
          .forEach((organization) => {
            courseAcc[organization] = (courseAcc[organization] || []).concat(
              omit<Course>(
                {
                  ...course,
                  groups: course.groups.filter(
                    (group) => group.organization === organization,
                  ),
                },
                "organizations",
              ),
            );
          });

        return courseAcc;
      },
      {},
    );

    Object.keys(coursesRecord || {}).forEach((organization) => {
      coursesRecord[organization].sort((courseA) =>
        courseA.groups.length === 0 ? 1 : -1,
      );
    });

    return coursesRecord;
  },
);

export const courseLimitsWithGroupedOrganizations = createSelector(
  coursesLimits,
  (limits): Array<GroupedCourseLimit> =>
    Object.values(
      limits.reduce(
        (acc, limit) => {
          if (isNil(acc[limit.course_id])) {
            const { course_id, name } = limit;
            acc[limit.course_id] = {
              id: course_id,
              name,
              organizations: [],
            };
          }

          const { organization: id, limit_current, limit_max } = limit;
          acc[limit.course_id].organizations.push({
            id,
            limit_current,
            limit_max,
          });

          return acc;
        },
        {} as Record<Guid, GroupedCourseLimit>,
      ),
    ),
);

export const courseLimitsWithGroupedCourses = createSelector(
  coursesLimits,
  (limits): Array<GroupedOrganizationLimit> =>
    Object.values(
      limits.reduce(
        (acc, limit) => {
          const { organization: organization_id } = limit;

          if (isNil(acc[organization_id])) {
            acc[organization_id] = {
              organization_id,
              courses: [],
            };
          }

          const { organization, ...course } = limit;
          acc[organization_id].courses.push(course);

          return acc;
        },
        {} as Record<Guid, GroupedOrganizationLimit>,
      ),
    ),
);
