import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";

export const coursePrefix = "[Delete Invitation Teacher]";

const deleteInvitationTeacherActions = {
  request: `${coursePrefix} Load`,
  requestSuccess: `${coursePrefix} Load success`,
  requestFail: `${coursePrefix} Load fail`,
};

export const deleteInvitationTeacherLoad = createAction(
  deleteInvitationTeacherActions.request,
  props<{ organizationId: string; invitationId: string }>(),
);

export const deleteInvitationTeacherLoadSuccess = createAction(
  deleteInvitationTeacherActions.requestSuccess,
);

export const deleteInvitationTeacherLoadFail = createAction(
  deleteInvitationTeacherActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
