import { ComponentRef, Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { tap } from "rxjs/operators";
import { TooltipComponent } from "../../blocks/tooltip/tooltip.component";
import {
  ModalContext,
  ModalContextService,
} from "../modal-context/modal-context.service";

@Injectable({
  providedIn: "root",
})
export class TooltipService {
  private currentContext: ModalContext<TooltipComponent>;
  private ref: ComponentRef<TooltipComponent>;

  contextSubject$: ReplaySubject<ModalContext<TooltipComponent>> =
    new ReplaySubject();

  constructor(private modalContext: ModalContextService) {
    this.createContext();
  }

  private createContext(): void {
    this.contextSubject$.next(
      this.modalContext.createContext(TooltipComponent)
    );
  }

  get context(): Observable<ModalContext<TooltipComponent>> {
    return this.contextSubject$.asObservable().pipe(
      tap((context) => {
        if (!this.currentContext) {
          this.currentContext = context;
          this.ref = this.currentContext.portalHost.attach(
            this.currentContext.portal
          );
        }
      })
    );
  }

  get componentRef(): ComponentRef<TooltipComponent> {
    return this.ref;
  }
}
