import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'li[pill], pill',
  template: `
    <span class="pill d-inline-flex align-items-center">
      <span class="pill-text">{{text}}</span> 
      <span *ngIf="showDelete" class="pill-delete typcn typcn-times" (click)="delete()"></span>
    </span>
  `,
  styleUrls: ['./pill.component.scss'],
  host: {
    class: 'd-inline-flex'
  }
})
export class PillComponent {
  @Input() text = '';
  @Input() showDelete = false;
  @Output() onDelete: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  delete() {
    this.onDelete.emit(this.text);
  }
}
