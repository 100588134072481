import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { AdminService } from "src/app/shared/services/admin/admin.service";
import {
  courseTeachersListLoad,
  courseTeachersListLoadFail,
  courseTeachersListLoadSuccess,
} from "../../actions/admin/course-teachers.actions";
import { CourseTeachers } from "src/app/shared/interfaces/orgadmin/course-teachers";

@Injectable()
export class CourseTeachersEffect {
  getInivitedTeachersList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(courseTeachersListLoad),
      switchMap((param) => {
        return this.adminService
          .getSingleSubscription(param.organizationId, param.subId)
          .pipe(
            map((res: CourseTeachers) =>
              courseTeachersListLoadSuccess({ teachersList: res }),
            ),
            catchError(({ error }) => of(courseTeachersListLoadFail(error))),
          );
      }),
    ),
  );

  constructor(
    private adminService: AdminService,
    private actions$: Actions,
  ) {}
}
