<div class="card-container">
  <div class="d-flex justify-content-center align-items-center pt-2">
    <img
      class="card-container__logo"
      src="assets/images/logo/logo.svg"
      alt="{{ 'GLOBAL.NAVBAR.APP' | translate }}"
    />
  </div>

  <div class="text-center pt-4 pb-2">
    <p class="card-container__subtitle-color">
      {{ "COURSES.CERTIFICATES.CERTIFICATE" | translate | uppercase }}
    </p>
    <h5 class="card-container__title-color">
      {{ certificate.course_name }}
    </h5>
  </div>
  <div class="text-center">
    <p class="card-container__subtitle-color">
      {{ "COURSES.CERTIFICATES.RELEASE_DATE" | translate }}
      {{ certificate.date | date: { format: "YYYY-MM-DD" } }}
    </p>
  </div>
  <div
    class="d-flex justify-content-center pt-4"
    (click)="certificateUrl(certificate.url)"
  >
    <span
      class="card-container__download-title card-container__download-file pr-3 pb-2"
      >{{ "COURSES.COURSE.EXERCISE.ANSWERS.DOWNLOAD" | translate }}</span
    >
    <icon
      class="card-container__download-icon card-container__download-file"
      set="uil"
      name="download-alt"
    />
  </div>
</div>
