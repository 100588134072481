import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { Guid } from "src/app/shared/types/guid";
import * as fromUI from "src/app/store/actions/ui.actions";
import * as fromUIStore from "src/app/store/reducers/ui.reducer";
import * as fromCourse from "src/app/store/actions/course.actions";
import * as fromCourseWS from "src/app/store/actions/course.websocket.actions";
import * as courseSelectors from "src/app/store/selectors/course.selectors";
import { CourseSelectorsState } from "src/app/store/selectors/course.selectors";
import { AnswerPayload } from "../../../interfaces/answer-payload";
import { Exercise } from "../../../interfaces/exercise";
import { ExerciseControl } from "../../../interfaces/exercise-control";
import { HelpRequest } from "../../../interfaces/help-request";
import { ExerciseMark } from "src/app/shared/enums/exercise-mark";
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  Observable,
  of,
  Subject,
  Subscription,
} from "rxjs";
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
} from "rxjs/operators";
import { Highlighter } from "src/app/shared/interfaces/highlighter";
import { ExerciseSelectionParams } from "src/app/shared/interfaces/exercise-selection";
import { AnswerType } from "src/app/shared/types/answer-type";
import { Router } from "@angular/router";
import { ExerciseStatus } from "../../../enums/exercise-status";
import { ExerciseSection } from "../../../enums/exercise-section";
import { CourseExerciseFileUploadModalComponent } from "../../modals/course-exercise-file-upload-modal/course-exercise-file-upload-modal.component";
import { ModalService } from "../../../services/modal/modal.service";
import { CourseExerciseFileUploadModalDataService } from "../../modals/course-exercise-file-upload-modal/services/course-exercise-file-upload-modal-data.service";
import { CodeConfigFile } from "../../../interfaces/code-config-file";
import { CourseExerciseAnswerListModalComponent } from "../../modals/course-exercise-answer-list-modal/course-exercise-answer-list-modal.component";
import { uniqueComponentId } from "../../../utils/unique-component-id";
import { StudentExerciseFileUploadApiService } from "../../../services/exercise/student-exercise-file-upload-api.service";
import { UploadedFileModel } from "../course-exercise-student-upload/interfaces/uploaded-file-model";
import { isEmpty, isNil, orderBy } from "lodash-es";
import { Nillable } from "../../../types/nillable";
import { TeacherExerciseFileUploadApiService } from "../../../services/exercise/teacher-exercise-file-upload-api.service";
import { CourseExerciseAnswerListModalDataService } from "../../modals/course-exercise-answer-list-modal/services/course-exercise-answer-list-modal-data.service";
import { AnswerResponse } from "../../../interfaces/answer-response";
import { FileService } from "../../../services/file/file.service";
import { S3ApiService } from "../../../services/s3/s3-api.service";
import { CourseExerciseRefreshService } from "./services/course-exercise-refresh.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { flaggedExerciseLoad } from "src/app/store/actions/flagged-exercises/create-flagged-exercise.actions";
import { cancelflaggedExerciseLoad } from "src/app/store/actions/flagged-exercises/cancel-flagged-exercise.actions";
import { ConfirmationModalComponent } from "../../modals/confirmation-modal/confirmation-modal.component";
import { TutorialTeacherSteps } from "src/app/shared/enums/tutorial-steps";
import * as fromTutorial from "src/app/store/reducers/tutorial.reducer";
import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpRequest,
} from "@angular/common/http";
import { CourseExerciseHelpComponent } from "../course-exercise-help/course-exercise-help.component";
import * as fromCourseStore from "src/app/store/reducers/course.reducer";
import { RequestStatus } from "src/app/shared/interfaces/request-status";
import { CreateFlaggedExerciseState } from "src/app/store/reducers/flagged-exercises/create-flagged-exercise.reducer";
import { CancelFlaggedExerciseState } from "src/app/store/reducers/flagged-exercises/cancel-flagged-exercise.reduer";
import { EditorOptions } from "src/app/modules/ide/types/editor-options";

const MARK_DELAY_MS = 4000;
const EXPIRY_DEBOUNDE_MS = 1000;

@Component({
  selector: "course-exercise-listbox-element-content",
  templateUrl: "./course-exercise-listbox-element-content.component.html",
  styleUrls: ["./course-exercise-listbox-element-content.component.scss"],
  providers: [
    CourseExerciseFileUploadModalDataService,
    CourseExerciseAnswerListModalDataService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseExerciseListboxElementContentComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() exercise: Exercise;
  @Input() courseId: Guid;
  @Input() activeGroup: Guid;
  @Input() topicId: Guid;

  @Input("teacher") isTeacher = false;

  @Input()
  emitters: {
    [key: string]: EventEmitter<ExerciseControl | HelpRequest | AnswerPayload>;
  };

  @ViewChild("exerciseBody")
  exerciseBody: ElementRef<HTMLDivElement>;
  @ViewChild(CourseExerciseHelpComponent)
  courseExerciseHelpComponent!: CourseExerciseHelpComponent;

  isSelecting = false;
  isTogglingHelp = false;
  isTogglingVideo = false;
  isTogglingHistory = false;
  isTogglingSelectedAnswers = false;
  isTogglingCorrectAnswers = false;
  isExerciseSolution = false;
  highlighterSubscription: Subscription;
  expirySubscription: Subscription;
  mark: ExerciseMark = ExerciseMark.None;
  currentFlaggedExerciseId: string;

  editorOptions: EditorOptions = {
    theme: "vs-dark",
    language: "plaintext",
    automaticLayout: true,
    readOnly: true,
  };

  isToggled$: Observable<boolean> = this.store
    .select((state) => state.ui.toggledExercises)
    .pipe(map((toggled) => toggled.includes(this.exercise.id)));

  bulkInProgress$: Observable<ExerciseSelectionParams> = this.store.select(
    (state) => {
      return (
        state[this.isTeacher ? "teacher" : "student"].singleCourse.currentTopic
          .bulkInProgress || {}
      );
    },
  );

  runningExerciseStatus$: Observable<RequestStatus> = this.store.select(
    (state) => state.courseState.requestStatus,
  );

  createflaggedExercise$: Observable<CreateFlaggedExerciseState> =
    this.store.select((state) => state.createFlaggedExercise);

  cancelFlaggedExercise$: Observable<CancelFlaggedExerciseState> =
    this.store.select((state) => state.cancelFlaggedExercise);

  readonly uploadedFile$ = new BehaviorSubject<Nillable<UploadedFileModel>>(
    null,
  );
  readonly studentAnswers$ = new BehaviorSubject<AnswerResponse[]>([]);
  readonly teacherAnswersCount$ = this.exerciseAnswerListModalData.data$.pipe(
    map((collection) => collection.length),
  );
  private cancelPendingRequests = new Subject<void>();

  tutorial$: Observable<TutorialTeacherSteps> = this.store.select(
    (state) => state.tutorial.tutorial_step,
  );

  TutorialTeacherSteps = TutorialTeacherSteps;
  AnswerType: typeof AnswerType = AnswerType;
  expirySubject$: Subject<boolean> = new Subject();

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private readonly store: Store<
      {
        ui: fromUIStore.UIState;
        tutorial: fromTutorial.TutorialState;
        courseState: fromCourseStore.CourseState;
        createFlaggedExercise: CreateFlaggedExerciseState;
        cancelFlaggedExercise: CancelFlaggedExerciseState;
      } & CourseSelectorsState
    >,
    private http: HttpClient,
    private readonly cd: ChangeDetectorRef,
    private readonly router: Router,
    private readonly modal: ModalService,
    private readonly exerciseFileUploadModal: CourseExerciseFileUploadModalDataService,
    private readonly exerciseAnswerListModalData: CourseExerciseAnswerListModalDataService,
    private readonly studentExerciseAnswerApi: StudentExerciseFileUploadApiService,
    private readonly teacherExerciseAnswerApi: TeacherExerciseFileUploadApiService,
    private readonly s3Api: S3ApiService,
    private readonly fileService: FileService,
    private readonly injector: Injector,
    private readonly courseExerciseRefreshService: CourseExerciseRefreshService,
  ) {}

  get isOn(): boolean {
    return this.exercise.available || this.isTeacher;
  }

  get isOnboarding(): boolean {
    return this.router.url.includes("onboarding");
  }

  ngOnInit(): void {
    this.listenToUploadedFileChange();
    this.listenToExerciseRefresh();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setToggle();
    this.isSelecting = false;
    this.isTogglingHelp = false;
    this.isTogglingVideo = false;
    this.isTogglingHistory = false;
    this.isTogglingSelectedAnswers = false;
    this.isTogglingCorrectAnswers = false;
    if (changes.exercise && changes.exercise.currentValue) {
      this.mark = changes.exercise.currentValue.mark;
    }
    this.expirySubject$.next();
  }

  ngAfterViewInit(): void {
    this.scrollToView();
    this.runExpiryMark();
    this.fetchAnswerListForTeacher();
    this.fetchAnswerListForStudent();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new fromUI.ToggleExerciseOff(this.exercise.id));
    this.highlighterSubscription.unsubscribe();
    this.expirySubscription.unsubscribe();

    this.cancelPendingRequests.next();
    this.cancelPendingRequests.complete();
    this.cancelPendingRequests = new Subject<void>();
  }

  get isIndividualExercise(): boolean {
    return this.exercise.section === ExerciseSection.INDIVIDUAL;
  }

  get isAnswerTypeFileUpload(): boolean {
    const { answer_type } = this.exercise;

    return answer_type === AnswerType.ANSWER_TYPE_FILE_UPLOAD;
  }

  get canShowNoneControlButtons(): boolean {
    const { answer_type, requires_laboratory } = this.exercise;

    return answer_type === AnswerType.ANSWER_TYPE_NONE && !requires_laboratory;
  }

  get canShowIDEControlButtons(): boolean {
    const { answer_type } = this.exercise;

    return [
      AnswerType.ANSWER_TYPE_CODE,
      AnswerType.ANSWER_TYPE_UPLOAD,
    ].includes(answer_type);
  }

  get canShowExerciseAnswer(): boolean {
    const { status, answer_type } = this.exercise;

    return answer_type === AnswerType.ANSWER_TYPE_FLAG && status === "started";
  }

  get canShowQuizControlButtons(): boolean {
    const { answer_type } = this.exercise;

    return answer_type === AnswerType.ANSWER_TYPE_QUIZ;
  }

  get canShowNoneControlButtonsInStartedExercise(): boolean {
    const { answer_type, requires_laboratory, status } = this.exercise;

    return (
      answer_type === AnswerType.ANSWER_TYPE_NONE &&
      requires_laboratory &&
      status === "started"
    );
  }

  get status(): Observable<ExerciseStatus> {
    return combineLatest([of(this.exercise), this.uploadedFile$]).pipe(
      map(([exercise, uploadedFile]) => {
        if (exercise.status === "started" || uploadedFile?.review === null) {
          return ExerciseStatus.STARTED;
        }

        if (uploadedFile?.review === false) {
          return ExerciseStatus.FAILED;
        }

        if (exercise.passed) {
          return ExerciseStatus.COMPLETED;
        }

        if (this.isIndividualExercise) {
          return ExerciseStatus.INDIVIDUAL;
        }

        return ExerciseStatus.NEW;
      }),
    );
  }

  private scrollToView() {
    this.highlighterSubscription = this.store
      .select(courseSelectors.selectCourseState)
      .pipe(
        map(
          (courseState) =>
            courseState.highlighter || { id: null, markType: null },
        ),
        tap((highlighter) => highlighter),
        distinctUntilChanged((x, y) => {
          return x.id === y.id;
        }),
      )
      .subscribe((highlighter: Highlighter) => {
        if (highlighter) {
          if (this.exercise.id === highlighter.id) {
            this.mark = highlighter.markType;
            this.exerciseBody.nativeElement.scrollIntoView();
            this.cd.detectChanges();
          }
        }

        this.expirySubject$.next();
      });
  }

  private runExpiryMark() {
    this.expirySubscription = this.expirySubject$
      .pipe(debounceTime(EXPIRY_DEBOUNDE_MS), delay(MARK_DELAY_MS))
      .subscribe(() => {
        this.mark = ExerciseMark.None;
        this.store.dispatch(new fromCourseWS.HighlightExercise(null));
      });
  }

  private setToggle(): void {
    if (
      this.exercise.status === "started" ||
      this.exercise.help ||
      this.exercise.video_help
    ) {
      this.store.dispatch(new fromUI.ToggleExerciseOn(this.exercise.id));
    }
  }

  toggle(toggleState): void {
    if (toggleState) {
      this.store.dispatch(new fromUI.ToggleExerciseOff(this.exercise.id));

      return;
    }

    this.store.dispatch(new fromUI.ToggleExerciseOn(this.exercise.id));
  }

  start(event: MouseEvent): void {
    event.preventDefault();
    event.stopImmediatePropagation();

    this.emitters["startExercise"].emit({
      CourseId: this.courseId,
      GroupId: this.activeGroup,
      TopicId: this.topicId,
      ExerciseId: this.exercise.id,
      answer_type: this.exercise.answer_type,
      requires_laboratory: this.exercise.requires_laboratory,
      requires_vpn: this.exercise.requires_vpn,
    });
  }

  stop(event: MouseEvent): void {
    event.preventDefault();
    this.emitters["stopExercise"].emit({
      CourseId: this.courseId,
      GroupId: this.activeGroup,
      TopicId: this.topicId,
      ExerciseId: this.exercise.id,
    });
  }

  answer(event: MouseEvent): void {
    event.preventDefault();

    this.emitters["answer"].emit({
      CourseId: this.courseId,
      ExerciseId: this.exercise.id,
      GroupId: this.isTeacher ? null : this.activeGroup,
      TopicId: this.topicId,
      request: { answer: null },
      was_passed_before: this.exercise.was_passed_before,
    });
  }

  help(isClicked: string | null): void {
    if (isClicked) {
      const modal = this.modal.showModal(
        {
          modalTitle: "COURSES.COURSE.EXERCISE.TITLE_CANCEL_FLAGGED_EXERCISE",
          message: "COURSES.COURSE.EXERCISE.CANCEL_FLAGGED_EXERCISE",
          confirmButtonText: "GLOBAL.CONFIRM",
          cancelButtonText: "GLOBAL.CANCEL_BUTTON",
          confirmButtonColor: "primary",
        },
        ConfirmationModalComponent,
        "ARCHIVE_GROUP",
      ) as ConfirmationModalComponent;

      modal.onConfirm
        .pipe(
          tap(() => {
            this.store.dispatch(
              cancelflaggedExerciseLoad({
                courseId: this.courseId,
                groupId: this.activeGroup,
                exerciseId: this.exercise.id,
                flagId: isClicked,
              }),
            );
          }),
          switchMap(() => this.cancelFlaggedExercise$),
          map((cancelFlaggedExercise) => {
            const { status, exercise } = cancelFlaggedExercise;

            if (status === "PENDING") {
              this.currentFlaggedExerciseId = exercise?.exercise || null;
            }

            if (status === "SUCCESS" && exercise) {
              const isCurrentExercise = this.exercise.id === exercise.exercise;

              if (isCurrentExercise) {
                this.currentFlaggedExerciseId = null;

                this.exercise = {
                  ...this.exercise,
                  help_requested: null,
                };
              }
            }
          }),
        )
        .subscribe();
    } else {
      this.store.dispatch(
        flaggedExerciseLoad({
          courseId: this.courseId,
          groupId: this.activeGroup,
          exerciseId: this.exercise.id,
        }),
      );

      this.createflaggedExercise$
        .pipe(
          tap(({ status, exercise }) => {
            if (status === "PENDING") {
              this.currentFlaggedExerciseId = exercise?.exercise || null;
            }

            if (status === "SUCCESS" && exercise) {
              const isCurrentExercise = this.exercise.id === exercise.exercise;

              if (isCurrentExercise) {
                this.currentFlaggedExerciseId = null;
                this.exercise = {
                  ...this.exercise,
                  help_requested: exercise.id || null,
                };
              }
            }
          }),
        )
        .subscribe();
    }
  }

  selectUnselect(event: MouseEvent): void {
    event.preventDefault();
    event.stopImmediatePropagation();

    if (this.isSelecting) {
      return;
    }

    this.isSelecting = true;

    this.store.dispatch(
      new fromCourse.SelectUnselectExercise({
        selection: {
          CourseId: this.courseId,
          GroupId: this.activeGroup,
          TopicId: this.topicId,
          exercise: this.exercise,
        },
        selectionParam: { selected: !this.exercise.available },
      }),
    );
  }

  selectUnselectHelp(event: MouseEvent): void {
    event.preventDefault();
    event.stopImmediatePropagation();

    if (this.isTogglingHelp) {
      return;
    }

    this.isTogglingHelp = true;

    this.store.dispatch(
      new fromCourse.SelectUnselectExercise({
        selection: {
          CourseId: this.courseId,
          GroupId: this.activeGroup,
          TopicId: this.topicId,
          exercise: this.exercise,
        },
        selectionParam: {
          enabled_help: !this.exercise.has_help,
        },
      }),
    );
  }

  selectUnselectVideo(event: MouseEvent): void {
    event.preventDefault();
    event.stopImmediatePropagation();

    if (this.isTogglingVideo) {
      return;
    }

    this.isTogglingVideo = true;

    this.store.dispatch(
      new fromCourse.SelectUnselectExercise({
        selection: {
          CourseId: this.courseId,
          GroupId: this.activeGroup,
          TopicId: this.topicId,
          exercise: this.exercise,
        },
        selectionParam: {
          enabled_video: !this.exercise.has_video,
        },
      }),
    );
  }

  selectUnselectHistory(event: MouseEvent): void {
    event.preventDefault();
    event.stopImmediatePropagation();

    if (!this.isTogglingHistory) {
      this.isTogglingHistory = true;

      this.store.dispatch(
        new fromCourse.SelectUnselectExercise({
          selection: {
            CourseId: this.courseId,
            GroupId: this.activeGroup,
            TopicId: this.topicId,
            exercise: this.exercise,
          },
          selectionParam: {
            new_quiz_properties: {
              has_solutions: !this.exercise.quiz_properties.has_solutions,
              show_selected_answers:
                this.exercise.quiz_properties.show_selected_answers,
              show_correct_answers:
                this.exercise.quiz_properties.show_correct_answers,
            },
          },
        }),
      );
    }
  }

  selectUnselectSelectedAnswers(): void {
    if (!this.isTogglingSelectedAnswers) {
      this.isTogglingSelectedAnswers = true;

      this.store.dispatch(
        new fromCourse.SelectUnselectExercise({
          selection: {
            CourseId: this.courseId,
            GroupId: this.activeGroup,
            TopicId: this.topicId,
            exercise: this.exercise,
          },
          selectionParam: {
            new_quiz_properties: {
              has_solutions: this.exercise.quiz_properties.has_solutions,
              show_selected_answers:
                !this.exercise.quiz_properties.show_selected_answers,
              show_correct_answers:
                this.exercise.quiz_properties.show_correct_answers,
            },
          },
        }),
      );
    }
  }

  selectUnselectCorrectAnswers(): void {
    if (!this.isTogglingCorrectAnswers) {
      this.isTogglingCorrectAnswers = true;

      this.store.dispatch(
        new fromCourse.SelectUnselectExercise({
          selection: {
            CourseId: this.courseId,
            GroupId: this.activeGroup,
            TopicId: this.topicId,
            exercise: this.exercise,
          },
          selectionParam: {
            new_quiz_properties: {
              has_solutions: this.exercise.quiz_properties.has_solutions,
              show_selected_answers:
                this.exercise.quiz_properties.show_selected_answers,
              show_correct_answers:
                !this.exercise.quiz_properties.show_correct_answers,
            },
          },
        }),
      );
    }
  }

  getAppearance(toggleState): { [key: string]: boolean } {
    return {
      toggled: this.isOn && toggleState,
      added: this.mark === ExerciseMark.Added,
      deleting: this.mark === ExerciseMark.Deleting,
      highlighted: this.mark === ExerciseMark.Highlighted,
      off: !this.isOn,
    };
  }

  handleDownload(data: { id: Guid; filename: string }): void {
    this.studentExerciseAnswerApi
      .download({
        courseId: this.courseId,
        exerciseId: this.exercise.id,
        groupId: this.activeGroup,
        fileId: data.id,
      })
      .pipe(
        tap((response) => {
          if (response.s3_url) {
            this.fileService.downloadS3File(response.s3_url, response.filename);
          }
        }),
      )
      .subscribe();
  }

  handleUploadFileClick(exercise: Exercise): void {
    this.modal.showModal(
      {
        modalTitle: "GLOBAL.UPLOAD_FILE",
        exerciseId: exercise.id,
        courseId: this.courseId,
        groupId: this.activeGroup,
      },
      CourseExerciseFileUploadModalComponent,
      uniqueComponentId(),
      this.injector,
    );
  }

  handleSendSolution(): void {
    this.uploadedFile$
      .pipe(
        take(1),
        switchMap((model) => {
          return this.studentExerciseAnswerApi
            .s3({
              courseId: this.courseId,
              exerciseId: this.exercise.id,
              groupId: this.activeGroup,
              filename: model.filename,
            })
            .pipe(
              switchMap((response) => {
                const url = response.s3_url.url;
                const file = new Blob([model.content], {
                  type: "application/octet-stream",
                });
                const formData = new FormData();

                Object.entries(response.s3_url.fields).forEach(
                  ([fieldName, fieldValue]: [string, unknown]) => {
                    formData.append(fieldName, fieldValue.toString());
                  },
                );
                formData.append("file", file, model.filename);

                const req = new HttpRequest("POST", url, formData, {
                  reportProgress: true,
                });

                return this.http.request(req).pipe(
                  takeUntil(this.cancelPendingRequests),
                  mergeMap((event: HttpEvent<any>) => {
                    switch (event.type) {
                      case HttpEventType.UploadProgress:
                        this.uploadedFile$.next({
                          id: response.id,
                          filename: response.filename,
                          review: response.review,
                          progress: event.total
                            ? Math.round((100 * event.loaded) / event.total)
                            : 0,
                        });
                        return EMPTY;
                      case HttpEventType.Response:
                        return this.studentExerciseAnswerApi
                          .uploadConfirmation({
                            courseId: this.courseId,
                            exerciseId: this.exercise.id,
                            groupId: this.activeGroup,
                            fileId: response.id,
                          })
                          .pipe(tap(() => this.fetchAnswerListForStudent()));
                      default:
                        this.uploadedFile$.next({
                          id: response.id,
                          filename: response.filename,
                          review: response.review,
                          progress: 0,
                        });
                        return EMPTY;
                    }
                  }),
                );
              }),
            );
        }),
      )
      .subscribe();
  }

  handleShowAnswerListModal(): void {
    this.modal.showModal(
      {
        modalTitle: "GLOBAL.UPLOAD_FILE",
        exerciseId: this.exercise.id,
        courseId: this.courseId,
        groupId: this.activeGroup,
      },
      CourseExerciseAnswerListModalComponent,
      uniqueComponentId(),
      this.injector,
    );
  }

  handleClearEvent(): void {
    this.exerciseFileUploadModal.clear();
  }

  private listenToUploadedFileChange(): void {
    this.exerciseFileUploadModal.data$
      .pipe(
        map((files: CodeConfigFile[]) => {
          const [first] = files;

          if (isNil(first)) {
            return null;
          }

          return { filename: first.filename, content: first.content };
        }),
      )
      .subscribe((res) => {
        this.uploadedFile$.next(res);
      });
  }

  private listenToExerciseRefresh(): void {
    this.courseExerciseRefreshService.refresh$
      .pipe(
        filter(Boolean),
        filter((exercise: Exercise) => this.exercise.id === exercise.id),
        filter(Boolean),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => this.fetchAnswerListForStudent());
  }

  private fetchAnswerListForTeacher(): void {
    if (!this.isAnswerTypeFileUpload || !this.isTeacher || !this.activeGroup) {
      return;
    }

    this.teacherExerciseAnswerApi
      .collection({
        exerciseId: this.exercise.id,
        courseId: this.courseId,
        groupId: this.activeGroup,
      })
      .subscribe((response) =>
        this.exerciseAnswerListModalData.patch(response),
      );
  }

  private fetchAnswerListForStudent(): void {
    if (!this.isAnswerTypeFileUpload || this.isTeacher) {
      return;
    }

    this.studentExerciseAnswerApi
      .collection({
        courseId: this.courseId,
        exerciseId: this.exercise.id,
        groupId: this.activeGroup,
      })
      .pipe(
        tap((collection) => {
          if (isEmpty(collection)) {
            this.uploadedFile$.next(null);
          }
        }),
        filter((collection) => !isEmpty(collection)),
      )
      .subscribe((collection) => {
        const [first] = orderBy(collection, "uploaded_at", "desc");
        const { filename, id, review } = first;

        this.studentAnswers$.next(collection);
        this.uploadedFile$.next({ id, filename, review, progress: null });
      });
  }

  handleCancelUpload(file: UploadedFileModel): void {
    this.cancelPendingRequests.next();
    this.cancelPendingRequests.complete();
    this.cancelPendingRequests = new Subject<void>();

    this.studentExerciseAnswerApi
      .cancelUpload({
        courseId: this.courseId,
        exerciseId: this.exercise.id,
        groupId: this.activeGroup,
        fileId: file.id,
      })
      .subscribe(() => {
        this.fetchAnswerListForStudent();
      });
  }

  callVideoHelpClick(): void {
    this.courseExerciseHelpComponent.handleVideoHelpClick();
  }

  callHelpClick(): void {
    this.courseExerciseHelpComponent.handleHelpClick();
  }

  viewExerciseSolution(): void {
    this.isExerciseSolution = !this.isExerciseSolution;
  }
}
