import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceNewLine'
})
export class ReplaceNewLinePipe implements PipeTransform {
  transform(value: any): any {
    return value.replace(/\n/g, '<br />');
  }
}
