import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "icon-input",
  templateUrl: "./icon-input.component.html",
  styleUrls: ["./icon-input.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconInputComponent {
  @Input() icon: string = "";
  @Input() set: string = "typcn";
  @Input() placeholder: string;
}
