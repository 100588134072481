import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { InvitedStudentsPerTeacher } from "src/app/shared/interfaces/orgadmin/invited-students";

export const coursePrefix = "[Invited Students List]";

const invitedStudentsListActions = {
  request: `${coursePrefix} Load`,
  requestSuccess: `${coursePrefix} Load success`,
  requestFail: `${coursePrefix} Load fail`,
};

export const invitedStudentsListLoad = createAction(
  invitedStudentsListActions.request,
  props<{ organizationId: string; courseId: string }>(),
);

export const invitedStudentsListLoadSuccess = createAction(
  invitedStudentsListActions.requestSuccess,
  props<{ invitedStudents: InvitedStudentsPerTeacher[] }>(),
);

export const invitedStudentsListLoadFail = createAction(
  invitedStudentsListActions.requestFail,
  props<{ error: string }>(),
);
