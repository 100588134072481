import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, filter, switchMap, take, tap } from "rxjs/operators";
import { Member } from "src/app/shared/interfaces/member";
import * as fromAuth from "src/app/store/actions/auth.actions";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";

@Injectable({
  providedIn: "root",
})
export class AdminGuard {
  constructor(
    private store: Store<{ account: fromAccountStore.AccountState }>,
  ) {}

  // TODO make these guards emit a value, don't rely on "token failed" - large change
  canLoad(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.store
      .select((state) => state.account.member)
      .pipe(
        tap((member: Member) => {
          if (member === null) {
            this.store.dispatch({ type: fromAuth.READ_TOKEN });
          }
        }),
        filter((member: Member) => member && member.is_orgadmin),
        take(1),
        switchMap(() => of(true)),
        catchError(() => of(false)),
      );
  }
}
