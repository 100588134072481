<tile>
  <carousel
    [elements]="limits"
    [elementTemplate]="carouselContentTemplateRef"
  />

  <ng-template #carouselContentTemplateRef let-limit="element">
    <organization-limit-widget-carousel-content [limit]="limit" />
  </ng-template>
</tile>
