<div>
  <hint class="w-100">
    {{ "COURSES.COURSE.INDIVIDUAL_GRADE_INFO" | translate }}
  </hint>

  <div class="pt-4 pb-4">
    <button
      *ngIf="!displayGradeCreator"
      [disabled]="currentEditingId || disabled"
      class="select-button"
      (click)="handleSetGradeCreator(true)"
    >
      <icon set="uil" name="plus" />
      <span class="button-title">
        {{ "COURSES.COURSE.ADD_GRADE" | translate }}
      </span>
    </button>
  </div>

  <div class="pb-3" *ngIf="displayGradeCreator">
    <individual-grade-bar
      [creatorMode]="true"
      [isEditing]="true"
      (acceptEmitter)="
        handleCreatePartialGrade($event); handleSetGradeCreator(false)
      "
      (cancelEmitter)="handleSetGradeCreator(false)"
    />
  </div>

  <div class="pb-3" *ngFor="let individualGrade of individualMarks">
    <individual-grade-bar
      [individualGrade]="individualGrade"
      [isEditing]="currentEditingId === individualGrade.id"
      (acceptEmitter)="handleCreatePartialGrade($event)"
      (editEmitter)="handleEditGrade(individualGrade.id)"
      (cancelEmitter)="setSelectedExercises($event)"
    />
  </div>
</div>
