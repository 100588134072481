import { Component, Input } from "@angular/core";

@Component({
  selector: "course-exercise-difficulty",
  templateUrl: "./course-exercise-difficulty.component.html",
  styleUrls: ["./course-exercise-difficulty.component.scss"],
})
export class CourseExerciseDifficultyComponent {
  @Input() difficulty: number;
}
