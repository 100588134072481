import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { AvatarComponent } from "./blocks/avatar/avatar.component";
import { BadgeComponent } from "./blocks/badge/badge.component";
import { BoxHeaderComponent } from "./blocks/box/box-header/box-header.component";
import { BoxComponent } from "./blocks/box/box.component";
import { CountdownComponent } from "./blocks/countdown/countdown.component";
import { ExerciseStatusComponent } from "./blocks/exercise-status/exercise-status.component";
import { HourPickerComponent } from "./blocks/hour-picker/hour-picker.component";
import { IconInputComponent } from "./blocks/icon-input/icon-input.component";
import { IndicatorComponent } from "./blocks/indicator/indicator.component";
import { ListProgressComponent } from "./blocks/list-progress/list-progress.component";
import { LoadingBoxComponent } from "./blocks/loading-box/loading-box.component";
import { LoadingSpinnerComponent } from "./blocks/loading-spinner/loading-spinner.component";
import { PageHeaderComponent } from "./blocks/page-header/page-header.component";
import { PhotoComponent } from "./blocks/photo/photo.component";
import { PillComponent } from "./blocks/pill/pill.component";
import { PillsInputComponent } from "./blocks/pills-input/pills-input.component";
import { PillsComponent } from "./blocks/pills/pills.component";
import { PopupMenuComponent } from "./blocks/popup-menu/popup-menu.component";
import { RichTextComponent } from "./blocks/rich-text/rich-text.component";
import { RoundButtonComponent } from "./blocks/round-button/round-button.component";
import { SimpleElementComponent } from "./blocks/simple-element/simple-element.component";
import { SimpleListItemComponent } from "./blocks/simple-list-item/simple-list-item.component";
import { ValidationMessageComponent } from "./blocks/validation-message/validation-message.component";
import { ValidationTextComponent } from "./blocks/validation-text/validation-text.component";
import { CourseExerciseAnswerComponent } from "./components/course/course-exercise-answer/course-exercise-answer.component";
import { CourseExerciseCompetencesComponent } from "./components/course/course-exercise-competences/course-exercise-competences.component";
import { CourseExerciseDescComponent } from "./components/course/course-exercise-desc/course-exercise-desc.component";
import { CourseExerciseHelpComponent } from "./components/course/course-exercise-help/course-exercise-help.component";
import { CourseExerciseStatComponent } from "./components/course/course-exercise-stat/course-exercise-stat.component";
import { CourseExerciseListboxElementContentComponent } from "./components/course/course-exercise-listbox-element-content/course-exercise-listbox-element-content.component";
import { CourseProgressTopicComponent } from "./components/course/course-progress-topic/course-progress-topic.component";
import { CourseProgressComponent } from "./components/course/course-progress/course-progress.component";
import { CourseTopicExercisesComponent } from "./components/course/course-topic-exercises/course-topic-exercises.component";
import { CourseTopicHeaderComponent } from "./components/course/course-topic-header/course-topic-header.component";
import { CourseTopicStatComponent } from "./components/course/course-topic-stat/course-topic-stat.component";
import { CourseTopicComponent } from "./components/course/course-topic/course-topic.component";
import { CourseListboxElementContentComponent } from "./components/course/course-listbox-element-content/course-listbox-element-content.component";
import { GroupInvitationListboxElementContentComponent } from "./components/group/group-invitation-listbox-element-content/group-invitation-listbox-element-content.component";
import { LanguagePickerComponent } from "./components/language-picker/language-picker.component";
import { ModalBoxComponent } from "./components/modal/modal-box/modal-box.component";
import { ModalOverlayComponent } from "./components/modal/modal-overlay/modal-overlay.component";
import { ModalComponent } from "./components/modal/modal.component";
import { ConfirmationModalComponent } from "./components/modals/confirmation-modal/confirmation-modal.component";
import { VpnConfigurationModalComponent } from "./components/modals/vpn-configuration-modal/vpn-configuration-modal.component";
import { WarningModalComponent } from "./components/modals/warning-modal/warning-modal.component";
import { NavbarElementComponent } from "./components/navbar/navbar-element/navbar-element.component";
import { NavbarNavElementComponent } from "./components/navbar/navbar-nav-element/navbar-nav-element.component";
import { NavbarNavComponent } from "./components/navbar/navbar-nav/navbar-nav.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { NotificationsIconComponent } from "./components/notifications/notifications-icon/notifications-icon.component";
import { NotificationComponent } from "./components/notifications/notifications-panel/notification.component";
import { NotificationsPanelComponent } from "./components/notifications/notifications-panel/notifications-panel.component";
import { PageColumnLayoutComponent } from "./components/page-column-layout/page-column-layout.component";
import { PageStandardLayoutComponent } from "./components/page-standard-layout/page-standard-layout.component";
import { ConfirmationDirective } from "./directives/confirmation/confirmation.directive";
import { HighlightDirective } from "./directives/highlight/highlight.directive";
import { PopupDirective } from "./directives/popup/popup.directive";
import { WarningModalDirective } from "./directives/warning-modal/warning-modal.directive";
import { HttpLoaderFactory } from "./factories/HttpLoaderFactory";
import { PanelPageComponent } from "./pages/panel-page/panel-page.component";
import { DayPipe } from "./pipes/day.pipe";
import { FormatHourPipe } from "./pipes/format-hour.pipe";
import { InvitationStatusPipe } from "./pipes/invitation-status.pipe";
import { IterationPipe } from "./pipes/iteration.pipe";
import { ReplaceNewLinePipe } from "./pipes/replace-new-line.pipe";
import { SanitizeContentPipe } from "./pipes/sanitize-content.pipe";
import { NotificationToastComponent } from "./components/notifications/notification-toast.component";
import { DateDifferencePipe } from "./pipes/date-difference.pipe";
import { WelcomeScreenModalComponent } from "./components/modals/welcome-screen-modal/welcome-screen-modal.component";
import { GlobalRankingPageComponent } from "./pages/global-ranking-page/global-ranking-page.component";
import { FluidTableComponent } from "./components/fluid-table/fluid-table.component";
import { RankingTableComponent } from "./components/ranking-table/ranking-table.component";
import { RecaptchaInfoComponent } from "./components/recaptcha-info/recaptcha-info.component";
import { ExerciseControlButtonsComponent } from "./components/course/exercise-control-buttons/exercise-control-buttons.component";
import { ExerciseTimerComponent } from "./components/exercise-timer/exercise-timer.component";
import { FooterComponent } from "./components/footer/footer.component";
import { ExerciseSolveConfirmModalComponent } from "./components/modals/exercise-solve-confirm-modal/exercise-solve-confirm-modal.component";
import { PointsPluralizationPipe } from "./pipes/points-pluralization.pipe";
import { StatusIconComponent } from "./blocks/status-icon/status-icon.component";
import { DropdownMenuComponent } from "./blocks/dropdown-menu/dropdown-menu.component";
import { DashboardMenuComponent } from "./components/dashboard-menu/dashboard-menu.component";
import { NavbarExerciseTimerComponent } from "./components/navbar-exercise-timer/navbar-exercise-timer.component";
import { QuizSolveConfirmModalComponent } from "./components/modals/quiz-solve-confirm-modal/quiz-solve-confirm-modal.component";
import { CookieBarComponent } from "./components/cookie-bar/cookie-bar.component";
import { NotificationsSnoozeComponent } from "./components/notifications/notifications-snooze/notifications-snooze.component";
import { NotificationsSettingsComponent } from "./components/notifications/notifications-settings/notifications-settings.component";
import { SettingGroupComponent } from "./components/notifications/notifications-settings/setting-group.component";
import { SnoozeGroupComponent } from "./components/notifications/notifications-snooze/snooze-group.component";
import { CodeCheckHistoryButtonComponent } from "./components/course/code-check-history-button/code-check-history-button.component";
import { CodeCheckHistoryModalComponent } from "./components/modals/code-check-history-modal/code-check-history-modal.component";
import { CodeCheckStatusPipe } from "./pipes/code-check-status.pipe";
import { MonacoEditorModule } from "ngx-monaco-editor";
import { DatePipe } from "./pipes/date.pipe";
import { RedeemModalComponent } from "./components/modals/redeem-modal/redeem-modal.component";
import { GroupReedemListboxElementContentComponent } from "./components/group/group-reedem-listbox-element-content/group-reedem-listbox-element-content.component";
import { SurpriseBoxComponent } from "./components/surprise-box/surprise-box.component";
import { ListPickerComponent } from "./components/list-picker/list-picker.component";
import { SliderPickerComponent } from "./components/slider-picker/slider-picker.component";
import { ProgressRingComponent } from "./blocks/progress-ring/progress-ring.component";
import { MoveStudentModalComponent } from "./components/modals/move-student-modal/move-student-modal.component";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { OrderByAvailabilityPipe } from "./pipes/order-by-availability.pipe";
import { AnnouncementsComponent } from "./components/announcements/announcements.component";
import { AnnouncementComponent } from "./components/announcement/announcement.component";
import { SwitchButtonComponent } from "./blocks/switch-button/switch-button.component";
import { TooltipDirective } from "./directives/tooltip/tooltip.directive";
import { TooltipComponent } from "./blocks/tooltip/tooltip.component";
import { FileSizePipe } from "./pipes/file-size.pipe";
import { GroupStudentMoveListboxElementContentComponent } from "./components/group/group-student-move-listbox-content-element/group-student-move-listbox-element-content.component";
import { QuizHistoryButtonComponent } from "./components/course/quiz-history-button/quiz-history-button.component";
import { QuizHistoryModalComponent } from "./components/modals/quiz-history-modal/quiz-history-modal.component";
import { CachedQuizFigureComponent } from "./blocks/cached-quiz-figure/cached-quiz-figure.component";
import { WidgetDirective } from "./directives/widget/widget.directive";
import { OrganizationLimitWidgetComponent } from "./components/widgets/organization-limit-widget/organization-limit-widget.component";
import { TileComponent } from "./blocks/tile/tile.component";
import { LimitTextComponent } from "./blocks/limit-text/limit-text.component";
import { CarouselComponent } from "./components/carousel/carousel.component";
import { DashboardLayoutComponent } from "./components/dashboard-layout/dashboard-layout.component";
import { DashboardSidebarComponent } from "./components/dashboard-sidebar/dashboard-sidebar.component";
import { SwitchButtonExpandedComponent } from "./blocks/switch-button-expanded/switch-button-expanded.component";
import { SwitchCheckboxComponent } from "./blocks/switch-checkbox/switch-checkbox.component";
import { EnvironmentTranslatePipe } from "./pipes/environment-translate.pipe";
import { HistoryModalHeaderComponent } from "./blocks/history-modal-blocks/history-modal-header/history-modal-header.component";
import { HistoryModalFrameComponent } from "./blocks/history-modal-blocks/history-modal-frame/history-modal-frame.component";
import { HistoryModalItemListComponent } from "./blocks/history-modal-blocks/history-modal-item-list/history-modal-item-list.component";
import { QuizHistoryModalItemComponent } from "./components/modals/quiz-history-modal/quiz-history-modal-item/quiz-history-modal-item.component";
import { CodeCheckHistoryModalItemComponent } from "./components/modals/code-check-history-modal/code-check-history-modal-item/code-check-history-modal-item.component";
import { ItemListModeSelectorComponent } from "./blocks/history-modal-blocks/item-list-mode-selector/item-list-mode-selector.component";
import { HistoryModalStudentsListComponent } from "./blocks/history-modal-blocks/history-modal-students-list/history-modal-students-list.component";
import { CodeCheckHistoryModalCheckTypeComponent } from "./components/modals/code-check-history-modal/code-check-history-modal-check-type/code-check-history-modal-check-type.component";
import { CodeCheckTypePipe } from "./pipes/code-check-type.pipe";
import { RankingWidgetComponent } from "./components/widgets/ranking-widget/ranking-widget.component";
import { DotsComponent } from "./blocks/dots/dots.component";
import { VpnConnectionModalComponent } from "./components/modals/vpn-connection-modal/vpn-connection-modal.component";
import { InvitationsWidgetComponent } from "./components/invitations-widget/invitations-widget.component";
import { CustomVideoPlayerComponent } from "./components/custom-video-player/custom-video-player.component";
import { ValidationHintComponent } from "./blocks/validation-hint/validation-hint.component";
import { NavbarIconComponent } from "./components/navbar-icon/navbar-icon.component";
import { HoverDirective } from "./directives/hover/hover.directive";
import { LanguagePickerButtonWithFlagLayoutComponent } from "./components/language-picker/language-picker-button-with-flag-layout/language-picker-button-with-flag-layout.component";
import { LanguagePickerLangsWithFlagLayoutComponent } from "./components/language-picker/language-picker-langs-with-flag-layout/language-picker-langs-with-flag-layout.component";
import { DefaultLanguagePickerButtonLayoutComponent } from "./components/language-picker/default-language-picker-button-layout/default-language-picker-button-layout.component";
import { DefaultLanguagePickerLangsLayoutComponent } from "./components/language-picker/default-language-picker-langs-layout/default-language-picker-langs-layout.component";
import { GroupTeacherNameComponent } from "./components/group/group-teacher-name/group-teacher-name.component";
import { NavbarExitExerciseButtonComponent } from "./components/navbar-exit-exercise-button/navbar-exit-exercise-button.component";
import { ExerciseIdeControlButtonsComponent } from "./components/course/exercise-ide-control-buttons/exercise-ide-control-buttons.component";
import { ExerciseQuizControlButtonsComponent } from "./components/course/exercise-quiz-control-buttons/exercise-quiz-control-buttons.component";
import { ExerciseNoneControlButtonsComponent } from "./components/course/exercise-none-control-buttons/exercise-none-control-buttons.component";
import { CustomVideoPlayerDirective } from "./directives/custom-video-player/custom-video-player.directive";
import { SectionTitleComponent } from "./blocks/section-title/section-title.component";
import { GroupListboxElementContentComponent } from "./components/group/group-listbox-element-content/group-listbox-element-content.component";
import { DividerComponent } from "./blocks/divider/divider.component";
import { ListboxComponent } from "./blocks/listbox/listbox.component";
import { ListboxElementComponent } from "./blocks/listbox-element/listbox-element.component";
import { StudentsCountBadgeComponent } from "./blocks/students-count-badge/students-count-badge.component";
import { ResolvedExercisesCountBadgeComponent } from "./blocks/resolved-exercises-count-badge/resolved-exercises-count-badge.component";
import { LogoComponent } from "./components/logo/logo.component";
import { CourseExerciseDifficultyComponent } from "./components/course/course-exercise-difficulty/course-exercise-difficulty.component";
import { ExerciseStatusCssClassPipe } from "./pipes/exercise-status-css-class.pipe";
import { GroupListboxEmptyComponent } from "./components/group/group-listbox-empty/group-listbox-empty.component";
import { ListboxEmptyComponent } from "./blocks/listbox-empty/listbox-empty.component";
import { GroupInvitationListboxEmptyComponent } from "./components/group/group-invitation-listbox-empty/group-invitation-listbox-empty.component";
import { CourseListboxEmptyComponent } from "./components/course/course-listbox-empty/course-listbox-empty.component";
import { OrganizationLimitWidgetCarouselContentComponent } from "./components/widgets/organization-limit-widget/organization-limit-widget-carousel-content/organization-limit-widget-carousel-content.component";
import { KeyIconComponent } from "./blocks/key-icon/key-icon.component";
import { PopupTriggerLabelWithChevronComponent } from "./blocks/popup-trigger-label-with-chevron/popup-trigger-label-with-chevron.component";
import { IconComponent } from "./blocks/icon/icon.component";
import { SelectWithPlaceholderComponent } from "./blocks/select-with-placeholder/select-with-placeholder.component";
import { SimpleDashboardWidgetComponent } from "./components/widgets/simple-dashboard-widget/simple-dashboard-widget.component";
import { GroupWidgetComponent } from "./components/widgets/group-widget/group-widget.component";
import { CourseGroupListComponent } from "./components/course/course-group-list/course-group-list.component";
import { CourseGroupListHeaderComponent } from "./components/course/course-group-list-header/course-group-list-header.component";
import { CourseGroupListActionsComponent } from "./components/course/course-group-list-actions/course-group-list-actions.component";
import { CourseGroupListEmptyComponent } from "./components/course/course-group-list-empty/course-group-list-empty.component";
import { RoundButtonClassPipe } from "./blocks/round-button/pipes/round-button-class.pipe";
import { NoDataPipe } from "./pipes/no-data.pipe";
import { DropdownComponent } from "./blocks/dropdown/dropdown.component";
import { DropdownItemComponent } from "./blocks/dropdown/components/dropdown-item/dropdown-item.component";
import { ClickOutsideDirective } from "./directives/click/click-outside.directive";
import { TabViewComponent } from "./blocks/tab-view/tab-view.component";
import { TabPanelComponent } from "./blocks/tab-view/components/tab-panel/tab-panel.component";
import { SelectButtonComponent } from "./blocks/select-button/select-button.component";
import { InputSwitchComponent } from "./blocks/input-switch/input-switch.component";
import { InputNumberComponent } from "./blocks/input-number/input-number.component";
import { StudentPassFilterFormComponent } from "../modules/teacher/components/student-pass-filter-form/student-pass-filter-form.component";
import { StudentRatingSystemFilterFormComponent } from "../modules/teacher/components/student-rating-system-filter-form/student-rating-system-filter-form.component";
import { StudentExerciseCellClassPipe } from "./components/student-stat-list-table/pipes/student-exercise-cell-class.pipe";
import { StudentExerciseGradeCellClassPipe } from "./components/student-stat-list-table/pipes/student-exercise-grade-cell-class.pipe";
import { StudentExerciseGradePipe } from "./components/student-stat-list-table/pipes/student-exercise-grade.pipe";
import { StudentExercisePercentageCellClassPipe } from "./components/student-stat-list-table/pipes/student-exercise-percentage-cell-class.pipe";
import { StudentExercisePercentagePipe } from "./components/student-stat-list-table/pipes/student-exercise-percentage.pipe";
import { StudentStatListTableComponent } from "./components/student-stat-list-table/student-stat-list-table.component";
import { OperatorLiteralPipe } from "./pipes/operator-literal.pipe";
import { HintComponent } from "./blocks/hint/hint.component";
import { NgxMaskModule } from "ngx-mask";
import { CourseExerciseFileUploadModalComponent } from "./components/modals/course-exercise-file-upload-modal/course-exercise-file-upload-modal.component";
import { FileListComponent } from "./blocks/file-upload/components/file-list/file-list.component";
import { ProgressBarComponent } from "./blocks/progress-bar/progress-bar.component";
import { FileUploadComponent } from "./blocks/file-upload/file-upload.component";
import { FormatExtensionsPipe } from "./pipes/format-extensions.pipe";
import { CourseExerciseAnswerTableComponent } from "./components/course/course-exercise-answer-table/course-exercise-answer-table.component";
import { CourseExerciseAnswerMenuComponent } from "./components/course/course-exercise-answer-menu/course-exercise-answer-menu.component";
import { ExerciseFileUploadControlButtonsComponent } from "./components/course/exercise-file-upload-control-buttons/exercise-file-upload-control-buttons.component";
import { CourseExerciseAnswerHistoryPopupMenuComponent } from "./components/course/course-exercise-answer-history-popup-menu/course-exercise-answer-history-popup-menu.component";
import { RoundBadgeComponent } from "./blocks/round-badge/round-badge.component";
import { CourseExerciseStudentUploadComponent } from "./components/course/course-exercise-student-upload/course-exercise-student-upload.component";
import { CourseExerciseAnswerListModalComponent } from "./components/modals/course-exercise-answer-list-modal/course-exercise-answer-list-modal.component";
import { AnswerStatusComponent } from "./blocks/answer-status/answer-status.component";
import { OrganizationSelectorComponent } from "./components/organization-selector/organization-selector.component";
import { OrganizationNamePipe } from "./pipes/organization-name.pipe";
import { CourseLimitWidgetComponent } from "./components/widgets/course-limit-widget/course-limit-widget.component";
import { PageToolbarComponent } from "./components/page-toolbar/page-toolbar.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { CardCourseComponent } from "./components/card-course/card-course.component";
import { TeacherDashboardGroupListboxElementContentComponent } from "./components/teacher-dashboard-group-listbox-element-content/teacher-dashboard-group-listbox-element-content.component";
import { TableComponent } from "./components/table/table.component";
import { CircleBadgeComponent } from "./blocks/circle-badge/circle-badge.component";
import { ShortNamePipe } from "./pipes/short-name.pipe";
import { StatusChipComponent } from "./blocks/status-chip/status-chip.component";
import { ColorStatusPipe } from "./pipes/color-status.pipe";
import { ExpansionPanelComponent } from "./components/expansion-panel/expansion-panel.component";
import { HighlightComponent } from "./blocks/highlight/highlight.component";
import { TutorialTextboxComponent } from "./blocks/tutorial-text-box/tutorial-text-box.component";
import { TutorialModalComponent } from "./components/modals/tutorial-modal/tutorial-modal.component";
import { ArrowPositionPipe } from "./pipes/arrow-position.pipe";
import { StudentIndividualGradesComponent } from "../modules/teacher/components/student-individual-grades/student-individual-grades.component";
import { IndividualGradeBarComponent } from "./components/individual-grade-bar/individual-grade-bar.component";
import { CardCertificateComponent } from "./components/card-certificate/card-certificate.component";
import { StudentExerciseTotalPointsPipe } from "./components/student-stat-list-table/pipes/student-exercise-total-points.pipe";
import { IconStatusPipe } from "./pipes/icon-status.pipe";
import { ExercisesStatListTableComponent } from "./components/exercises-stat-list-table/exercises-stat-list-table.component";
import { FlagExerciseBtnComponent } from "./components/flag-exercise-btn/flag-exercise-btn.component";
import { FlaggedExercisesListComponent } from "./components/flagged-exercises-list/flagged-exercises-list.component";
import { RingProgressBarComponent } from "./components/ring-progress-bar/ring-progress-bar.component";

@NgModule({
  declarations: [
    PanelPageComponent,
    IconInputComponent,
    NavbarComponent,
    NavbarNavComponent,
    AvatarComponent,
    NavbarNavElementComponent,
    PageHeaderComponent,
    PageStandardLayoutComponent,
    CourseListboxElementContentComponent,
    ListProgressComponent,
    NavbarElementComponent,
    RichTextComponent,
    CourseProgressComponent,
    PageColumnLayoutComponent,
    BoxHeaderComponent,
    CourseProgressTopicComponent,
    BoxComponent,
    RoundButtonComponent,
    CourseTopicComponent,
    CourseTopicHeaderComponent,
    CourseTopicExercisesComponent,
    CourseExerciseListboxElementContentComponent,
    CourseExerciseHelpComponent,
    CourseExerciseDescComponent,
    CourseExerciseCompetencesComponent,
    BadgeComponent,
    CourseExerciseAnswerComponent,
    WarningModalComponent,
    WarningModalDirective,
    ValidationMessageComponent,
    ValidationTextComponent,
    LoadingBoxComponent,
    LoadingSpinnerComponent,
    ReplaceNewLinePipe,
    LanguagePickerComponent,
    CountdownComponent,
    PhotoComponent,
    GroupInvitationListboxElementContentComponent,
    HourPickerComponent,
    PillsInputComponent,
    PillComponent,
    PillsComponent,
    SimpleListItemComponent,
    SimpleElementComponent,
    ExerciseStatusComponent,
    CourseExerciseStatComponent,
    CourseTopicStatComponent,
    HighlightDirective,
    IndicatorComponent,
    PopupMenuComponent,
    DayPipe,
    FormatHourPipe,
    ModalComponent,
    ModalOverlayComponent,
    ModalBoxComponent,
    ConfirmationModalComponent,
    ConfirmationDirective,
    InvitationStatusPipe,
    SanitizeContentPipe,
    IterationPipe,
    PopupDirective,
    VpnConfigurationModalComponent,
    NotificationsIconComponent,
    NotificationsPanelComponent,
    NotificationComponent,
    NotificationToastComponent,
    DateDifferencePipe,
    WelcomeScreenModalComponent,
    GlobalRankingPageComponent,
    FluidTableComponent,
    RankingTableComponent,
    RecaptchaInfoComponent,
    ExerciseControlButtonsComponent,
    ExerciseTimerComponent,
    FooterComponent,
    ExerciseSolveConfirmModalComponent,
    PointsPluralizationPipe,
    StatusIconComponent,
    DropdownMenuComponent,
    DashboardMenuComponent,
    NavbarExerciseTimerComponent,
    QuizSolveConfirmModalComponent,
    CookieBarComponent,
    NotificationsSnoozeComponent,
    NotificationsSettingsComponent,
    SettingGroupComponent,
    SnoozeGroupComponent,
    CodeCheckHistoryButtonComponent,
    CodeCheckHistoryModalComponent,
    CodeCheckStatusPipe,
    DatePipe,
    RedeemModalComponent,
    GroupReedemListboxElementContentComponent,
    SurpriseBoxComponent,
    ListPickerComponent,
    SliderPickerComponent,
    ProgressRingComponent,
    PaginationComponent,
    OrderByAvailabilityPipe,
    AnnouncementsComponent,
    AnnouncementComponent,
    SwitchButtonComponent,
    TooltipDirective,
    TooltipComponent,
    FileSizePipe,
    WidgetDirective,
    MoveStudentModalComponent,
    GroupStudentMoveListboxElementContentComponent,
    QuizHistoryButtonComponent,
    QuizHistoryModalComponent,
    CachedQuizFigureComponent,
    OrganizationLimitWidgetComponent,
    TileComponent,
    LimitTextComponent,
    CarouselComponent,
    DashboardLayoutComponent,
    DashboardSidebarComponent,
    SwitchButtonExpandedComponent,
    SwitchCheckboxComponent,
    EnvironmentTranslatePipe,
    HistoryModalHeaderComponent,
    HistoryModalFrameComponent,
    HistoryModalItemListComponent,
    QuizHistoryModalItemComponent,
    CodeCheckHistoryModalItemComponent,
    ItemListModeSelectorComponent,
    HistoryModalStudentsListComponent,
    CodeCheckHistoryModalCheckTypeComponent,
    CodeCheckTypePipe,
    RankingWidgetComponent,
    DotsComponent,
    VpnConnectionModalComponent,
    InvitationsWidgetComponent,
    CustomVideoPlayerComponent,
    ValidationHintComponent,
    GroupTeacherNameComponent,
    NavbarIconComponent,
    HoverDirective,
    LanguagePickerButtonWithFlagLayoutComponent,
    LanguagePickerLangsWithFlagLayoutComponent,
    DefaultLanguagePickerButtonLayoutComponent,
    DefaultLanguagePickerLangsLayoutComponent,
    NavbarExitExerciseButtonComponent,
    ExerciseIdeControlButtonsComponent,
    ExerciseQuizControlButtonsComponent,
    ExerciseNoneControlButtonsComponent,
    CustomVideoPlayerDirective,
    GroupListboxElementContentComponent,
    DividerComponent,
    SectionTitleComponent,
    ListboxComponent,
    ListboxElementComponent,
    StudentsCountBadgeComponent,
    ResolvedExercisesCountBadgeComponent,
    LogoComponent,
    CourseExerciseDifficultyComponent,
    ExerciseStatusCssClassPipe,
    ListboxEmptyComponent,
    GroupListboxEmptyComponent,
    GroupInvitationListboxEmptyComponent,
    CourseListboxEmptyComponent,
    OrganizationLimitWidgetCarouselContentComponent,
    KeyIconComponent,
    PopupTriggerLabelWithChevronComponent,
    IconComponent,
    SelectWithPlaceholderComponent,
    SimpleDashboardWidgetComponent,
    GroupWidgetComponent,
    CourseGroupListComponent,
    CourseGroupListHeaderComponent,
    CourseGroupListActionsComponent,
    CourseGroupListEmptyComponent,
    RoundButtonClassPipe,
    NoDataPipe,
    DropdownComponent,
    DropdownItemComponent,
    ClickOutsideDirective,
    TabViewComponent,
    TabPanelComponent,
    SelectButtonComponent,
    InputSwitchComponent,
    InputNumberComponent,
    StudentStatListTableComponent,
    StudentRatingSystemFilterFormComponent,
    StudentPassFilterFormComponent,
    StudentExerciseCellClassPipe,
    StudentExerciseGradePipe,
    StudentExercisePercentagePipe,
    StudentExerciseTotalPointsPipe,
    StudentExerciseGradeCellClassPipe,
    StudentExercisePercentageCellClassPipe,
    OperatorLiteralPipe,
    CourseExerciseFileUploadModalComponent,
    FileListComponent,
    ProgressBarComponent,
    HintComponent,
    ProgressBarComponent,
    FileUploadComponent,
    FormatExtensionsPipe,
    CourseExerciseAnswerTableComponent,
    CourseExerciseAnswerMenuComponent,
    ExerciseFileUploadControlButtonsComponent,
    CourseExerciseAnswerHistoryPopupMenuComponent,
    RoundBadgeComponent,
    CourseExerciseStudentUploadComponent,
    CourseExerciseAnswerListModalComponent,
    AnswerStatusComponent,
    CardCourseComponent,
    TeacherDashboardGroupListboxElementContentComponent,
    TableComponent,
    CircleBadgeComponent,
    ShortNamePipe,
    StatusChipComponent,
    ColorStatusPipe,
    IconStatusPipe,
    OrganizationSelectorComponent,
    OrganizationNamePipe,
    CourseLimitWidgetComponent,
    PageToolbarComponent,
    ExpansionPanelComponent,
    HighlightComponent,
    TutorialTextboxComponent,
    TutorialModalComponent,
    ArrowPositionPipe,
    StudentIndividualGradesComponent,
    IndividualGradeBarComponent,
    CardCertificateComponent,
    ExercisesStatListTableComponent,
    FlagExerciseBtnComponent,
    FlaggedExercisesListComponent,
    RingProgressBarComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MonacoEditorModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ReactiveFormsModule,
    NgxMaskModule,
    OverlayModule,
  ],
  exports: [
    PanelPageComponent,
    ReactiveFormsModule,
    TranslateModule,
    IconInputComponent,
    PageStandardLayoutComponent,
    PageColumnLayoutComponent,
    PageHeaderComponent,
    BoxComponent,
    BoxHeaderComponent,
    RoundButtonComponent,
    CourseListboxElementContentComponent,
    CourseProgressComponent,
    CourseTopicComponent,
    CourseExerciseHelpComponent,
    WarningModalComponent,
    WarningModalDirective,
    ValidationMessageComponent,
    ValidationTextComponent,
    LoadingBoxComponent,
    LoadingSpinnerComponent,
    ReplaceNewLinePipe,
    LanguagePickerComponent,
    CountdownComponent,
    PhotoComponent,
    GroupInvitationListboxElementContentComponent,
    HourPickerComponent,
    PillsInputComponent,
    PillComponent,
    PillsComponent,
    SimpleListItemComponent,
    SimpleElementComponent,
    ExerciseStatusComponent,
    CourseExerciseStatComponent,
    CourseTopicStatComponent,
    HighlightDirective,
    IndicatorComponent,
    PopupMenuComponent,
    DayPipe,
    FormatHourPipe,
    ModalComponent,
    ModalOverlayComponent,
    ModalBoxComponent,
    ConfirmationModalComponent,
    ConfirmationDirective,
    InvitationStatusPipe,
    SanitizeContentPipe,
    IterationPipe,
    PopupDirective,
    VpnConfigurationModalComponent,
    WelcomeScreenModalComponent,
    GlobalRankingPageComponent,
    FluidTableComponent,
    RankingTableComponent,
    RecaptchaInfoComponent,
    ExerciseControlButtonsComponent,
    FooterComponent,
    ExerciseSolveConfirmModalComponent,
    PointsPluralizationPipe,
    StatusIconComponent,
    DropdownMenuComponent,
    DashboardMenuComponent,
    CourseExerciseDescComponent,
    NavbarExerciseTimerComponent,
    QuizSolveConfirmModalComponent,
    RichTextComponent,
    CookieBarComponent,
    CodeCheckStatusPipe,
    DatePipe,
    GroupReedemListboxElementContentComponent,
    ListPickerComponent,
    SliderPickerComponent,
    ProgressRingComponent,
    PaginationComponent,
    OrderByAvailabilityPipe,
    AnnouncementsComponent,
    AnnouncementComponent,
    SwitchButtonComponent,
    TooltipDirective,
    TooltipComponent,
    FileSizePipe,
    WidgetDirective,
    MoveStudentModalComponent,
    GroupStudentMoveListboxElementContentComponent,
    CachedQuizFigureComponent,
    OrganizationLimitWidgetComponent,
    TileComponent,
    LimitTextComponent,
    CarouselComponent,
    DashboardLayoutComponent,
    DashboardSidebarComponent,
    SwitchButtonExpandedComponent,
    SwitchCheckboxComponent,
    RankingWidgetComponent,
    DotsComponent,
    VpnConnectionModalComponent,
    InvitationsWidgetComponent,
    CustomVideoPlayerComponent,
    ValidationHintComponent,
    LanguagePickerButtonWithFlagLayoutComponent,
    LanguagePickerLangsWithFlagLayoutComponent,
    NavbarExitExerciseButtonComponent,
    ExerciseIdeControlButtonsComponent,
    ExerciseQuizControlButtonsComponent,
    EnvironmentTranslatePipe,
    CustomVideoPlayerDirective,
    BadgeComponent,
    GroupListboxElementContentComponent,
    DividerComponent,
    SectionTitleComponent,
    ListboxComponent,
    ListboxElementComponent,
    StudentsCountBadgeComponent,
    LogoComponent,
    GroupListboxEmptyComponent,
    GroupInvitationListboxEmptyComponent,
    CourseListboxEmptyComponent,
    KeyIconComponent,
    SelectWithPlaceholderComponent,
    GroupWidgetComponent,
    IconComponent,
    CourseGroupListComponent,
    CourseGroupListHeaderComponent,
    CourseGroupListActionsComponent,
    CourseGroupListEmptyComponent,
    NavbarNavComponent,
    NavbarNavElementComponent,
    NotificationsIconComponent,
    SimpleDashboardWidgetComponent,
    HoverDirective,
    NoDataPipe,
    DropdownComponent,
    ClickOutsideDirective,
    TabPanelComponent,
    TabViewComponent,
    SelectButtonComponent,
    InputSwitchComponent,
    InputNumberComponent,
    CodeCheckHistoryButtonComponent,
    StudentStatListTableComponent,
    StudentRatingSystemFilterFormComponent,
    StudentPassFilterFormComponent,
    StudentExerciseCellClassPipe,
    StudentExerciseGradePipe,
    StudentExercisePercentagePipe,
    StudentExerciseTotalPointsPipe,
    StudentExerciseGradeCellClassPipe,
    StudentExercisePercentageCellClassPipe,
    OperatorLiteralPipe,
    HintComponent,
    CourseExerciseFileUploadModalComponent,
    FileUploadComponent,
    FormatExtensionsPipe,
    CourseExerciseAnswerTableComponent,
    CourseExerciseAnswerComponent,
    CardCourseComponent,
    TeacherDashboardGroupListboxElementContentComponent,
    TableComponent,
    CircleBadgeComponent,
    ShortNamePipe,
    StatusChipComponent,
    ColorStatusPipe,
    IconStatusPipe,
    OrganizationSelectorComponent,
    OrganizationNamePipe,
    CourseLimitWidgetComponent,
    PageToolbarComponent,
    ExpansionPanelComponent,
    HighlightComponent,
    TutorialModalComponent,
    TutorialTextboxComponent,
    ArrowPositionPipe,
    StudentIndividualGradesComponent,
    CardCertificateComponent,
    IndividualGradeBarComponent,
    ExercisesStatListTableComponent,
    FlagExerciseBtnComponent,
    FlaggedExercisesListComponent,
    RingProgressBarComponent,
  ],
  providers: [OrderByAvailabilityPipe, ExerciseStatusCssClassPipe],
  bootstrap: [],
})
export class SharedModule {}
