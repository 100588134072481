import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Topic } from "../../interfaces/topic";
import { FlaggedExerciseList } from "../../interfaces/flagged-exercises";

@Component({
  selector: "flagged-exercises-list",
  templateUrl: "./flagged-exercises-list.component.html",
  styleUrls: ["./flagged-exercises-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlaggedExercisesListComponent implements OnChanges {
  @Input() topics: Array<Topic>;
  @Input() flaggedExercises: Array<FlaggedExerciseList>;

  @Output() emitModal = new EventEmitter<Array<FlaggedExerciseList>>();

  openExercisesList: boolean = false;
  topicId: string;
  flaggedExercisesCount = 0;

  ngOnChanges(changes: SimpleChanges): void {
    const { topics } = changes;

    if (topics) {
      this.flaggedExercisesCount = 0;

      const topicsArray = topics.currentValue as Array<Topic>;
      topicsArray.forEach(
        (topic) => (this.flaggedExercisesCount += topic.report_count),
      );
    }
  }

  handleOpenExercises(topicId: string): void {
    if (this.topicId === topicId) {
      this.openExercisesList = !this.openExercisesList;
    } else {
      this.openExercisesList = true;
      this.topicId = topicId;
    }
  }

  handleOpenModal(exerciseId: string): void {
    const reportedUsersExercise = this.flaggedExercises.filter(
      (exercise) => exercise.exercise === exerciseId,
    );

    this.emitModal.emit(reportedUsersExercise);
  }
}
