import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "history-modal-header",
  template: `
    <div class="row no-gutters">
      <div class="col-12">
        <h5 class="heading-6 history-modal-header" translate>
          {{ title }}
          <small>{{ suffix }}</small>
        </h5>
      </div>
    </div>
  `,
  styleUrls: ["./history-modal-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryModalHeaderComponent {
  @Input() title: string;
  @Input() suffix: string;

  constructor() {}
}
