import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { CertifcatesService } from "src/app/shared/services/certificates/certificates.service";
import {
  certificatesLoad,
  certificatesLoadFail,
  certificatesLoadSuccess,
} from "../actions/certificates.actions";
import { Certificate } from "src/app/shared/interfaces/certificate";

@Injectable()
export class CertificatesEffect {
  certificatesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(certificatesLoad),
      switchMap(() => {
        return this.certificatesService.getCertificates().pipe(
          map((response: Certificate[]) =>
            certificatesLoadSuccess({
              certificates: response,
            }),
          ),
          catchError(({ error }) => {
            return of(certificatesLoadFail({ error }));
          }),
        );
      }),
    ),
  );

  constructor(
    private certificatesService: CertifcatesService,
    private actions$: Actions,
  ) {}
}
