<ng-container
  *ngIf="{ isTeacher: isTeacher$ | async, exercise: data } as solveConfirm"
>
  <modal-overlay>
    <modal-box>
      <div class="exercise-solve-modal-body row no-gutters text-center">
        <div class="col-12">
          <div>
            <svg
              class="congrats-star"
              viewBox="0 0 92 92"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              [ngClass]="{
                'gray-star': solveConfirm.isTeacher || !solveConfirm.exercise.points || solveConfirm.exercise.was_passed_before
              }">
              <path
                class="star"
                d="M31.7147 27.755L3.17971 30.9183C2.15055 31.0429 1.24055 31.7308 0.899297 32.7763C0.558047 33.8217 0.899297 34.9104 1.65763 35.5983C10.1401 43.3388 22.8855 54.9304 22.8855 54.9304C22.8747 54.9304 19.3864 71.7925 17.0681 83.0429C16.8731 84.0612 17.2414 85.1392 18.1297 85.7838C19.0126 86.4283 20.1501 86.4392 21.0439 85.9354C31.0322 80.2588 45.9931 71.7221 45.9931 71.7221L70.9314 85.9408C71.8414 86.4392 72.9789 86.4283 73.8618 85.7838C74.7501 85.1392 75.1185 84.0612 74.9181 83.0483L69.1168 54.9304L90.3447 35.6146C91.1031 34.905 91.4389 33.8163 91.1031 32.7763C90.7672 31.7363 89.8572 31.0483 88.8281 30.9292C77.4097 29.6508 60.2876 27.755 60.2876 27.755L48.4631 1.5925C48.0189 0.655418 47.0872 0 45.9931 0C44.8989 0 43.9618 0.660834 43.5393 1.5925L31.7147 27.755Z"
                fill="#FDB022"/>
            </svg>
          </div>
        </div>

        <div class="col-12 congrats-title">
          <h5 class="heading-1">
            {{
              "COURSES.COURSE.EXERCISE.SOLVED_WINDOW.CONGRATULATIONS" | translate
            }}
          </h5>
        </div>

        <div class="col-12">
          <p class="solve-message">
            <span
              class="d-block"
              [innerHTML]="
                'COURSES.COURSE.EXERCISE.SOLVED_WINDOW.EXERCISE_SOLVE' + (solveConfirm.exercise.was_passed_before ? '_AGAIN' : '')
                  | translate: { exercise: solveConfirm.exercise.name }
              "
            >
            </span>

            <ng-container
              *ngIf="
                !solveConfirm.isTeacher &&
                solveConfirm.exercise.points > 0 &&
                !solveConfirm.exercise.was_passed_before
              "
            >
              <br />
              <span class="d-block" translate>
                COURSES.COURSE.EXERCISE.SOLVED_WINDOW.POINTS_RECEIVED
              </span>

              <span class="points-circle">
                <span class="d-block heading-1 points-value">
                  {{ solveConfirm.exercise.points }}
                </span>

                <span class="points-label">
                  {{
                    solveConfirm.exercise.points
                      | pointsPluralization: { currentLang: currentLang }
                      | translate
                  }}
                </span>
              </span>
            </ng-container>
          </p>
        </div>

        <div class="col-12 button-panel">
          <button class="theme-hprimarydark ok-button" (click)="closeConfirm()">
            {{ "GLOBAL.OK_BUTTON" | translate }}
          </button>
        </div>
      </div>
    </modal-box>
  </modal-overlay>
</ng-container>
