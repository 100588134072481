import { Component, Input } from '@angular/core';

@Component({
  selector: 'course-topic-stat',
  template: `
    <box>
      <box-header><h4>{{name}}</h4></box-header>
 
      <ng-content></ng-content>
    </box>
  `,
  styleUrls: ['./course-topic-stat.component.scss']
})
export class CourseTopicStatComponent {
  @Input() name = '';
  constructor() {}
}
