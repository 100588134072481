import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "dots",
  template: `
    <div class="dots">
      <span
        *ngFor="let dot of count | iteration; let i = index"
        [ngClass]="{ active: activeElement === i }"
        (click)="dotClick(i)"
        class="dot"
      ></span>
    </div>
  `,
  styleUrls: ["./dots.component.scss"],
})
export class DotsComponent {
  @Input() count: number;
  @Input() activeElement: number;
  @Output() dotClicked: EventEmitter<number> = new EventEmitter();

  constructor() {}

  dotClick(index: number): void {
    this.dotClicked.emit(index);
  }
}
