import { Component, Input } from '@angular/core';
import { ModalComponent } from '../../modal/modal.component';

@Component({
  selector: 'warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss']
})
export class WarningModalComponent extends ModalComponent {
  @Input() text: string;
  @Input() buttonText: string;
  @Input() icon = 'warning-outline';

  constructor() {
    super();
  }

  dismiss(): void {
    this.close.emit('close');
  }
}
