import * as fromAccount from "../actions/account.actions";
import * as fromAuth from "../actions/auth.actions";
import { Member } from "src/app/shared/interfaces/member";
import { ErrorListing } from "src/app/shared/types/error-listing";
import { RedeemResult } from "src/app/shared/interfaces/redeem-result";
import {
  CourseLimit,
  OrganizationLimit,
} from "src/app/shared/interfaces/limit";
import { Nillable } from "src/app/shared/types/nillable";
import { Guid } from "src/app/shared/types/guid";
import {
  isCourseLimit,
  isOrganizationLimit,
} from "src/app/shared/utils/limit-type-guards";

export interface AccountState {
  member: Member | null;
  getMemberFailed: boolean;
  registerCompleted: boolean;
  registerFailed: boolean;
  registerErrors: ErrorListing;
  changePasswordCompleted: boolean;
  changePasswordFailed: boolean;
  passwordErrors: ErrorListing;
  forgotPasswordCompleted: boolean;
  forgotPasswordFailed: boolean;
  forgotPasswordErrors: ErrorListing;
  resetPasswordCompleted: boolean;
  resetPasswordFailed: boolean;
  resetPasswordErrors: ErrorListing;
  changeEmailSent: boolean;
  changeEmailSentFailed: boolean;
  emailSentErrors: ErrorListing;
  changeEmailCompleted: boolean;
  changeEmailFailed: boolean;
  additionalSettings: {
    updatingInRanking: boolean;
  };
  redeemCode: string | null;
  redeemCompleted: boolean;
  redeemFailed: boolean;
  redeemResult: RedeemResult | null;
  redeemError: ErrorListing;
  redeemHold: boolean;

  organizationsLimits: Array<OrganizationLimit>;
  organizationsLimitsCompleted: boolean;
  coursesLimits: Array<CourseLimit>;
  coursesLimitsCompleted: boolean;
  courseLimit: CourseLimit | null;

  currentOrganization: Nillable<Guid>;
}

export const initialState: AccountState = {
  member: null,
  getMemberFailed: false,
  registerCompleted: false,
  registerFailed: false,
  registerErrors: {},
  changePasswordCompleted: false,
  changePasswordFailed: false,
  passwordErrors: {},
  forgotPasswordCompleted: false,
  forgotPasswordFailed: false,
  forgotPasswordErrors: {},
  resetPasswordCompleted: false,
  resetPasswordFailed: false,
  resetPasswordErrors: {},
  changeEmailSent: false,
  changeEmailSentFailed: false,
  emailSentErrors: {},
  changeEmailCompleted: false,
  changeEmailFailed: false,
  additionalSettings: {
    updatingInRanking: false,
  },
  redeemCode: null,
  redeemCompleted: false,
  redeemFailed: false,
  redeemResult: null,
  redeemError: {},
  redeemHold: true,

  organizationsLimits: [],
  organizationsLimitsCompleted: false,
  coursesLimits: [],
  coursesLimitsCompleted: false,
  courseLimit: null,

  currentOrganization: null,
};

export function reducer(
  state = initialState,
  action: fromAccount.AccountActions | fromAuth.AuthActions,
): AccountState {
  switch (action.type) {
    case fromAuth.LOGIN: {
      return {
        ...state,
        resetPasswordCompleted: false,
        resetPasswordFailed: false,
        resetPasswordErrors: {},
        changeEmailCompleted: false,
        changeEmailFailed: false,
      };
    }

    case fromAccount.GET_MEMBER: {
      return {
        ...state,
        member: null,
      };
    }

    case fromAccount.GET_MEMBER_COMPLETED: {
      return {
        ...state,
        member: (<fromAccount.GetMemberCompleted>action).payload.member,
      };
    }

    case fromAccount.GET_MEMBER_FAILED: {
      return {
        ...state,
        getMemberFailed: true,
      };
    }

    case fromAccount.REGISTER: {
      return {
        ...state,
        registerCompleted: false,
        registerFailed: false,
      };
    }

    case fromAccount.REGISTER_TEACHER: {
      return {
        ...state,
        registerCompleted: false,
        registerFailed: false,
      };
    }

    case fromAccount.REGISTER_COMPLETED: {
      return {
        ...state,
        registerCompleted: true,
        registerFailed: false,
      };
    }

    case fromAccount.REGISTER_FAILED: {
      return {
        ...state,
        registerCompleted: false,
        registerFailed: true,
        registerErrors: action.payload,
      };
    }

    case fromAccount.CHANGE_PASSWORD: {
      return {
        ...state,
        changePasswordCompleted: false,
        changePasswordFailed: false,
      };
    }

    case fromAccount.CHANGE_PASSWORD_COMPLETED: {
      return {
        ...state,
        changePasswordCompleted: true,
        changePasswordFailed: false,
      };
    }

    case fromAccount.CHANGE_PASSWORD_FAILED: {
      return {
        ...state,
        changePasswordCompleted: false,
        changePasswordFailed: true,
        passwordErrors: action.payload,
      };
    }

    case fromAccount.FORGOT_PASSWORD: {
      return {
        ...state,
        forgotPasswordCompleted: false,
        forgotPasswordFailed: false,
        forgotPasswordErrors: {},
      };
    }

    case fromAccount.FORGOT_PASSWORD_COMPLETED: {
      return {
        ...state,
        forgotPasswordCompleted: true,
        forgotPasswordFailed: false,
      };
    }

    case fromAccount.FORGOT_PASSWORD_FAILED: {
      return {
        ...state,
        forgotPasswordCompleted: false,
        forgotPasswordFailed: true,
        forgotPasswordErrors: action.payload,
      };
    }

    case fromAccount.RESET_PASSWORD: {
      return {
        ...state,
        resetPasswordCompleted: false,
        resetPasswordFailed: false,
        resetPasswordErrors: {},
      };
    }

    case fromAccount.RESET_PASSWORD_COMPLETED: {
      return {
        ...state,
        resetPasswordCompleted: true,
        resetPasswordFailed: false,
      };
    }

    case fromAccount.RESET_PASSWORD_FAILED: {
      return {
        ...state,
        resetPasswordCompleted: false,
        resetPasswordFailed: true,
        resetPasswordErrors: action.payload,
      };
    }

    case fromAccount.CHANGE_EMAIL: {
      return {
        ...state,
        changeEmailSent: false,
        changeEmailSentFailed: false,
        emailSentErrors: {},
      };
    }

    case fromAccount.CHANGE_EMAIL_SENT: {
      return {
        ...state,
        changeEmailSent: true,
        changeEmailSentFailed: false,
        emailSentErrors: {},
      };
    }

    case fromAccount.CHANGE_EMAIL_SENT_FAILED: {
      return {
        ...state,
        changeEmailSent: false,
        changeEmailSentFailed: true,
        emailSentErrors: action.payload,
      };
    }

    case fromAccount.CHANGE_EMAIL_CHECK: {
      return {
        ...state,
        changeEmailCompleted: false,
        changeEmailFailed: false,
      };
    }

    case fromAccount.CHANGE_EMAIL_COMPLETED: {
      return {
        ...state,
        changeEmailCompleted: true,
      };
    }

    case fromAccount.CHANGE_EMAIL_FAILED: {
      return {
        ...state,
        changeEmailFailed: true,
      };
    }

    case fromAccount.UPDATE_USER_VPN_CONNECTION_STATUS: {
      return {
        ...state,
        member: {
          ...state.member,
          remote_ip: action.payload.remote_ip,
        },
      };
    }

    case fromAccount.UPDATE_IN_RANKING: {
      return {
        ...state,
        additionalSettings: {
          ...state.additionalSettings,
          updatingInRanking: true,
        },
      };
    }

    case fromAccount.UPDATE_IN_RANKING_COMPLETED: {
      return {
        ...state,
        additionalSettings: {
          ...state.additionalSettings,
          updatingInRanking: false,
        },
        member: {
          ...state.member,
          in_ranking: action.payload.in_ranking,
        },
      };
    }

    case fromAccount.SET_REDEEM_CODE: {
      return {
        ...state,
        redeemCode: action.payload,
      };
    }

    case fromAccount.REDEEM: {
      return {
        ...state,
        redeemCompleted: false,
        redeemFailed: false,
        redeemResult: null,
        redeemError: {},
      };
    }

    case fromAccount.REDEEM_COMPLETED: {
      return {
        ...state,
        redeemHold: false,
        redeemCompleted: true,
        redeemResult: action.payload,
      };
    }

    case fromAccount.REDEEM_FAILED: {
      return {
        ...state,
        redeemHold: false,
        redeemFailed: true,
        redeemError: action.payload,
      };
    }

    case fromAccount.RELEASE_REDEEM: {
      return {
        ...state,
        redeemHold: false,
      };
    }

    case fromAccount.GET_LIMITS: {
      return {
        ...state,
        [`${action.payload}Limits`]: [],
        [`${action.payload}LimitsCompleted`]: false,
      };
    }

    case fromAccount.GET_LIMITS_COMPLETED: {
      return {
        ...state,
        coursesLimits: action.payload.limits,
        [`${action.payload.type}LimitsCompleted`]: true,
      };
    }

    case fromAccount.UPDATE_LIMIT_COMPLETED: {
      return {
        ...state,
        courseLimit: action.payload.limit,
      };
    }

    case fromAccount.SET_CURRENT_ORGANIZATION: {
      return {
        ...state,
        currentOrganization: action.payload,
      };
    }

    default:
      return state;
  }
}
