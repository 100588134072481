<ng-container
  *ngIf="{
    completed: redeemCompleted$ | async,
    result: redeemResult$ | async,
    failed: redeemFailed$ | async,
    error: redeemError$ | async,
    isTeacher: isTeacher$ | async
  } as redeem"
>
  <modal-overlay>
    <modal-box>
      <div class="redeem-modal-body">
        <div class="d-flex flex-column h-100">
          <h5 class="heading-5 redeem-modal-title">
            <span class="prefix">{{
              "ACCOUNT.REDEEM.REDEEM" | translate
            }}</span>
            <span [translate]="getStatusTitle(redeem)"></span>
          </h5>

          <div
            class="redeem-modal-content row no-gutters"
            *ngIf="redeem.completed || redeem.failed; else loading"
          >
            <ng-container *ngIf="redeem.completed">
              <div
                class="col-6 d-none d-md-flex align-items-center justify-content-center"
              >
                <surprise-box></surprise-box>
              </div>

              <div class="col-auto col-md-6 redeem-modal-coursegroups">
                <ul listbox [noContentTemplateRef]="noContentTemplateRef">
                  <li
                    listbox-element
                    *ngFor="let group of redeem.result.new_coursegroups"
                  >
                    <group-reedem-listbox-element-content
                      [teacher]="redeem.isTeacher"
                      [group]="group"
                      [course]="group.course"
                      (onGo)="close.emit('close')"
                    />
                  </li>
                </ul>
              </div>
            </ng-container>

            <ng-container *ngIf="redeem.failed">
              <div
                class="col-12 d-flex flex-column align-items-center justify-content-center"
              >
                <i class="uil uil-frown"></i>
                <p class="mt-4 heading-4" *ngIf="redeem.error.code">
                  {{ redeem.error.code[0] }}
                </p>
              </div>
            </ng-container>
          </div>

          <div class="d-flex justify-content-end mt-4">
            <button class="theme-hprimary" (click)="close.emit('close')">
              {{ "GLOBAL.CLOSE_BUTTON" | translate }}
            </button>
          </div>
        </div>
      </div>
    </modal-box>
  </modal-overlay>
</ng-container>

<ng-template #noContentTemplateRef>
  <group-listbox-empty />
</ng-template>

<ng-template #loading>
  <div class="d-flex align-items-center justify-content-center w-100 h-100">
    <loading-spinner width="100" height="100"></loading-spinner>
  </div>
</ng-template>
