import { ComponentRef, Injectable, Injector, Type } from "@angular/core";
import { ModalComponent } from "../../components/modal/modal.component";
import { ModalData } from "../../interfaces/modal-data";
import * as ModalContext from "../modal-context/modal-context.service";
import { BehaviorSubject } from "rxjs";
import { Nillable } from "../../types/nillable";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  constructor(
    private readonly modalContextService: ModalContext.ModalContextService,
  ) {}

  showModal<T extends ModalData>(
    data: T,
    modalType: Type<ModalComponent>,
    id: string,
    injector?: Nillable<Injector>,
  ): ModalComponent {
    let context: ModalContext.ModalContext<ModalComponent> =
      this.modalContextService.createContext<ModalComponent>(
        modalType,
        injector,
      );
    let componentRef = context.portalHost.attach(context.portal);

    this.setData<T>(context, componentRef as ComponentRef<ModalComponent>, data);

    return componentRef.instance as ModalComponent;
  }

  closeModal(
    context: ModalContext.ModalContext<ModalComponent>,
    componentRef: ComponentRef<ModalComponent>,
  ): void {
    context.portalHost.detach();
    componentRef.destroy();
    this.modalContextService.deleteContext(context);
  }

  private setData<T extends ModalData>(
    context: ModalContext.ModalContext<ModalComponent>,
    componentRef: ComponentRef<ModalComponent>,
    data: T
  ): void {
    componentRef.instance.modalTitle = data.modalTitle;
    componentRef.instance.data = data;

    componentRef.instance.close.subscribe((status: string) => {
      if (status === "close") {
        this.closeModal(context, componentRef);
        if (data.onClose) {
          data.onClose();
        }
      }
    });
  }
}
