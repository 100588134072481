import { createReducer, on } from "@ngrx/store";
import {
  sendInviteTeacher,
  sendInviteTeacherFail,
} from "../../actions/admin/send-invite-teacher.actions";
import { InvitationsRequest } from "src/app/shared/interfaces/invitations-request";

export interface SendInviteTeacherState {
  sendInvitationsFailed: boolean;
  error: InvitationsRequest | null;
}

const initialState: SendInviteTeacherState = {
  sendInvitationsFailed: false,
  error: null,
};

export const sendInviteTeacherReducer = createReducer(
  initialState,
  on(
    sendInviteTeacher,
    (state): SendInviteTeacherState => ({
      ...state,
      sendInvitationsFailed: false,
      error: null,
    }),
  ),
  on(
    sendInviteTeacherFail,
    (state, { error }): SendInviteTeacherState => ({
      ...state,
      sendInvitationsFailed: true,
      error,
    }),
  ),
);
