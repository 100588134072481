import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";
import { Topic } from "../../../interfaces/topic";
import { Guid } from "src/app/shared/types/guid";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "course-progress",
  templateUrl: "./course-progress.component.html",
  styleUrls: ["./course-progress.component.scss"],
})
export class CourseProgressComponent implements OnChanges {
  @Input() currentTopic: Topic;
  @Input() topics: Array<Topic>;
  @Input() courseId: Guid;
  @Input() groupId: Guid;
  @Input() hasRanking: boolean;
  @Input("teacher") isTeacher = false;

  currentTopicIndex: number = 0;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnChanges() {
    this.findCurrentTopicIndex();
  }

  private findCurrentTopicIndex() {
    if (this.currentTopic) {
      this.currentTopicIndex = this.topics.findIndex((topic: Topic) => {
        return this.currentTopic.id === topic.id;
      });
    }
  }

  setTopicParam(topicId: Guid) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { topicId },
      queryParamsHandling: "merge",
    });
  }
}
