<div [class.disabled]="isDisabled" class="row align-items-center">
  <div class="col">
    <div class="row align-items-center align-items-center">
      <div class="col-12 col-sm-4">
        <div class="d-flex align-items-center">
          <span class="heading-6">
            {{ element.title !== " " ? element.title : element.email }}
          </span>
        </div>
      </div>

      <div class="col-12 col-sm">
        <div
          class="d-flex align-items-center justify-content-sm-center my-3 my-sm-0"
        >
          <ng-container *ngIf="dashboardTeacher">
            <students-count-badge
              color="{{ isDisabled ? 'hlightgray' : 'hlogo' }}"
              [count]="element.used_limits"
            />
          </ng-container>
          <ng-container *ngIf="!dashboardTeacher">
            <students-count-badge
              color="{{ isDisabled ? 'hlightgray' : 'hlogo' }}"
              [tooltip]="'ADMIN.AVAILABLE_ACCESSES' | translate"
              [count]="
                usedAndMaxLimits && !usedLimit
                  ? usedAndMaxLimits
                  : groupUsedLimit
                  ? groupUsedLimit
                  : '0'
              "
            />
          </ng-container>
        </div>
      </div>

      <div class="col-12 col-sm-4">
        <div class="d-inline-flex align-items-center">
          <div
            class="course-name"
            [ngClass]="{
              'empty-group': possibleEmptyGroups && !element.data.length
            }"
          >
            <span
              *ngIf="
                possibleEmptyGroups && !element.data.length;
                else noEmptyGroup
              "
              [tooltip]="'COURSES.DASHBOARD.NO_GROUPS_TO_SHOW' | translate"
            >
              {{ element.sub_title | translate }}
            </span>

            <ng-template #noEmptyGroup>
              {{ element.sub_title | translate }}
            </ng-template>
          </div>
          <div class="ml-3">
            <ng-content />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col col-lg-2">
    <div class="buttons d-flex align-items-center justify-content-end">
      <round-button
        *ngIf="deleteBtn"
        set="uil"
        icon="trash-alt"
        class="hprimarydark"
        tooltip="GROUPS.EDIT.DELETE_BUTTON"
        position="bottom"
        (click)="handleDeleteEmitter(element)"
      />
      <round-button
        *ngIf="!isDisabled && !editBtn && pathBtn"
        [link]="element.path"
        [set]="set"
        [icon]="icon"
        class="hprimarydark"
      />
      <round-button
        *ngIf="!isDisabled && editBtn"
        [set]="set"
        [icon]="icon"
        class="hprimarydark"
        tooltip="ADMIN.EDIT_LIMIT"
        position="bottom"
        (click)="handleEditEmitter(element)"
      />
    </div>
  </div>
</div>
