import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpansionPanelComponent {
  @Input() title: string;
  @Input() content: string;
  isToggled = true;

  constructor(private cdr: ChangeDetectorRef) {}

  toggle(): void {
    this.isToggled = !this.isToggled;
    this.cdr.markForCheck();
  }
}
