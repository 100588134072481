import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
} from "@angular/core";
import {
  StatListTableRow,
  TopicExercise,
  TopicSummary,
} from "./interfaces/stat-list-table-row";
import { StudentStatListTableColumn } from "./enums/student-stat-list-table-column";
import { RowsExpander } from "src/app/shared/utils/rows-expander";
import {
  StudentStatElement,
  StudentStatElementTopic,
} from "../../interfaces/student";
import { AnswerType } from "../../types/answer-type";
import { Guid } from "../../types/guid";
import { Nillable } from "../../types/nillable";
import {
  Config,
  StudentPartialGrade,
  StudentPassCriteriaApi,
} from "../../interfaces/student-pass-criteria-api";
import { PassCriteriaType } from "../../enums/pass-criteria-type";
import { StatsService } from "../../services/statistics/stats.service";
import { Observable } from "rxjs";
import * as moment from "moment";
import { tap } from "rxjs/operators";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "student-stat-list-table",
  templateUrl: "./student-stat-list-table.component.html",
  styleUrls: ["./student-stat-list-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentStatListTableComponent implements OnChanges {
  @Input() courseId: Nillable<Guid>;
  @Input() headerTemplateRef: Nillable<TemplateRef<void>>;

  @Input() statistics: StudentStatElement[] = [];
  @Input() showUserBy: Nillable<StudentStatListTableColumn> =
    StudentStatListTableColumn.NAME;

  @Input() isTeacher: boolean;
  @Input() criteria: Nillable<StudentPassCriteriaApi>;
  @Input() isOrgAdmin: boolean;

  currentPartialGrade$: Observable<string> = this.stats.currentPartialGradeId;
  selectedExercises$: Observable<string[]> = this.stats.currentItems;
  selectableExercises$: Observable<boolean> = this.stats.selectableFlag;
  highlightableExercises$: Observable<boolean> =
    this.stats.highlightableExercises;

  topics: StudentStatElementTopic[] = [];
  columns: { id: string; name: string }[] = [];
  rows: StatListTableRow[] = [];
  AnswerType = AnswerType;
  PassCriteriaType = PassCriteriaType;
  rowsExpander = new RowsExpander();
  gradesHistoryIsOpen: boolean = false;
  currentGradeId: string;
  currentUserId: string;
  gradeOneIsAvailable: boolean;

  selectedExercises: string[] = [];

  constructor(
    private stats: StatsService,
    private destroyRef: DestroyRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { statistics, showUserBy, criteria } = changes;
    if (statistics) {
      this.configureTopics();
      this.rowsExpander.configure(this.topics);

      this.columns = this.getTableColumns();
      this.rows = this.getTableRows();
    }

    if (showUserBy) {
      this.columns = this.getTableColumns();
    }

    if (
      (criteria &&
        criteria.currentValue?.criteria_type ==
          PassCriteriaType.PARTIAL_GRADES) ||
      (criteria &&
        criteria.currentValue?.criteria_type == PassCriteriaType.GRADES)
    ) {
      this.checkLowestGrade();
    }

    if (
      criteria &&
      criteria.currentValue?.criteria_type == PassCriteriaType.PARTIAL_GRADES &&
      !this.rowsExpander.isAllExpanded
    ) {
      this.rowsExpander.toggleExpandAllState();
    }

    this.highlightableExercises$
      .pipe(
        tap((selectable) => {
          if (selectable && !this.rowsExpander.isAllExpanded) {
            this.rowsExpander.toggleExpandAllState();
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();

    this.selectedExercises$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((selected) => {
        setTimeout(() => {
          this.selectedExercises = selected;
        });
      });
  }

  private getTableColumns(): { id: string; name: string }[] {
    return this.statistics?.map((statistic: StudentStatElement) => ({
      id: statistic.id,
      name: statistic[this.showUserBy],
    }));
  }

  private getTableRows(): StatListTableRow[] {
    return this.topics.map((topic: StudentStatElementTopic) => ({
      name: topic.topic_name,
      topicSummary: this.getTopicSummary(topic),
      exercises: this.getTopicExerciseListWithSummary(topic),
    }));
  }

  getGrades(
    config: Config,
    column: { id: string; name: string },
  ): StudentPartialGrade[] {
    return config.grades
      .filter((grade) => grade.user === column.id)
      .map((grade) => ({
        ...grade,
        created_at: moment(grade.created_at).format("DD-MM-YYYY"),
      }));
  }

  getLastGrade(grades: StudentPartialGrade[]): StudentPartialGrade | null {
    return grades.length ? grades[grades.length - 1] : null;
  }

  checkLowestGrade(): void {
    this.gradeOneIsAvailable =
      this.criteria.grades[this.criteria.grades.length - 1].active;
  }

  private getTopicSummary(topic: StudentStatElementTopic): TopicSummary[] {
    return this.statistics.map((statistic) => {
      const found = statistic.topics.find(
        (innerTopic) => innerTopic.topic_name === topic.topic_name,
      );

      return {
        completed: found.completed,
        all: found.exercise_count,
      };
    });
  }

  private getTopicExerciseListWithSummary(
    topic: StudentStatElementTopic,
  ): TopicExercise[] {
    return topic.exercises.map((exercise) => ({
      name: exercise.name,
      exerciseSummary: this.statistics.map((statistic) => {
        const found = statistic.topics.find(
          (innerTopic) => innerTopic.topic_name === topic.topic_name,
        );
        const foundExercise = found.exercises.find(
          (innerExercise) => innerExercise.name === exercise.name,
        );

        return {
          exercise: {
            id: foundExercise.id,
            answer_type: foundExercise.answer_type,
            name: foundExercise.name,
          },
          points: foundExercise.points,
          maxPoints: foundExercise.max_points,
          passedTime: foundExercise.passed_at,
        };
      }),
    }));
  }

  private configureTopics(): void {
    const [first] = this.statistics ?? [];

    this.topics = first?.topics ?? [];
  }

  handleSetExercises(exercise: TopicExercise, configId: string): void {
    const exerciseId = exercise.exerciseSummary[0].exercise?.id;
    this.stats.toggleItem(exerciseId);
  }

  isSelectedExercise(
    exercise: TopicExercise,
    selectedExercises: string[],
  ): boolean {
    const exerciseId = exercise.exerciseSummary[0].exercise?.id;
    return selectedExercises.includes(exerciseId);
  }

  toggleGradesHistory(gradeId: string, userId: string): void {
    if (this.currentGradeId === gradeId && this.currentUserId === userId) {
      this.gradesHistoryIsOpen = !this.gradesHistoryIsOpen;
    } else {
      this.gradesHistoryIsOpen = true;
      this.currentGradeId = gradeId;
      this.currentUserId = userId;
    }
  }
}
