import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MovableGroup } from "src/app/shared/interfaces/group";
import { Guid } from "src/app/shared/types/guid";

@Component({
  selector: "group-student-move-listbox-content-element",
  templateUrl: "./group-student-move-listbox-element-content.component.html",
  styleUrls: ["./group-student-move-listbox-element-content.component.scss"],
})
export class GroupStudentMoveListboxElementContentComponent {
  @Input() group: MovableGroup;
  @Input() inProgress: boolean;

  @Output() moveClick = new EventEmitter();

  moveStudent(GroupId: Guid): void {
    this.moveClick.emit(GroupId);
  }
}
