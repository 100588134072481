import { Pipe, PipeTransform } from "@angular/core";
import { Nillable } from "../types/nillable";
import { isNil } from "lodash-es";

@Pipe({
  name: "noData",
})
export class NoDataPipe implements PipeTransform {
  transform<T>(value: Nillable<T>, text = "-"): T | string {
    if (isNil(value)) {
      return text;
    }

    return value;
  }
}
