import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Lang } from "../../../interfaces/lang";

@Component({
  selector: "default-language-picker-button-layout",
  templateUrl: "./default-language-picker-button-layout.component.html",
  styleUrls: ["./default-language-picker-button-layout.component.scss"],
})
export class DefaultLanguagePickerButtonLayoutComponent {
  @Input() currentLang: Lang;
  @Input() isToggled: boolean;

  @Output() toggleClickEvent = new EventEmitter<MouseEvent>();

  handleToggleClick(event: MouseEvent) {
    this.toggleClickEvent.emit(event);
  }
}
