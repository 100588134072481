import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {
  @Input() width = 32;
  @Input() height = 32;

  constructor() {}

  ngOnInit() {}

  getSpinnerDimensions(): { [key: string]: string } {
    return {
      width: this.width + 'px',
      height: this.height + 'px'
    };
  }
}
