import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import {
  CourseAdmin,
  PagionationData,
} from "../../interfaces/orgadmin/course-organization";
import { Observable } from "rxjs";
import { InvitedTeachers } from "../../interfaces/orgadmin/invited-teacher";
import { InvitationsRequest } from "../../interfaces/invitations-request";
import {
  CourseGroup,
  CourseTeachers,
  TeacherDetails,
} from "../../interfaces/orgadmin/course-teachers";
import {
  GroupStudents,
  InvitedStudentsPerTeacher,
  InvitedUser,
} from "../../interfaces/orgadmin/invited-students";
import { CreateTeamTeachers } from "src/app/modules/orgadmin/components/team-teachers/team-teachers-output.model";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  constructor(private readonly apiService: ApiService) {}

  getSubscriptions(
    organizationId: string,
    page: number,
    size: number,
    search?: string,
    active?: boolean,
  ): Observable<PagionationData<CourseAdmin>> {
    const params = new HttpParams()
      .set("page", page ? page : 1)
      .set("size", size ? size : 10)
      .set("search", search ? search : "")
      .set("active", active);

    return this.apiService.getRequest<PagionationData<CourseAdmin>>(
      `orgadmin/organizations/${organizationId}/subscriptions/?${params.toString()}`,
    );
  }

  getInvitedTeachers(
    organizationId: string,
    active: string = "False",
  ): Observable<InvitedTeachers> {
    return this.apiService.getRequest<InvitedTeachers>(
      `orgadmin/organizations/${organizationId}/invitations-teacher/?active_teachers=${active}`,
    );
  }

  sendInviteTeacher(
    organizationId: string,
    payload: InvitationsRequest,
  ): Observable<InvitedUser[]> {
    return this.apiService.postRequest<InvitedUser[]>(
      `orgadmin/organizations/${organizationId}/invitations-teacher/`,
      payload,
    );
  }

  getSingleSubscription(
    organizationId: string,
    subId: string,
  ): Observable<CourseTeachers> {
    return this.apiService.getRequest<CourseTeachers>(
      `orgadmin/organizations/${organizationId}/subscriptions/${subId}/`,
    );
  }

  createTeamTeachers(
    organizationId: string,
    courseId: string,
    payload: CreateTeamTeachers,
  ) {
    return this.apiService.postRequest(
      `orgadmin/organizations/${organizationId}/course/${courseId}/team-teachers/`,
      payload,
    );
  }

  editTeamTeachers(
    organizationId: string,
    courseId: string,
    teamId: string,
    payload: Partial<CreateTeamTeachers>,
  ) {
    return this.apiService.patchRequest(
      `orgadmin/organizations/${organizationId}/course/${courseId}/team-teachers/${teamId}/`,
      payload,
    );
  }

  deleteTeamTeachers(organizationId: string, courseId: string, teamId: string) {
    return this.apiService.deleteRequest(
      `orgadmin/organizations/${organizationId}/course/${courseId}/team-teachers/${teamId}/`,
    );
  }

  getInvitedStudents(
    organizationId: string,
    courseId: string,
  ): Observable<InvitedStudentsPerTeacher[]> {
    return this.apiService.getRequest<InvitedStudentsPerTeacher[]>(
      `orgadmin/organizations/${organizationId}/course/${courseId}/active-users/`,
    );
  }

  getActiveTeacher(
    organizationId: string,
    teacherId: string,
  ): Observable<TeacherDetails> {
    return this.apiService.getRequest<TeacherDetails>(
      `orgadmin/organizations/${organizationId}/teacher/${teacherId}`,
    );
  }

  deleteInvitationTeacher(
    organizationId: string,
    inviteId: string,
  ): Observable<void> {
    return this.apiService.deleteRequest<void>(
      `orgadmin/organizations/${organizationId}/invitations-teacher/${inviteId}/`,
    );
  }

  deleteInvitationStudent(
    organizationId: string,
    courseId: string,
    inviteId: string,
  ): Observable<void> {
    return this.apiService.deleteRequest<void>(
      `orgadmin/organizations/${organizationId}/course/${courseId}/active-users/${inviteId}/`,
    );
  }

  getInvitedStudentsInGroup(
    organizationId: string,
    teacherId: string,
    groupId: string,
  ): Observable<GroupStudents> {
    return this.apiService.getRequest<GroupStudents>(
      `orgadmin/organizations/${organizationId}/teacher/${teacherId}/group/${groupId}/invitations/`,
    );
  }

  getStatsGroup(organizationId: string, teacherId: string, groupId: string) {
    return this.apiService.getRequest(
      `orgadmin/organizations/${organizationId}/teacher/${teacherId}/group/${groupId}/stats/`,
    );
  }

  archiveGroup(
    organizationId: string,
    teacherId: string,
    groupId: string,
  ): Observable<void> {
    return this.apiService.putRequest<void>(
      `orgadmin/organizations/${organizationId}/teacher/${teacherId}/group/${groupId}/archive/`,
      { archived: true },
    );
  }
}
