import { Component } from '@angular/core';

@Component({
  selector: 'recaptcha-info',
  template: `
    <p class="paragraph-small">
      <span class="color-hannotationgray" [innerHTML]="'GLOBAL.RECAPTCHA_INFO' | translate">
      </span>
    </p>
  `,
  styleUrls: ['./recaptcha-info.component.scss']
})
export class RecaptchaInfoComponent {

  constructor() { }


}
