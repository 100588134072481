import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { last, takeWhile, withLatestFrom } from "rxjs/operators";
import { webSocket, WebSocketSubject } from "rxjs/webSocket";
import * as errorConfig from "src/app/config/errors";
import * as fromAccount from "src/app/store/actions/account.actions";
import * as fromCourse from "src/app/store/actions/course.actions";
import * as fromCourseWS from "src/app/store/actions/course.websocket.actions";
import * as fromGroups from "src/app/store/actions/groups.actions";
import * as fromDashboard from "src/app/store/actions/dashboard.actions";
import * as fromExerciseCode from "src/app/store/actions/exercise-code.actions";
import * as fromUI from "src/app/store/actions/ui.actions";
import * as fromRanking from "src/app/store/actions/ranking.actions";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";
import * as fromUIStore from "src/app/store/reducers/ui.reducer";
import { environment } from "../../../../environments/environment";
import { NotificationToastComponent } from "../../components/notifications/notification-toast.component";
import { ExerciseMark } from "../../enums/exercise-mark";
import { CodeRunVerification } from "../../interfaces/code-run-verification";
import { CodeVerification } from "../../interfaces/code-verification";
import { Member } from "../../interfaces/member";
import { NotificationElement } from "../../interfaces/notification-element";
import {
  WebsocketMessage,
  WebsocketMessageUIBody,
} from "../../interfaces/websocket-message";
import {
  NotificationSettings,
  NotificationSnooze,
} from "../../types/notification-settings";
import { WebSocketMessageType } from "../../types/websocket-message-type";
import { EscapeService } from "../escape/escape.service";
import { NotificationsService } from "../notifications/notifications.service";
import { WarningModalService } from "../warning-modal/warning-modal.service";
import { Exercise } from "../../interfaces/exercise";
import { PrepareAction } from "../../enums/prepare-action";
import { AnswerType } from "../../types/answer-type";
import { Guid } from "../../types/guid";
import { CourseExerciseRefreshService } from "../../components/course/course-exercise-listbox-element-content/services/course-exercise-refresh.service";
import { flaggedExerciseListLoad } from "src/app/store/actions/flagged-exercises/get-flagged-exercises.actions";
import { Group } from "../../interfaces/group";
import { FlaggedExerciseList } from "../../interfaces/flagged-exercises";
import { courseProgressLoad } from "src/app/store/actions/course-progress.actions";

@Injectable({
  providedIn: "root",
})
export class WebSocketService {
  private wsUrl = environment.wsLocation;
  private accessTokenMessage;
  private closeManually: boolean = false;

  subject: WebSocketSubject<any>;

  retryTimeoutPeriod: 0 | 1000 | 5000 | 10000 = 0;
  retryTimeout: number = 0;

  constructor(
    private store: Store<{
      ui: fromUIStore.UIState;
      account: fromAccountStore.AccountState;
    }>,
    private notificationsService: NotificationsService,
    private warningModalService: WarningModalService,
    private translate: TranslateService,
    private router: Router,
    private escapeService: EscapeService,
    private readonly courseExerciseRefreshService: CourseExerciseRefreshService,
  ) {}

  connect(closeManually = true): void {
    if (this.subject && !this.subject.closed) {
      this.closeManually = closeManually;
      this.subject.unsubscribe();
    }

    if (!this.subject || this.subject.closed) {
      clearTimeout(this.retryTimeout);
      this.subject = webSocket(this.wsUrl);

      this.subject.subscribe(
        (msg: WebsocketMessage) => {
          // It's ok to reset timeout rate now
          this.retryTimeoutPeriod = 0;

          let ui: WebsocketMessageUIBody = msg.body as WebsocketMessageUIBody;
          let notifications: Array<NotificationElement> =
            msg.body as Array<NotificationElement>;

          this.store
            .select((state) => state.account.member)
            .pipe(
              takeWhile((member: Member) => !member, true),
              last(),
              withLatestFrom(
                this.store.select((state) => state.ui.notificationSettings),
                this.store.select((state) => state.ui.startedExerciseId),
              ),
            )
            .subscribe(
              ([member, notificationSettings, startedExerciseId]: [
                Member,
                NotificationSettings,
                Guid | null,
              ]) => {
                let showToast: boolean = false;
                let showModal: boolean = false;
                let messageText: string = "";
                let url: string = "";
                let useHighlighter: boolean = false;
                let markType: ExerciseMark = ExerciseMark.None;
                let affectedCount: number = 0;

                switch (msg.type) {
                  case "NOTIFICATION_RECEIVED":
                    if (notifications.length > 0) {
                      this.notificationsService.showToastr(
                        notifications[0] as NotificationElement,
                      );

                      this.actionByUrl(notifications[0].content.url);
                    }
                    this.store.dispatch(new fromUI.ClearNotifications());
                    this.store.dispatch(new fromUI.GetNotifications(1));
                    break;

                  case "EXERCISE_REVIEWED": {
                    showToast = this.shouldShowToast(
                      msg.type,
                      notificationSettings,
                    );

                    const reviewLabel = ui.review
                      ? "EXERCISE_REVIEW_PASSED"
                      : "EXERCISE_REVIEW_FAILED";
                    messageText = `GLOBAL.WEBSOCKET_MESSAGES.${reviewLabel}`;
                    url = `/student/courses/${ui.course.id}/groups/${ui.group.id}?topicId=${ui.topic.id}`;

                    useHighlighter = true;
                    (ui.exercise as Exercise).mark = ExerciseMark.Highlighted;
                    markType = ExerciseMark.Highlighted;

                    this.courseExerciseRefreshService.refresh(
                      ui.exercise as Exercise,
                    );

                    this.store.dispatch(
                      new fromCourseWS.PrepareTopicForUpdate({
                        groupId: ui.group.id,
                        topicId: ui.topic.id,
                        exercises: [ui.exercise] as Array<Exercise>,
                        action: msg.type as PrepareAction,
                      }),
                    );

                    break;
                  }

                  case "EXERCISE_ADDED":
                  case "EXERCISE_DELETED":
                  case "EXERCISE_HELP_TURNED_ON":
                  case "EXERCISE_HELP_TURNED_OFF":
                  case "EXERCISE_QUIZ_SOLUTIONS_TURNED_ON":
                  case "EXERCISE_QUIZ_SOLUTIONS_TURNED_OFF":
                    const pluralLangs = {
                      pl: "pl-PL",
                      en: "en-US",
                    };

                    const plural = new Intl.PluralRules(
                      pluralLangs[this.translate.currentLang],
                    );

                    showToast = this.shouldShowToast(
                      msg.type,
                      notificationSettings,
                    );

                    affectedCount = (<Array<Exercise>>ui.exercise).length;

                    messageText = `GLOBAL.WEBSOCKET_MESSAGES.${
                      affectedCount > 1
                        ? `BULK.${plural.select(affectedCount).toUpperCase()}_`
                        : ""
                    }${msg.type}`;
                    url = `/${
                      member.is_teacher ? "teacher" : "student"
                    }/courses/${ui.course.id}/groups/${ui.group.id}?topicId=${
                      ui.topic.id
                    }`;

                    if (
                      msg.type.includes("HELP") ||
                      msg.type.includes("QUIZ")
                    ) {
                      useHighlighter = true;
                      markType = ExerciseMark.Highlighted;
                    }

                    if (msg.type.includes("DELETED")) {
                      this.store.dispatch(
                        new fromCourseWS.RefreshRunningExercise(ui),
                      );
                    }

                    if (
                      (!member.is_teacher && msg.type.includes("ADDED")) ||
                      (!member.is_teacher && msg.type.includes("DELETED"))
                    ) {
                      this.store.dispatch(
                        courseProgressLoad({
                          CourseId: ui.course.id,
                          GroupId: ui.group.id,
                        }),
                      );
                    }

                    this.store.dispatch(
                      new fromCourseWS.PrepareTopicForUpdate({
                        groupId: ui.group.id,
                        topicId: ui.topic.id,
                        exercises: ui.exercise as Array<Exercise>,
                        action: msg.type as PrepareAction,
                      }),
                    );
                    break;

                  case "EXERCISE_STARTED": {
                    const exercise = <Exercise>ui.exercise;
                    const { is_teacher } = member;

                    useHighlighter = true;
                    markType = ExerciseMark.Highlighted;
                    exercise.mark = ExerciseMark.Highlighted;

                    this.completeExerciseStartingProcess(exercise, ui);

                    switch (exercise.answer_type) {
                      case AnswerType.ANSWER_TYPE_CODE:
                        this.navigateToIDECode(
                          ui,
                          exercise,
                          is_teacher,
                          startedExerciseId,
                        );
                        this.showRunningExerciseToolbar();

                        break;

                      case AnswerType.ANSWER_TYPE_UPLOAD:
                        this.navigateToIDEUpload(
                          ui,
                          exercise,
                          is_teacher,
                          startedExerciseId,
                        );
                        this.showRunningExerciseToolbar();

                        break;

                      case AnswerType.ANSWER_TYPE_QUIZ:
                        this.navigateToQuiz(
                          ui,
                          exercise,
                          is_teacher,
                          startedExerciseId,
                        );
                        this.showRunningExerciseToolbar();

                        break;

                      case AnswerType.ANSWER_TYPE_NONE:
                        if (!exercise.requires_laboratory) {
                          this.markAsAnswered(ui, exercise);
                        } else {
                          this.showRunningExerciseToolbar();
                        }

                        break;

                      default:
                        this.showRunningExerciseToolbar();
                        break;
                    }

                    this.resetStartedExerciseId(startedExerciseId, exercise.id);

                    break;
                  }

                  case "EXERCISE_STATUS_UPDATE": {
                    const exercise = <Exercise>ui.exercise;

                    useHighlighter = true;
                    markType = ExerciseMark.Highlighted;
                    exercise.mark = ExerciseMark.Highlighted;

                    this.completeExerciseStartingProcess(exercise, ui);
                    this.showRunningExerciseToolbar();
                    this.resetStartedExerciseId(startedExerciseId, exercise.id);

                    break;
                  }

                  case "EXERCISE_START_FAILURE":
                    const exercise = <Exercise>ui.exercise;

                    showModal = true;
                    messageText =
                      "GLOBAL.WEBSOCKET_MESSAGES.EXERCISE_START_FAILURE";

                    this.store.dispatch(
                      new fromCourse.StartExerciseFailed({
                        CourseId: ui.course.id,
                        TopicId: ui.topic.id,
                        ExerciseId: exercise.id,
                      }),
                    );

                    this.showRunningExerciseToolbar();
                    this.resetStartedExerciseId(startedExerciseId, exercise.id);

                    break;

                  case "EXERCISE_SOLVED":
                    showToast = this.shouldShowToast(
                      msg.type,
                      notificationSettings,
                    );
                    messageText = "GLOBAL.WEBSOCKET_MESSAGES.EXERCISE_SOLVED";
                    url = `/teacher/courses/${ui.course.id}/groups/${ui.group.id}?topicId=${ui.topic.id}`;

                    useHighlighter = true;
                    markType = ExerciseMark.Highlighted;
                    (<Exercise>ui.exercise).mark = ExerciseMark.Highlighted;

                    this.store.dispatch(
                      new fromCourseWS.UpdateCourseStats({
                        CourseId: ui.course.id,
                        GroupId: ui.group.id,
                        TopicId: ui.topic.id,
                      }),
                    );

                    break;

                  case "STUDENT_SUSPENDED":
                    showToast = this.shouldShowToast(
                      msg.type,
                      notificationSettings,
                    );
                    messageText = "GLOBAL.WEBSOCKET_MESSAGES.STUDENT_SUSPENDED";
                    url = `/student/dashboard`;

                    if (this.router.url.includes(ui.group.id)) {
                      this.router.navigate([url]);
                    } else if (this.router.url.includes("dashboard")) {
                      this.store.dispatch(new fromDashboard.GetDashboard());
                    }

                    this.showRunningExerciseToolbar();

                    break;

                  case "VPN_UPDATED":
                    showToast = this.shouldShowToast(
                      msg.type,
                      notificationSettings,
                    );
                    messageText = `GLOBAL.WEBSOCKET_MESSAGES.VPN_UPDATED_${
                      (<Member>ui.user).remote_ip ? "ON" : "OFF"
                    }`;

                    this.store.dispatch(
                      new fromAccount.UpdateUserVPNConnectionStatus(
                        ui.user as Member,
                      ),
                    );
                    break;

                  case "USER_VPN_UPDATED":
                    showToast = this.shouldShowToast(
                      msg.type,
                      notificationSettings,
                    );
                    messageText = `GLOBAL.WEBSOCKET_MESSAGES.USER_VPN_UPDATED_${
                      (<Member>ui.user).remote_ip ? "ON" : "OFF"
                    }`;

                    this.store.dispatch(
                      new fromCourseWS.UpdateUserVPNConnectionStatus(
                        ui.user as Member,
                      ),
                    );
                    break;

                  case "CODE_CHECK_RESULT":
                    showToast = false;
                    this.store.dispatch(
                      new fromExerciseCode.WebsocketVerification(
                        msg.body as CodeVerification,
                      ),
                    );
                    break;

                  case "CODE_CHECK_RUN_RESULT":
                    showToast = false;
                    this.store.dispatch(
                      new fromExerciseCode.WebsocketRun(
                        msg.body as CodeRunVerification,
                      ),
                    );
                    break;

                  case "RANKING_REQUEST_FINISHED":
                    showToast = false;
                    this.store.dispatch(
                      new fromRanking.RankingRequestFinished(
                        msg.body as Required<
                          Pick<WebsocketMessageUIBody, "hash">
                        >,
                      ),
                    );
                    break;

                  case "STUDENT_HELP_REQUEST_CREATED":
                    const flaggedExercises = msg.body as {
                      course_group: Group;
                      shr: FlaggedExerciseList;
                    };

                    this.store.dispatch(
                      flaggedExerciseListLoad({
                        courseId: flaggedExercises.course_group.course.id,
                        groupId: flaggedExercises.course_group.id,
                      }),
                    );
                    break;

                  case "STUDENT_HELP_REQUEST_CANCELED":
                    const flaggedExercise = msg.body as {
                      course_group: Group;
                      shr: FlaggedExerciseList;
                    };

                    this.store.dispatch(
                      flaggedExerciseListLoad({
                        courseId: flaggedExercise.course_group.course.id,
                        groupId: flaggedExercise.course_group.id,
                      }),
                    );
                    break;
                }

                if (messageText) {
                  let exerciseName =
                    typeof ui.exercise !== "undefined"
                      ? Array.isArray(ui.exercise)
                        ? ui.exercise.length === 1
                          ? ui.exercise[0].name
                          : ""
                        : ui.exercise.name
                      : "";

                  this.translate
                    .get(messageText, {
                      courseName: ui.course ? ui.course.name : "",
                      groupName: ui.group ? ui.group.name : "",
                      topicName: ui.topic ? ui.topic.name : "",
                      exerciseName,
                      userName: ui.user
                        ? this.escapeService.escapeHTML(ui.user.name)
                        : "",
                      userEmail: ui.user
                        ? this.escapeService.escapeHTML((<Member>ui.user).email)
                        : "",
                      userRemoteIP: ui.user
                        ? this.escapeService.escapeHTML(
                            (<Member>ui.user).remote_ip,
                          )
                        : "",
                      affectedCount,
                    })
                    .subscribe((resource: string) => {
                      if (showToast) {
                        let toastr: NotificationToastComponent =
                          this.notificationsService.showToastr({
                            id: null,
                            content: {
                              message: resource,
                              url: url,
                              urlType: "default",
                            },
                            type: "info",
                            created_at: moment().format(),
                            read_at: null,
                          } as NotificationElement);

                        if (useHighlighter) {
                          let exerciseId = Array.isArray(ui.exercise)
                            ? ui.exercise.length === 1
                              ? ui.exercise[0].id
                              : null
                            : ui.exercise.id;

                          toastr.notificationClick.subscribe(() => {
                            this.store.dispatch(
                              new fromCourseWS.HighlightExercise({
                                id: exerciseId,
                                markType,
                              }),
                            );
                          });
                        }
                      } else if (showModal) {
                        this.warningModalService.showModal({
                          modalTitle: `GLOBAL.MESSAGES.ERROR_TITLES.ERROR_400`,
                          text: resource,
                          icon: errorConfig.ERROR_ICONS[400],
                        });
                      }
                    });
                }
              },
            );

          return false;
        },
        (error) => {
          this.retry(this.accessTokenMessage);
        },
        () => {
          if (!this.closeManually) {
            this.retry(this.accessTokenMessage);
          } else {
            this.closeManually = false;
          }
        },
      );
    }
  }

  authorize(accessTokenMessage): void {
    this.accessTokenMessage = accessTokenMessage;
    this.send(accessTokenMessage);
  }

  send(message: any): void {
    this.subject.next(message);
  }

  private shouldShowToast(
    messageType: WebSocketMessageType,
    settings: NotificationSettings,
  ): boolean {
    if (settings) {
      let date: string = settings.get("notificationSnooze").get("date");
      let period: NotificationSnooze["period"] = settings
        .get("notificationSnooze")
        .get("period");
      let isSnoozed =
        (date &&
          Number.isInteger(<number>period) &&
          moment().isBefore(moment(date))) ||
        period === "always";
      return (
        !isSnoozed && settings.get("notificationTypeSettings").get(messageType)
      );
    } else {
      return true;
    }
  }

  private retry(accessTokenMessage): void {
    switch (this.retryTimeoutPeriod) {
      case 0:
        this.retryTimeoutPeriod = 1000;
        break;

      case 1000:
        this.retryTimeoutPeriod = 5000;
        break;

      case 5000:
        this.retryTimeoutPeriod = 10000;

        break;
    }

    console.error(
      "Connection error. Retry in " + this.retryTimeoutPeriod / 1000 + "s.",
    );
    this.retryTimeout = setTimeout(() => {
      this.connect(false);
      this.authorize(accessTokenMessage);
    }, this.retryTimeoutPeriod) as unknown as number;
  }

  private actionByUrl(url: string | undefined): void {
    if (
      typeof url !== "undefined" &&
      url.includes("student/groups/invitations")
    ) {
      this.store.dispatch(new fromGroups.GetInvitations());
      return;
    }

    return;
  }

  private completeExerciseStartingProcess(
    exercise: Exercise,
    ui: WebsocketMessageUIBody,
  ): void {
    this.store.dispatch(
      new fromCourse.StartExerciseCompleted({
        exercise: exercise,
        TopicId: ui.topic.id,
        GroupId: ui.group?.id,
      }),
    );
  }

  private markAsAnswered(ui: WebsocketMessageUIBody, exercise: Exercise): void {
    this.store.dispatch(
      new fromCourse.Answer({
        CourseId: ui.course.id,
        ExerciseId: exercise.id,
        GroupId: ui.group?.id,
        TopicId: ui.topic.id,
        request: { answer: null },
        was_passed_before: exercise.was_passed_before,
      }),
    );
  }

  private navigateToQuiz(
    ui: WebsocketMessageUIBody,
    exercise: Exercise,
    isTeacher: boolean,
    startedExerciseId: Guid | null,
  ): void {
    if (startedExerciseId) {
      void this.router.navigate(
        [
          `/quiz/${ui.course.id}${
            isTeacher ? "/" : `/groups/${ui.group.id}/`
          }exercise/${exercise.id}`,
        ],
        {
          queryParamsHandling: "merge",
        },
      );
    }
  }

  private navigateToIDEUpload(
    ui: WebsocketMessageUIBody,
    exercise: Exercise,
    isTeacher: boolean,
    startedExerciseId: Guid | null,
  ): void {
    if (startedExerciseId) {
      void this.router.navigate(
        [
          `/ide/upload/${ui.course.id}${
            isTeacher ? "/" : `/groups/${ui.group.id}/`
          }exercise/${exercise.id}`,
        ],
        {
          queryParamsHandling: "merge",
        },
      );
    }
  }

  private navigateToIDECode(
    ui: WebsocketMessageUIBody,
    exercise: Exercise,
    isTeacher: boolean,
    startedExerciseId: Guid | null,
  ): void {
    if (startedExerciseId === exercise.id) {
      void this.router.navigate(
        [
          `/ide/code/${ui.course.id}${
            isTeacher ? "/" : `/groups/${ui.group.id}/`
          }exercise/${exercise.id}`,
        ],
        {
          queryParamsHandling: "merge",
        },
      );
    }
  }

  private showRunningExerciseToolbar(): void {
    this.store.dispatch(new fromUI.GetRunningExercise());
  }

  private resetStartedExerciseId(
    startedExerciseId: Guid | null,
    exerciseId: Guid,
  ): void {
    if (startedExerciseId === exerciseId) {
      this.store.dispatch(new fromUI.SetStartedId(null));
    }
  }
}
