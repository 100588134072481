import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

@Component({
  selector: "flag-exercise-btn",
  templateUrl: "./flag-exercise-btn.component.html",
  styleUrls: ["./flag-exercise-btn.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlagExerciseBtnComponent {
  @Input() isClicked: string | null = null;
  @Output() clicked: EventEmitter<string | null> = new EventEmitter<
    string | null
  >();

  emitAction(isClicked: string | null): void {
    this.clicked.emit(isClicked);
  }
}
