import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map, tap } from "rxjs/operators";
const RESIZE_DEBOUNCE_MS = 100;

@Directive({
  selector: "[widget]",
})
export class WidgetDirective implements OnInit, OnDestroy {
  @Input() resizable: boolean = false;
  @Input() sizes: Array<number>;
  @Output() breakpointHit: EventEmitter<number> = new EventEmitter();

  constructor(private ref: ElementRef) {}

  private resizeSubscription: Subscription;
  private resizeSubject: Subject<number> = new Subject();
  private resizeObserver: ResizeObserver;

  ngOnInit(): void {
    if (this.resizable) {
      this.sizes.sort();
      this.setupResize();
    }
  }

  ngOnDestroy(): void {
    if (this.resizable) {
      this.resizeSubscription.unsubscribe();
      this.resizeObserver.unobserve(this.ref.nativeElement);
      this.resizeObserver.disconnect();
    }

    delete this.resizeSubject;
  }

  private setupResize(): void {
    this.resizeObserver = new ResizeObserver(
      (entries: Array<ResizeObserverEntry>) => {
        this.resizeSubject.next(entries[0].contentRect.width);
      },
    );

    this.resizeObserver.observe(this.ref.nativeElement);

    this.resizeSubscription = this.resizeSubject
      .pipe(
        debounceTime(RESIZE_DEBOUNCE_MS),
        map((size) => this.getRangeIndex(size)),
        distinctUntilChanged((prev, next) => prev === next),

        tap((index) => {
          this.breakpointHit.emit(this.sizes[index]);
        }),
      )
      .subscribe();
  }

  private getRangeIndex(_size: number): number {
    return this.sizes.filter((size) => _size >= size).length - 1;
  }
}
