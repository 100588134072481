<ng-container *ngIf="{ isRunning: isRunning$ | async } as controlButtons">
  <quiz-history-button
    *ngIf="isTeacher || exercise.quiz_properties?.has_solutions"
    [courseId]="courseId"
    [teacher]="isTeacher"
    [exercise]="exercise"
  >
  </quiz-history-button>

  <button
    [disabled]="exercise.status === 'starting'"
    class="theme-hlogo exercise-control-button"
    type="button"
    (click)="start($event, controlButtons.isRunning)"
  >
    <countdown *ngIf="exercise.status === 'starting'"></countdown>
    {{ "QUIZ.EXERCISE.GO_TO_QUIZ" | translate }}
  </button>
</ng-container>
