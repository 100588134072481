import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { VideoPlayer } from "../../models/video-player";

@Component({
  selector: "custom-video-player",
  templateUrl: "./custom-video-player.component.html",
  styleUrls: ["./custom-video-player.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomVideoPlayerComponent implements AfterViewInit, OnDestroy {
  @ViewChild("player", { static: false }) playerRef: ElementRef;
  private player: VideoPlayer;

  @Input() src: string;

  constructor(private translate: TranslateService) {}

  ngAfterViewInit(): void {
    const currentLang = this.translate.currentLang;
    this.player = new VideoPlayer();
    this.player.initPlayer(this.playerRef.nativeElement, currentLang, {
      sources: [
        {
          src: this.src,
          type: "application/x-mpegURL",
        },
      ],
      responsive: true,
    });
  }

  ngOnDestroy(): void {
    this.player.disposePlayer();
  }
}
