import { Injectable } from "@angular/core";
import { NavbarNavItem } from "../models/navbar-nav-item";
import { Guid } from "../types/guid";

@Injectable({
  providedIn: "root",
})
export class NavbarNavAdminProvider {
  getRightSideItems(organizationId?: Guid): Array<NavbarNavItem> {
    return organizationId
      ? [
          {
            label: "??",
            icon: "mortar-board",
            iconSet: "typcn",
            route: "/test",
          },
          {
            label: "??",
            icon: "users-alt",
            iconSet: "uil",
            route: "/test",
          },
        ]
      : [];
  }

  getLeftSideItems(organizationId?: Guid): Array<NavbarNavItem> {
    return organizationId
      ? [
          {
            label: "GLOBAL.NAVBAR.NAV.DASHBOARD",
            icon: "home-outline",
            iconSet: "typcn",
            route: `/admin/organization/${organizationId}`,
          },
        ]
      : [];
  }
}
