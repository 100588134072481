import { Injectable } from '@angular/core';
import { WarningModalComponent } from '../../components/modals/warning-modal/warning-modal.component';
import { WarningModalData } from '../../interfaces/warning-modal-data';
import { ModalService } from '../modal/modal.service';

@Injectable({
  providedIn: 'root'
})
export class WarningModalService {
  constructor(private modalService: ModalService) {}

  showModal(data: WarningModalData) {
    if (!data.buttonText) {
      data.buttonText = 'GLOBAL.OK_BUTTON';
    }

    if (!data.icon) {
      data.icon = 'warning-outline';
    }
    this.modalService.showModal<WarningModalData>(data, WarningModalComponent, 'WARNING_MODAL');
  }
}
