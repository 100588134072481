import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { GroupStudents } from "src/app/shared/interfaces/orgadmin/invited-students";

export const coursePrefix = "[Invited Group Students List]";

const invitedGroupStudentsListActions = {
  request: `${coursePrefix} Load`,
  requestSuccess: `${coursePrefix} Load success`,
  requestFail: `${coursePrefix} Load fail`,
};

export const invitedGroupStudentsListLoad = createAction(
  invitedGroupStudentsListActions.request,
  props<{ organizationId: string; teacherId: string; groupId: string }>(),
);

export const invitedGroupStudentsListLoadSuccess = createAction(
  invitedGroupStudentsListActions.requestSuccess,
  props<{ invitedStudents: GroupStudents }>(),
);

export const invitedGroupStudentsListLoadFail = createAction(
  invitedGroupStudentsListActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
