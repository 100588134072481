<div class="d-flex flex-column w-100">
  <ul class="file-list">
    <ng-container [ngSwitch]="item?.code_check.check_type">
      <li
        *ngSwitchCase="CodeCheckType.VERIFY"
        class="tests-tab"
        [class.active]="activeTab === 'tests'"
        (click)="activeTab = 'tests'"
      >
        <i class="uil uil-file-check-alt"></i>
        {{ "COURSES.COURSE.EXERCISE.CODE_CHECK_HISTORY.TESTS" | translate }}
      </li>

      <!--  in case of run there is only one test
            show its tabs alongside other main tabs -->
      <ng-container *ngSwitchCase="CodeCheckType.RUN">
        <ng-container *ngIf="item?.code_check.test_results[0] as test">
          <li
            *ngFor="let tab of testTabs"
            [class.active]="activeTab === tab"
            (click)="activeTab = tab"
          >
            <i class="uil uil-file-info-alt"></i> {{ tab }}
          </li>
        </ng-container>
      </ng-container>
    </ng-container>

    <li
      *ngFor="let file of item?.code_check.files; let i = index"
      [class.active]="activeTab === i"
      (click)="activeTab = i"
    >
      <i class="uil uil-file"></i> {{ file.filename }}
    </li>
  </ul>

  <ngx-monaco-editor
    class="monaco-editor"
    *ngIf="isNumber(activeTab) && item?.code_check.files[activeTab]"
    [options]="editorOptions"
    [(ngModel)]="item?.code_check.files[activeTab].content"
  >
  </ngx-monaco-editor>

  <ng-container *ngIf="testTabs.includes(activeTab)">
    <ngx-monaco-editor
      *ngIf="item?.code_check.test_results[0] as test"
      class="monaco-editor"
      [options]="testEditorOptions"
      [(ngModel)]="test[activeTab]"
    >
    </ngx-monaco-editor>
  </ng-container>

  <div class="tests-list-box" *ngIf="activeTab === 'tests'">
    <ul class="test-list" [ngClass]="{ 'test-active': activeTest }">
      <li
        *ngFor="let test of item?.code_check.test_results"
        [ngClass]="getTestClass(test, activeTest, isTeacher)"
        (click)="isTeacher ? (activeTest = test) : null"
      >
        <div class="test-status"></div>
        <div class="test-stats">
          <p class="label test-name">{{ test.name }}</p>
          <p *ngIf="test.result.description" class="test-desc">
            {{ test.result.description }}
          </p>
          <span class="test-numbers">
            <ng-container *ngIf="test.memory && test.time">
              <i class="typcn typcn-watch"></i>
              {{ test.time
              }}{{ test.time_limit ? "/" + test.time_limit : "" }}s,
              <i class="uil uil-processor"></i>
              {{ test.memory
              }}{{ test.memory_limit ? "/" + test.memory_limit : "" }}KB
            </ng-container>
          </span>
        </div>
      </li>
    </ul>

    <div class="active-test" *ngIf="activeTest && isTeacher">
      <ul class="test-tab-list">
        <li
          *ngFor="let tab of testTabs"
          [class.active]="activeTestTab === tab"
          (click)="activeTestTab = tab"
        >
          <i class="uil uil-file-info-alt"></i> {{ tab }}
        </li>
      </ul>

      <ngx-monaco-editor
        class="monaco-editor"
        [options]="testEditorOptions"
        [(ngModel)]="activeTest[activeTestTab]"
      >
      </ngx-monaco-editor>
    </div>
  </div>
</div>
