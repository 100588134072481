import {
  Component,
  ContentChildren,
  Input,
  QueryList,
  TemplateRef,
} from "@angular/core";
import { ListboxElementComponent } from "../listbox-element/listbox-element.component";

@Component({
  selector: "ul[listbox], ol[listbox], listbox",
  templateUrl: "./listbox.component.html",
  styleUrls: ["./listbox.component.scss"],
})
export class ListboxComponent {
  @Input() noContentTemplateRef: TemplateRef<void>;

  @ContentChildren(ListboxElementComponent)
  elements: QueryList<ListboxElementComponent>;

  get elementsCount(): number {
    return this.elements.length;
  }
}
