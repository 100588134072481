import { Pipe, PipeTransform } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";
import { Guid } from "../types/guid";
import { Nillable } from "../types/nillable";

@Pipe({
  name: "organizationName",
})
export class OrganizationNamePipe implements PipeTransform {
  constructor(
    private store: Store<{ account: fromAccountStore.AccountState }>,
  ) {}

  transform(value: Nillable<Guid>): Observable<string> {
    return this.store.select(
      (state) =>
        state.account.member?.organizations?.find(
          (organization) => organization.id === value,
        )?.name || "",
    );
  }
}
