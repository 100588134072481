import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'div[simple-element], simple-element',
  template: `
    <ng-content></ng-content>
  `,
  host: {
    class: 'simple-element'
  }
})
export class SimpleElementComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
