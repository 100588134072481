import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Group } from "../../../interfaces/group";
import { Guid } from "../../../types/guid";
import { CourseGroupActionType } from "./types/course-group-action-type";

@Component({
  selector: "course-group-list-actions",
  templateUrl: "./course-group-list-actions.component.html",
  styleUrls: ["./course-group-list-actions.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseGroupListActionsComponent {
  @Input({ required: true }) courseId: Guid;
  @Input() group?: Group;
  @Input() isTeacher?: boolean;
  @Input() disabledActions: Array<CourseGroupActionType> = [];

  isDisabled(action: CourseGroupActionType): boolean {
    return this.disabledActions.includes(action);
  }
}
