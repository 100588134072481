import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { PackageUsers } from "src/app/shared/interfaces/packages/package-users";

export const coursePrefix = "[Package Users List]";

const packageUsersListActions = {
  request: `${coursePrefix} Load`,
  requestActiveSuccess: `${coursePrefix} Load success`,
  requestFail: `${coursePrefix} Load fail`,
};

export const packageUsersListLoad = createAction(
  packageUsersListActions.request,
  props<{
    organizationId: string;
    packageId: string;
    teacherId?: string;
    search?: string;
  }>(),
);

export const packageUsersListLoadSuccess = createAction(
  packageUsersListActions.requestActiveSuccess,
  props<{ users: PackageUsers }>(),
);

export const loadPackageUsersListFailure = createAction(
  packageUsersListActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
