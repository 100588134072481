import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { RowsExpander } from "../../utils/rows-expander";
import { StatElementWithoutStudent } from "../../interfaces/student";
import { StatsService } from "../../services/statistics/stats.service";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "exercises-stat-list-table",
  templateUrl: "./exercises-stat-list-table.component.html",
  styleUrls: ["./exercises-stat-list-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExercisesStatListTableComponent implements OnChanges {
  @Input() exercises: StatElementWithoutStudent;
  rowsExpander = new RowsExpander();
  selectedExercises: string[] = [];

  currentPartialGrade$: Observable<string> = this.stats.currentPartialGradeId;
  selectedExercises$: Observable<string[]> = this.stats.currentItems;
  selectableExercises$: Observable<boolean> = this.stats.selectableFlag;
  highlightableExercises$: Observable<boolean> =
    this.stats.highlightableExercises;

  constructor(
    private stats: StatsService,
    private destroyRef: DestroyRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { exercises } = changes;

    if (exercises) this.rowsExpander.configure(this.exercises.topics);

    this.highlightableExercises$
      .pipe(
        tap((selectable) => {
          if (selectable && !this.rowsExpander.isAllExpanded) {
            this.rowsExpander.toggleExpandAllState();
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();

    this.selectedExercises$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((selected) => {
        setTimeout(() => {
          this.selectedExercises = selected;
        });
      });
  }

  handleSetExercises(
    exercise: { id: string; name: string; order: number },
    configId: string,
  ): void {
    const exerciseId = exercise.id;
    this.stats.toggleItem(exerciseId);
  }

  isSelectedExercise(
    exercise: { id: string; name: string; order: number },
    selectedExercises: string[],
  ): boolean {
    const exerciseId = exercise.id;
    return selectedExercises.includes(exerciseId);
  }
}
