<div #trigger class="popup-trigger" role="button" (click)="show()">
  <ng-content select="[popup-trigger]" />
</div>

<ul
  #menu
  class="popup-menu"
  [class]="'position-' + position"
  [ngClass]="{ show: visible }"
  (mouseleave)="hide()"
>
  <ng-content select="[popup-content]" />
</ul>
