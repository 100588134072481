<div
  class="switch-button"
  role="button"
  [class]="'theme-' + theme"
  [ngClass]="{
    toggled: toggled,
    busy: busy
  }"
  (click)="onClick($event)"
>
  <div class="switch-button-face">
    <span class="button-icon {{ set }} {{ set }}-{{ icon }}"></span>
  </div>

  <div class="switch-button-busy">
    <countdown [color]="theme" />
  </div>
</div>
