import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Nillable } from "../../types/nillable";

@Component({
  selector: "switch-checkbox",
  templateUrl: "./switch-checkbox.component.html",
  styleUrls: ["./switch-checkbox.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchCheckboxComponent),
      multi: true,
    },
  ],
})
export class SwitchCheckboxComponent implements ControlValueAccessor {
  @Input() toggled: boolean = false;
  @Input() busy: boolean = false;
  @Input() label?: string;
  @Input() name: string;

  @Output() clicked: EventEmitter<boolean> = new EventEmitter();

  onChange = (value: Nillable<boolean>): void => {};
  onTouched = () => {};

  value: Nillable<boolean>;

  handleOnChange(_toggled: boolean): void {
    if (!this.busy) {
      this.clicked.emit(_toggled);
      this.value = _toggled;
      this.onChange(_toggled);
      this.writeValue(_toggled);
    }
  }

  registerOnChange(fn: (value: Nillable<boolean>) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: boolean): void {
    this.toggled = value;
  }
}
