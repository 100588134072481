import * as fromRanking from "../actions/ranking.actions";
import { Ranking } from "src/app/shared/interfaces/ranking";
import { RankingCriteria } from "src/app/shared/models/ranking-criteria";
import { EmptyRanking } from "src/app/shared/models/ranking";

export interface RankingState {
  ranking: Ranking;
  getRankingCompleted: boolean;
  rankingCriteria: RankingCriteria;
  hash: string | null;
}

export const initialState: RankingState = {
  ranking: null,
  getRankingCompleted: false,
  rankingCriteria: new RankingCriteria(),
  hash: null,
};

export function reducer(
  state = initialState,
  action: fromRanking.RankingActions
): RankingState {
  switch (action.type) {
    case fromRanking.GET_RANKING:
      return {
        ...state,
        getRankingCompleted: false,
        hash: null,
      };

    case fromRanking.GET_RANKING_COMPLETED:
      return {
        ...state,
        getRankingCompleted: true,
        ranking: action.payload,
      };

    case fromRanking.GET_RANKING_FAILED:
      return {
        ...state,
        getRankingCompleted: true,
        ranking: new EmptyRanking(),
      };

    case fromRanking.UPDATE_RANKING_CRITERIA:
      return {
        ...state,
        rankingCriteria: action.payload,
      };

    case fromRanking.GET_RANKING_DEFFERED:
      return {
        ...state,
        getRankingCompleted: false,
        hash: action.payload.hash,
      };

    default:
      return state;
  }
}
