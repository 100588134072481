import { RankingQuery } from "../enums/ranking-query";
import { Guid } from "../types/guid";

export class RankingCriteria {
  filter_by?: RankingQuery.FilterBy;
  group_by?: RankingQuery.GroupBy;
  span?: RankingQuery.Span;
  region_type?: RankingQuery.RegionType;
  region_scope?: RankingQuery.RegionScope;
  filter_pk?: Guid;
  ranking_type?: RankingQuery.RankingType;
  page: number;
  page_size: number;

  constructor() {
    this.page = 0;
    this.page_size = 10;
    this.span = RankingQuery.Span.all;
    this.ranking_type = RankingQuery.RankingType.points;
    this.group_by = RankingQuery.GroupBy.user;

    this.filter_by = undefined;
    this.filter_pk = undefined;
    this.region_scope = undefined;
    this.region_type = undefined;
  }
}
