import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { StatusValues } from "../../enums/status-values";
import { InvitedUser } from "../../interfaces/orgadmin/invited-students";
import { SimpleName } from "../../interfaces/orgadmin/simple-name";

export interface Column<T> {
  key: keyof T;
  title: string;
  value: (
    item: T,
  ) =>
    | string
    | string[]
    | number
    | Array<InvitedUser & { teacher: string } & { course: SimpleName }>;
  columnWidth: number;
}

@Component({
  selector: "table-generic",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent<T> {
  @Input() tableHeader: string;
  @Input() columns: Column<T>[];
  @Input() rows: T[];
  @Input() deleteBtn: boolean = false;
  @Input() teacherTable: boolean = false;
  @Output() actionEmitter = new EventEmitter<T>();
  @Output() rowEmitter = new EventEmitter<T>();
  @Output() tooltipValueEmitter = new EventEmitter<T>();

  StatusValues = StatusValues;
  isOpen: boolean = false;

  currentRow: T;

  emitActionValue(row: T): void {
    this.actionEmitter.emit(row);
  }

  emitRowValue(row: T): void {
    this.rowEmitter.emit(row);
  }

  emitTooltipValue(row: T): void {
    this.tooltipValueEmitter.emit(row);
  }

  toggleTooltip(row: T) {
    if (this.currentRow === row) {
      this.isOpen = !this.isOpen;
    } else {
      this.isOpen = true;
      this.currentRow = row;
    }
  }
}
