import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from "@angular/core";
import { HistoryPagination } from "src/app/shared/interfaces/history-pagination";
import { Guid } from "src/app/shared/types/guid";

type classSet = { [key: string]: boolean };

@Component({
  selector: "history-modal-item-list",
  templateUrl: "./history-modal-item-list.component.html",
  styleUrls: ["./history-modal-item-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryModalItemListComponent {
  @Input() list: HistoryPagination<{ id: Guid }>;
  @Input() activeItemId: Guid;
  @Input() statusClassFn: (item: unknown) => classSet | string;
  @Input() statusIconClassFn: (item: unknown) => classSet | string;
  @Input() itemText: TemplateRef<any>;
  @Input() itemDate: TemplateRef<any>;
  @Input() loadMoreText: string;

  @Output() itemClicked: EventEmitter<unknown> = new EventEmitter();
  @Output() nextPage: EventEmitter<number> = new EventEmitter();

  constructor() {}

  getStatusClass(item: unknown): classSet | string {
    return this.statusClassFn(item);
  }

  getStatusIconClass(item: unknown): classSet | string {
    return this.statusIconClassFn(item);
  }

  clicked(item: unknown): void {
    if ((<{ id: Guid }>item).id === this.activeItemId) {
      return;
    }
    this.itemClicked.emit(item);
  }

  nextPageClicked(): void {
    this.nextPage.emit(this.list.next);
  }
}
