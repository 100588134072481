import { NotificationElement } from "src/app/shared/interfaces/notification-element";
import { VPNPassword } from "src/app/shared/interfaces/vpn-password";
import { Guid } from "src/app/shared/types/guid";
import { NotificationSettings } from "src/app/shared/types/notification-settings";
import { RunningExercise } from "src/app/shared/types/running-exercise";
import * as fromUI from "../actions/ui.actions";
import * as fromCourse from "../actions/course.actions";
import * as moment from "moment";
import { Announcement } from "src/app/shared/interfaces/announcement";

export interface UIState {
  vpnPassword: VPNPassword;
  getVPNPasswordCompleted: boolean;
  notificationsCount: number;
  unreadNotificationsCount: number;
  notifications: Array<NotificationElement>;
  notificationsCurrentPage: number;
  getNotificationsCompleted: boolean;
  notificationSettings: NotificationSettings | null;
  runningExercise: RunningExercise | null;
  localFetchTime: moment.Moment | null;
  getRunningExerciseCompleted: boolean;
  toggledExercises: Array<Guid>;
  getAnnouncementsCompleted: boolean;
  announcements: Array<Announcement>;
  startedExerciseId: Guid | null;
}

export const initialState: UIState = {
  vpnPassword: null,
  getVPNPasswordCompleted: false,
  notificationsCount: 0,
  unreadNotificationsCount: 0,
  notifications: [],
  notificationsCurrentPage: 1,
  getNotificationsCompleted: false,
  notificationSettings: null,
  runningExercise: [],
  localFetchTime: null,
  getRunningExerciseCompleted: false,
  toggledExercises: [],
  getAnnouncementsCompleted: false,
  announcements: [],
  startedExerciseId: null,
};

export function reducer(
  state = initialState,
  action: fromUI.UIActions | fromCourse.CourseActions,
): UIState {
  switch (action.type) {
    case fromUI.GET_VPN_PASSWORD:
      return {
        ...state,
        getVPNPasswordCompleted: false,
      };

    case fromUI.GET_VPN_PASSWORD_COMPLETED:
      return {
        ...state,
        vpnPassword: action.payload,
        getVPNPasswordCompleted: true,
      };

    case fromUI.GET_VPN_PASSWORD_FAILED:
      return {
        ...state,
        vpnPassword: { password: null },
        getVPNPasswordCompleted: true,
      };

    case fromUI.GET_NOTIFICATIONS:
      return {
        ...state,
        getNotificationsCompleted: false,
      };

    case fromUI.GET_NOTIFICATIONS_COMPLETED:
      const currentPage = state.notificationsCurrentPage + 1;
      return {
        ...state,
        getNotificationsCompleted: true,
        notificationsCount: action.payload.count,
        notificationsCurrentPage: currentPage,
        notifications: state.notifications.concat(action.payload.results),
      };

    case fromUI.CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notificationsCount: 0,
        notificationsCurrentPage: 1,
        notifications: [],
      };

    case fromUI.MARK_NOTIFICATION_AS_READ:
      const notificationsCount = state.unreadNotificationsCount - 1;
      return {
        ...state,
        notifications: state.notifications.map(
          (notification: NotificationElement) =>
            notification.id === action.payload
              ? { ...notification, read_at: " " }
              : notification,
        ),
        unreadNotificationsCount:
          state.unreadNotificationsCount > 0 ? notificationsCount : 0,
      };

    case fromUI.MARK_ALL_NOTIFICATIONS_AS_READ:
      return {
        ...state,
        notifications: state.notifications.map((notification) => ({
          ...notification,
          read_at: " ",
        })),
        unreadNotificationsCount: 0,
      };

    case fromUI.SET_NOTIFICATION_SETTINGS: {
      return {
        ...state,
        notificationSettings: action.payload,
      };
    }

    case fromUI.UPDATE_UNREAD_NOTIFICATIONS: {
      return {
        ...state,
        unreadNotificationsCount: action.payload,
      };
    }

    case fromUI.GET_RUNNING_EXERCISE: {
      return {
        ...state,
        runningExercise: [],
        localFetchTime: null,
        getRunningExerciseCompleted: false,
      };
    }

    case fromUI.GET_RUNNING_EXERCISE_COMPLETED: {
      return {
        ...state,
        runningExercise: action.payload,
        localFetchTime: moment(),
        getRunningExerciseCompleted: true,
      };
    }

    case fromUI.EXTEND_RUNNING_EXERCISE: {
      return {
        ...state,
        getRunningExerciseCompleted: false,
        localFetchTime: null,
      };
    }

    case fromUI.EXTEND_RUNNING_EXERCISE_COMPLETED: {
      return {
        ...state,
        runningExercise: [action.payload],
        localFetchTime: moment(),
        getRunningExerciseCompleted: true,
      };
    }

    case fromUI.RESET_RUNNING_EXERCISE: {
      return {
        ...state,
        runningExercise: [],
        localFetchTime: null,
        getRunningExerciseCompleted: true,
      };
    }

    case fromUI.TOGGLE_EXERCISE_ON: {
      return {
        ...state,
        toggledExercises: state.toggledExercises
          .filter((id) => id !== action.payload)
          .concat(action.payload),
      };
    }

    case fromUI.TOGGLE_EXERCISE_OFF: {
      return {
        ...state,
        toggledExercises: state.toggledExercises.filter(
          (id) => id !== action.payload,
        ),
      };
    }

    case fromUI.GET_ANNOUNCEMENTS: {
      return {
        ...state,
        getAnnouncementsCompleted: false,
        announcements: [],
      };
    }

    case fromUI.GET_ANNOUNCEMENTS_COMPLETED: {
      return {
        ...state,
        getAnnouncementsCompleted: true,
        announcements: action.payload,
      };
    }

    case fromUI.SET_STARTED_ID: {
      return {
        ...state,
        startedExerciseId: action.payload
      };
    }

    case fromCourse.START_EXERCISE:
    case fromCourse.START_TEACHER_EXERCISE: {
      return {
        ...state,
        startedExerciseId: action.payload.ExerciseId,
      };
    }

    default:
      return state;
  }
}
