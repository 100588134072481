import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Toast, ToastPackage, ToastrService } from "ngx-toastr";
import { NotificationElement } from "../../interfaces/notification-element";
import { flyInOut } from "../../animations/fly-in-out";
import { Store } from "@ngrx/store";

@Component({
  selector: "notification-toast",
  template: `
    <div class="notification-toast">
      <notification
        [notification]="notification"
        [showMarkDot]="false"
        (actionPerformed)="emitClick()"
      ></notification>
    </div>
  `,
  styleUrls: ["./notification-toast.component.scss"],
  animations: [flyInOut],
})
export class NotificationToastComponent extends Toast {
  @Input() notification: NotificationElement;
  @Output() notificationClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    private store: Store<{}>
  ) {
    super(toastrService, toastPackage);
  }

  emitClick() {
    this.notificationClick.emit();
  }
}
