<ng-container
  *ngIf="{
    groups: matchingGroups$ | async,
    getGroupsCompleted: getMatchingGroupsCompleted$ | async,
    moveCompleted: moveStudentCompleted$ | async,
    moveInProgress: moveStudentInProgress$ | async,
    moveFailed: moveStudentFailed$ | async,
    moveErrors: moveStudentErrors$ | async
  } as move"
>
  <modal-overlay>
    <modal-box>
      <div class="move-modal-body">
        <div class="d-flex flex-column h-100">
          <h5 class="heading-5 move-modal-title">
            <span class="prefix">
              {{ "GROUPS.EDIT.MOVE_STUDENT.TITLE" | translate }}
            </span>
            <span>{{ data.name }}</span>
          </h5>

          <div class="move-modal-content">
            <ul
              class="label color-hinvalid validation-text mb-3"
              *ngIf="move.moveFailed"
            >
              <li *ngFor="let message of move.moveErrors.coursegroup">
                {{ message }}
              </li>

              <li *ngFor="let message of move.moveErrors.student">
                {{ message }}
              </li>
            </ul>

            <div class="listbox-container">
              <ul
                listbox
                *ngIf="move.getGroupsCompleted; else loading"
                [noContentTemplateRef]="noContentTemplateRef"
              >
                <li listbox-element *ngFor="let group of move.groups">
                  <group-student-move-listbox-content-element
                    [group]="group"
                    [inProgress]="move.moveInProgress"
                    (moveClick)="moveStudent($event)"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end mt-4">
          <button
            *ngIf="!move.moveInProgress; else inProgress"
            class="theme-hprimary"
            (click)="close.emit('close')"
          >
            {{ "GLOBAL.CANCEL_BUTTON" | translate }}
          </button>
        </div>
      </div>
    </modal-box>
  </modal-overlay>
</ng-container>

<ng-template #noContentTemplateRef>
  <group-listbox-empty />
</ng-template>

<ng-template #loading>
  <div class="d-flex justify-content-center">
    <loading-spinner />
  </div>
</ng-template>

<ng-template #inProgress>
  <loading-spinner />
</ng-template>
