<div
  class="switch-button switch-button-expanded"
  role="button"
  [tooltip]="tooltipText"
  [class]="'theme-' + theme"
  [ngClass]="{
    toggled: toggled,
    busy: busy
  }"
  (click)="onClick($event)"
>
  <div class="switch-button-face">
    <span class="button-icon {{ set }} {{ set }}-{{ icon }}"></span>
  </div>
  <div class="switch-button-busy">
    <countdown [color]="theme"></countdown>
  </div>
</div>

<div
  class="switch-button-arrow"
  role="button"
  [class]="'theme-' + theme"
  [ngClass]="{
    toggled: toggled
  }"
  (click)="toggle($event)"
>
  <span
    class="arrow-icon typcn"
    [ngClass]="{
      'typcn-arrow-sorted-down': !isExpanded,
      'typcn-arrow-sorted-up': isExpanded
    }"
  ></span>
</div>

<popup-menu class="expanded-menu" (hidden)="isExpanded = false">
  <ng-container popup-content>
    <ng-content></ng-content>
  </ng-container>
</popup-menu>
