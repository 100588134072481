import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {catchError, map, mergeMap, switchMap} from "rxjs/operators";
import { of } from "rxjs";
import { TutorialService } from "src/app/shared/services/tutorial/tutorial.service";
import { UserTutorial } from "../../shared/interfaces/user-tutorial";
import * as fromTutorials from "../actions/tutorial.actions";

@Injectable()
export class TutorialEffects {
  getTutorial$ = createEffect(() => this.actions$.pipe(
    ofType(fromTutorials.GET_TUTORIALS),
    mergeMap(() =>
      this.tutorialService.getTutorialInfo().pipe(
        mergeMap((userTutorial: UserTutorial) => [
          {
            type: fromTutorials.GET_TUTORIALS_SUCCESS,
            payload: userTutorial,
          },
        ]),
        catchError(() => {
          return of({
            type: fromTutorials.GET_TUTORIALS_FAILURE,
          });
        })
      )
    )
  ));

  updateTutorial$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromTutorials.SET_TUTORIAL_STEP, fromTutorials.SKIP_TUTORIAL),
      mergeMap((action:fromTutorials.SetTutorialStep) =>
        this.tutorialService.updateTutorialInfo({
          tutorial_step: action.payload.tutorial_step,
          tutorial_skip: action.payload.tutorial_skip,
          id: action.payload.id
        }).pipe(
          map(() => ({ type: '[Tutorial] Tutorial Updated Successfully' })),
          catchError(() => of({ type: '[Tutorial] Tutorial Update Failed' }))
        )
      )
    )
  );


  constructor(
    private actions$: Actions,
    private tutorialService: TutorialService,
  ) {}
}
