import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import { CourseTeachers } from "src/app/shared/interfaces/orgadmin/course-teachers";
import {
  courseTeachersListLoad,
  courseTeachersListLoadSuccess,
  courseTeachersListLoadFail,
} from "../../actions/admin/course-teachers.actions";

export interface CourseTeachersListState {
  teachers: CourseTeachers | null;
  error: HttpErrorResponse | null;
}

const initialState: CourseTeachersListState = {
  teachers: null,
  error: null,
};

export const courseTeachersListReducer = createReducer(
  initialState,
  on(
    courseTeachersListLoad,
    (state): CourseTeachersListState => ({
      ...state,
      error: null,
    }),
  ),
  on(
    courseTeachersListLoadSuccess,
    (state, action): CourseTeachersListState => ({
      ...state,
      teachers: action.teachersList,
      error: null,
    }),
  ),
  on(
    courseTeachersListLoadFail,
    (state, { error }): CourseTeachersListState => ({
      ...state,
      error,
    }),
  ),
);
