import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { take } from "rxjs/operators";
import { QuizQuestion } from "src/app/modules/quiz/interfaces/quiz-question";
import { QuizService } from "../../services/quiz/quiz.service";
import { Guid } from "../../types/guid";

@Component({
  selector: "cached-quiz-figure",
  templateUrl: "./cached-quiz-figure.component.html",
  styleUrls: ["./cached-quiz-figure.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CachedQuizFigureComponent implements OnInit {
  @Input() courseId: Guid;
  @Input() groupId: Guid;
  @Input() exerciseId: Guid;
  @Input() question: QuizQuestion;

  figure: string;
  figure_ready: boolean = false;

  constructor(
    private quizService: QuizService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getFigure();
  }

  private getFigure(): void {
    if (this.question.has_figure) {
      this.quizService
        .getFigure({
          CourseId: this.courseId,
          GroupId: this.groupId,
          ExerciseId: this.exerciseId,
          QuestionId: this.question.id,
        })
        .pipe(take(1))
        .subscribe((data) => {
          const reader = new FileReader();
          reader.readAsDataURL(data);
          reader.onloadend = () => {
            this.figure = reader.result as string;
            this.figure_ready = true;
            this.cd.detectChanges();
          };
        });
    }
  }
}
