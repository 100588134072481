import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { PackageCourseUsers } from "src/app/shared/interfaces/packages/package-course-users";
import { PackageUsers } from "src/app/shared/interfaces/packages/package-users";

export const coursePrefix = "[Package Course Users List]";

const packageCourseUsersListActions = {
  request: `${coursePrefix} Load`,
  requestActiveSuccess: `${coursePrefix} Load success`,
  requestFail: `${coursePrefix} Load fail`,
};

export const packageCourseUsersListLoad = createAction(
  packageCourseUsersListActions.request,
  props<{
    organizationId: string;
    packageId: string;
    courseId: string;
    teacherId?: string;
  }>(),
);

export const packageCourseUsersListLoadSuccess = createAction(
  packageCourseUsersListActions.requestActiveSuccess,
  props<{ users: PackageCourseUsers }>(),
);

export const loadPackageCourseUsersListFailure = createAction(
  packageCourseUsersListActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
