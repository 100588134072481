import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import {
  loadPackageCourseUsersListFailure,
  packageCourseUsersListLoad,
  packageCourseUsersListLoadSuccess,
} from "../../actions/packages/package-course-users.actions";
import { PackageCourseUsers } from "src/app/shared/interfaces/packages/package-course-users";

export interface PackageCourseUsersListState {
  users: PackageCourseUsers;
  error: HttpErrorResponse | null;
}

const initialState: PackageCourseUsersListState = {
  users: null,
  error: null,
};

export const packageCourseUsersListReducer = createReducer(
  initialState,
  on(
    packageCourseUsersListLoad,
    (state): PackageCourseUsersListState => ({
      ...state,
      users: null,
      error: null,
    }),
  ),
  on(
    packageCourseUsersListLoadSuccess,
    (state, action): PackageCourseUsersListState => ({
      ...state,
      users: action.users,
      error: null,
    }),
  ),
  on(loadPackageCourseUsersListFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);
