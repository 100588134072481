<ng-container *ngIf="tutorial$ | async as tutorial">
  <ul class="navbar-nav-list left">
    <ng-container *ngFor="let item of leftSideNavItems">
      <li class="navbar-nav-list-item">
        <navbar-nav-element
          (click)="hideMenu()"
          [outlined]="false"
          [label]="item.label | translate"
          [icon]="item.icon"
          [set]="item.iconSet"
          [route]="item.route"
          [showTutorial]="
            isTeacher
              ? tutorial.tutorial_step === TutorialTeacherSteps.DASHBOARD_BTN
              : tutorial.tutorial_step === TutorialSteps.DASHBOARD_BTN
          "
        />
      </li>
      <tutorial-text-box
        *ngIf="
          isTeacher
            ? tutorial.tutorial_step === TutorialTeacherSteps.DASHBOARD_BTN
            : tutorial.tutorial_step === TutorialSteps.DASHBOARD_BTN
        "
        [customStyle]="{ top: '80px', left: '120px' }"
        [title]="'TUTORIAL_DATA.DASHBOARD_TITLE' | translate"
        [message]="'TUTORIAL_DATA.DASHBOARD_MESSAGE' | translate"
        [step]="
          isTeacher
            ? TutorialTeacherSteps.DASHBOARD_BTN
            : TutorialSteps.DASHBOARD_BTN
        "
      ></tutorial-text-box>
    </ng-container>
  </ul>

  <ul class="navbar-nav-list right">
    <ng-container *ngFor="let item of rightSideNavItems; let i = index">
      <li class="navbar-nav-list-item">
        <navbar-nav-element
          (click)="hideMenu()"
          [label]="item.label | translate"
          [icon]="item.icon"
          [set]="item.iconSet"
          [route]="item.route"
          [showTutorial]="
            isTeacher ? i === showTutorialId : i + 1 === showTutorialId
          "
        />
      </li>
    </ng-container>
    <tutorial-text-box
      *ngIf="
        isTeacher && tutorial.tutorial_step === TutorialTeacherSteps.GROUPS_BTN
      "
      [customStyle]="{
        top: '80px',
        right: '120px'
      }"
      [title]="'TUTORIAL_TEACHER_DATA.GROUPS_BTN_LABEL' | translate"
      [message]="'TUTORIAL_TEACHER_DATA.GROUPS_BTN' | translate"
      [step]="TutorialTeacherSteps.GROUPS_BTN"
    ></tutorial-text-box>
    <tutorial-text-box
      *ngIf="
        isTeacher
          ? tutorial.tutorial_step === TutorialTeacherSteps.COURSES_BTN
          : tutorial.tutorial_step === TutorialSteps.COURSES_BTN
      "
      [customStyle]="{
        top: '80px',
        right: '120px'
      }"
      [title]="'TUTORIAL_DATA.COURSE_TITLE' | translate"
      [message]="'TUTORIAL_DATA.COURSE_MESSAGE' | translate"
      [step]="
        isTeacher ? TutorialTeacherSteps.COURSES_BTN : TutorialSteps.COURSES_BTN
      "
    ></tutorial-text-box>
    <tutorial-text-box
      *ngIf="
        isTeacher
          ? tutorial.tutorial_step === TutorialTeacherSteps.RANKING_BTN
          : tutorial.tutorial_step === TutorialSteps.RANKING_BTN
      "
      [customStyle]="{
        top: '80px',
        right: '120px'
      }"
      [title]="'TUTORIAL_DATA.RANKING_TITLE' | translate"
      [message]="
        isTeacher
          ? ('TUTORIAL_TEACHER_DATA.RANKING_MESSAGE' | translate)
          : ('TUTORIAL_DATA.RANKING_MESSAGE' | translate)
      "
      [step]="
        isTeacher ? TutorialTeacherSteps.RANKING_BTN : TutorialSteps.RANKING_BTN
      "
    ></tutorial-text-box>
  </ul>
</ng-container>
