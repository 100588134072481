<div
  class="sheet-container hcontainer hcontainer-extended background-color-hbackgroundgray"
>
  <header class="sheet-title">
    <img
      src="/assets/images/logo_svg/cyberskiller_skrocone_czarny.svg"
      alt="Stylesheet"
    />
    <p class="heading-1">Styled elements sheet</p>
  </header>

  <div class="group background-color-hwhite">
    <h1>H1 Heading biggest</h1>
    <h2>H2 Heading bigger</h2>
    <h3>H3 Heading big</h3>
    <h4>H4 Heading normal</h4>
    <h5>H5 Heading small</h5>
    <h6>H6 Heading smallest</h6>

    <p>Sample in-system paragraph</p>
    <label>Sample label</label>
  </div>

  <div class="group background-color-hwhite">
    <div class="row no-gutters">
      <div class="col"><input type="text" placeholder="Sample textbox" /></div>
      <div class="col"><input type="email" placeholder="Sample email" /></div>
      <div class="col">
        <input type="password" placeholder="Sample password" />
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col">
        <icon-input icon="globe-outline"
          ><input type="text" placeholder="Iconized textbox"
        /></icon-input>
      </div>

      <div class="col">
        <icon-input icon="home-outline">
          <select>
            <option>Sample select option 1</option>
            <option>Sample select option 2</option>
          </select></icon-input
        >
      </div>

      <div class="col">
        <textarea placeholder="Sample textarea"></textarea>
      </div>
    </div>

    <div class="row no-gutters flex-wrap">
      <div class="col-2">
        <button>Transparent button</button>
      </div>
      <div class="col-2">
        <button class="theme-hprimary">Primary button</button>
      </div>
      <div class="col-2">
        <button class="theme-hsecondary">Secondary button</button>
      </div>
      <div class="col-2">
        <button class="theme-hgray">Gray button</button>
      </div>
      <div class="col-2">
        <button class="theme-hwhite">White button</button>
      </div>
      <div class="col-2">
        <button class="theme-hblack">Black button</button>
      </div>
      <div class="col-2">
        <button class="theme-hlabelgray">Label gray button</button>
      </div>
      <div class="col-2">
        <button class="theme-hlightgray">Lightgray button</button>
      </div>
      <div class="col-2">
        <button class="theme-hbackgroundgray">Background gray button</button>
      </div>
    </div>

    <!-- <input type="checkbox" name="TestCbx" id="TestCbx"><label for="TextCbx">Sample checkbox</label>
      <input type="radio" name="TestRadio" id="TestRadio1"><label for="TestRadio1">Sample radio 1</label>
      <input type="radio" name="TestRadio" id="TestRadio2"><label for="TestRadio2">Sample radio 2</label> -->
  </div>

  <div class="group background-color-hwhite">
    <h2 class="rt-example-title">Example for rich text</h2>
    <div class="rich-text">
      <h3>Example h3 title</h3>
      <p>
        Vivamus finibus ligula velit, vel eleifend sapien vehicula posuere.
        Aenean est magna, blandit non dui ut, posuere porta dolor. Nunc a
        vehicula nunc, vehicula eleifend leo. Aenean enim leo, pellentesque at
        bibendum in, consequat sit amet sem. Etiam eu ante in odio porta
        pellentesque. Phasellus mollis efficitur sapien. Ut rhoncus arcu tortor,
        eget malesuada enim tincidunt et.
        <img
          src="https://www.finalsite.com/uploaded/FridayFolder/quadratic_formula.png"
          alt=""
          style="width: 100px"
          class="d-inline-block"
        />
        Aliquam elementum nisi dictum sollicitudin volutpat. Morbi ut lacus
        velit.
        <img
          src="https://images.pexels.com/photos/972391/pexels-photo-972391.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260"
          alt="sample image"
        />
        Cras in mi ex. Nulla facilisi.
      </p>

      <h4>YouTube video</h4>
      <iframe
        style="width: 500px; height: 282px"
        src="https://www.youtube.com/embed/HSsqzzuGTPo"
        frameborder="0"
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</div>
