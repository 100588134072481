import { HttpErrorResponse } from "@angular/common/http";
import { Action, createAction, props } from "@ngrx/store";
import { Organization } from "src/app/shared/interfaces/organization";
import { Guid } from "src/app/shared/types/guid";

export const organizationsPrefix = "[Organizations List]";
export const DOWNLOAD_ORGANIZATION_REPORT =
  "[Organization Method] DownloadOrganizationReport";

const organizationsListActions = {
  request: `${organizationsPrefix} Load`,
  requestSuccess: `${organizationsPrefix} Load success`,
  requestFail: `${organizationsPrefix} Load fail`,
};

export const organizationsListLoad = createAction(
  organizationsListActions.request,
);

export const organizationsListLoadSuccess = createAction(
  organizationsListActions.requestSuccess,
  props<{ organizations: Organization[] }>(),
);

export const organizationsListLoadFail = createAction(
  organizationsListActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);

export class DownloadOrganizationReport implements Action {
  readonly type = DOWNLOAD_ORGANIZATION_REPORT;

  constructor(
    public payload: {
      OrganizationId: Guid;
    }
  ) {}
}
