import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from "@angular/core";
import { Observable } from "rxjs";
import { StatsService } from "../../services/statistics/stats.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { map, tap } from "rxjs/operators";
import { Config } from "../../interfaces/student-pass-criteria-api";
import { ConfirmationModalComponent } from "../modals/confirmation-modal/confirmation-modal.component";
import { ModalService } from "../../services/modal/modal.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

export interface PartialGradePayload {
  is_active: boolean;
  name: string;
  exercises: string[];
}
@Component({
  selector: "individual-grade-bar",
  templateUrl: "./individual-grade-bar.component.html",
  styleUrls: ["./individual-grade-bar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndividualGradeBarComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() individualGrade: Config | null;
  @Input() creatorMode: boolean = false;
  @Input() isEditing: boolean = false;
  @Input() studentView: boolean = false;

  @Output() acceptEmitter = new EventEmitter<{
    form: PartialGradePayload;
    id: string | null;
  }>();
  @Output() cancelEmitter = new EventEmitter<string[]>();
  @Output() editEmitter = new EventEmitter<string>();

  private stats = inject(StatsService);
  private fb = inject(FormBuilder);
  private modal = inject(ModalService);
  private destroyRef = inject(DestroyRef);

  currentPartialGradeId$: Observable<string> = this.stats.currentPartialGradeId;
  selectedExercises$: Observable<string[]> = this.stats.currentItems;
  selectable$: Observable<boolean> = this.stats.selectableFlag;

  displayExercises: boolean = false;
  loader: boolean = false;
  form = this.initForm();

  get is_active() {
    return this.form.controls.is_active as FormControl<boolean>;
  }

  get name() {
    return this.form.controls.name as FormControl<string>;
  }

  get exercises() {
    return this.form.controls.exercises as FormControl<string[]>;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["isEditing"]) {
      if (this.isEditing) {
        this.form.enable();
      } else {
        this.form.disable();
      }
    }

    if (changes["individualGrade"]) {
      this.loader = false;
      this.stats.changeSelectable(false);
    }
  }

  ngOnInit(): void {
    if (this.individualGrade) {
      this.loadData();
    }
    this.setUpValidators();

    this.stats.currentItems
      .pipe(
        map((items) => this.exercises.setValue(items)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private initForm(): FormGroup {
    return this.fb.group({
      is_active: this.fb.control<boolean>(true),
      name: this.fb.control<string>("", [Validators.required]),
      exercises: this.fb.control<string[]>([]),
    });
  }

  private setUpValidators(): void {
    if (this.creatorMode) {
      this.exercises.setValidators([Validators.required]);
    } else {
      this.exercises.clearValidators();
    }
    this.exercises.updateValueAndValidity();
  }

  private loadData(): void {
    const { name, is_active, exercises } = this.individualGrade;
    const exerciseIds = exercises.map((exercise) => exercise.id);

    this.form.patchValue({
      name,
      is_active,
      exercises: exerciseIds,
    });

    exercises.forEach(({ id }) => {
      this.stats.addItem(id);
    });
  }

  acceptGrade(): void {
    this.selectedExercises$
      .pipe(
        tap((exercises) => {
          this.form.patchValue({ exercises });
        }),
      )
      .subscribe();

    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if (
      !this.creatorMode &&
      this.individualGrade &&
      this.exercises.value.length === 0
    ) {
      const modal = this.modal.showModal(
        {
          modalTitle: "COURSES.COURSE.DELETE_GRADE_TITLE",
          message: this.individualGrade.name,
          confirmButtonText: "GROUPS.EDIT.DELETE_BUTTON",
          cancelButtonText: "GLOBAL.CANCEL_BUTTON",
          confirmButtonColor: "primary",
        },
        ConfirmationModalComponent,
        "REMOVE_PARTIAL_GRADE",
      ) as ConfirmationModalComponent;

      modal.onConfirm
        .pipe(
          tap((confirm) => {
            if (confirm) {
              this.cancelEmitter.emit(this.exercises.value);
            }
          }),
        )
        .subscribe();
    } else {
      this.loader = true;
      this.isEditing = false;

      this.stats.setCurrentPartialGrade(null);
      this.stats.changeSelectable(false);

      this.acceptEmitter.emit({
        form: this.form.value,
        id: this.individualGrade ? this.individualGrade.id : null,
      });
    }
  }

  cancelGrade(): void {
    if (!this.creatorMode) {
      const { name, exercises, is_active } = this.individualGrade;
      const exerciseIds = exercises.map((exercise) => exercise.id);
      this.name.setValue(name);
      this.is_active.setValue(is_active);
      this.cancelEmitter.emit(exerciseIds);
    } else {
      this.cancelEmitter.emit([]);
    }

    this.stats.changeSelectable(false);
  }

  editGrade(): void {
    this.stats.setCurrentPartialGrade(this.individualGrade.id);
    this.stats.changeSelectable(true);
    this.stats.changeHighlightable(false);
    this.stats.clearExercises();

    this.isEditing = true;
    this.individualGrade.exercises.map((exercise) =>
      this.stats.addItem(exercise.id),
    );

    this.editEmitter.emit(this.individualGrade.id);
  }

  viewExercises(gradeId: string): void {
    this.stats.setCurrentPartialGrade(this.individualGrade.id);
    if (this.individualGrade.id === gradeId) {
      this.stats.setCurrentPartialGrade(null);
      this.displayExercises = false;
    } else {
      this.stats.setCurrentPartialGrade(this.individualGrade.id);
      this.displayExercises = true;
    }

    this.stats.changeHighlightable(this.displayExercises);
    this.stats.clearExercises();

    this.individualGrade.exercises.map((exercise) =>
      this.stats.addItem(exercise.id),
    );
  }

  ngOnDestroy(): void {
    this.stats.changeSelectable(false);
    this.stats.clearExercises();
  }
}
