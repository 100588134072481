<box>
  <div
    class="course-progress-heading d-flex align-items-center justify-content-between"
  >
    <h6 class="course-progress-heading-label">
      {{ "COURSES.COURSE.SUBJECTS" | translate | uppercase }}
    </h6>

    <div class="d-flex flex-row">
      <div *ngIf="courseId && hasRanking && !isTeacher">
        <round-button
          class="hprimarydark"
          icon="trophy"
          set="uil"
          tooltip="GROUPS.LIST.GROUP_RANK"
          [link]="'/' + (isTeacher ? 'teacher' : 'student') + '/ranking'"
          [params]="{ filter_by: 'coursegroup', filter_pk: groupId }"
          [pure]="true"
        />
      </div>
      <div *ngIf="courseId && !isTeacher">
        <round-button
          class="hprimarydark"
          icon="chart-line"
          tooltip="COURSES.COURSES.COURSE_STATS"
          [link]="
            '/student/courses/' + courseId + '/groups/' + groupId + '/stats'
          "
          [pure]="true"
        />
      </div>
    </div>
  </div>

  <div class="progress-wrapper">
    <ul class="progress-list">
      <li class="progress-topic" *ngFor="let topic of topics; let i = index">
        <course-progress-topic
          [topic]="topic"
          [state]="{ index: i, currentTopicIndex: currentTopicIndex }"
          (topicChange)="setTopicParam($event)"
          [courseId]="courseId"
          [groupId]="groupId"
        />
      </li>
    </ul>
  </div>
</box>
