import { HttpClient } from "@angular/common/http";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { BehaviorSubject, interval, Observable, Subscription } from "rxjs";
import { shortHumanizer } from "src/app/config/humanizers";
import * as fromCourseWS from "src/app/store/actions/course.websocket.actions";
import * as fromCourse from "src/app/store/actions/course.actions";
import * as fromUI from "src/app/store/actions/ui.actions";
import * as fromUIStore from "src/app/store/reducers/ui.reducer";
import { ExerciseMark } from "../../enums/exercise-mark";
import { ConfigStorageService } from "../../services/config-storage/config-storage.service";
import { Guid } from "../../types/guid";
import {
  RunningExercise,
  RunningExerciseData,
} from "../../types/running-exercise";
import { AnswerType } from "../../types/answer-type";
import { filter, map, take, withLatestFrom } from "rxjs/operators";
import { UIService } from "../../services/ui/ui.service";

type ExerciseTimerTimes = {
  timeLeftCountdown: string;
  millisecondsLeft: number;
  totalExerciseMilliseconds: number;
};

@Component({
  selector: "exercise-timer",
  templateUrl: "./exercise-timer.component.html",
  styleUrls: ["./exercise-timer.component.scss"],
})
export class ExerciseTimerComponent implements AfterViewInit, OnDestroy {
  @Output() timeExceeded: EventEmitter<void> = new EventEmitter();

  extendTime$: BehaviorSubject<number> = new BehaviorSubject<number>(900000);
  tickSubscription: Subscription;

  millisecondsLeft: number | null = null;
  totalExerciseMilliseconds: number = 1;
  timeLeftCountdown: string | null = null;

  stopping: boolean = false;
  stopPressed: boolean = false;

  AnswerType: typeof AnswerType = AnswerType;

  runningExercise$: Observable<RunningExercise> = this.store.select(
    (state) => state.ui.runningExercise,
  );
  getRunningExerciseCompleted$: Observable<boolean> = this.store.select(
    (state) => state.ui.getRunningExerciseCompleted,
  );
  localFetchTime$: Observable<moment.Moment> = this.store.select(
    (state) => state.ui.localFetchTime,
  );

  constructor(
    private store: Store<{ ui: fromUIStore.UIState }>,
    private configStorage: ConfigStorageService,
    private http: HttpClient,
    private translate: TranslateService,
    private uiService: UIService,
  ) {}

  ngAfterViewInit() {
    this.getExtendTime();
  }

  ngOnDestroy() {
    if (this.tickSubscription) {
      this.tickSubscription.unsubscribe();
    }
  }

  protected getExtendTime() {
    this.http
      .get<{ [key: string]: any }>("config/config.json")
      .subscribe((config) => {
        this.configStorage.store(config);

        this.extendTime$.next(config.extendExerciseTime);

        this.manageTimers();

        this.tickSubscription = interval(1000).subscribe(() => {
          this.manageTimers();
        });
      });
  }

  protected manageTimers() {
    this.runningExercise$
      .pipe(
        filter((runningExercise) => typeof runningExercise[0] !== "undefined"),
        take(1),
        map(([runningExercise]) => runningExercise),
        withLatestFrom(this.localFetchTime$.pipe(filter(moment.isMoment))),
      )
      .subscribe(
        ([runningExercise, localFetchTime]: [
          RunningExerciseData,
          moment.Moment,
        ]) => {
          const timeLeft = this.getTimeLeft(
            moment(),
            runningExercise.started_at,
            runningExercise.ends_at,
            localFetchTime
              .clone()
              .add(runningExercise.ends_in_seconds, "seconds"),
          );

          if (runningExercise.status === "started") {
            this.timeLeftCountdown = timeLeft.timeLeftCountdown;
          }
          this.millisecondsLeft = timeLeft.millisecondsLeft;
          this.totalExerciseMilliseconds = timeLeft.totalExerciseMilliseconds;

          if (
            runningExercise.status === "started" &&
            this.millisecondsLeft <= 0
          ) {
            this.uiService
              .getRunningExercise()
              .pipe(map(([updatedRunningExercise]) => updatedRunningExercise))
              .subscribe((updatedRunningExercise) => {
                if (
                  updatedRunningExercise &&
                  updatedRunningExercise.ends_in_seconds === 0
                ) {
                  this.stopping = true;
                  return;
                }

                if (!updatedRunningExercise) {
                  this.timeExceeded.emit();
                }

                this.store.dispatch(
                  new fromUI.GetRunningExerciseCompleted([
                    updatedRunningExercise,
                  ]),
                );
              });
          } else {
            this.stopping = false;
          }
        },
      );
  }

  protected getTimeLeft(
    currentTime: moment.Moment,
    startTime: string,
    endTime: string,
    localEndTime: moment.Moment,
  ): ExerciseTimerTimes {
    let leftDifference: moment.Duration = moment.duration(
      moment.utc(localEndTime).local().diff(currentTime),
    );

    let totalDifference: moment.Duration = moment.duration(
      moment.utc(endTime).local().diff(moment.utc(startTime).local()),
    );

    let countdown: string = `${shortHumanizer(leftDifference, {
      language: this.getCurrentLang() + "Short",
      delimiter: " ",
      spacer: "",
      largest: 5,
      round: true,
    })}`;

    return {
      timeLeftCountdown: countdown,
      millisecondsLeft: leftDifference.asMilliseconds(),
      totalExerciseMilliseconds: totalDifference.asMilliseconds(),
    };
  }

  protected getCurrentLang(): string {
    return this.translate.currentLang;
  }

  setHighlighter(exerciseId: Guid) {
    this.store.dispatch(
      new fromCourseWS.HighlightExercise({
        id: exerciseId,
        markType: ExerciseMark.Highlighted,
      }),
    );
  }

  extend(exerciseId: Guid) {
    this.store.dispatch(new fromUI.ExtendRunningExercise(exerciseId));
  }

  stop(runningExercise: RunningExercise) {
    this.timeLeftCountdown = null;
    this.millisecondsLeft = 0;
    this.timeExceeded.emit();
    this.stopPressed = true;

    if (runningExercise[0].group_id) {
      this.store.dispatch(
        new fromCourse.StopExercise({
          CourseId: runningExercise[0].course_id,
          TopicId: runningExercise[0].topic_id,
          ExerciseId: runningExercise[0].id,
          GroupId: runningExercise[0].group_id,
        }),
      );
    } else {
      this.store.dispatch(
        new fromCourse.StopTeacherExercise({
          CourseId: runningExercise[0].course_id,
          TopicId: runningExercise[0].topic_id,
          ExerciseId: runningExercise[0].id,
          GroupId: null,
        }),
      );
    }
  }
}
