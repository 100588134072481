import { Pipe, PipeTransform } from "@angular/core";
import { StatusValues } from "../enums/status-values";

@Pipe({
  name: "iconStatus",
})
export class IconStatusPipe implements PipeTransform {
  transform(value: StatusValues) {
    const shortcutMap = new Map([
      [
        StatusValues.NONE,
        {
          backgroundColor: "#F2F4F7",
          color: "#98A2B3",
          set: "uil",
          icon: "envelope-question",
          value: "ADMIN.STATUS.NONE",
        },
      ],
      [
        StatusValues.PENDING,
        {
          backgroundColor: "#FFFAEB",
          color: "#F79009",
          set: "uil",
          icon: "envelope-question",
          value: "ADMIN.STATUS.PENDING",
        },
      ],
      [
        StatusValues.ACTIVE,
        {
          backgroundColor: "#ECFDF3",
          color: "#12B76A",
          set: "uil",
          icon: "check",
          value: "ADMIN.STATUS.ACTIVE",
        },
      ],
      [
        StatusValues.EXPIRED,
        {
          backgroundColor: "#FEF3F2",
          color: "#F04438",
          set: "uil",
          icon: "envelope-question",
          value: "ADMIN.STATUS.EXPIRIED",
        },
      ],
      [
        StatusValues.REJECTED,
        {
          backgroundColor: "#FEF3F2",
          color: "#B42318",
          set: "uil",
          icon: "envelope-question",
          value: "ADMIN.STATUS.REJECTED",
        },
      ],
      [
        StatusValues.REMOVED,
        {
          backgroundColor: "#ECFDF3",
          color: "#ffffff",
          set: "typcn",
          icon: "envelope-question",
          value: "ADMIN.STATUS.REMOVED",
        },
      ],
      [
        StatusValues.DELIVERED,
        {
          backgroundColor: "#ECFDF3",
          color: "#12B76A",
          set: "uil",
          icon: "envelopes",
          value: "ADMIN.STATUS.DELIVERED",
        },
      ],
      [
        StatusValues.FAILED,
        {
          backgroundColor: "#FEF3F2",
          color: "#FEF3F2",
          set: "uil",
          icon: "envelope-question",
          value: "ADMIN.STATUS.FAILED",
        },
      ],
    ]);
    return shortcutMap.get(value);
  }
}
