import { Component, Input, Output, EventEmitter } from "@angular/core";
import { IconName } from "src/app/shared/types/icon-name";
import { IconSet } from "src/app/shared/types/icon-set";

export interface DashboardWidgetData {
  label?: string;
  sub_label?: string;
  numerical_value?: string;
}
@Component({
  selector: "simple-dashboard-widget",
  templateUrl: "./simple-dashboard-widget.component.html",
  styleUrls: ["./simple-dashboard-widget.component.scss"],
})
export class SimpleDashboardWidgetComponent {
  @Input() data: DashboardWidgetData;
  @Input() set: IconSet = "typcn";
  @Input() icon: IconName;
  @Output() widgetClick = new EventEmitter<void>();

  onWidgetClick() {
    this.widgetClick.emit();
  }
}
