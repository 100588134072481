import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import {
  resolveflaggedExerciseFailure,
  resolveflaggedExerciseLoad,
  resolveflaggedExerciseLoadSuccess,
} from "../../actions/flagged-exercises/resolve-flagged-exercise.actions";
import { FlaggedExerciseList } from "src/app/shared/interfaces/flagged-exercises";

export interface ResolveFlaggedExerciseState {
  exercise: FlaggedExerciseList | null;
  courseId: string | null;
  groupId: string | null;
  error: HttpErrorResponse | null;
}

const initialState: ResolveFlaggedExerciseState = {
  exercise: null,
  courseId: null,
  groupId: null,
  error: null,
};

export const resolveFlaggedExerciseReducer = createReducer(
  initialState,
  on(
    resolveflaggedExerciseLoad,
    (state): ResolveFlaggedExerciseState => ({
      ...state,
      exercise: null,
      error: null,
    }),
  ),
  on(
    resolveflaggedExerciseLoadSuccess,
    (state, action): ResolveFlaggedExerciseState => ({
      ...state,
      exercise: action.exercise,
      courseId: action.courseId,
      groupId: action.groupId,
      error: null,
    }),
  ),
  on(resolveflaggedExerciseFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);
