<div class="carousel">
  <div class="carousel-container">
    <div class="carousel-container-reel" #reelElement>
      <div
        *ngFor="let element of elements; let i = index"
        class="carousel-element"
      >
        <ng-container
          [ngTemplateOutlet]="elementTemplate"
          [ngTemplateOutletContext]="{
            element: element,
            index: i,
            activeElement: activeElement
          }"
        >
        </ng-container>
      </div>
    </div>
  </div>
</div>

<dots
  *ngIf="showDots && elements.length > 1"
  [count]="elements.length"
  [activeElement]="activeElement"
  (dotClicked)="setActiveElement($event)"
  class="carousel-dots"
></dots>
