import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { FlaggedExercise } from "src/app/shared/interfaces/flagged-exercises";

export const coursePrefix = "[Flagged Exercise]";

const flaggedExerciseActions = {
  request: `${coursePrefix} Load`,
  requestActiveSuccess: `${coursePrefix} Load success`,
  requestFail: `${coursePrefix} Load fail`,
};

export const flaggedExerciseLoad = createAction(
  flaggedExerciseActions.request,
  props<{
    courseId: string;
    groupId: string;
    exerciseId: string;
  }>(),
);

export const flaggedExerciseLoadSuccess = createAction(
  flaggedExerciseActions.requestActiveSuccess,
  props<{ exercise: FlaggedExercise }>(),
);

export const flaggedExerciseFailure = createAction(
  flaggedExerciseActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
