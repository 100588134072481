import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { Nillable } from "../../types/nillable";
import { isNil } from "lodash-es";
import { ExerciseStatus } from "../../enums/exercise-status";
import { KeyIconComponent } from "../key-icon/key-icon.component";

@Component({
  selector: "answer-status",
  templateUrl: "./answer-status.component.html",
  styleUrls: ["./answer-status.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnswerStatusComponent
  implements OnChanges, AfterViewInit, OnDestroy
{
  @Input() status: Nillable<boolean>;

  @ViewChild("statusIcon", { read: ViewContainerRef })
  private readonly iconContainer: ViewContainerRef;

  constructor(private readonly cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { status } = changes;

    if (!isNil(status) && !status.firstChange && this.iconContainer) {
      this.createIconComponent(status.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.iconContainer.clear();
    this.iconContainer.detach();
  }

  ngAfterViewInit(): void {
    this.createIconComponent(this.status);
  }

  private createIconComponent(status: Nillable<boolean>): void {
    this.iconContainer.clear();

    const iconComponent = this.iconContainer.createComponent(KeyIconComponent);
    const { instance } = iconComponent;

    instance.size = "xs";

    switch (status) {
      case true:
        instance.kind = "success";
        instance.theme = "dark-outline";
        instance.icon = "check";

        break;

      case false:
        instance.icon = "cancel-outline";
        instance.kind = "error";
        instance.set = "typcn";

        break;

      case null:
        instance.kind = "warning";
        instance.theme = "light";
        instance.icon = "stopwatch";

        break;
    }

    this.cd.detectChanges();
  }
}
