import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { CourseProgress } from "src/app/shared/interfaces/course-progress";

export const coursePrefix = "[Course Progress]";

const courseProgressActions = {
  request: `${coursePrefix} Load`,
  requestActiveSuccess: `${coursePrefix} Load Course Progress success`,
  requestFail: `${coursePrefix} Load fail`,
};

export const courseProgressLoad = createAction(
  courseProgressActions.request,
  props<{
    CourseId: string;
    GroupId: string;
  }>(),
);

export const courseProgressLoadSuccess = createAction(
  courseProgressActions.requestActiveSuccess,
  props<{ courseProgress: CourseProgress }>(),
);

export const loadCourseProgressFailure = createAction(
  courseProgressActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
