import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Group } from "../../../interfaces/group";
import { Guid } from "../../../types/guid";
import { CourseGroupActionType } from "../course-group-list-actions/types/course-group-action-type";

@Component({
  selector: "course-group-list",
  templateUrl: "./course-group-list.component.html",
  styleUrls: ["./course-group-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseGroupListComponent {
  @Input() groups: Array<Group> = [];
  @Input() courseId: Guid;
  @Input() isTeacher = false;
  @Input() activeGroup?: Guid;
  @Input() disabledActions: Array<CourseGroupActionType> = [];

  get active(): Group {
    return this.groups.find((group) => this.isActive(group.id));
  }

  get rest(): Group[] {
    return this.groups.filter((group) => !this.isActive(group.id));
  }

  private isActive(id: Guid): boolean {
    return this.activeGroup === id;
  }
}
