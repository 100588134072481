import { createReducer, on } from "@ngrx/store";
import {
  PagionationData,
  SubscribtionOrganization,
} from "src/app/shared/interfaces/orgadmin/course-organization";
import { HttpErrorResponse } from "@angular/common/http";
import {
  coursesAdminInactiveListLoad,
  coursesAdminInactiveListLoadSuccess,
  loadInactiveTeachersFailure,
} from "../../actions/admin/course-inactive-admin.actions";

export interface AdminInactiveCourseListState {
  inactiveCourses: PagionationData<SubscribtionOrganization> | null;
  error: HttpErrorResponse | null;
}

const initialState: AdminInactiveCourseListState = {
  inactiveCourses: {
    next: 0,
    page_count: 0,
    previous: 0,
    result_count: 0,
    results: [],
  },
  error: null,
};

export const adminInactiveCourseListReducer = createReducer(
  initialState,
  on(
    coursesAdminInactiveListLoad,
    (state): AdminInactiveCourseListState => ({
      ...state,
      inactiveCourses: null,
      error: null,
    }),
  ),
  on(
    coursesAdminInactiveListLoadSuccess,
    (state, action): AdminInactiveCourseListState => ({
      ...state,
      inactiveCourses: action.coursesInactiveList,
      error: null,
    }),
  ),
  on(loadInactiveTeachersFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);
