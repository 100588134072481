<ul class="file-list">
  <ng-container *ngFor="let file of files">
    <li class="file-list-item">
      <div class="file-list-item-content">
        <icon
          class="file-list-item-content-icon file-list-item-content-icon-left"
          name="attachment-outline"
        />

        <div class="w-100">
          <div class="mb-2">
            <div class="file-list-item-content-label">
              {{ file.filename }}
            </div>
            <div class="file-list-item-content-value">
              {{ file.content.size | fileSize }}
            </div>
          </div>
        </div>

        <icon
          class="file-list-item-content-icon"
          set="uil"
          name="check-circle"
        />
      </div>
    </li>
  </ng-container>
</ul>
