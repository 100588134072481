import * as fromAuth from '../actions/auth.actions';
import { AuthToken } from '../../shared/interfaces/auth-token';
import { ErrorListing } from 'src/app/shared/types/error-listing';

export interface AuthState {
  authToken: AuthToken;
  loginFailed: boolean;
  loginErrors: ErrorListing;
  readTokenFailed: boolean;
}

export const initialState: AuthState = {
  authToken: {},
  loginFailed: false,
  loginErrors: {},
  readTokenFailed: false
};

export function reducer(
  state = initialState,
  action: fromAuth.AuthActions
): AuthState {
  switch (action.type) {
    case fromAuth.LOGIN_COMPLETED: {
      return {
        ...state,
        authToken: (<fromAuth.LoginCompleted>action).payload.authToken,
        loginFailed: false
      };
    }

    case fromAuth.LOGIN_FAILED: {
      return {
        ...state,
        loginFailed: true,
        loginErrors: action.payload
      };
    }

    case fromAuth.LOGOUT: {
      return initialState;
    }

    case fromAuth.READ_TOKEN: {
      return state;
    }

    case fromAuth.READ_TOKEN_FAILED: {
      return {
        ...state,
        readTokenFailed: true
      };
    }

    case fromAuth.REFRESH_TOKEN_COMPLETED: {
      return {
        ...state,
        authToken: {
          ...state.authToken,
          access: (<fromAuth.RefreshTokenCompleted>action).payload
        }
      };
    }

    default: {
      return state;
    }
  }
}
