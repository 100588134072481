<div class="language-picker">
  <div class="language-picker-button">
    <ng-container
      *ngIf="buttonLayoutTemplateRef; else defaultButtonLayoutTemplateRef"
    >
      <ng-container
        [ngTemplateOutlet]="buttonLayoutTemplateRef"
        [ngTemplateOutletContext]="{
          currentLang: getCurrentLang(),
          isToggled: isToggled,
          toggleClickFn: this.toggleList.bind(this)
        }"
      />
    </ng-container>
  </div>

  <ng-container *ngIf="isToggled">
    <div class="language-picker-langs">
      <ng-container
        *ngIf="langsLayoutTemplateRef; else defaultLangsLayoutTemplateRef"
      >
        <ng-container
          [ngTemplateOutlet]="langsLayoutTemplateRef"
          [ngTemplateOutletContext]="{
            langs: langs,
            langChangeFn: setLang.bind(this)
          }"
        />
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template #defaultButtonLayoutTemplateRef>
  <default-language-picker-button-layout
    [currentLang]="getCurrentLang()"
    [isToggled]="isToggled"
    (toggleClickEvent)="toggleList($event)"
  />
</ng-template>

<ng-template #defaultLangsLayoutTemplateRef>
  <default-language-picker-langs-layout
    [langs]="langs"
    (langChangeEvent)="setLang($event)"
  />
</ng-template>
