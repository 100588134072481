<tile>
  <box-header tile-header>
    {{ "GROUPS.LIST.INVITATIONS" | translate }}
  </box-header>

  <ul class="invitations-list">
    <li
      *ngFor="let invitation of invitations.slice(0, 2)"
      class="invitations-list-element"
    >
      <p class="invitation-details">
        <span class="label course-name">{{ invitation.group.name }}</span>
        <span class="paragraph-small color-hlabelgray">
          {{ invitation.course_name }}
        </span>
      </p>
      <a
        routerLink="/student/groups/invitations/{{ invitation.id }}"
        class="invitation-link"
      ></a>

      <div class="invitation-buttons">
        <button
          class="invitation-button btn-success-500"
          (click)="acceptInvitation(invitation.id)"
        >
          <icon name="user-add-outline" />
        </button>

        <button
          class="invitation-button btn-error-500"
          (click)="rejectInvitation(invitation.id)"
        >
          <icon name="cancel-outline" />
        </button>
      </div>
    </li>
  </ul>

  <a routerLink="/student/groups/invitations" class="label all-invitations">
    {{ "COURSES.DASHBOARD.INVITATIONS.SHOW_ALL" | translate }}
    <span *ngIf="invitations.length > 1">
      (
      <span class="count-all">{{ invitations.length }}</span>
      )
    </span>
  </a>
</tile>
