import { Component, EventEmitter, Input, Output } from "@angular/core";
import * as moment from "moment";
import { NotificationSnooze } from "src/app/shared/types/notification-settings";

@Component({
  selector: "li[snooze-group]",
  template: `
    <input
      type="radio"
      [id]="name"
      [value]="period"
      [ngModel]="value"
      (ngModelChange)="onChange($event)"
    />
    <label class="radio settings-checkbox" [for]="name">{{
      "GLOBAL.NOTIFICATIONS.SETTINGS.SNOOZE." + name | translate
    }}</label>
  `,
  styleUrls: [
    "./snooze-group.component.scss",
    "../notifications-settings/setting-group.component.scss",
  ],
})
export class SnoozeGroupComponent {
  @Input() value: NotificationSnooze["period"];
  @Input() name: string;
  @Input() period: NotificationSnooze["period"];
  @Output() valueChanged: EventEmitter<NotificationSnooze> = new EventEmitter();

  constructor() {}

  onChange(event: boolean) {
    this.valueChanged.emit({
      period: event ? this.period : undefined,
      date:
        Number.isInteger(<number>this.period) && event
          ? moment().add(this.period, "minutes").toString()
          : undefined,
    });
  }
}
