import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Lang } from "../../../interfaces/lang";

@Component({
  selector: "language-picker-button-with-flag-layout",
  templateUrl: "./language-picker-button-with-flag-layout.component.html",
  styleUrls: ["./language-picker-button-with-flag-layout.component.scss"],
})
export class LanguagePickerButtonWithFlagLayoutComponent {
  @Input() theme = "hbackgroundgray";
  @Input() currentLang: Lang;
  @Input() isToggled: boolean;

  @Output() toggleClickEvent = new EventEmitter<MouseEvent>();

  handleToggleClick(event: MouseEvent) {
    this.toggleClickEvent.emit(event);
  }
}
