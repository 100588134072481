import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";
import { Member } from "../../interfaces/member";

@Component({
  selector: "panel-page",
  templateUrl: "./panel-page.component.html",
  styleUrls: ["./panel-page.component.scss"],
})
export class PanelPageComponent {
  account$: Observable<Member> = this.store.select(
    (state) => state.account.member
  );
  constructor(
    private store: Store<{ account: fromAccountStore.AccountState }>
  ) {}
}
