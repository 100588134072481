import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "arrowClass",
})
export class ArrowPositionPipe implements PipeTransform {
  transform(position: "top" | "right" | "bottom" | "left"): string {
    return `tooltip__arrow--${position}`;
  }
}
