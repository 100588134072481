import { Component, OnInit } from "@angular/core";
import { ModalComponent } from "../../modal/modal.component";
import { HttpClient } from "@angular/common/http";
import { ConfigStorageService } from "src/app/shared/services/config-storage/config-storage.service";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "welcome-screen-modal",
  templateUrl: "./welcome-screen-modal.component.html",
  styleUrls: ["./welcome-screen-modal.component.scss"],
})
export class WelcomeScreenModalComponent
  extends ModalComponent
  implements OnInit
{
  steps: Array<{ text: string; videoUrl: string }> = [];
  dontShowWelcomeAgain = false;

  constructor(
    private http: HttpClient,
    private configStorage: ConfigStorageService,
    private cookieService: CookieService
  ) {
    super();
  }

  ngOnInit() {
    this.http
      .get<{ [key: string]: any }>("config/config.json")
      .subscribe((config) => {
        this.configStorage.store(config);
        this.steps = config.welcomeScreen.steps;
      });
  }

  closeWelcome() {
    this.close.emit("close");
  }

  setUnsetCookie() {
    let cookieName = "cyber-skiller-skip-welcome";

    if (this.dontShowWelcomeAgain && !this.cookieService.check(cookieName)) {
      this.cookieService.set(
        cookieName,
        "true",
        730,
        "/",
        undefined,
        undefined,
        "Strict"
      );
    } else if (
      !this.dontShowWelcomeAgain &&
      this.cookieService.check(cookieName)
    ) {
      this.cookieService.delete(cookieName, "/");
    }
  }
}
