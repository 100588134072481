<modal-overlay>
  <modal-box>
    <div class="history-modal-frame">
      <ng-content></ng-content>

      <div class="row no-gutters history-modal-content">
        <div
          *ngIf="
            mode === ItemListMode.STUDENTS || listCompleted;
            else !listCompleted ? listLoading : ''
          "
          class="col-3 left-column"
        >
          <ng-content select="[left-column]"></ng-content>
        </div>
        <div class="right-column col-9 d-flex w-100 h-100" [ngSwitch]="mode">
          <ng-container
            *ngSwitchCase="ItemListMode.STUDENTS"
            [ngTemplateOutlet]="selectStudent"
          ></ng-container>
          <ng-container *ngSwitchCase="ItemListMode.ITEM_LIST">
            <ng-container *ngIf="itemCompleted; else loading">
              <ng-container
                *ngIf="
                  item;
                  else list && list.result_count ? noSelected : noSubmissions
                "
              >
                <ng-content select="[content]"></ng-content>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <div class="row no-gutters mt-4">
        <div class="col-12 d-flex justify-content-end">
          <ng-content select="[footer]"></ng-content>
        </div>
      </div>
    </div>
  </modal-box>
</modal-overlay>

<ng-template #listLoading>
  <div class="col-3 item-list-container">
    <div class="d-flex align-items-center justify-content-center w-100 h-100">
      <loading-spinner width="100" height="100"></loading-spinner>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <div class="d-flex align-items-center justify-content-center w-100 h-100">
    <loading-spinner width="100" height="100"></loading-spinner>
  </div>
</ng-template>

<ng-template #selectStudent>
  <div class="d-flex align-items-center justify-content-center w-100 h-100">
    <p class="h4" translate>
      COURSES.COURSE.EXERCISE.HISTORY.SELECT_STUDENT_FROM_LIST
    </p>
  </div>
</ng-template>

<ng-template #noSelected>
  <div class="d-flex align-items-center justify-content-center w-100 h-100">
    <p class="h4" translate>
      {{ noSelectedText }}
    </p>
  </div>
</ng-template>

<ng-template #noSubmissions>
  <div class="d-flex align-items-center justify-content-center w-100 h-100">
    <p class="h4" translate>
      {{ noSubmissionsText }}
    </p>
  </div>
</ng-template>
