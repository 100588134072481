<div *ngIf="item" class="d-flex flex-column w-100">
  <div class="history-header">
    <h5 class="heading-1">{{ item.quiz.title }}</h5>
    <rich-text
      class="quiz-description"
      [content]="item.quiz.description"
    ></rich-text>

    <span class="label"
      >{{ "COURSES.COURSE.EXERCISE.QUIZ_HISTORY.STARTED" | translate }}:
      {{ item.start | date }}</span
    >
    <span class="label" *ngIf="item.end"
      >{{ "COURSES.COURSE.EXERCISE.QUIZ_HISTORY.ENDED" | translate }}:
      {{ item.end | date }}</span
    >

    <badge
      theme="{{
        item.score >= item.quiz.pass_mark ? 'hsecondary' : 'hinvalid'
      }}"
    >
      {{
        "COURSES.COURSE.EXERCISE.QUIZ_HISTORY." +
          (item.score >= list.quiz.pass_mark ? "PASSED" : "FAILED") | translate
      }}
    </badge>
  </div>

  <div
    class="quiz-question"
    *ngFor="let question of item.questions; let i = index"
  >
    <h6 class="question-content">
      <span class="question-number">{{ i + 1 }}</span
      >{{ question.content }}
    </h6>
    <rich-text class="explanation" [content]="question.explanation"></rich-text>

    <cached-quiz-figure
      [courseId]="courseId"
      [groupId]="isTeacher ? undefined : activeGroup"
      [exerciseId]="exerciseId"
      [question]="question"
    >
    </cached-quiz-figure>

    <div class="answers">
      <ng-container *ngFor="let answer of question.answer_set">
        <input
          type="checkbox"
          class="quiz-answer"
          [ngClass]="{
            'multi-choice': question.multi_choice,
            correct: list.quiz.show_correct_answers && answer.correct,
            incorrect: list.quiz.show_correct_answers && !answer.correct
          }"
          [checked]="list.quiz.show_selected_answers && answer.selected"
          disabled
        /><label class="checkbox">
          <ng-container *ngIf="isBoolean(answer.content); else textContent">
            {{
              "QUIZ.EXERCISE.BOOLEAN_" + (answer.content ? "TRUE" : "FALSE")
                | translate
            }}
          </ng-container>

          <ng-template #textContent>
            {{ answer.content }}
          </ng-template>
        </label>
      </ng-container>
    </div>
  </div>
</div>
