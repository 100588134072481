<div
  *ngIf="{
    id: currentPartialGrade$ | async,
    selectable: selectableExercises$ | async,
    highlightable: highlightableExercises$ | async
  } as data"
  class="stat-datatable-wrapper"
>
  <table class="stat-datatable">
    <thead class="stat-datatable-thead">
      <tr>
        <th class="datatable-column-sticky">
          <button
            type="button"
            class="row-expandable-action"
            (click)="rowsExpander.toggleExpandAllState()"
          >
            <span class="table-label">
              {{ "COURSES.COURSE.EXPAND_ALL_ACTION" | translate }}
            </span>

            <span
              class="row-expandable-action-icon"
              [class.row-expandable-action--expanded]="
                rowsExpander.isAllExpanded
              "
            >
              <icon name="chevron-right" />
            </span>
          </button>
        </th>
      </tr>
    </thead>
    <tbody class="stat-datatable-tbody">
      <ng-container *ngFor="let row of exercises.topics; let i = index">
        <tr class="row-expandable">
          <td class="datatable-column-sticky">
            <button
              type="button"
              class="row-expandable-action"
              (click)="rowsExpander.toggleExpandState(i)"
            >
              <span class="table-label">{{ row.name }}</span>

              <span
                class="row-expandable-action-icon"
                [class.row-expandable-action--expanded]="
                  rowsExpander.isExpanded(i)
                "
              >
                <icon name="chevron-right" />
              </span>
            </button>
          </td>
        </tr>

        <ng-container *ngFor="let exercise of row.exercises">
          <tr *ngIf="rowsExpander.isExpanded(i)">
            <td
              class="datatable-column-sticky"
              [ngClass]="{
                'stat-row':
                  data.highlightable &&
                  isSelectedExercise(exercise, selectedExercises)
              }"
            >
              <span class="table-label table-label--display">
                <ng-container *ngIf="data.selectable">
                  <input
                    type="checkbox"
                    [id]="exercise.name"
                    class="pass-course quiz-answer multi-choice no-bg"
                    (click)="handleSetExercises(exercise, data.id)"
                    [checked]="isSelectedExercise(exercise, selectedExercises)"
                  />
                  <label
                    class="pass-course--label checkbox"
                    [for]="exercise.name"
                  >
                    &nbsp;
                  </label>
                </ng-container>
                {{ exercise.name }}
              </span>
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</div>
