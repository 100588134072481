<ng-container [ngSwitch]="status">
  <ng-container *ngSwitchCase="UploadStatus.Preloaded">
    <button
      type="button"
      class="course-exercise-student-upload-action course-exercise-student-upload-action-preloaded"
      (click)="handleUploadFileClickForPreloaded()"
    >
      <div class="mr-2">
        <icon set="uil" name="file-upload-alt" />
      </div>

      <span>
        {{ "GLOBAL.FILE_UPLOADED" | translate }}: {{ file.filename }}
      </span>
    </button>

    <span
      class="course-exercise-student-upload-action-clear"
      (click)="handleClearClick()"
    >
      <span
        [tooltip]="
          'COURSES.COURSE.EXERCISE.DELETE_ANSWER_AND_SOLVE_AGAIN' | translate
        "
      >
        <icon name="delete-outline" />
      </span>
    </span>
  </ng-container>

  <ng-container *ngSwitchCase="UploadStatus.Sent">
    <button
      type="button"
      class="course-exercise-student-upload-action course-exercise-student-upload-action-sent"
      (click)="handleDownloadClick()"
    >
      <icon name="attachment-outline" />

      <span> {{ "GLOBAL.FILE_POSTED" | translate }}: {{ file.filename }} </span>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="UploadStatus.Failed">
    <button
      type="button"
      class="course-exercise-student-upload-action course-exercise-student-upload-action-failed"
      (click)="handleDownloadClick()"
    >
      <icon name="attachment-outline" />

      <span> {{ "GLOBAL.FILE_POSTED" | translate }}: {{ file.filename }} </span>
    </button>

    <span
      class="course-exercise-student-upload-action-clear"
      (click)="handleClearClick()"
    >
      <span
        [tooltip]="
          'COURSES.COURSE.EXERCISE.DELETE_ANSWER_AND_SOLVE_AGAIN' | translate
        "
      >
        <icon name="delete-outline" />
      </span>
    </span>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <button
      type="button"
      class="theme-hprimarydark"
      (click)="handleUploadFileClick()"
    >
      <icon name="attachment-outline" />

      <span>
        {{ "GLOBAL.UPLOAD_FILE" | translate }}
      </span>
    </button>
  </ng-container>
</ng-container>
