import { Action } from "@ngrx/store";
import {
  CodeCheckListItemRequest,
  CodeCheckListRequest,
} from "src/app/shared/interfaces/code-check-list-request";
import { CodeCheckListResults } from "src/app/shared/interfaces/code-check-list-results";
import { CodeVerification } from "src/app/shared/interfaces/code-verification";

export const GET_CODE_CHECK_LIST = "[CodeCheckHistory Method] GetCodeCheckList";
export const GET_CODE_CHECK_LIST_PAGE =
  "[CodeCheckHistory Method] GetCodeCheckListPage";
export const GET_CODE_CHECK_LIST_COMPLETED =
  "[CodeCheckHistory Method] GetCodeCheckListCompleted";
export const GET_CODE_CHECK_LIST_PAGE_COMPLETED =
  "[CodeCheckHistory Method] GetCodeCheckListPageCompleted";

export const GET_CODE_CHECK = "[CodeCheckHistory Method] GetCodeCheck";
export const GET_CODE_CHECK_COMPLETED =
  "[CodeCheckHistory Method] GetCodeCheckCompleted";

export class GetCodeCheckList implements Action {
  readonly type = GET_CODE_CHECK_LIST;

  constructor(public payload: CodeCheckListRequest) {}
}

export class GetCodeCheckListPage implements Action {
  readonly type = GET_CODE_CHECK_LIST_PAGE;

  constructor(public payload: CodeCheckListRequest) {}
}

export class GetCodeCheckListCompleted implements Action {
  readonly type = GET_CODE_CHECK_LIST_COMPLETED;

  constructor(public payload: CodeCheckListResults) {}
}

export class GetCodeCheckListPageCompleted implements Action {
  readonly type = GET_CODE_CHECK_LIST_PAGE_COMPLETED;

  constructor(public payload: CodeCheckListResults) {}
}

export class GetCodeCheck implements Action {
  readonly type = GET_CODE_CHECK;

  constructor(public payload: CodeCheckListItemRequest) {}
}

export class GetCodeCheckCompleted implements Action {
  readonly type = GET_CODE_CHECK_COMPLETED;

  constructor(public payload: CodeVerification) {}
}

export type CodeCheckHistoryActions =
  | GetCodeCheckList
  | GetCodeCheckListPage
  | GetCodeCheckListCompleted
  | GetCodeCheckListPageCompleted
  | GetCodeCheck
  | GetCodeCheckCompleted;
