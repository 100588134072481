import { Component } from '@angular/core';

@Component({
  selector: 'modal-box',
  template: `
    <box><ng-content></ng-content></box>
  `,
  styles: [],
  host: {
    class: 'modal'
  }
})
export class ModalBoxComponent {
  constructor() {}
}
