<ng-container
  *ngIf="{
    runningExercise: runningExercise$ | async,
    getRunningExerciseCompleted: getRunningExerciseCompleted$ | async,
    extendTime: extendTime$ | async
  } as exTimer"
>
  <div
    class="exercise-timer-wrapper"
    *ngIf="
      exTimer.getRunningExerciseCompleted && exTimer.runningExercise.length
    "
  >
    <div class="row no-gutters align-items-center timer-control-wrapper">
      <div class="col d-flex align-items-center timer-content-wrapper">
        <button
          class="shadow theme-hsecondary"
          [disabled]="!exTimer.getRunningExerciseCompleted"
          *ngIf="
            exTimer.runningExercise[0].extendable &&
            millisecondsLeft <= exTimer.extendTime &&
            !stopPressed &&
            exTimer.runningExercise[0].status !== 'starting'
          "
          (click)="extend(exTimer.runningExercise[0].id)"
        >
          <countdown size="12px" *ngIf="!exTimer.getRunningExerciseCompleted" />
          {{ "GLOBAL.TIMER_WIDGET.EXTEND_BUTTON" | translate }}
        </button>

        <div *ngIf="stopping" class="timer">
          <countdown color="hlogo" size="12px"></countdown>
          {{ "GLOBAL.TIMER_WIDGET.STOPPING" | translate }}
        </div>

        <div
          *ngIf="exTimer.runningExercise[0].status === 'starting'"
          class="timer"
        >
          <countdown color="hannotationgray" size="12px" />
          {{ "GLOBAL.TIMER_WIDGET.STARTING" | translate }}
        </div>
      </div>
    </div>
  </div>

  <div
    class="exercise-timer-wrapper wrapper-loading d-flex align-items-center justify-content-center"
    *ngIf="!exTimer.getRunningExerciseCompleted"
  >
    <loading-spinner></loading-spinner>
  </div>
</ng-container>

<ng-template #stopButtonContent let-exTimer>
  <countdown
    size="12px"
    *ngIf="!exTimer.getRunningExerciseCompleted"
  ></countdown>
  <span
    *ngIf="exTimer.getRunningExerciseCompleted"
    class="typcn typcn-media-stop"
  ></span>
  {{ "GLOBAL.TIMER_WIDGET.STOP_BUTTON" | translate }}
</ng-template>
