import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { Observable } from "rxjs";
import { ExerciseFileUploadAnswerRequest } from "../../interfaces/exercise-file-upload-answer-request";
import { ExerciseFileUploadAnswerReviewRequest } from "../../interfaces/exercise-file-upload-answer-review-request";
import {
  ExerciseFileUploadBulkDownloadRequest,
  ExerciseFileUploadDownloadRequest,
  S3DownloadResponse,
} from "../../interfaces/exercise-file-upload-download-request";
import { AnswerResponse } from "../../interfaces/answer-response";

@Injectable({
  providedIn: "root",
})
export class TeacherExerciseFileUploadApiService {
  constructor(private readonly apiService: ApiService) {}

  collection(
    request: ExerciseFileUploadAnswerRequest,
  ): Observable<AnswerResponse[]> {
    const { courseId, groupId, exerciseId } = request;

    return this.apiService.getRequest(
      `courses/${courseId}/groups/${groupId}/exercises/${exerciseId}/review/`,
    );
  }

  pass(request: ExerciseFileUploadAnswerReviewRequest): Observable<void> {
    const { courseId, groupId, exerciseId, fileId } = request;
    const data = { review: true };

    return this.apiService.putRequest(
      `courses/${courseId}/groups/${groupId}/exercises/${exerciseId}/review/${fileId}/`,
      data,
    );
  }

  fail(
    request: ExerciseFileUploadAnswerReviewRequest,
  ): Observable<AnswerResponse> {
    const { courseId, groupId, exerciseId, fileId } = request;
    const data = { review: false };

    return this.apiService.putRequest(
      `courses/${courseId}/groups/${groupId}/exercises/${exerciseId}/review/${fileId}/`,
      data,
    );
  }

  download(
    request: ExerciseFileUploadDownloadRequest,
  ): Observable<S3DownloadResponse> {
    const { courseId, groupId, exerciseId, fileId } = request;

    return this.apiService.getRequest<S3DownloadResponse>(
      `courses/${courseId}/groups/${groupId}/exercises/${exerciseId}/review/${fileId}/download/`,
    );
  }

  downloadBulk(
    request: ExerciseFileUploadBulkDownloadRequest,
  ): Observable<S3DownloadResponse> {
    const { courseId, groupId, exerciseId } = request;

    return this.apiService.getRequest<S3DownloadResponse>(
      `courses/${courseId}/groups/${groupId}/exercises/${exerciseId}/bulk_download/`,
    );
  }
}
