import {
  Component,
  DestroyRef,
  EventEmitter,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Nillable } from "../../../../shared/types/nillable";
import { Observable, of } from "rxjs";
import { SelectItem } from "../../../../shared/blocks/dropdown/models/select-item";
import { StudentPassFilterForm } from "./types/student-pass-filter-form";
import { StudentPassFilterFormData } from "./types/models/student-pass-filter-form-data";
import { StudentPassConditionFormService } from "./types/services/student-pass-condition-form.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { isNil } from "lodash-es";

@Component({
  selector: "student-pass-filter-form",
  templateUrl: "./student-pass-filter-form.component.html",
  styleUrls: ["./student-pass-filter-form.component.scss"],
})
export class StudentPassFilterFormComponent implements OnInit {
  @Output() saveEvent = new EventEmitter<StudentPassFilterFormData>();

  suffix = "%";

  readonly form = this.buildForm();
  readonly passSelectItems$ = this.passSelectItems();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly studentPassCondition: StudentPassConditionFormService,
    private readonly destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.listenToDataChange();
  }

  handleSaveClick(): void {
    if (this.form.invalid) {
      return;
    }

    this.studentPassCondition.save(this.toFormData());
    this.saveEvent.emit(this.toFormData());
  }

  private buildForm(): FormGroup<StudentPassFilterForm> {
    return this.formBuilder.group({
      passFrom: this.formBuilder.control<Nillable<string>>(null, [
        Validators.min(0),
        Validators.max(100),
        Validators.required,
      ]),
    });
  }

  private toFormData(): StudentPassFilterFormData {
    const { passFrom } = this.form.getRawValue();

    return new StudentPassFilterFormData(parseFloat(passFrom));
  }

  prepareFormData(data: Nillable<StudentPassFilterFormData>): {
    passFrom: string;
  } {
    return isNil(data) ? null : { passFrom: `${data.passFrom}${this.suffix}` };
  }

  private passSelectItems(): Observable<SelectItem<string>[]> {
    return of([
      new SelectItem("30%", "30%"),
      new SelectItem("40%", "40%"),
      new SelectItem("50%", "50%"),
    ]);
  }

  private listenToDataChange(): void {
    this.studentPassCondition.data
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: Nillable<StudentPassFilterFormData>) => {
        if (isNil(data)) {
          this.form.reset();

          return;
        }

        this.form.patchValue(this.prepareFormData(data));
      });
  }
}
