import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Lang } from "../../../interfaces/lang";

@Component({
  selector: "language-picker-langs-with-flag-layout",
  templateUrl: "./language-picker-langs-with-flag-layout.component.html",
  styleUrls: ["./language-picker-langs-with-flag-layout.component.scss"],
})
export class LanguagePickerLangsWithFlagLayoutComponent {
  @Input() langs: Array<Lang>;
  @Output() langChangeEvent = new EventEmitter<string>();

  handleLangClick(name: string): void {
    this.langChangeEvent.emit(name);
  }
}
