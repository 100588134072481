import { Component, Input, Type, DestroyRef } from "@angular/core";
import { ModalComponent } from "../../components/modal/modal.component";
import { VpnConfigurationModalComponent } from "../../components/modals/vpn-configuration-modal/vpn-configuration-modal.component";
import { NavbarElementComponent } from "../../components/navbar/navbar-element/navbar-element.component";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import * as fromTutorial from "../../../store/reducers/tutorial.reducer";
import {
  TutorialSteps,
  TutorialTeacherSteps,
} from "../../enums/tutorial-steps";
import { TutorialState } from "../../../store/reducers/tutorial.reducer";

@Component({
  selector: "avatar",
  templateUrl: "./avatar.component.html",
  styleUrls: ["./avatar.component.scss"],
})
export class AvatarComponent extends NavbarElementComponent {
  @Input() name = "";
  @Input() isAdmin = false;
  @Input() isTeacher = false;
  @Input("remoteIP") remote_ip;

  vpnModal: Type<ModalComponent> = VpnConfigurationModalComponent;

  tutorial$: Observable<TutorialState> = this.store.select(
    (state) => state.tutorial,
  );

  TutorialSteps = TutorialSteps;
  TutorialTeacherSteps = TutorialTeacherSteps;

  constructor(
    private store: Store<{
      tutorial: fromTutorial.TutorialState;
    }>,
  ) {
    super();
  }

  hideMainMenu(event: MouseEvent) {
    event.preventDefault();
    super.toggle();
  }
}
