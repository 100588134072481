import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'page-toolbar',
  templateUrl: './page-toolbar.component.html',
  styleUrls: ['./page-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageToolbarComponent {

}
