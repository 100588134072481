<ng-container [ngSwitch]="mode">
  <div *ngSwitchCase="ItemListMode.STUDENTS">
    <ng-content select="[student-list]"></ng-content>
  </div>

  <div *ngSwitchCase="ItemListMode.ITEM_LIST">
    <button
      *ngIf="showBackButton"
      class="back-button btn-link"
      (click)="backToStudents()"
    >
      <i class="typcn typcn-arrow-left"></i>
      {{ "COURSES.COURSE.EXERCISE.HISTORY.BACK_TO_STUDENT_LIST" | translate }}
    </button>
    <ng-content select="[item-list]"></ng-content>
  </div>
</ng-container>
