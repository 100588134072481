<ng-container
  *ngIf="{ member: member$ | async, tutorial: tutorial$ | async } as dashboard"
>
  <tutorial-text-box
    *ngIf="dashboard.tutorial.tutorial_step === TutorialSteps.MAIN_PANEL"
    [customStyle]="{
      top: '10px',
      left: '50%'
    }"
    [title]="'TUTORIAL_DATA.MAIN_PANEL_TITLE' | translate"
    [message]="
      dashboard.member.is_teacher
        ? ('TUTORIAL_TEACHER_DATA.DASHBOARD_MESSAGE' | translate)
        : ('TUTORIAL_DATA.MAIN_PANEL_MESSAGE' | translate)
    "
    [step]="TutorialSteps.MAIN_PANEL"
  />
  <div
    class="dashboard"
    [ngClass]="{
      'sidebar-expanded': expanded,
      'tutorial-border':
        dashboard.tutorial.tutorial_step === TutorialSteps.MAIN_PANEL
    }"
  >
    <ng-content select="[dashboard-body]"></ng-content>
    <dashboard-sidebar [expanded]="expanded" (expand)="setExpand($event)">
      <ng-content select="[sidebar-content]"></ng-content>
    </dashboard-sidebar>
  </div>
</ng-container>
