<ng-container
  *ngIf="{
    userId: userId$ | async,
    settings: notificationSettings$ | async
  } as snoozeView">
  <p class="paragraph-small color-hannotationgray mb-3">
    {{"GLOBAL.NOTIFICATIONS.SETTINGS.SNOOZE.DESC" | translate}}
  </p>
  <ul *ngIf="snoozeView.settings" class="snooze-list">
    <li
      *ngFor="let group of snoozeOptions"
      snooze-group
      [name]="group.name"
      [value]="snoozeView.settings.toJS().notificationSnooze.period"
      [period]="group.period"
      (valueChanged)="
        setSetting($event, snoozeView.settings, snoozeView.userId)
      "></li>
  </ul>


</ng-container>
