import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  inject,
} from "@angular/core";
import { TabPanelComponent } from "./components/tab-panel/tab-panel.component";
import { Nillable } from "../../types/nillable";
import { isEmpty, isNil } from "lodash-es";
import { Observable, combineLatest, of } from "rxjs";
import { map, switchMap, take, tap } from "rxjs/operators";
import { SelectItem } from "../dropdown/models/select-item";
import { StudentPassConditionTabName } from "src/app/modules/teacher/components/student-pass-condition/enums/student-pass-condition-tab-name";
import { TranslateService } from "@ngx-translate/core";
import { FormControl } from "@angular/forms";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "tab-view",
  templateUrl: "./tab-view.component.html",
  styleUrls: ["./tab-view.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabViewComponent implements OnInit, AfterContentInit {
  @Output() onChangeEvent = new EventEmitter<number>();
  @Input() initTab: number | null;
  @Input()
  set disabled(value: Nillable<boolean>) {
    if (value) {
      this.criteriaControl.reset();
    }
    this._disabled = value;
  }

  get disabled(): Nillable<boolean> {
    return this._disabled;
  }

  private _disabled: Nillable<boolean> = false;

  @ContentChildren(TabPanelComponent) tabPanels: QueryList<
    Nillable<TabPanelComponent>
  >;

  private readonly translate = inject(TranslateService);
  private readonly destroyRef = inject(DestroyRef);

  criteriaControl = new FormControl<number | null>(null);
  criteriaOptions$: Observable<SelectItem<StudentPassConditionTabName>[]> =
    this.criteriaFormOptions();

  ngOnInit(): void {
    this.criteriaFormOptions()
      .pipe(
        map((options) => {
          options.forEach((option) => {
            if (this.initTab === option.value - 1) {
              this.criteriaControl.patchValue(option.value);
            }
          });
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  ngAfterContentInit(): void {
    this.configureActiveTabPanel();
  }

  handleTabClick(option: SelectItem<StudentPassConditionTabName>): void {
    Array.from(this.tabPanels).map((tab, index) => {
      if (option.value - 1 === index) {
        if (tab.disabled) {
          return;
        }

        if (tab.selected) {
          return;
        }

        this.onChangeEvent.next(option.value - 1);
        this.deselectTabPanel();
        tab.selected = true;
      }
    });
  }

  private findSelectedTabPanel(): Observable<Nillable<TabPanelComponent>> {
    return combineLatest(
      Array.from(this.tabPanels).map((tab) => tab.isSelected),
    ).pipe(
      switchMap((tabs) => {
        const index = tabs.findIndex((selected) => selected);
        return index > -1
          ? of(Array.from(this.tabPanels)[index])
          : of(undefined);
      }),
      take(1),
    );
  }

  private configureActiveTabPanel(): void {
    this.findSelectedTabPanel().subscribe((firstSelected) => {
      const tabs = Array.from(this.tabPanels);

      if (!(isNil(firstSelected) && !isEmpty(tabs))) {
        return;
      }

      const firstEnabled = tabs.find((tab) => !tab.disabled);

      if (isNil(firstEnabled)) {
        const [firstDisabled] = tabs;

        firstDisabled.selected = true;

        return;
      }

      firstEnabled.selected = true;
    });
  }

  private deselectTabPanel(): void {
    this.findSelectedTabPanel().subscribe((selectedTab) => {
      if (isNil(selectedTab)) {
        return;
      }

      selectedTab.selected = false;
    });
  }

  private criteriaFormOptions(): Observable<
    SelectItem<StudentPassConditionTabName>[]
  > {
    return of([
      new SelectItem(
        this.translate.instant("COURSES.COURSE.PASS"),
        StudentPassConditionTabName.PASS + 1,
      ),
      new SelectItem(
        this.translate.instant("COURSES.COURSE.RATING_SYSTEM"),
        StudentPassConditionTabName.RATING_SYSTEM + 1,
      ),
    ]);
  }
}
