<div class="avatar-wrapper" *ngIf="tutorial$ | async as tutorial">
  <dropdown-menu>
    <ng-container dropdown-face>
      <div class="photo-status">
        <span
          class="icon"
          [ngClass]="{
            'tutorial-border': isTeacher
              ? tutorial.tutorial_step === TutorialTeacherSteps.USER_BTN
              : tutorial.tutorial_step === TutorialSteps.USER_BTN
          }"
        >
          <i class="typcn typcn-user-outline"></i>
        </span>
        <status-icon icon="plug" [isOk]="remote_ip !== null" />
      </div>

      <p class="user-name label">{{ name }}</p>
    </ng-container>

    <ng-container dropdown-menu>
      <li>
        <a
          [routerLink]="['/account/settings']"
          (click)="hideMainMenu($event)"
          class="d-flex dropdown-anchor align-items-center"
        >
          <span class="typcn typcn-user-outline"></span>
          <span>{{ "GLOBAL.AVATAR.ACCOUNT_LINK" | translate }}</span>
        </a>
      </li>

      <li>
        <a
          href="#"
          (click)="hideMainMenu($event)"
          [popup]="vpnModal"
          class="d-flex dropdown-anchor align-items-center"
        >
          <span class="typcn typcn-link-outline"></span>
          <span>{{ "GLOBAL.AVATAR.LAB_LINK" | translate }}</span>
        </a>
      </li>

      <li *ngIf="isAdmin">
        <a
          [routerLink]="['/orgadmin']"
          (click)="hideMainMenu($event)"
          class="d-flex dropdown-anchor align-items-center"
        >
          <span class="uil uil-university pr-2"></span>
          <span>{{ "ADMIN.SWITCH_TO_ADMIN" | translate }}</span>
        </a>
      </li>

      <li>
        <a
          (click)="hideMainMenu($event)"
          class="d-flex dropdown-anchor align-items-center"
          [routerLink]="['/account/logout']"
        >
          <span class="typcn typcn-power"></span>
          <span>{{ "GLOBAL.AVATAR.LOGOUT_LINK" | translate }}</span>
        </a>
      </li>
    </ng-container>
  </dropdown-menu>

  <tutorial-text-box
    *ngIf="
      isTeacher
        ? tutorial.tutorial_step === TutorialTeacherSteps.USER_BTN
        : tutorial.tutorial_step === TutorialSteps.USER_BTN
    "
    [customStyle]="{ top: '80px', right: '-10px', width: '250px' }"
    [title]="'TUTORIAL_DATA.USER_TITLE' | translate"
    [message]="'TUTORIAL_DATA.USER_MESSAGE' | translate"
    [step]="isTeacher ? TutorialTeacherSteps.USER_BTN : TutorialSteps.USER_BTN"
  />
</div>
