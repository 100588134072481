import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'course-exercise-desc',
  templateUrl: './course-exercise-desc.component.html',
  styleUrls: ['./course-exercise-desc.component.scss']
})
export class CourseExerciseDescComponent implements OnInit {
  @Input() content = '';
  
  constructor() { }

  ngOnInit() {
  }

}
