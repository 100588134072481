import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Student } from "src/app/shared/interfaces/student";

@Component({
  selector: "history-modal-students-list",
  templateUrl: "./history-modal-students-list.component.html",
  styleUrls: ["./history-modal-students-list.component.scss"],
})
export class HistoryModalStudentsListComponent {
  @Input() students: Array<Student> = [];
  @Input() mySubmissionsText: string;
  @Output() studentClicked: EventEmitter<Student | null> = new EventEmitter();

  constructor() {}
}
