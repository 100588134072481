<section
  class="navbar d-block d-md-flex align-items-center"
  [ngClass]="{ toggled: isToggled }"
>
  <header class="navbar-header d-flex align-items-center">
    <div
      class="menu-button d-block d-md-none"
      [ngClass]="{ toggled: isToggled }"
      (click)="toggle()"
    >
      <span class="bar"></span>
      <span class="bar"></span>
      <span class="bar"></span>
      <span class="typcn typcn-arrow-down"></span>
    </div>

    <logo></logo>
  </header>

  <div class="navbar-content w-100" [ngClass]="{ 'show-mobile': isToggled }">
    <div
      class="d-flex w-100 flex-column-reverse flex-md-row align-items-md-center"
    >
      <div class="flex-grow-1">
        <navbar-nav
          [isTeacher]="account.is_teacher"
          (toggleFunction)="toggle()"
        />
      </div>

      <div
        class="flex-grow-0 d-flex flex-column-reverse flex-md-row align-items-md-center"
      >
        <notifications-icon />

        <avatar
          [name]="account.name"
          [isAdmin]="account.is_orgadmin"
          [isTeacher]="account.is_teacher"
          [remoteIP]="account.remote_ip"
          (toggleFunction)="toggle()"
        />

        <language-picker (onLangPick)="storeLangSession($event)" />
      </div>
    </div>
  </div>
</section>
