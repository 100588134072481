import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { AnswerResponse } from "../../../../interfaces/answer-response";

@Injectable()
export class CourseExerciseAnswerListModalDataService {
  private readonly dataSource$ = new BehaviorSubject<AnswerResponse[]>([]);

  get data(): AnswerResponse[] {
    return this.dataSource$.value;
  }

  get data$(): Observable<AnswerResponse[]> {
    return this.dataSource$.asObservable();
  }

  patch(output: AnswerResponse[]): void {
    this.dataSource$.next(output);
  }

  clear(): void {
    this.dataSource$.next([]);
  }
}
