<ng-container
  *ngIf="{
    getAnnouncementsCompleted: getAnnouncementsCompleted$ | async,
    announcements: announcements$ | async
  } as ann"
>
  <div
    class="announcements-container"
    *ngIf="ann.getAnnouncementsCompleted; else loadingAnnouncements"
  >
    <announcement
      *ngFor="let announcement of ann.announcements"
      [announcement]="announcement"
    ></announcement>
  </div>
</ng-container>

<ng-template #loadingAnnouncements>
  <loading-spinner></loading-spinner>
</ng-template>
