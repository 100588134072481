<div class="course-group-list-header">
  <div class="d-flex align-items-center">
    <badge icon="group" theme="hprimary-700" class="mr-2">
      <span class="ml-3">
        {{ active?.students_count ?? 0 }}
      </span>
    </badge>

    <ng-container *ngIf="hasAnyGroup; else defaultTemplate">
      <div class="course-group-list-dropdown">
        <dropdown-menu class="dropdown-light dropdown-rounded">
          <ng-container dropdown-face>
            <div class="course-group-list-dropdown-header">
              <span class="course-group-list-dropdown-header-label">
                {{ active?.name || "COURSES.COURSE.CHOOSE_GROUP" | translate }}
              </span>
            </div>
          </ng-container>

          <ng-container dropdown-menu>
            <li *ngFor="let group of rest">
              <a
                class="label dropdown-anchor dropdown-anchor-light align-items-center justify-content-between"
                [routerLink]="[getLink(group.id)]"
              >
                <badge icon="group" theme="hprimary-700">
                  <span class="ml-3">
                    {{ group.students_count ?? 0 }}
                  </span>
                </badge>

                <span class="course-group-list-dropdown-label">
                  {{ group.name }}
                </span>

                <round-button
                  class="hprimary-700"
                  icon="chevron-right"
                  [link]="getLink(group.id)"
                />
              </a>
            </li>

            <li *ngIf="isTeacher">
              <a
                class="dropdown-anchor dropdown-anchor-light align-items-center justify-content-center label"
                [routerLink]="['/teacher/groups/add/' + courseId]"
              >
                <span class="course-group-list-dropdown-label">
                  {{ "COURSES.COURSE.ADD_GROUP" | translate }}
                </span>

                <icon name="plus" />
              </a>
            </li>
          </ng-container>
        </dropdown-menu>
      </div>
    </ng-container>

    <ng-template #defaultTemplate>
      <a
        class="course-group-list-header-link"
        [routerLink]="['/teacher/groups/add/' + courseId]"
      >
        <span class="mr-2">
          {{ "COURSES.COURSE.ADD_GROUP" | translate }}
        </span>

        <icon name="plus" />
      </a>
    </ng-template>
  </div>

  <ng-container *ngIf="isTeacher">
    <course-group-list-actions
      [courseId]="courseId"
      [group]="active"
      [isTeacher]="isTeacher"
      [disabledActions]="disabledActions"
    />
  </ng-container>
</div>
