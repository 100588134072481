export namespace RankingQuery {
  export enum FilterBy {
    user = "user",
    organization = "organization",
    region = "region",
    coursegroup = "coursegroup",
  }

  export enum GroupBy {
    user = "user",
    organization = "organization",
    region = "region",
  }

  export enum Span {
    all = "all",
    day = "day",
    week = "week",
    month = "month",
  }

  export enum RegionScope {
    sub = "sub", // (all descendants of the region)
    chi = "chi", // (children of the region)
    sib = "sib", // (siblings of the region)
    par = "par", // (parent of the region)
    one = "one", // (only filtered region)
  }

  export enum RegionType {
    city = "city",
  }

  export enum RankingType {
    points = "points",
    count = "count",
    quiz = "quiz",
    sprint = "sprint",
    days = "days",
  }
}
