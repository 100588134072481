<ng-container *ngIf="tutorial$ | async as tutorial">
  <a *ngIf="isTeacher" routerLink="/ide" class="dashboard-menu-item-link">
    <simple-dashboard-widget
      [hover]
      [data]="{
        sub_label: 'COURSES.DASHBOARD.PLACES_MENU.CODE_EDITOR'
      }"
      icon="code"
      [ngClass]="{
        'tutorial-border':
          tutorial.tutorial_step === TutorialTeacherSteps.EDITOR_CODE
      }"
    ></simple-dashboard-widget>
  </a>

  <tutorial-text-box
    *ngIf="
      isTeacher && tutorial.tutorial_step === TutorialTeacherSteps.EDITOR_CODE
    "
    [customStyle]="{
      'margin-left': '-335px',
      'margin-top': '-165px'
    }"
    [title]="'TUTORIAL_TEACHER_DATA.EDITOR_CODE_LABEL' | translate"
    [message]="'TUTORIAL_TEACHER_DATA.EDITOR_CODE' | translate"
    [step]="TutorialTeacherSteps.EDITOR_CODE"
  />

  <a
    routerLink="../courses/onboarding"
    class="dashboard-menu-item-link"
    (click)="setFirstStepsDescVisibility('hidden')"
  >
    <simple-dashboard-widget
      [hover]
      [data]="{
        sub_label: 'COURSES.DASHBOARD.PLACES_MENU.ONBOARDING'
      }"
      icon="rocket"
      set="uil"
      [ngClass]="{
        'tutorial-border': isTeacher
          ? tutorial.tutorial_step === TutorialTeacherSteps.FIRST_STEPS
          : tutorial.tutorial_step === TutorialSteps.FIRST_STEPS
      }"
    ></simple-dashboard-widget>
  </a>
  <tutorial-text-box
    *ngIf="
      isTeacher
        ? tutorial.tutorial_step === TutorialTeacherSteps.FIRST_STEPS
        : tutorial.tutorial_step === TutorialSteps.FIRST_STEPS
    "
    [customStyle]="{
      'margin-left': '-423px',
      'margin-top': '-165px'
    }"
    [title]="'TUTORIAL_DATA.ONBOARDING_TITLE' | translate"
    [message]="'TUTORIAL_DATA.ONBOARDING_MESSAGE' | translate"
    [step]="
      isTeacher ? TutorialTeacherSteps.FIRST_STEPS : TutorialSteps.FIRST_STEPS
    "
  ></tutorial-text-box>

  <div *ngIf="firstStepsDescVisible" class="onboarding-first-steps">
    <p
      class="first-steps-desc"
      [innerHTML]="'COURSES.DASHBOARD.PLACES_MENU.ONBOARDING_DESC' | translate"
    ></p>
  </div>
</ng-container>
