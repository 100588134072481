import {
  ChangeDetectionStrategy,
  Component,
  Input
} from "@angular/core";
import { Topic } from "../../../interfaces/topic";

@Component({
  selector: "course-topic-header",
  templateUrl: "./course-topic-header.component.html",
  styleUrls: ["./course-topic-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseTopicHeaderComponent {
  @Input() currentTopic: Topic;
}
