import { Directive, ElementRef, EventEmitter, HostListener, Output, } from "@angular/core";

@Directive({
  selector: "[clickOutside]",
})
export class ClickOutsideDirective {
  @Output() clickedOutsideEvent = new EventEmitter<void>();

  constructor(private readonly elementRef: ElementRef) {}

  @HostListener("document:click", ["$event"])
  handleDocumentClick(event: MouseEvent) {
    if (this.isOutsideClicked(event)) {
      return;
    }

    this.clickedOutsideEvent.emit();
  }

  private isOutsideClicked(event: MouseEvent): boolean {
    return this.elementRef.nativeElement.contains(event.target);
  }
}
