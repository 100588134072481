<div class="progress-bar-container">
  <div
    role="progressbar"
    class="progress-bar progress-bar-determinate"
  >
    <div
      class="progress-bar-value progress-bar-value-animate"
      [style.width]="value + '%'"
      [style.background]="color">
    </div>
  </div>

  <ng-container *ngIf="showValue">
    <div class="progress-bar-label">
      <div [style.display]="labelDisplayClass">
        {{ value }}{{ unit }}
      </div>
    </div>
  </ng-container>
</div>
