import { Component } from '@angular/core';

@Component({
  selector: 'indicator',
  template: `
    <span class="indicator"></span>
  `,
  styleUrls: ['./indicator.component.scss']
})
export class IndicatorComponent {
  constructor() {}
}
