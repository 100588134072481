<div
  class="popup-trigger-container"
  [ngClass]="{
    'tutorial-icon':
      (tutorial$ | async) === TutorialTeacherSteps.COURSE_EXPAND_GROUPS &&
      (isTeacher$ | async)
  }"
>
  <div class="popup-trigger-label">{{ label }}</div>

  <div class="popup-trigger-chevron">
    <icon name="chevron-right" [class.rotate-90]="expanded" />
  </div>
</div>
