<div class="row align-items-center">
  <div class="col">
    <p class="group-name d-flex flex-column">
      <span *ngIf="isTeacher" class="heading-6">{{ group.name }}</span>
      <span *ngIf="!isTeacher" class="heading-6">{{ course.name }}</span>
      <span class="color-hlabelgray label" *ngIf="!isTeacher">
        {{ "GROUPS.LIST.LEAD" | translate }}: {{ group.teacher_name }}
      </span>
    </p>

    <p
      *ngIf="group.weekday !== null"
      class="group-datetime paragraph-small color-hannotationgray"
    >
      {{ group.weekday | day | translate }} <br />
      {{ group.start_time | formatHour }} - {{ group.end_time | formatHour }}
    </p>
  </div>

  <div class="col-auto">
    <round-button
      icon="chevron-right"
      [link]="
        isTeacher
          ? 'teacher'
          : 'student' + '/courses/' + course.id + '/groups/' + group.id
      "
      (click)="onClick()"
    />
  </div>
</div>
