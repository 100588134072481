import { Action } from "@ngrx/store";
import { Topic } from "src/app/shared/interfaces/topic";
import { Exercise } from "src/app/shared/interfaces/exercise";
import { Guid } from "src/app/shared/types/guid";
import { Highlighter } from "src/app/shared/interfaces/highlighter";
import { Member } from "src/app/shared/interfaces/member";
import { WebsocketMessageUIBody } from "src/app/shared/interfaces/websocket-message";
import { PrepareAction } from "src/app/shared/enums/prepare-action";

export const UPDATE_TOPIC = "[Course WS Method] UpdateTopic";
export const UPDATE_COURSE_STATS = "[Course WS Method] UpdateCourseStats";

export const PREPARE_TOPIC_FOR_UPDATE =
  "[Course WS Method] PrepareTopicForUpdate";

export const HIGHLIGHT_EXERCISE = "[Course WS Method] HighlightExercise";

export const UPDATE_USER_VPN_CONNECTION_STATUS =
  "[Course WS Method] UpdateUserVPNConnectionStatus";

export const BULK_WS_UPDATE = "[Course WS Method] BulkWsUpdate";
export const REFRESH_RUNNING_EXERCISE =
  "[Course WS Method] RefreshRunningExercise";

export class UpdateTopic implements Action {
  readonly type = UPDATE_TOPIC;

  constructor(public payload: Pick<Topic, "id" | "exercises"> & { groupId: Guid; }) {}
}

export class UpdateCourseStats implements Action {
  readonly type = UPDATE_COURSE_STATS;

  constructor(
    public payload: { CourseId: Guid; GroupId: Guid; TopicId: Guid }
  ) {}
}

export class PrepareTopicForUpdate implements Action {
  readonly type = PREPARE_TOPIC_FOR_UPDATE;

  constructor(
    public payload: {
      groupId?: Guid;
      topicId: Guid;
      exercises: Array<Exercise>;
      action: PrepareAction;
    }
  ) {}
}

export class HighlightExercise implements Action {
  readonly type = HIGHLIGHT_EXERCISE;

  constructor(public payload: Highlighter) {}
}

export class UpdateUserVPNConnectionStatus implements Action {
  readonly type = UPDATE_USER_VPN_CONNECTION_STATUS;

  constructor(public payload: Member) {}
}

export class BulkWsUpdate implements Action {
  readonly type = BULK_WS_UPDATE;

  constructor(public payload: WebsocketMessageUIBody) {}
}

export class RefreshRunningExercise implements Action {
  readonly type = REFRESH_RUNNING_EXERCISE;

  constructor(public payload: WebsocketMessageUIBody) {}
}

export type CourseWSActions =
  | UpdateTopic
  | UpdateCourseStats
  | PrepareTopicForUpdate
  | HighlightExercise
  | UpdateUserVPNConnectionStatus
  | BulkWsUpdate
  | RefreshRunningExercise;
