import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";
import { InvitedTeachers } from "src/app/shared/interfaces/orgadmin/invited-teacher";

export const coursePrefix = "[Invited Teachers List]";

const invitedTeachersListActions = {
  request: `${coursePrefix} Load`,
  requestSuccess: `${coursePrefix} Load success`,
  requestFail: `${coursePrefix} Load fail`,
};

export const invitedTeachersListLoad = createAction(
  invitedTeachersListActions.request,
  props<{ id: string; active: string }>(),
);

export const invitedTeachersListLoadSuccess = createAction(
  invitedTeachersListActions.requestSuccess,
  props<{ invitedTeachers: InvitedTeachers }>(),
);

export const invitedTeachersListLoadFail = createAction(
  invitedTeachersListActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
