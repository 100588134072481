<div
  class="dropdown-menu d-flex align-items-center"
  (click)="toggleMenu()"
  [ngClass]="{ active: isActive }"
>
  <ng-content select="[dropdown-face]"></ng-content>
  <span class="dropdown-arrow typcn typcn-chevron-right" [ngClass]="{ active: isActive }"></span>
</div>

<div class="dropdown-menu-content" *ngIf="isActive">
  <nav class="dropdown-menu-nav">
    <ul class="nav-list">
      <ng-content select="[dropdown-menu]"></ng-content>
    </ul>
  </nav>
</div>
