<button
  class="round-button"
  [ngClass]="{ 'round-button-pressed': isClicked }"
  [tooltip]="
    isClicked
      ? ('COURSES.COURSE.EXERCISE.FLAGGED_EXERCISE_INFO' | translate)
      : ('COURSES.COURSE.EXERCISE.FLAG_EXERCISE' | translate)
  "
  position="bottom"
  (click)="emitAction(isClicked)"
>
  <span class="uil uil-map-marker-question"></span>
</button>
