import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import { FlaggedExerciseList } from "src/app/shared/interfaces/flagged-exercises";
import {
  flaggedExerciseListFailure,
  flaggedExerciseListLoad,
  flaggedExerciseListLoadSuccess,
} from "../../actions/flagged-exercises/get-flagged-exercises.actions";

export interface FlaggedExerciseListState {
  exercises: FlaggedExerciseList[] | null;
  error: HttpErrorResponse | null;
}

const initialState: FlaggedExerciseListState = {
  exercises: null,
  error: null,
};

export const flaggedExerciseListReducer = createReducer(
  initialState,
  on(
    flaggedExerciseListLoad,
    (state): FlaggedExerciseListState => ({
      ...state,
      exercises: null,
      error: null,
    }),
  ),
  on(
    flaggedExerciseListLoadSuccess,
    (state, action): FlaggedExerciseListState => ({
      ...state,
      exercises: action.exercises,
      error: null,
    }),
  ),
  on(flaggedExerciseListFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);
