<ng-container
  *ngIf="{
    runningExercise: runningExercise$ | async,
    getRunningExerciseCompleted: getRunningExerciseCompleted$ | async,
    extendTime: extendTime$ | async
  } as exTimer"
>
  <div
    class="exercise-timer-wrapper"
    *ngIf="
      exTimer.getRunningExerciseCompleted && exTimer.runningExercise.length
    "
  >
    <div class="row no-gutters align-items-center timer-control-wrapper">
      <div class="col timer-content-wrapper">
        <div class="row no-gutters align-items-center justify-content-between">
          <div class="col-6 exercise-title">
            <a
              *ngIf="exTimer.runningExercise[0]?.course_id"
              [routerLink]="
                '/' +
                (exTimer.runningExercise[0].group_id ? 'student' : 'teacher') +
                '/courses/' +
                exTimer.runningExercise[0].course_id +
                (exTimer.runningExercise[0].group_id
                  ? '/groups/' + exTimer.runningExercise[0].group_id
                  : '')
              "
              [queryParams]="{ topicId: exTimer.runningExercise[0].topic_id }"
              (click)="setHighlighter(exTimer.runningExercise[0].id)"
            >
              <span class="title-prefix">{{
                "GLOBAL.TIMER_WIDGET.RUNNING" | translate
              }}</span>
              {{ exTimer.runningExercise[0].name }}
            </a>
          </div>

          <div class="col time-left">
            <ng-container
              *ngIf="!stopping &&
              timeLeftCountdown &&
              exTimer.runningExercise[0]?.requires_laboratory &&
              exTimer.runningExercise[0]?.status !== 'starting'"
            >
              <i class="typcn typcn-stopwatch timer-icon"></i> {{ timeLeftCountdown }}
            </ng-container>

            <ng-container *ngIf="stopping">
              <div class="d-flex justify-content-center align-items-center">
                <countdown color="hannotationgray" size="12px" />
                {{ "GLOBAL.TIMER_WIDGET.STOPPING" | translate }}
              </div>
            </ng-container>

            <ng-container
              *ngIf="exTimer.runningExercise[0]?.status === 'starting'"
            >
              <div class="d-flex justify-content-center align-items-center">
                <countdown color="hannotationgray" size="12px" />
                {{ "GLOBAL.TIMER_WIDGET.STARTING" | translate }}
              </div>
            </ng-container>
          </div>

          <div class="col-auto">
            <div class="row no-gutters align-items-center">
              <div class="col-auto control-buttons">
                <ng-container
                  *ngIf="
                    exTimer.runningExercise[0]?.extendable &&
                    millisecondsLeft !== null &&
                    millisecondsLeft <= exTimer.extendTime &&
                    !stopPressed &&
                    exTimer.runningExercise[0]?.status !== 'starting'
                  "
                >
                  <button
                    class="theme-hsecondary"
                    [disabled]="!exTimer.getRunningExerciseCompleted"
                    (click)="extend(exTimer.runningExercise[0].id)"
                  >
                    <countdown
                      size="12px"
                      *ngIf="!exTimer.getRunningExerciseCompleted"
                    />

                    {{ "GLOBAL.TIMER_WIDGET.EXTEND_BUTTON" | translate }}
                  </button>
                </ng-container>

                <button
                  class="btn-error-600"
                  [disabled]="!exTimer.getRunningExerciseCompleted"
                  (click)="stop(exTimer.runningExercise)"
                >
                  <countdown
                    size="12px"
                    *ngIf="!exTimer.getRunningExerciseCompleted"
                  />

                  {{ "GLOBAL.TIMER_WIDGET.STOP_BUTTON" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container
      *ngIf="
        exTimer.runningExercise[0]?.extendable &&
        millisecondsLeft > 0 &&
        millisecondsLeft <= exTimer.extendTime
      "
    >
      <div class="progress-wrapper">
        <div class="progress-base d-flex justify-content-end">
          <div
            class="progress-remain"
            [ngStyle]="{
              width: (millisecondsLeft / totalExerciseMilliseconds) * 100 + '%'
            }"
          ></div>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="!exTimer.getRunningExerciseCompleted">
    <div
      class="exercise-timer-wrapper wrapper-loading wrapper-toggled d-flex align-items-center justify-content-center"
    >
      <loading-spinner />
    </div>
  </ng-container>
</ng-container>
