import { HttpErrorResponse } from "@angular/common/http";
import { createAction, props } from "@ngrx/store";

export const coursePrefix = "[Delete Invitation Student]";

const deleteInvitationStudentActions = {
  request: `${coursePrefix} Load`,
  requestSuccess: `${coursePrefix} Load success`,
  requestFail: `${coursePrefix} Load fail`,
};

export const deleteInvitationStudentLoad = createAction(
  deleteInvitationStudentActions.request,
  props<{ organizationId: string; courseId: string; invitationId: string }>(),
);

export const deleteInvitationStudentLoadSuccess = createAction(
  deleteInvitationStudentActions.requestSuccess,
);

export const deleteInvitationStudentLoadFail = createAction(
  deleteInvitationStudentActions.requestFail,
  props<{ error: HttpErrorResponse }>(),
);
