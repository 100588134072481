import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { EMPTY, of } from "rxjs";
import { catchError, switchMap, withLatestFrom } from "rxjs/operators";
import { Ranking, RankingProcessing } from "src/app/shared/interfaces/ranking";
import { RankingService } from "src/app/shared/services/ranking/ranking.service";
import * as fromRanking from "../actions/ranking.actions";
import { WarningModalService } from "src/app/shared/services/warning-modal/warning-modal.service";
import * as errorConfig from "src/app/config/errors";
import { EmptyRanking } from "src/app/shared/models/ranking";
import * as fromRankingStore from "src/app/store/reducers/ranking.reducer";
import { Store } from "@ngrx/store";
import { RankingCriteria } from "src/app/shared/models/ranking-criteria";
import { Router } from "@angular/router";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { NotificationsService } from "src/app/shared/services/notifications/notifications.service";

@Injectable()
export class RankingEffect {
  
  getRanking$ = createEffect(() => this.actions$.pipe(
    ofType(fromRanking.GET_RANKING),
    switchMap((action: fromRanking.GetRanking) =>
      this.rankingService.getRanking(action.payload).pipe(
        switchMap((response: HttpResponse<Ranking | RankingProcessing>) => {
          switch (response.status) {
            case 200:
              return [
                {
                  type: fromRanking.GET_RANKING_COMPLETED,
                  payload: response.body,
                },
              ];
            case 202:
              return [
                {
                  type: fromRanking.GET_RANKING_DEFFERED,
                  payload: response.body,
                },
              ];
            case 204:
              return [
                {
                  type: fromRanking.GET_RANKING_COMPLETED,
                  payload: new EmptyRanking(),
                },
              ];
          }
        }),
        catchError((errorResponse: HttpErrorResponse) => {
          switch (errorResponse.status) {
            case 400:
              this.warningModalService.showModal({
                modalTitle: `GLOBAL.MESSAGES.ERROR_TITLES.ERROR_400`,
                text: "GLOBAL.MESSAGES.ERROR_MESSAGES.ERROR_5XX",
                icon: errorConfig.ERROR_ICONS[errorResponse.status],
              });
              break;

            case 409:
              let text = "GLOBAL.MESSAGES.ERROR_MESSAGES.ERROR_5XX";
              if (errorResponse.error.hasOwnProperty("in_ranking")) {
                text = errorResponse.error.in_ranking as string;
                this.notificationsService.showToastr({
                  id: "in-ranking-toastr",
                  content: {
                    message: text,
                  },
                  type: "info",
                  created_at: "",
                  read_at: null,
                  just_remove: true,
                });

                const criteria: RankingCriteria = new RankingCriteria();
                criteria.page = 1;
                criteria.ranking_type = action.payload.ranking_type;

                return of({}).pipe(
                  switchMap(() => [
                    {
                      type: fromRanking.UPDATE_RANKING_CRITERIA,
                      payload: criteria,
                    },
                    {
                      type: fromRanking.GET_RANKING,
                      payload: criteria,
                    },
                  ])
                );
              } else {
                this.warningModalService.showModal({
                  modalTitle: `GLOBAL.MESSAGES.ERROR_TITLES.ERROR_409`,
                  text,
                  icon: errorConfig.ERROR_ICONS[errorResponse.status],
                });
              }
              break;
          }

          return of({
            type: fromRanking.GET_RANKING_FAILED,
          });
        })
      )
    )
  ));

  
  rankingRequestFinished$ = createEffect(() => this.actions$.pipe(
    ofType(fromRanking.RANKING_REQUEST_FINISHED),
    withLatestFrom(
      this.store.select((state) => state.ranking.rankingCriteria),
      this.store.select((state) => state.ranking.hash)
    ),
    switchMap(
      ([action, criteria, hash]: [
        fromRanking.RankingRequestFinished,
        RankingCriteria,
        string
      ]) => {
        if (
          this.router.url.includes("/ranking") &&
          hash === action.payload.hash
        ) {
          this.store.dispatch(new fromRanking.GetRanking(criteria));
        }

        return of(EMPTY);
      }
    )
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private rankingService: RankingService,
    private warningModalService: WarningModalService,
    private store: Store<{
      ranking: fromRankingStore.RankingState;
    }>,
    private router: Router,
    private notificationsService: NotificationsService
  ) {}
}
