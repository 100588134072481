import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { EMPTY, of } from "rxjs";
import { mergeMap, withLatestFrom } from "rxjs/operators";
import { ExerciseMark } from "src/app/shared/enums/exercise-mark";
import { Exercise } from "src/app/shared/interfaces/exercise";
import * as fromCourseWS from "../actions/course.websocket.actions";
import * as fromCourse from "../actions/course.actions";
import * as fromUI from "../actions/ui.actions";
import * as fromCourseStore from "../reducers/course.reducer";
import * as fromUIStore from "../reducers/ui.reducer";
import { CourseGroup } from "src/app/shared/interfaces/course-group";
import { CourseSelectorsState, selectCourseState } from "../selectors/course.selectors";
import { Topic } from "src/app/shared/interfaces/topic";
import { PrepareAction } from "src/app/shared/enums/prepare-action";
import { CourseService } from "src/app/shared/services/course/course.service";
import { ImmutableExerciseMap } from "src/app/shared/types/immutable-exercise";

@Injectable()
export class CourseWebsocketEffect {
  constructor(
    private actions$: Actions,
    private store: Store<{ ui: fromUIStore.UIState } | CourseSelectorsState>,
    private courseService: CourseService,
  ) {}

  prepareTopicForUpdate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromCourseWS.PREPARE_TOPIC_FOR_UPDATE),
        withLatestFrom(this.store.select(selectCourseState)),
        mergeMap(
          ([action, singleCourse]: [
            fromCourseWS.PrepareTopicForUpdate,
            fromCourseStore.CourseState,
          ]) => {
            if (singleCourse && singleCourse.course) {
              const topic: Topic = singleCourse.course.topics.find(
                (topic) => topic.id === action.payload.topicId,
              );

              if (topic) {
                const exerciseMap: ImmutableExerciseMap = this.courseService
                  .createImmutableExerciseMap(topic.exercises)
                  .map((value) =>
                    value.merge({ mark: ExerciseMark.None }),
                  ) as ImmutableExerciseMap;
                let newExerciseMap: ImmutableExerciseMap =
                  this.courseService.createImmutableExerciseMap(
                    action.payload.exercises,
                  );

                let modifiedNewExerciseMap: ImmutableExerciseMap;
                let mergedExercises: Array<Exercise> = [];
                let mergeFn: Function;

                switch (action.payload.action) {
                  case PrepareAction.EXERCISE_REVIEWED: {
                    modifiedNewExerciseMap =
                      this.courseService.modifyImmutableExerciseMap(
                        newExerciseMap,
                        {
                          mark: ExerciseMark.Highlighted,
                        },
                      );

                    mergeFn = exerciseMap.mergeDeep;

                    break;
                  }

                  case PrepareAction.ADD:
                    modifiedNewExerciseMap =
                      this.courseService.modifyImmutableExerciseMap(
                        newExerciseMap,
                        { mark: ExerciseMark.Added },
                      );

                    mergeFn = exerciseMap.mergeDeep;
                    break;

                  case PrepareAction.DELETE:
                    const removedExerciseMap =
                      this.courseService.modifyImmutableExerciseMap(
                        newExerciseMap,
                        {
                          status: "not-started",
                          mark: ExerciseMark.Deleting,
                        },
                      );

                    // Keep some param states
                    modifiedNewExerciseMap =
                      removedExerciseMap.map<ImmutableExerciseMap>(
                        (value, key) => {
                          const {
                            answer_type,
                            can_start,
                            extendable,
                            section,
                            passed,
                            was_passed_before,
                          } = exerciseMap.get(key).toJS() as Partial<Exercise>;
                          return value.merge({
                            answer_type,
                            passed,
                            was_passed_before,
                            can_start,
                            extendable,
                            section,
                          });
                        },
                      ) as ImmutableExerciseMap;

                    mergeFn = exerciseMap.merge;
                    break;

                  case PrepareAction.HELP_ON:
                  case PrepareAction.HELP_OFF:
                  case PrepareAction.QUIZ_SOLUTIONS_ON:
                  case PrepareAction.QUIZ_SOLUTIONS_OFF:
                    if (action.payload.action === PrepareAction.HELP_OFF) {
                      newExerciseMap = newExerciseMap.map((imExercise) => {
                        if (imExercise.get("has_help") === false) {
                          imExercise = imExercise.set("help", undefined);
                        }
                        if (imExercise.get("has_video") === false) {
                          imExercise = imExercise.set("video_help", undefined);
                        }
                        return imExercise;
                      });
                    }

                    modifiedNewExerciseMap =
                      this.courseService.modifyImmutableExerciseMap(
                        newExerciseMap,
                        {
                          mark: ExerciseMark.Highlighted,
                        },
                      );

                    mergeFn = exerciseMap.mergeDeep;
                    break;
                }

                const mergedExerciseMap = mergeFn.call(
                  exerciseMap,
                  modifiedNewExerciseMap,
                );
                mergedExercises = <Array<Exercise>>(
                  mergedExerciseMap.toList().toJS()
                );

                this.store.dispatch(
                  new fromCourseWS.UpdateTopic({
                    groupId: action.payload.groupId,
                    id: action.payload.topicId,
                    exercises: mergedExercises,
                  }),
                );
              }
            }

            return of(EMPTY);
          },
        ),
      ),
    { dispatch: false },
  );

  updateCourseStats$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCourseWS.UPDATE_COURSE_STATS),
      withLatestFrom(
        this.store.select(
          (state) =>
            state as {
              teacher?: { singleCourse: fromCourseStore.CourseState };
            },
        ),
      ),
      mergeMap(
        ([action, state]: [
          fromCourseWS.UpdateCourseStats,
          {
            teacher?: { singleCourse: fromCourseStore.CourseState };
          },
        ]) => {
          let singleCourse: fromCourseStore.CourseState =
            state.teacher.singleCourse;
          let actions: Array<fromCourse.CourseActions> = [];

          if (
            singleCourse.course &&
            singleCourse.activeGroup &&
            (singleCourse.course as CourseGroup).course.id ===
              action.payload.CourseId &&
            singleCourse.activeGroup === action.payload.GroupId
          ) {
            if (
              singleCourse.currentTopic &&
              singleCourse.currentTopic.id === action.payload.TopicId
            ) {
              actions.push({
                type: fromCourse.GET_TOPIC_STATISTICS,
                payload: {
                  CourseId: action.payload.CourseId,
                  GroupId: action.payload.GroupId,
                  TopicId: action.payload.TopicId,
                },
              });
            }

            actions.push({
              type: fromCourse.GET_COURSE_STATISTICS,
              payload: {
                CourseId: action.payload.CourseId,
                GroupId: action.payload.GroupId,
              },
            });
          }

          return actions;
        },
      ),
    ),
  );

  refreshRunningExercise$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromCourseWS.REFRESH_RUNNING_EXERCISE),
        withLatestFrom(
          this.store.select((state) => {
            return state as { ui: fromUIStore.UIState };
          }),
        ),
        mergeMap(
          ([action, state]: [
            fromCourseWS.RefreshRunningExercise,
            { ui: fromUIStore.UIState },
          ]) => {
            if (
              state.ui.runningExercise &&
              state.ui.runningExercise.length &&
              (<Array<Exercise>>action.payload.exercise)
                .map((exercise) => exercise.id)
                .includes(state.ui.runningExercise[0].id)
            ) {
              this.store.dispatch(new fromUI.GetRunningExercise());
            }
            return of(EMPTY);
          },
        ),
      ),
    { dispatch: false },
  );
}
