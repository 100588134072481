import { Component, Input } from "@angular/core";
import * as fromTutorial from "src/app/store/reducers/tutorial.reducer";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { TutorialTeacherSteps } from "../../enums/tutorial-steps";
@Component({
  selector: "popup-trigger-label-with-chevron",
  templateUrl: "./popup-trigger-label-with-chevron.component.html",
  styleUrls: ["./popup-trigger-label-with-chevron.component.scss"],
})
export class PopupTriggerLabelWithChevronComponent {
  @Input() expanded = false;
  @Input() label = false;

  tutorial$: Observable<TutorialTeacherSteps> = this.store.select(
    (state) => state.tutorial.tutorial_step,
  );

  isTeacher$: Observable<boolean> = this.store.select(
    (state) => state.account.member.is_teacher,
  );

  TutorialTeacherSteps = TutorialTeacherSteps;

  constructor(
    private store: Store<{
      tutorial: fromTutorial.TutorialState;
      account: fromAccountStore.AccountState;
    }>,
  ) {}
}
