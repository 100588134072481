<modal-overlay>
  <modal-box>
    <div
      *ngIf="tutorialId$ | async as id"
      class="warning-modal-body row no-gutters"
    >
      <div class="tutorial-modal">
        <div class="tutorial-container">
          <h3 class="tutorial-modal-title">
            {{ "TUTORIAL_MODAL.TITLE" | translate }}
          </h3>
        </div>
        <div class="tutorial-container">
          <h4>{{ "TUTORIAL_MODAL.INFO" | translate }}</h4>
        </div>
        <div class="tutorial-container">
          <button
            type="button"
            class="btn-primary-900"
            (click)="handleTutorialStart(id)"
          >
            {{ "TUTORIAL_MODAL.GO" | translate }}
          </button>
        </div>
        <div>
          <button class="btn-white" (click)="handleTutorialSkip(id)">
            {{ "TUTORIAL_MODAL.SKIP" | translate }}
          </button>
        </div>
      </div>
    </div>
  </modal-box>
</modal-overlay>
