<div class="row no-gutters align-items-center">
  <div class="col">
    <p class="heading-6">
      {{ group.name }}
    </p>

    <p class="paragraph-small color-hblack">
      {{ group.course }}
    </p>

    <p
      *ngIf="group.weekday !== null"
      class="paragraph-small color-hannotationgray"
    >
      {{ group.weekday | day | translate }} <br />
      {{ group.start_time | formatHour }} - {{ group.end_time | formatHour }}
    </p>
  </div>

  <div class="col-auto">
    <round-button
      *ngIf="!inProgress"
      icon="chevron-right"
      tooltip="GROUPS.EDIT.MOVE_STUDENT.MOVE_BUTTON"
      confirmation="GROUPS.EDIT.MOVE_STUDENT.MOVE_CONFIRMATION"
      confirmation-icon="warning"
      [pure]="true"
      (onConfirm)="moveStudent(group.id)"
    />
  </div>
</div>
