import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { Ranking } from "../../interfaces/ranking";
import { Store } from "@ngrx/store";
import * as fromAccountStore from "src/app/store/reducers/account.reducer";
import * as fromRankingStore from "src/app/store/reducers/ranking.reducer";
import * as fromRanking from "src/app/store/actions/ranking.actions";
import * as rankingSelectors from "src/app/store/selectors/ranking.selectors";
import {
  PickerOption,
  PickerOptionLabel,
} from "../../interfaces/picker-option";
import { ActivatedRoute, Router } from "@angular/router";
import { RankingCriteria } from "../../models/ranking-criteria";
import { withLatestFrom } from "rxjs/operators";
import { RankingQuery } from "../../enums/ranking-query";
import { Guid } from "../../types/guid";

@Component({
  selector: "global-ranking-page",
  templateUrl: "./global-ranking-page.component.html",
  styleUrls: ["./global-ranking-page.component.scss"],
})
export class GlobalRankingPageComponent implements OnInit, OnDestroy {
  getRankingCompleted$: Observable<boolean> = this.store.select(
    (state) => state.ranking.getRankingCompleted
  );
  ranking$: Observable<Ranking> = this.store.select(
    (state) => state.ranking.ranking
  );
  isTeacher$: Observable<boolean> = this.store.select(
    (state) => state.account.member.is_teacher
  );
  inRanking$: Observable<boolean> = this.store.select(
    (state) => state.account.member.in_ranking
  );
  criteria$: Observable<RankingCriteria> = this.store.select(
    (state) => state.ranking.rankingCriteria
  );
  rankRegions$: Observable<Array<PickerOption>> = this.store.select(
    rankingSelectors.selectCities
  );
  rankOrganizations$: rankingSelectors.Organizations$ = this.store.select(
    rankingSelectors.selectOrganizations
  );
  rankCoursegroups$: Observable<rankingSelectors.CoursegroupsData> =
    this.store.select(rankingSelectors.selectCoursegroups);
  hasFilters$: Observable<boolean> = this.store.select(
    rankingSelectors.hasFilters
  );

  paramSubscription: Subscription;
  span: typeof RankingQuery.Span = RankingQuery.Span;
  filterBy: typeof RankingQuery.FilterBy = RankingQuery.FilterBy;
  groupBy: typeof RankingQuery.GroupBy = RankingQuery.GroupBy;
  rankType: typeof RankingQuery.RankingType = RankingQuery.RankingType;
  regionType: typeof RankingQuery.RegionType = RankingQuery.RegionType;

  rankTypes: Array<PickerOption<RankingQuery.RankingType>> = [
    {
      label: "RANKING.TYPE.POINTS",
      value: RankingQuery.RankingType.points,
      icon: "coins",
    },
    {
      label: "RANKING.TYPE.EXERCISE",
      value: RankingQuery.RankingType.count,
      icon: "clipboard-notes",
    },
    {
      label: "RANKING.TYPE.QUIZ",
      value: RankingQuery.RankingType.quiz,
      icon: "question-circle",
    },
    {
      label: "RANKING.TYPE.DAILY",
      value: RankingQuery.RankingType.days,
      icon: "analysis",
    },
    {
      label: "RANKING.TYPE.SPRINT",
      value: RankingQuery.RankingType.sprint,
      icon: "game-structure",
    },
  ];

  rankDescriptions: Record<RankingQuery.RankingType, string> = {
    [RankingQuery.RankingType.points]: "RANKING.TYPE.DESC.POINTS",
    [RankingQuery.RankingType.count]: "RANKING.TYPE.DESC.EXERCISE",
    [RankingQuery.RankingType.quiz]: "RANKING.TYPE.DESC.QUIZ",
    [RankingQuery.RankingType.days]: "RANKING.TYPE.DESC.DAILY",
    [RankingQuery.RankingType.sprint]: "RANKING.TYPE.DESC.SPRINT",
  };

  rankSpans: Array<PickerOption<RankingQuery.Span>> = [
    { label: "RANKING.SPAN.ALL", value: RankingQuery.Span.all },
    { label: "RANKING.SPAN.DAY", value: RankingQuery.Span.day },
    { label: "RANKING.SPAN.WEEK", value: RankingQuery.Span.week },
    { label: "RANKING.SPAN.MONTH", value: RankingQuery.Span.month },
  ];

  rankFilters: Array<PickerOption<RankingQuery.FilterBy>> = [
    {
      label: "RANKING.FILTER.GLOBAL",
      value: null,
      icon: "globe",
    },
    {
      label: "RANKING.FILTER.REGION",
      value: RankingQuery.FilterBy.region,
      icon: "building",
    },
    {
      label: "RANKING.FILTER.ORGANIZATION",
      value: RankingQuery.FilterBy.organization,
      icon: "university",
    },
  ];

  rankRanges: Array<PickerOption<RankingQuery.GroupBy>> = [
    {
      label: "RANKING.GROUP_BY.USER",
      value: RankingQuery.GroupBy.user,
      icon: "users-alt",
    },
    {
      label: "RANKING.GROUP_BY.REGION",
      value: RankingQuery.GroupBy.region,
      icon: "building",
    },
    {
      label: "RANKING.GROUP_BY.ORGANIZATION",
      value: RankingQuery.GroupBy.organization,
      icon: "university",
    },
  ];

  rankRanges2: Array<PickerOption<RankingQuery.GroupBy>> = [
    {
      label: "RANKING.GROUP_BY.USER",
      value: RankingQuery.GroupBy.user,
      icon: "users-alt",
    },
    {
      label: "RANKING.GROUP_BY.ORGANIZATION",
      value: RankingQuery.GroupBy.organization,
      icon: "university",
    },
  ];

  unitLabels: { [rankingType: string]: string } = {
    points: "RANKING.TABLE.POINTS",
    count: "RANKING.TABLE.EXERCISES",
    quiz: "RANKING.TABLE.POINTS",
    days: "RANKING.TABLE.DAYS",
    sprint: "RANKING.TABLE.POINTS",
  };

  expanded: boolean = false;

  constructor(
    private store: Store<{
      ranking: fromRankingStore.RankingState;
      account: fromAccountStore.AccountState;
    }>,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.initParamSubscription();
  }

  ngOnDestroy() {
    this.paramSubscription.unsubscribe();
  }

  setSpan(event: PickerOption<RankingQuery.Span>) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: <RankingCriteria>{ span: event.value, page: null },
      queryParamsHandling: "merge",
    });
  }

  setFilter(
    event: PickerOption<RankingQuery.FilterBy>,
    rank: {
      regions: Array<PickerOption>;
      organizations: Array<PickerOption>;
    }
  ) {
    let filter_pk: Guid = null;
    let region_scope: RankingQuery.RegionScope = null;

    switch (event.value) {
      case this.filterBy.region:
        if (rank.regions.length) {
          filter_pk = rank.regions[0].value;
          region_scope = RankingQuery.RegionScope.one;
        }
        break;

      case this.filterBy.organization:
        filter_pk = rank.organizations[0].value;
        break;
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: <RankingCriteria>{
        filter_by: event.value,
        group_by: null,
        page: null,
        region_scope,
        filter_pk,
        region_type: null,
      },
      queryParamsHandling: "merge",
    });
  }

  setRegion(event: PickerOption<Guid>) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: <RankingCriteria>{
        group_by: null,
        page: null,
        filter_pk: event.value,
      },
      queryParamsHandling: "merge",
    });
  }

  setOrganization(event: PickerOption<Guid>) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: <RankingCriteria>{
        filter_by: this.filterBy.organization, // Needed because it has to reset coursegroup filter_by just in case
        group_by: null,
        page: null,
        filter_pk: event.value,
      },
      queryParamsHandling: "merge",
    });
  }

  setCoursegroup(event: PickerOption<Guid>, organizationId: Guid) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: <RankingCriteria>{
        filter_by: event.value
          ? this.filterBy.coursegroup
          : this.filterBy.organization, // Just in case change it from organization
        group_by: null,
        page: null,
        filter_pk: event.value || organizationId,
      },
      queryParamsHandling: "merge",
    });
  }

  setGroupBy(event: PickerOption<RankingQuery.GroupBy>) {
    let region_type: RankingQuery.RegionType = null;

    if (event.value === this.groupBy.region) {
      region_type = RankingQuery.RegionType.city;
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: <RankingCriteria>{
        group_by: event.value,
        page: null,
        region_type,
      },
      queryParamsHandling: "merge",
    });
  }

  setRankType(picked: PickerOption<RankingQuery.RankingType>) {
    let criteria: Partial<RankingCriteria> = {};

    // Set specific criteria for some rank types
    switch (picked.value) {
      case this.rankType.days:
        criteria.span = null;
        criteria.group_by = null;
        criteria.region_type = null;
        break;
      case this.rankType.sprint:
        criteria.group_by = null;
        criteria.region_type = null;
        break;
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: <RankingCriteria>{
        ranking_type: picked.value,
        page: null,
        ...criteria,
      },
      queryParamsHandling: "merge",
    });
  }

  getCourseUrl(rank: {
    groups: rankingSelectors.CoursegroupsData;
    criteria: RankingCriteria;
    isTeacher: boolean;
  }) {
    return (
      "/" +
      (rank.isTeacher ? "teacher" : "student") +
      "/courses/" +
      rank.groups.courseId +
      "/groups/" +
      rank.criteria.filter_pk
    );
  }

  shouldShowGrouping(rank: {
    organizations: Array<PickerOption>;
    criteria: RankingCriteria;
  }): boolean {
    return (
      rank.organizations.length &&
      rank.criteria.filter_by !== this.filterBy.organization &&
      rank.criteria.filter_by !== this.filterBy.coursegroup &&
      ![this.rankType.sprint, this.rankType.days].includes(
        rank.criteria.ranking_type
      )
    );
  }

  getHighlightItems(rank: {
    organizations: Array<PickerOption>;
    regions: Array<PickerOption>;
    criteria: RankingCriteria;
  }): Array<Guid> | null {
    if (rank.criteria.group_by === this.groupBy.region) {
      return rank.regions.map((region) => region.value);
    } else if (rank.criteria.group_by === this.groupBy.organization) {
      return rank.organizations.map((organization) => organization.value);
    } else {
      return null;
    }
  }

  groupName(
    options: Array<PickerOption<Guid, PickerOptionLabel>>,
    filter_pk: Guid
  ): string {
    const option = options.find((option) => option.value === filter_pk);

    return option ? option.label.text : "";
  }

  toggleFiltersMobile() {
    this.expanded = !this.expanded;
  }

  private initParamSubscription() {
    this.paramSubscription = this.route.queryParams
      .pipe(withLatestFrom(this.isTeacher$, this.inRanking$))
      .subscribe(([params, isTeacher, inRanking]) => {
        const criteria = new RankingCriteria();

        Object.keys(criteria).forEach((param) => {
          if (params[param]) {
            criteria[param] = params[param];
          }
        });

        if (
          (isTeacher || !inRanking) &&
          (!criteria.page || Number(criteria.page) === 0)
        ) {
          criteria.page = 1;
        }

        this.store.dispatch(new fromRanking.UpdateRankingCriteria(criteria));

        if (
          [
            this.filterBy.region,
            this.filterBy.coursegroup,
            this.filterBy.organization,
          ].includes(criteria.filter_by) &&
          !criteria.filter_pk
        ) {
          this.store.dispatch(new fromRanking.GetRankingFailed());
        } else {
          this.store.dispatch(new fromRanking.GetRanking(criteria));
        }
      });
  }
}
