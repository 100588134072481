import { createReducer, on } from "@ngrx/store";
import {
  PagionationData,
  SubscribtionOrganization,
} from "src/app/shared/interfaces/orgadmin/course-organization";

import { HttpErrorResponse } from "@angular/common/http";
import {
  coursesAdminActiveListLoad,
  coursesAdminActiveListLoadSuccess,
  loadActiveTeachersFailure,
} from "../../actions/admin/course-admin.actions";

export interface AdminCourseListState {
  activeCourses: PagionationData<SubscribtionOrganization> | null;
  error: HttpErrorResponse | null;
}

const initialState: AdminCourseListState = {
  activeCourses: {
    next: 0,
    page_count: 0,
    previous: 0,
    result_count: 0,
    results: [],
  },
  error: null,
};

export const adminCourseListReducer = createReducer(
  initialState,
  on(
    coursesAdminActiveListLoad,
    (state): AdminCourseListState => ({
      ...state,
      activeCourses: null,
      error: null,
    }),
  ),
  on(
    coursesAdminActiveListLoadSuccess,
    (state, action): AdminCourseListState => ({
      ...state,
      activeCourses: action.coursesActiveList,
      error: null,
    }),
  ),
  on(loadActiveTeachersFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);
