<div class="row align-items-center">
  <div class="col">
    <div class="row align-items-center">
      <div class="col-lg-12 col-xl">
        <div class="row align-items-center">
          <div class="col">
            <span class="heading-6 group-container">
              {{ invitation.group.name }}
            </span>
          </div>

          <div class="col">
            <span>{{ invitation.course_name }}</span>
          </div>

          <div class="col">
            <group-teacher-name [name]="invitation.teacher_name" />
          </div>

          <div
            class="col font-weight-bold text-uppercase text-md-right text-lg-left"
          >
            <ng-container *ngIf="invitation.weekday !== null">
              {{ invitation.weekday | day | translate }}
              <br />
              {{ invitation.start_time | formatHour }} -
              {{ invitation.end_time | formatHour }}
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-5 col-md-3 col-lg-2">
    <div class="row align-items-center justify-content-end">
      <div class="col-auto">
        <button
          class="btn-success-500 mr-4 btn-rounded"
          type="button"
          (click)="respondToInvitation(true)"
        >
          <icon class="icon-container" name="user-add-outline" />
        </button>

        <button
          class="btn-error-500 btn-rounded"
          type="button"
          (click)="respondToInvitation(false)"
        >
          <icon class="icon-container" name="cancel-outline" />
        </button>
      </div>
    </div>
  </div>
</div>
