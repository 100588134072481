import { Action } from "@ngrx/store";
import { Guid } from "src/app/shared/types/guid";
import { Group, MovableGroup } from "src/app/shared/interfaces/group";
import { GroupRequest } from "src/app/shared/interfaces/group-request";
import { InvitationsRequest } from "src/app/shared/interfaces/invitations-request";
import { ErrorListing } from "src/app/shared/types/error-listing";
import { Invitation } from "src/app/shared/interfaces/invitation";
import { Student } from "src/app/shared/interfaces/student";

export const GET_GROUP = "[Group Method] GetGroup";
export const GET_GROUP_COMPLETED = "[Group Method] GetGroupCompleted";

export const EDIT_GROUP = "[Group Method] EditGroup";

export const GET_INVITATIONS = "[Group Method] GetInvitations";
export const GET_INVITATIONS_COMPLETED =
  "[Group Method] GetInvitationsCompleted";
export const GET_INVITATIONS_FAILED = "[Group Method] GetInvitationsFailed";
export const SEND_INVITATIONS = "[Group Method] SendInvitations";
export const SEND_INVITATIONS_COMPLETED =
  "[Group Method] SendInvitationsCompleted";
export const SEND_INVITATIONS_FAILED = "[Group Method] SendInvitationsFailed";
export const DELETE_INVITATION = "[Group Method] DeleteInvitation";
export const DELETE_INVITATION_COMPLETED =
  "[Group Method] DeleteInvitationCompleted";

export const DELETE_STUDENT_FROM_GROUP =
  "[Group Method] DeleteStudentFromGroup";

export const GROUP_FAILED = "[Group Method] GroupFailed";

export const SUSPEND_UNSUSPEND_STUDENT =
  "[GroupMethod] SuspendUnsuspendStudent";

export const SEARCH_USERS = "[Group Method] SearchUsers";
export const SEARCH_USERS_COMPLETED = "[Group Method] SearchUsersCompleted";

export const GET_MATCHING_GROUPS = "[Group Method] GetMatchingGroups";
export const GET_MATCHING_GROUPS_COMPLETED =
  "[Group Method] GetMatchingGroupsCompleted";
export const MOVE_STUDENT = "[Group Method] MoveStudent";
export const MOVE_STUDENT_COMPLETED = "[Group Method] MoveStudentCompleted";
export const MOVE_STUDENT_FAILED = "[Group Method] MoveStudentFailed";

export class GetGroup implements Action {
  readonly type = GET_GROUP;

  constructor(public payload: { GroupId: Guid }) {}
}

export class GetGroupCompleted implements Action {
  readonly type = GET_GROUP_COMPLETED;

  constructor(public payload: Group) {}
}

export class EditGroup implements Action {
  readonly type = EDIT_GROUP;

  constructor(public payload: { GroupId: Guid; request: GroupRequest }) {}
}

export class GetInvitations implements Action {
  readonly type = GET_INVITATIONS;

  constructor(
    public payload: {
      GroupId: Guid;
      CourseId: Guid;
    }
  ) {}
}

export class GetInvitationsCompleted implements Action {
  readonly type = GET_INVITATIONS_COMPLETED;

  constructor(public payload: Array<Invitation>) {}
}

export class GetInvitationsFailed implements Action {
  readonly type = GET_INVITATIONS_FAILED;
}

export class SendInvitations implements Action {
  readonly type = SEND_INVITATIONS;

  constructor(
    public payload: {
      GroupId: Guid;
      CourseId: Guid;
      OrganizationId: Guid;
      body: InvitationsRequest;
    }
  ) {}
}

export class SendInvitationsCompleted implements Action {
  readonly type = SEND_INVITATIONS_COMPLETED;

  constructor(public payload: Array<Invitation>) {}
}

export class SendInvitationsFailed implements Action {
  readonly type = SEND_INVITATIONS_FAILED;

  constructor(public payload: ErrorListing) {}
}

export class DeleteInvitation implements Action {
  readonly type = DELETE_INVITATION;

  constructor(
    public payload: {
      GroupId: Guid;
      CourseId: Guid;
      OrganizationId: Guid;
      InvitationId: Guid;
    }
  ) {}
}

export class DeleteInvitationCompleted implements Action {
  readonly type = DELETE_INVITATION_COMPLETED;

  constructor(public payload: { InvitationId: Guid }) {}
}

export class DeleteStudentFromGroup implements Action {
  readonly type = DELETE_STUDENT_FROM_GROUP;

  constructor(
    public payload: { GroupId: Guid; CourseId: Guid; StudentId: Guid }
  ) {}
}

export class GroupFailed implements Action {
  readonly type = GROUP_FAILED;

  constructor(public payload: ErrorListing) {}
}

export class SuspendUnsuspendStudent implements Action {
  readonly type = SUSPEND_UNSUSPEND_STUDENT;

  constructor(
    public payload: {
      data: { GroupId: Guid; CourseId: Guid; StudentId: Guid };
      body: { blocked: boolean };
    }
  ) {}
}

export class SearchUsers implements Action {
  readonly type = SEARCH_USERS;

  constructor(public payload: string) {}
}

export class SearchUsersCompleted implements Action {
  readonly type = SEARCH_USERS_COMPLETED;

  constructor(public payload: Array<Student>) {}
}

export class GetMatchingGroups implements Action {
  readonly type = GET_MATCHING_GROUPS;

  constructor(
    public payload: { GroupId: Guid; CourseId: Guid; StudentId: Guid }
  ) {}
}

export class GetMatchingGroupsCompleted implements Action {
  readonly type = GET_MATCHING_GROUPS_COMPLETED;

  constructor(public payload: Array<MovableGroup>) {}
}

export class MoveStudent implements Action {
  readonly type = MOVE_STUDENT;
  constructor(
    public payload: {
      data: { GroupId: Guid; CourseId: Guid; StudentId: Guid };
      body: { coursegroup: Guid };
    }
  ) {}
}

export class MoveStudentCompleted implements Action {
  readonly type = MOVE_STUDENT_COMPLETED;
}

export class MoveStudentFailed implements Action {
  readonly type = MOVE_STUDENT_FAILED;

  constructor(public payload: ErrorListing) {}
}

export type GroupActions =
  | GetGroup
  | GetGroupCompleted
  | EditGroup
  | GetInvitations
  | GetInvitationsCompleted
  | GetInvitationsFailed
  | SendInvitations
  | SendInvitationsCompleted
  | SendInvitationsFailed
  | DeleteInvitation
  | DeleteInvitationCompleted
  | DeleteStudentFromGroup
  | GroupFailed
  | SuspendUnsuspendStudent
  | SearchUsers
  | SearchUsersCompleted
  | GetMatchingGroups
  | GetMatchingGroupsCompleted
  | MoveStudent
  | MoveStudentCompleted
  | MoveStudentFailed;
