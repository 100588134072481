import { ChangeDetectorRef, Pipe, PipeTransform } from "@angular/core";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { Environment } from "../interfaces/environment";

@Pipe({
  name: "environmentTranslate",
  pure: false,
})
export class EnvironmentTranslatePipe
  extends TranslatePipe
  implements PipeTransform {
  private langKey = (environment as Environment).langKey;

  constructor(
    private _translateService: TranslateService,
    private __ref: ChangeDetectorRef
  ) {
    super(_translateService, __ref);
  }
  transform(value: string): string {
    return super.transform(
      value.replace("$langKey", this.langKey || "DEFAULT")
    );
  }
}