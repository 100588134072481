import {
  Component,
  HostListener,
  ViewChild,
  ElementRef,
  Input,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  ChangeDetectorRef,
} from "@angular/core";

@Component({
  selector: "popup-menu",
  templateUrl: "./popup-menu.component.html",
  styleUrls: ["./popup-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupMenuComponent {
  @ViewChild("trigger", { static: true }) trigger: ElementRef;
  @ViewChild("menu", { static: true }) menu: ElementRef;

  @Output() hidden: EventEmitter<void> = new EventEmitter();

  @Input() position: "right" | "left" = "left";

  visible = false;

  constructor(private readonly cd: ChangeDetectorRef) {}

  show(): void {
    this.visible = true;
    this.cd.detectChanges();
  }

  hide(event?: MouseEvent): void {
    if (event) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }

    this.visible = false;
    this.hidden.emit();
  }

  @HostListener("document:click", ["$event"])
  onPopupClick(event: MouseEvent) {
    if (
      !this.menu.nativeElement.contains(event.target) &&
      !this.trigger.nativeElement.contains(event.target) &&
      this.visible
    ) {
      this.hide();
    }
  }
}
