<div
  class="file-upload-container"
  *ngIf="{ dragging: dragging$ | async } as fileUpload"
>
  <div
    class="file-upload-body"
    [ngClass]="{ dragover: fileUpload.dragging }"
    (click)="openFilePicker()"
    (drop)="dataDropped($event)"
    (dragover)="dragover($event)"
    (dragleave)="dragleave()"
  >
    <span class="typcn typcn-document-add"></span>
    <p class="file-upload-body-label heading-3">
      <span *ngIf="!fileUpload.dragging; else draggingText">
        {{ "IDE.UPLOAD.CLICK_TO_ADD_" + (maxFiles > 1 ? "MULTIPLE" : "SINGLE") | translate }}
      </span>
      <small class="d-block mt-4">
        {{ "IDE.UPLOAD.MAX_FILE_SIZE" | translate: { maxFileSize: maxFileSize | fileSize } }}
      </small>
      <small *ngIf="extensions.length" class="d-block mt-4">
        {{ "IDE.UPLOAD.ALLOWED_EXTENSIONS" | translate }}:
        {{ extensions | formatExtensions: { space: true } }}
      </small>
    </p>
  </div>

  <ng-container *ngIf="customFileListTemplateRef; else defaultTemplate">
    <ng-container [ngTemplateOutlet]="customFileListTemplateRef" [ngTemplateOutletContext]="{ files }"/>
  </ng-container>

  <ng-template #defaultTemplate>
    <div class="file-list-body" *ngIf="files.length">
      <h3 class="file-list-header color-hwhite">
        {{ "IDE.UPLOAD.ADDED_FILES" | translate }}
      </h3>
      <ul class="file-list">
        <li class="file-list-element" *ngFor="let file of files; let i = index">
        <span>
          {{ file.filename }}
        </span>
          <round-button
            [pure]="false"
            icon="trash"
            theme="color-hwhite"
            (action)="removeFile(i); filesAmountExceeded = false;"
          ></round-button>
        </li>
      </ul>
    </div>
  </ng-template>

  <div class="invalid-file-list" *ngIf="filesAmountExceeded">
    <validation-message>
      {{ "IDE.UPLOAD.FILE_AMOUNT" | translate: { maxFiles: maxFiles } }}
    </validation-message>
  </div>

  <div class="invalid-file-list" *ngIf="invalidFileList.length">
    <validation-message [customTemplate]="validatorTemplate">
    </validation-message>
  </div>
</div>

<input
  class="file-picker"
  type="file"
  accept="{{ extensions | formatExtensions }}"
  [multiple]="maxFiles > 1"
  (change)="getFiles($event)"
  #filePicker
/>

<ng-template #draggingText>
  {{ "IDE.UPLOAD.DROP_FILES" | translate }}
</ng-template>

<ng-template #validatorTemplate>
  <ul class="file-list">
    <li class="file-list-element" *ngFor="let file of invalidFileList; let i = index">
      <span>
        <b>{{ file.name }}</b>:
        {{ file.reason | translate: { maxFileSize: maxFileSize | fileSize } }}
      </span>
    </li>
  </ul>
</ng-template>
