import { Component, Input } from "@angular/core";
import { Course } from "../../../interfaces/course";
import { CourseListGroup } from "../../../interfaces/group";
import { Store } from "@ngrx/store";
import * as fromGroups from "../../../../store/actions/groups.actions";
import * as fromTutorial from "src/app/store/reducers/tutorial.reducer";
import { Observable } from "rxjs";
import { TutorialTeacherSteps } from "src/app/shared/enums/tutorial-steps";

@Component({
  selector: "group-listbox-element-content",
  templateUrl: "./group-listbox-element-content.component.html",
  styleUrls: ["./group-listbox-element-content.component.scss"],
})
export class GroupListboxElementContentComponent {
  @Input() showProgressBadge = true;
  @Input("teacher") isTeacher = false;
  @Input() course: Course;
  @Input() group: CourseListGroup;
  @Input() showOrganization = false;
  @Input() showCourse = false;

  tutorial$: Observable<TutorialTeacherSteps> = this.store.select(
    (state) => state.tutorial.tutorial_step,
  );

  TutorialTeacherSteps = TutorialTeacherSteps;

  constructor(
    private readonly store: Store<{ tutorial: fromTutorial.TutorialState }>,
  ) {}

  archiveGroup() {
    this.store.dispatch(new fromGroups.ArchiveGroup(this.group.id));
  }
}
