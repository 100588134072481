import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { AdminService } from "src/app/shared/services/admin/admin.service";
import {
  statsGroupLoad,
  statsGroupLoadFail,
  statsGroupLoadSuccess,
} from "../../actions/admin/stats-group.actions";
import { Guid } from "src/app/shared/types/guid";
import { StatElement } from "src/app/shared/interfaces/student";

@Injectable()
export class StatsGroupEffect {
  getStatsGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(statsGroupLoad),
      switchMap((param) => {
        return this.adminService
          .getStatsGroup(param.organizationId, param.teacherId, param.groupId)
          .pipe(
            map((stats) => {
              if (!stats.hasOwnProperty("id")) {
                const statsWithUser = Object.keys(stats).map(
                  (studentId: Guid) => ({
                    id: studentId,
                    ...stats[studentId],
                    topics: Object.keys(stats[studentId].topics).map(
                      (topicId: Guid) => {
                        return {
                          id: topicId,
                          ...stats[studentId].topics[topicId],
                          exercises: Object.keys(
                            stats[studentId].topics[topicId].exercises,
                          ).map((exerciseId: Guid) => {
                            return {
                              id: exerciseId,
                              ...stats[studentId].topics[topicId].exercises[
                                exerciseId
                              ],
                            };
                          }),
                        };
                      },
                    ),
                  }),
                );

                return {
                  stats_with_user: statsWithUser,
                  stats_without_user: null,
                };
              } else {
                return { stats_with_user: null, stats_without_user: stats };
              }
            }),
            map((stats: StatElement) => statsGroupLoadSuccess({ stats })),
            catchError(({ error }) => of(statsGroupLoadFail(error))),
          );
      }),
    ),
  );

  constructor(
    private adminService: AdminService,
    private actions$: Actions,
  ) {}
}
