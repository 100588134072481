<div class="pills-input">
    <pills>
      <li
        pill
        *ngFor="let value of values; let i = index"
        text="{{ value }}"
        [showDelete]="true"
        (onDelete)="removeValue($event, i)"
      ></li>
    </pills>

    <div class="input-container">
      <textarea
        class="pill-source"
        [ngClass]="{ 'parse-error': hasError }"
        [disabled]="isDisabled"
        #pillSource
      ></textarea>
      <div class="textarea pill-source-clone">
        <span #sourceClone></span>
        <span class="virtual-caret" #virtualCaret></span>
      </div>

      <div
        class="hints-container"
        [ngStyle]="hintsPositionStyle"
        [ngClass]="{'d-none': !useHints || !hintsVisible || isPristine}">
            <ul
                class="hints"
                [ngClass]="{'d-none': !hints}"
                #hintsList>
                <li
                    class="hint"
                    *ngFor="let hint of hints"
                    tabindex="-1"
                    (click)="parseHint(hint.value)">
                    <ng-container
                        [ngTemplateOutlet]="customHintContent || hintContent"
                        [ngTemplateOutletContext]="{$implicit: hint}"
                    ></ng-container>
                </li>
            </ul>
            <loading-spinner [ngClass]="{'d-none': hints}"></loading-spinner>
        </div>
    </div>

    <p *ngIf="hasError" class="label color-hinvalid validation-text">
      {{ validationMessage | translate }}
    </p>
  </div>

  <ng-template #loading>
  </ng-template>

  <ng-template #hintContent let-hint>
    {{hint.value}}
  </ng-template>
