<h1 class="logo">
  <ng-container *ngIf="logoLink$ | async as path; else disabledRedirect">
    <a [routerLink]="[path]" class="logo-anchor">
      <img
        class="brand-img d-block"
        src="assets/images/logo/brand.svg"
        alt="{{ 'GLOBAL.NAVBAR.APP' | translate }}"
      />
      <span class="title">{{ "GLOBAL.NAVBAR.APP" | translate }}</span>
    </a>
  </ng-container>
  <ng-template #disabledRedirect>
    <div class="logo-anchor">
      <img
        class="brand-img d-block"
        src="assets/images/logo/brand.svg"
        alt="{{ 'GLOBAL.NAVBAR.APP' | translate }}"
      />
      <span class="title">{{ "GLOBAL.NAVBAR.APP" | translate }}</span>
    </div>
  </ng-template>
</h1>
