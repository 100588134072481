import { Component, Input } from "@angular/core";

@Component({
  selector: "group-teacher-name",
  templateUrl: "./group-teacher-name.component.html",
  styleUrls: ["./group-teacher-name.component.scss"],
})
export class GroupTeacherNameComponent {
  @Input() name: string;
  @Input() label = "GROUPS.LIST.LEAD";
}
