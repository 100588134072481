import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { VideoPlayer } from "../../models/video-player";

// Renders video player(s) based on presence of video tags
@Directive({
  selector: "[customVideoPlayer]",
})
export class CustomVideoPlayerDirective implements AfterViewInit, OnDestroy {
  @Input() customVideoPlayerObserve: string | undefined;

  private observer: MutationObserver;
  private videoPlayers: Array<VideoPlayer> = [];

  constructor(
    private ref: ElementRef<Element>,
    private translate: TranslateService,
  ) {}

  ngAfterViewInit(): void {
    this.initVideoPlayers();
    this.startObserve();
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
    this.clearVideoPlayers();
  }

  private startObserve(): void {
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === "childList") {
          this.clearVideoPlayers();
          this.initVideoPlayers();
        }
      });
    });

    this.observer.observe(
      this.customVideoPlayerObserve
        ? this.ref.nativeElement.querySelector(this.customVideoPlayerObserve)
        : this.ref.nativeElement.childNodes[0],
      {
        characterData: true,
        characterDataOldValue: true,
        childList: true,
      },
    );
  }

  private clearVideoPlayers(): void {
    this.videoPlayers.forEach((videoPlayer) => {
      videoPlayer.disposePlayer();
    });

    this.videoPlayers = [];
  }

  private initVideoPlayers(): void {
    const videoElements: Array<HTMLVideoElement> = Array.from(
      this.ref.nativeElement.querySelectorAll("video"),
    );

    this.videoPlayers = videoElements.map((videoElement) => {
      const player = new VideoPlayer();
      player.initPlayer(videoElement, this.translate.currentLang);

      return player;
    });
  }
}
