import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { PickerOption } from "../../interfaces/picker-option";

@Component({
  selector: "list-picker",
  templateUrl: "./list-picker.component.html",
  styleUrls: ["./list-picker.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListPickerComponent {
  @Input() value: any;
  @Input() options: Array<PickerOption> = [];
  @Input() icon: string;
  @Input() disabled: boolean = false;
  @Input() optionTemplate: TemplateRef<any>;
  @Output() picked: EventEmitter<PickerOption> = new EventEmitter();

  @ViewChild("pickerContainer") container: ElementRef;
  @ContentChild("customLabel") customLabel: ElementRef;

  opened = false;

  constructor() {}

  pick(option: PickerOption) {
    this.picked.emit(option);
    this.close();
  }

  open() {
    if (!this.disabled) {
      this.opened = true;
    }
  }

  close() {
    this.opened = false;
  }

  toggle() {
    if (!this.opened) {
      this.open();
    } else {
      this.close();
    }
  }

  get valueLabel(): string {
    return (
      this.options.find((option) => option.value === this.value) || {
        label: "",
      }
    ).label;
  }

  get valueIcon(): string {
    return (
      this.options.find((option) => option.value === this.value) || {
        icon: "",
      }
    ).icon;
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    if (!this.container.nativeElement.contains(event.target)) {
      this.close();
    }
  }
}
