import { Component, OnInit } from "@angular/core";
import { ModalComponent } from "../../modal/modal.component";
import * as fromUI from "src/app/store/actions/ui.actions";
import * as fromUIStore from "src/app/store/reducers/ui.reducer";
import { Store } from "@ngrx/store";
import { VPNPassword } from "src/app/shared/interfaces/vpn-password";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";

@Component({
  selector: "vpn-configuration-modal",
  templateUrl: "./vpn-configuration-modal.component.html",
  styleUrls: ["./vpn-configuration-modal.component.scss"],
})
export class VpnConfigurationModalComponent
  extends ModalComponent
  implements OnInit
{
  vpnPassword$: Observable<VPNPassword> = this.store.select(
    (state) => state.ui.vpnPassword
  );

  getVpnPasswordCompleted$: Observable<boolean> = this.store.select(
    (state) => state.ui.getVPNPasswordCompleted
  );

  vpnPasswordToggled: boolean = false;
  vpnPasswordCopied: boolean = false;

  constructor(private store: Store<{ ui: fromUIStore.UIState }>) {
    super();
  }

  ngOnInit() {
    this.getVPNPassword();
  }

  connectToLab(event: MouseEvent) {
    event.preventDefault();
    this.store.dispatch(new fromUI.ConnectToLab());
  }

  closeVPNConfig() {
    this.close.emit("close");
  }

  copyToClipboard(password: string) {
    navigator.clipboard.writeText(password).then(() => {
      this.vpnPasswordCopied = true;
    });
  }

  private getVPNPassword() {
    this.store.dispatch(new fromUI.GetVPNPassword());
  }
}
