import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
  name: "date",
})
export class DatePipe implements PipeTransform {
  transform(
    value: string | moment.Moment,
    args: { format: string } = { format: "DD-MM-YYYY H:mm:ss" }
  ): any {
    return moment(value).format(args.format);
  }
}
