<div class="select-with-placeholder d-flex w-100 no-gutters">
  <div class="input-body col">
    <input
      *ngIf="placeholder"
      tabindex="-1"
      type="text"
      class="select-placeholder"
      value="{{ placeholder | translate }}"
      readonly
    />
    <ng-content></ng-content>
  </div>
</div>
