import { Component, EventEmitter, Input, Output } from "@angular/core";
import { WebSocketMessageType } from "src/app/shared/types/websocket-message-type";

@Component({
  selector: "li[setting-group]",
  template: `
    <input
      type="checkbox"
      [id]="name"
      [ngModel]="value"
      (ngModelChange)="onChange($event)"
    />
    <label
      class="checkbox settings-checkbox"
      [for]="name"
      [innerHTML]="'GLOBAL.NOTIFICATIONS.SETTINGS.TYPES.' + name | translate"
    >
    </label>
  `,
  styleUrls: ["./setting-group.component.scss"],
})
export class SettingGroupComponent {
  @Input() appliesTo: Array<WebSocketMessageType>;
  @Input() value: boolean;
  @Input() name: string;
  @Output() valueChanged: EventEmitter<{
    value: boolean;
    appliesTo: Array<WebSocketMessageType>;
  }> = new EventEmitter();

  constructor() {}

  onChange(value: boolean) {
    this.valueChanged.emit({ value, appliesTo: this.appliesTo });
  }
}
