import { Component } from '@angular/core';

@Component({
  selector: 'li[simple-list-item], simple-list-item',
  template: `
    <ng-content></ng-content>
  `,
  host: {
    class:
      'row no-gutters align-items-center justify-content-between flex-wrap flex-md-nowrap'
  }
})
export class SimpleListItemComponent {
  constructor() {}
}
