<div class="surprise-box-container" 
    [attr.style]="sanitizer.bypassSecurityTrustStyle('--size: ' + size + 'px')">
    <div class="surprise-box">
  
      <div class="wall wall-right"></div>
      <div class="wall wall-back"></div>
      <div class="bright-container">
        <div class="bright"></div>
      </div>
      <div class="wall wall-left"></div>
      <div class="wall wall-front">
        <img class="logo" src="assets/images/logo/logo.svg" alt="CyberSkiller" />
      </div>
    </div>
  </div>