<div
  class="chip"
  [style.background-color]="(statusValue | colorStatus).backgroundColor"
  [style.color]="(statusValue | colorStatus).color"
>
  <span
    class="dot"
    [style.background-color]="(statusValue | colorStatus).dot"
  ></span>
  <span>{{ (statusValue | colorStatus).value | translate }}</span>
</div>
