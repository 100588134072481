import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ListboxEmptyVariant } from "../../../types/listbox-empty-variant";

@Component({
  selector: "group-listbox-empty",
  templateUrl: "./group-listbox-empty.component.html",
  styleUrls: ["./group-listbox-empty.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupListboxEmptyComponent {
  @Input() showIcon = false;
  @Input() variant: ListboxEmptyVariant;
}
