import { Injectable } from "@angular/core";

import { Observable, of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { AccountService } from "src/app/shared/services/account/account.service";

@Injectable({
  providedIn: "root",
})
export class LoginLoadGuard  {
  constructor(private accountService: AccountService) {}

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return this.accountService.checkIsLoggedIn().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }
}
