<div class="panel-container">
  <div 
    class="d-flex justify-content-between" 
    (click)="toggle()"
    >
      <p class="heading-4 panel-container__header">{{ title }}</p>
      <span
        class="typcn typcn-chevron-right panel-container__icon"
        [ngClass]="{ toggled: isToggled }"
      >
      </span>
  </div>
  <div 
    *ngIf="isToggled" 
    class="panel-container__desc"
    >
      <rich-text [content]="content" />
  </div>
</div>
