import { Injectable } from "@angular/core";
import { ApiService } from "../api/api.service";
import { Observable, of } from "rxjs";
import { NotificationsResults } from "../../interfaces/notifications-results";
import { Guid } from "../../types/guid";
import { NotificationElement } from "../../interfaces/notification-element";
import { NotificationToastComponent } from "../../components/notifications/notification-toast.component";
import { ToastrService } from "ngx-toastr";
import { Howl } from "howler";
import { NotificationSettings } from "../../types/notification-settings";
import { fromJS } from "immutable";
import {
  WebSocketMessageAccessConfig,
  WebSocketMessageAccessConfigType,
  WebSocketMessageType,
} from "../../types/websocket-message-type";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  soundAsset: string = "/assets/sounds/notification";
  notificationSound: Howl;

  constructor(private apiService: ApiService, private toastr: ToastrService) {
    this.notificationSound = new Howl({
      src: [
        `${this.soundAsset}.ogg`,
        `${this.soundAsset}.mp3`,
        `${this.soundAsset}.wav`,
      ],
    });
  }

  getNotifications(page: number): Observable<NotificationsResults> {
    return this.apiService.getRequest<NotificationsResults>(
      `notifications/?page=${page}&per_page=5`
    );
  }

  markAsRead(id: Guid): Observable<NotificationElement> {
    return this.apiService.putRequest<NotificationElement>(
      `notifications/${id}/`,
      {}
    );
  }

  markAllAsRead(): Observable<void> {
    return this.apiService.putRequest<void>(`notifications/mark_all_read/`, {});
  }

  loadSettings(
    accessMember: "teacher" | "student",
    userId: Guid
  ): Observable<NotificationSettings | null> {
    let notificationSettings = fromJS(
      JSON.parse(
        window.localStorage.getItem(
          `cyber-skiller-notification-settings-${userId}`
        )
      )
    ) as unknown as NotificationSettings | null;

    let defaultSettings = this.createSettings(accessMember);

    if (!notificationSettings) {
      notificationSettings = defaultSettings;
    }

    defaultSettings
      .get("notificationTypeSettings")
      .keySeq()
      .toArray()
      .forEach((type: WebSocketMessageType) => {
        if (
          !notificationSettings
            .get("notificationTypeSettings")
            .toJS()
            .hasOwnProperty(type)
        ) {
          notificationSettings = notificationSettings.setIn(
            ["notificationTypeSettings", type],
            true
          );
        }
      });

    return of(notificationSettings);
  }

  saveSettings(data: { settings: NotificationSettings; userId: Guid }): void {
    window.localStorage.setItem(
      `cyber-skiller-notification-settings-${data.userId}`,
      JSON.stringify(data.settings.toJS())
    );
  }

  showToastr(notification: NotificationElement): NotificationToastComponent {
    let toaster = this.toastr.info("", null, {
      toastComponent: NotificationToastComponent,
      toastClass: "cyberskiller-toast",
      positionClass: "toast-bottom-right",
      timeOut: 15000,
    });

    toaster.portal.instance.notification = notification;
    this.notificationSound.play();

    return toaster.portal.instance;
  }

  createSettings(accessMember: "teacher" | "student"): NotificationSettings {
    return fromJS({
      notificationTypeSettings: Object.entries(WebSocketMessageAccessConfig)
        .filter(([name, access]: [string, WebSocketMessageAccessConfigType]) =>
          ["both", accessMember].includes(access)
        )
        .reduce((acc, [name], index, array) => {
          return { ...acc, [name]: true };
        }, {}),
      notificationSnooze: {
        period: undefined,
      },
    }) as unknown as NotificationSettings;
  }
}
