import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ModalComponent } from "../../modal/modal.component";

@Component({
  selector: "vpn-connection-modal",
  templateUrl: "./vpn-connection-modal.component.html",
  styleUrls: ["../warning-modal/warning-modal.component.scss"],
})
export class VpnConnectionModalComponent extends ModalComponent {
  text: string = "GLOBAL.MESSAGES.VPN_CONNECTION_REMIND";

  constructor(private router: Router) {
    super();
  }

  dismiss(): void {
    this.close.emit("close");
  }

  help(): void {
    this.router.navigate([
      `/${this.isTeacher ? "teacher" : "student"}/courses/onboarding/`,
    ]);

    this.dismiss();
  }

  get isTeacher(): boolean {
    return this.router.url.includes("teacher");
  }
}
