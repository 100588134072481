import { createReducer, on } from "@ngrx/store";
import { HttpErrorResponse } from "@angular/common/http";
import {
  deleteTeamTeacherLoad,
  deleteTeamTeacherLoadFail,
  deleteTeamTeacherLoadSuccess,
} from "../../actions/admin/delete-team-teacher.action";

export interface DeleteTeamTeachersState {
  error: HttpErrorResponse | null;
}

const initialState: DeleteTeamTeachersState = {
  error: null,
};

export const deleteTeamTeachersReducer = createReducer(
  initialState,
  on(
    deleteTeamTeacherLoad,
    (state): DeleteTeamTeachersState => ({
      ...state,
      error: null,
    }),
  ),
  on(
    deleteTeamTeacherLoadSuccess,
    (state): DeleteTeamTeachersState => ({
      ...state,
      error: null,
    }),
  ),
  on(
    deleteTeamTeacherLoadFail,
    (state, { error }): DeleteTeamTeachersState => ({
      ...state,
      error,
    }),
  ),
);
