import { Component, Input } from "@angular/core";
import { CourseLimit } from "src/app/shared/interfaces/limit";

@Component({
  selector: "organization-limit-widget",
  templateUrl: "./organization-limit-widget.component.html",
  styleUrls: ["./organization-limit-widget.component.scss"],
})
export class OrganizationLimitWidgetComponent {
  @Input() limits: Array<CourseLimit> = [];
}
