<ng-container *ngIf="elementsCount > 0; else noElementsTemplateRef">
  <ng-content />
</ng-container>

<ng-template #noElementsTemplateRef>
  <ng-container *ngIf="noContentTemplateRef; else defaultNoContentTemplateRef">
    <ng-container [ngTemplateOutlet]="noContentTemplateRef" />
  </ng-container>

  <ng-template #defaultNoContentTemplateRef>
    <listbox-empty>
      <ng-container text>
        {{ "GLOBAL.NO_DATA_TO_SHOW" | translate }}
      </ng-container>
    </listbox-empty>
  </ng-template>
</ng-template>
