import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Nillable } from "../../types/nillable";
import { isNil } from "lodash-es";

@Component({
  selector: "progress-bar",
  templateUrl: "./progress-bar.component.html",
  styleUrls: ["./progress-bar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
  @Input() value: Nillable<number>;
  @Input() showValue: boolean;
  @Input() color: Nillable<string>;
  @Input() unit: string = "%";

  get labelDisplayClass(): string {
    if (!isNil(this.value) && this.value !== 0) {
      return "flex";
    }

    return "none";
  }
}
