<div [class.disabled]="isDisabled" class="card-container">
  <h5 class="title-color">
    <span [tooltip]="course.title">{{ course.title }}</span>
  </h5>
  <p class="pb-3 pt-4">
    <span>{{ course.sub_title }}</span>
  </p>
  <div class="d-flex flex-row align-items-center">
    <p class="mr-3">{{ "ADMIN.ACTIVE_USERS" | translate }}:</p>
    <h3 class="subtitle-color">
      {{
        course.max_limits !== "0"
          ? course.used_limits + "/" + course.max_limits
          : course.used_limits
      }}
    </h3>
  </div>
</div>
