import { HttpClient } from "@angular/common/http";
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { Lang } from "../../interfaces/lang";

@Component({
  selector: "language-picker",
  templateUrl: "./language-picker.component.html",
  styleUrls: ["./language-picker.component.scss"],
})
export class LanguagePickerComponent implements OnInit {
  @Input() buttonLayoutTemplateRef: TemplateRef<{
    currentLang: Lang;
    isToggled: boolean;
    toggleClickFn: (event: MouseEvent) => void;
  }>;
  @Input() langsLayoutTemplateRef: TemplateRef<{
    langs: Array<Lang>;
    langChangeFn: (lang: string) => void;
  }>;

  @Output() onLangPick: EventEmitter<string> = new EventEmitter<string>();

  langs: Array<Lang> = [{ name: "en", icon: "GB.png" }];
  langsUpdated = false;
  isToggled = false;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private ref: ElementRef,
    private cookieService: CookieService,
  ) {}

  ngOnInit() {
    this.getLangList();
  }

  setLang(name: string) {
    this.translate.use(name);
    this.isToggled = false;
    this.cookieService.set(
      "cyber-skiller-lang",
      name,
      365,
      "/",
      undefined,
      undefined,
      "Strict",
    );
    this.onLangPick.emit(name);
  }

  getCurrentLang() {
    let currentLang = this.translate.currentLang;
    return (
      this.langs.find((lang: Lang) => currentLang === lang.name) ||
      this.langs[0]
    );
  }

  toggleList(event: MouseEvent) {
    event.preventDefault();
    this.isToggled = !this.isToggled;
  }

  @HostListener("document:click", ["$event"])
  onDocumentClick(event: MouseEvent) {
    if (!this.ref.nativeElement.contains(event.target)) {
      this.isToggled = false;
    }
  }

  private getLangList() {
    this.http
      .get<{ langs: Array<Lang> }>("assets/i18n/langs.json")
      .subscribe((result: { langs: Array<Lang> }) => {
        this.translate.addLangs(result.langs.map((lang) => lang.name));
        this.langs = result.langs;
      });
  }
}
