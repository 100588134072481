import { Component, Input } from "@angular/core";
import { ExerciseStatus } from "../../../enums/exercise-status";

@Component({
  selector: "course-exercise-stat",
  template: `
    <div class="exercise-stat align-items-center row no-gutters">
      <div class="exercise-name col-12 col-md-6 d-flex align-items-center">
        <exercise-status [status]="status()" />
        <h6>{{ name }}</h6>
      </div>
      <div class="col-12 col-md-6 completed-date" *ngIf="isCompleted">
        <p>
          {{ completedDate }}
        </p>
      </div>
    </div>
  `,
  styleUrls: ["./course-exercise-stat.component.scss"],
})
export class CourseExerciseStatComponent {
  @Input() name = "";
  @Input() isCompleted: string | null = null;
  @Input() completedDate = "";

  status(): ExerciseStatus {
    if (this.isCompleted !== null) {
      return ExerciseStatus.COMPLETED;
    }

    return ExerciseStatus.UNCOMPLETED;
  }
}
