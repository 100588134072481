<div role="group" class="select-button-container">
  <ng-container *ngFor="let option of options; let i = index">
    <button
      class="select-button"
      [class.select-button--highlight]="isSelected(option)"
      (click)="handleOptionSelect($event, option, i)"
    >
      <span> {{ option.label | translate }} </span>
    </button>
  </ng-container>
</div>
