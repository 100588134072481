<div class="notifications-icon" *ngIf="tutorial$ | async as tutorial">
  <div
    [hover]
    class="notifications-icon-body"
    (click)="toggleMenu()"
    [ngClass]="{ active: isActive }"
  >
    <div
      class="icon d-flex align-items-center justify-content-center"
      [ngClass]="{
        'tutorial-border':
          (isTeacher$ | async)
            ? tutorial.tutorial_step === TutorialTeacherSteps.NOTIFICATIONS_BTN
            : tutorial.tutorial_step === TutorialSteps.NOTIFICATIONS_BTN
      }"
    >
      <span
        class="uil"
        [ngClass]="{
          'uil-bell': !isSnoozed && !isAllTurnedOff,
          'uil-bell-slash': isSnoozed || isAllTurnedOff
        }"
      ></span>

      <span
        class="notifications-counter"
        *ngIf="unreadNotificationsCount$ | async"
      >
        {{ unreadNotificationsCount$ | async }}
      </span>
    </div>
  </div>

  <notifications-panel
    [ngStyle]="{ display: isActive ? 'block' : 'none' }"
    [active]="isActive"
    (onPanelNotify)="closePanel()"
  />

  <tutorial-text-box
    *ngIf="
      (isTeacher$ | async)
        ? tutorial.tutorial_step === TutorialTeacherSteps.NOTIFICATIONS_BTN
        : tutorial.tutorial_step === TutorialSteps.NOTIFICATIONS_BTN
    "
    [customStyle]="{
      top: '80px',
      right: '-10px',
      width: '350px'
    }"
    [title]="'TUTORIAL_DATA.NOTIFY_TITLE' | translate"
    [message]="'TUTORIAL_DATA.NOTIFY_MESSAGE' | translate"
    [step]="
      (isTeacher$ | async)
        ? TutorialTeacherSteps.NOTIFICATIONS_BTN
        : TutorialSteps.NOTIFICATIONS_BTN
    "
  />
</div>
