import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { Exercise } from "../../../interfaces/exercise";
import { Nillable } from "../../../types/nillable";
import { UploadStatus } from "./enums/upload-status";
import { isNil } from "lodash-es";
import { UploadedFileModel } from "./interfaces/uploaded-file-model";
import { Guid } from "../../../types/guid";

@Component({
  selector: "course-exercise-student-upload",
  templateUrl: "./course-exercise-student-upload.component.html",
  styleUrls: ["./course-exercise-student-upload.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CourseExerciseStudentUploadComponent {
  @Output() uploadEvent = new EventEmitter<void>();
  @Output() clearEvent = new EventEmitter<void>();
  @Output() downloadEvent = new EventEmitter<{ id: Guid; filename: string }>();

  @Input({ required: true }) exercise!: Exercise;
  @Input() file: Nillable<UploadedFileModel>;

  UploadStatus = UploadStatus;

  get status(): UploadStatus {
    if (isNil(this.file)) {
      return UploadStatus.New;
    }

    const { filename, review, id } = this.file;

    if (filename && !isNil(id) && review === null) {
      return UploadStatus.Sent;
    }

    if (filename && !isNil(id) && review === false) {
      return UploadStatus.Failed;
    }

    if (filename && isNil(review)) {
      return UploadStatus.Preloaded;
    }

    return UploadStatus.Sent;
  }

  handleUploadFileClick(): void {
    this.uploadEvent.emit();
  }

  handleUploadFileClickForPreloaded(): void {
    this.clearEvent.emit();
    this.uploadEvent.emit();
  }

  handleClearClick(): void {
    this.clearEvent.emit();
  }

  handleDownloadClick(): void {
    const { id, filename } = this.file;

    this.downloadEvent.emit({ id, filename });
  }
}
